<script>
import CreativeTraits from '@master/Traits/CreativeTraits.vue';

export default {
  mixins: [CreativeTraits],

  props: {
    creative: Object,
  },

  methods: {
    open(event, button = false) {
      if (this.isProcessing()) {
        event.preventDefault();
      } else if (button) {
        this.$router.push(this.creative.path).catch(_ => {
          /** supress duplicate route errors */
        });
      }
    },
  },
};
</script>
