<template lang="pug">
Modal(title="Enable Two-Factor Authentication" size="modal-sm" :loading="loading")
  template(v-slot:body)
    ol.pl-16
      li.color-gray-800
        h3 Download an authenticator app.
        span.fs-14 We recommend&nbsp;
          a(:href="google_auth_url" target="_blank") Google Authenticator
          span ,&nbsp;
          a(:href="ms_auth_url" target="_blank") Microsoft Authenticator
          span .
      li.mt-24.color-gray-800
        h3 Scan the QR code with your app.
        div(v-if="try_loading_again")
          Button(type="link" label="Try loading QR Code again." :disabled="loading" @click="requestMFAQrCode")
        div.mt-8.p-16.bg-gray-100.inline-block.border-radius(v-else-if="mfa_qr != null")
          img(:src="mfa_qr" alt="QR Code" width="172" height="172")
      li.mt-24.color-gray-800
        h3 Enter the code generated by your authenticatior app.
        form(@submit.prevent="verify")
          Input.mt-8(v-model="mfa_code" placeholder="Enter the code")
  template(v-slot:footer)
    Buttons
      Button(type="link-primary" label="Cancel" :disabled="verifing" @click="close")
      Button(type="success" label="Verify code" :loading="verifing" :submit="true")
</template>

<script>
import { b64toBlob } from '@helpers/Global';

import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';
import Input from '@master/UI/Input/Input.vue';
import Modal from '@master/Modals/Modal.vue';

export default {
  name: 'Enable2FAModal',

  components: {
    Button,
    Buttons,
    Input,
    Modal,
  },

  computed: {
    google_auth_url() {
      if (this.mac_user) {
        return 'https://apps.apple.com/us/app/google-authenticator/id388497605';
      }
      return 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2';
    },

    ms_auth_url() {
      if (this.mac_user) {
        return 'https://apps.apple.com/us/app/microsoft-authenticator/id983156458';
      }
      return 'https://play.google.com/store/apps/details?id=com.azure.authenticator';
    },
  },

  data() {
    return {
      mfa_qr: null,
      mfa_code: '',
      try_loading_again: false,
      loading: false,
      verifing: false,
      mac_user: navigator.userAgent.indexOf('Mac OS X') !== -1,
    };
  },

  created() {
    this.requestMFAQrCode();
  },

  methods: {
    close() {
      this.$emit('close');
    },

    requestMFAQrCode() {
      if (this.loading) {
        return;
      }

      this.try_loading_again = false;
      this.loading = true;

      this.$http
        .post('mfa')
        .then(response => {
          this.mfa_qr = window.URL.createObjectURL(b64toBlob(response.b64, response.type));
        })
        .catch(() => {
          this.try_loading_again = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    verify() {
      if (this.verifing) {
        return;
      }

      this.verifing = true;

      this.$http
        .put('mfa', { code: this.mfa_code })
        .then(response => {
          this.$user.set({ mfa_enabled: response.mfa_enabled });

          if (response.mfa_enabled) {
            this.close();
            this.$alert('Two-factor authentication successfully added.', 'Account secured', {
              image: 'success',
            });
          }
        })
        .catch(_ => {
          /** error msg handled by httpservice */
        })
        .finally(_ => {
          this.verifing = false;
          this.mfa_code = '';
        });
    },
  },
};
</script>

<style scoped>
li::marker {
  font-weight: 500;
}
</style>
