<template lang="pug">
div
  div(:class="styles.header")
    div(:class="{[flight_status]: true, [styles.status]: true}" v-tooltip="{value: flight_status_label}")
    div(:class="styles.input")
      input(ref="input" type="text" v-model="flight.name" @change="change")

  div(:class="styles.details" v-if="dsp_options != null && flight?.settings?.dsps != null")
    section
      label Export tags for:
        i.nexd-icon-32-help(aria-hidden="true" v-tooltip="{template: 'cm.platform'}")
      SearchSelectMultiple(:value="flight.settings.dsps" placeholder="Select Platform(s)" :options="dsp_options" @change="changeDsps")

    NotificationBox.self-justify-end(v-if="is_dco" :info="tooltip" width="300px")
</template>

<script>
import styles from './FlightHeader.module.scss';

import DSPService from '@master/Services/Cache/DSPService';
import FlightService from '@master/Services/FlightService';

import FlightTraits from '@master/Traits/FlightTraits.vue';
import Filters from '@master/Filters';
import { DSP, STATUS_CLASS, FLIGHTS } from '@master/constants';

import NotificationBox from '@master/UI/NotificationBox/NotificationBox.vue';
import SearchSelectMultiple from '@master/UI/SearchSelect/SearchSelectMultiple.vue';

export default {
  name: 'FlightHeader',
  mixins: [FlightTraits],

  components: {
    NotificationBox,
    SearchSelectMultiple,
  },

  computed: {
    flight_status() {
      if (this.isDraft()) return this.styles[STATUS_CLASS.DRAFT];
      if (this.isWaiting()) return this.styles[STATUS_CLASS.PENDING];
      if (this.isPaused()) return this.styles[STATUS_CLASS.PAUSED];
      if (this.isLive()) return this.styles[STATUS_CLASS.LIVE];
      if (this.isFinished()) return this.styles[STATUS_CLASS.FINISHED];
      if (this.isArchived() || this.isDuplicationInProgress() || this.isImportInProgress()) return this.styles[STATUS_CLASS.ARCHIVED];
      return '';
    },

    flight_status_label() {
      return Filters.StatusFilter(this.flight.status, false);
    },

    is_dco() {
      return this.flight?.type === FLIGHTS.TYPE.TYPE_DCO;
    },
  },

  data() {
    return {
      styles,

      flight: null,

      dsp_options: null,
      tooltip: `
        Calculations are updated every 6 hours.
        Please note that before the first optimization occurs,
        the campaign must accumulate a minimum of 10,000 impressions.
      `,
    };
  },

  created() {
    DSPService.subscribe(dsps => {
      if (dsps != null) {
        this.dsp_options = dsps.reduce((dsp_options, dsp) => {
          if (dsp?.slug === DSP.GOOGLEADS) {
            return dsp_options;
          }

          dsp_options[dsp.slug] = dsp.title;
          return dsp_options;
        }, {});
      }
    }, this);

    FlightService.subscribe(data => {
      this.flight = data?.flight;
    }, this);
  },

  methods: {
    change() {
      FlightService.update(this.flight);
    },

    async changeDsps(dsps) {
      this.flight.settings.dsps = dsps;
      this.change();

      if (!this.isExported()) {
        return;
      }

      const confirm = await this.$confirm('These changes require a new tag', "To implement these changes, please generate a new tag file and update the file on the publisher's side.", {
        buttons: [
          {
            type: 'link-primary',
            label: 'Cancel',
            action: false,
          },
          {
            type: 'primary',
            label: 'Generate new tag',
            action: true,
          },
        ],
      });

      if (confirm) {
        FlightService.publish();
      }
    },
  },
};
</script>
