import { alert } from '@libs/alerts';
import { searchLongStringsAndBreak } from '@helpers/Global';

/**
 * @param {string[] | Record<string, string>[]} not_accepted_assets - Array of duplicated assets names
 * @param {string[] | Record<string, string>[]} api_upload_erros - Array of errors from API
 * @returns {Promise<boolean>} - Returns false by default
 */
export function showAssetUploadErrors(not_accepted_assets = [], api_upload_erros = []) {
  let message;

  if (not_accepted_assets?.length > 0) {
    message = `The following files are not supported:<br>${serializeErrorItems(not_accepted_assets)}.<br>Please check the list of permitted file types and try again.`;
  } else if (api_upload_erros?.length > 0) {
    message = `There was an error uploading the assets:<br>${serializeErrorItems(api_upload_erros)}`;
  } else {
    message = 'Sorry, something went wrong.';
  }

  return alert(undefined, message, 'Oops! Upload error.');
}

function serializeErrorItems(errors) {
  return errors.map(error => (typeof error === 'string' ? searchLongStringsAndBreak(error) : searchLongStringsAndBreak(error?.name))).join(', ');
}
