<template lang="pug">
ListItem(
  v-if="to != null"
  :classes="classes"
  :draggable="true"
  v-bind="$props"
  v-on="$listeners"
)
  slot
div(
  v-else
  :class="classes"
  :draggable="true"
  @dragstart.stop="$emit('dragstart', $event)"
  @drag="$emit('drag', $event)"
  @dragend="$emit('dragend', $event)"
  @drop="$emit('drop', $event)"
  @dragover="$emit('dragover', $event)"
  @dragenter="$emit('dragenter', $event)"
  @dragleave="$emit('dragleave', $event)"
  @click="$emit('click', $event)"
)
  slot
</template>

<script>
import ListItem from '@cm/UI/Global/ListItem.vue';

export default {
  name: 'GroupAssetContainer',

  components: {
    ListItem,
  },

  props: {
    ...ListItem.props,

    to: {
      type: [String, Object],
      required: false,
      default: () => null,
    },
  },
};
</script>
