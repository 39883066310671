<template lang="pug">
div.social-import(:id="is_import ? 'creative-list-item' : ''")
  template(v-if="is_import && creative")
    CreativeName(:creative="creative" :is_new="true")
    CardDivider

    //- creatives's type and size selection
    CreativeDetails(
      ref="creative-details"
      :creative="creative"
      :is_new="true"
      @createNewCreative="createNewCreative"
    )
    CardDivider

  div.social-import-login
    img(:src="`${$cdn}dist/assets/import-in.svg`" alt="import-in")
    div.flex-grow.text-center.pl-32.pr-32
      div.fs-18 Import from Social
      div.color-gray-800.mt-12
        span(v-if="facebook_connected") You are connected. You can now choose a post you want to import or enter a link to the Facebook post instead.
        span(v-else) Connect to Facebook. You need an advertiser account and rights on Facebook to choose your post for importing.
      FacebookConnect.mt-24(ref="facebook" @tokenReceived="tokenReceived" @error="notify")
    img(:src="`${$cdn}dist/assets/import-in.svg`" alt="import-in")

  div.social-import-create
    template(v-if="facebook_connected")
      Button.mb-32(type="primary" label="Choose post" :animate="true" @click="openFBImportModal")
      Divider(text="OR" style="--border-color: #e5ecf5")
      ExternalImport(@select="importFacebookPost")
      Divider(text="OR" style="--border-color: #e5ecf5")
    template(v-else)
      Divider(text="OR" style="--border-color: #e5ecf5")
    Button.mt-24(type="primary" :outline="true" label="Skip Import" @click="skip")
    section.mt-16.text-center.fs-12.color-cyan-blue ... and create a lookalike!

  Buttons.mr-24.mb-16
    Button(type="link-primary" label="Cancel" @click="cancel")

  FbPageModal(
    v-if="show_facebook_import_modal && facebook_connected"
    :token="user_long_living_token"
    @cancel="closeFBImportModal"
    @select="importFacebookPost"
  )

  LoadingModal(
    v-else-if="loading"
    head="Importing social banner"
    body="Please wait while files are uploaded and optimized for better performance"
  )
</template>

<script>
import { newCreative } from '@helpers/Creative';
import TemplatesService from '@master/Services/TemplatesService';
import CreativeSizeService from '@master/Services/CreativeSizeService';

import Divider from '@master/UI/Divider/Divider.vue';
import FacebookConnect from '@cm/Views/Creatives/Cards/Social/FacebookConnect.vue';
import ExternalImport from '@cm/Views/Creatives/Cards/Social/ExternalImport.vue';
import FbPageModal from '@cm/Views/Creatives/Modals/FbPageModal.vue';
import CreativeName from '@cm/Views/Creatives/Creative/CreativeName.vue';
import CreativeDetails from '@cm/Views/Creatives/Creative/CreativeDetails.vue';

import LoadingModal from '@master/UI/LoadingModal.vue';
import CardDivider from '@master/UI/Divider/CardDivider.vue';
import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';

import { TEMPLATE } from '@master/constants';

export default {
  name: 'SocialImport',

  props: {
    // if component is used as import then true, but when used as choose layout when creative is created then false.
    is_import: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    Divider,
    FacebookConnect,
    ExternalImport,
    FbPageModal,
    CreativeName,
    CreativeDetails,
    LoadingModal,
    CardDivider,
    Button,
    Buttons,
  },

  computed: {
    disable_create() {
      return this.creative?.width === 0 || this.creative?.height === 0 || this.size_warning;
    },
  },

  data() {
    return {
      creative: null,
      loading: false,
      facebook_connected: false,
      user_long_living_token: null,
      show_facebook_import_modal: false,
      import_url: '',
      size_warning: false,
    };
  },

  created() {
    CreativeSizeService.subscribe(state => {
      this.size_warning = state?.has_warning ?? false;
    }, this);

    newCreative().then(creative => (this.creative = creative));
  },

  methods: {
    tokenReceived({ token, connected }) {
      this.facebook_connected = connected;
      this.user_long_living_token = token;
    },

    openFBImportModal() {
      this.show_facebook_import_modal = true;
    },

    closeFBImportModal() {
      this.show_facebook_import_modal = false;
    },

    importFacebookPost(data) {
      const obj = {
        campaign_id: this.$route.params.campaign_id ?? null,
        folder_id: this.$route.params.folder_id ?? null,
        import: [
          {
            type: 'facebook',
            ...data,
            width: this.creative.width > 0 ? this.creative.width : 300,
            height: this.creative.height > 0 ? this.creative.height : 250,
          },
        ],
      };
      this.closeFBImportModal();
      this.loading = true;
      const path = `campaigns/${this.$route.params.campaign_id}/creatives`;
      this.$http
        .post(path, obj)
        .then(response => {
          if (this.is_import) {
            this.$emit('onImport', response);
          } else {
            this.$emit('onImport', response[0]);
          }
        })
        .finally(_ => {
          this.loading = false;
        });
    },

    validateFacebookConnectionStatus() {
      return this.$refs.facebook.validateFacebookConnectionStatus();
    },

    createNewCreative() {
      if (!CreativeSizeService.validate(this.creative)) return;

      if (this.disable_create) {
        return this.$refs['creative-details']?.$refs['combined-width-height-input']?.open(true);
      }

      this.$emit('onCreate', this.creative);
    },

    async skip() {
      const layout = await TemplatesService.get(TEMPLATE.SOCIAL_DEFAULT);

      if (layout?.layout_id) {
        this.$set(this.creative, 'template_id', null);
        this.$set(this.creative, 'template', null);
        this.$set(this.creative, 'layout_id', layout.layout_id);
        this.$set(this.creative, 'layout', layout);
      }

      if (this.is_import) {
        return this.createNewCreative();
      }

      this.$emit('onCreate', layout);
    },

    async cancel() {
      if (await this.$confirm('Are you sure?', 'All changes will be discarded')) {
        this.$emit('onRemove');
      }
    },

    notify(message) {
      this.closeFBImportModal();
      this.$alert(message, 'Missing Facebook permission(s)');
    },
  },
};
</script>
