import UserService from '@master/Services/UserService';
import router from '@root/src/router/Router';
import { VIEW } from '@master/constants';

const CUSTOM_EVENT = 'custom_event';

/**
 * @description since types are not available in JS, I define the type as regular object
 */
const AnalyticsData = {
  event: CUSTOM_EVENT,
  event_name: '',
  content_view: '',
  content_view_id: '',
  element_name: '',
  element_type: '',
  interaction_type: '',
  user_id: '',
};

/**
 * @name event
 * @description Example event names
 * content_interaction
 * form_interaction
 * ...
 */

/**
 * @class GoogleAnalytics
 * @description Google Analytics
 * @example window.ga.e({}) - Pushes new event to dataLayer
 */
class GoogleAnalytics {
  /**
   * @param {AnalyticsData} data
   * @returns {void} - Pushes new event to dataLayer
   */
  e(data) {
    if (data == null) return;

    data = this.serializeData(data);

    if (process.env.VUE_APP_ENV !== 'prd') {
      return window.debug && console.log('%c[GA]', 'background: blue; color: white', '\t', data);
    }

    if (!Array.isArray(window?.dataLayer)) {
      window.dataLayer = [];
    }

    window.dataLayer.push(data);
  }

  /**
   * @param {Omit<AnalyticsData, 'content_view' | 'content_view_id' | 'user_id'>} data
   * @returns {AnalyticsData}
   */
  serializeData(data) {
    const outout = {
      event: data?.event ?? CUSTOM_EVENT,
      event_name: data?.event_name ?? 'content_interaction',
      content_view: this.getViewName(),
      content_view_id: this.getViewId(),
      element_name: data?.element_name ?? undefined,
      element_type: data?.element_type ?? undefined,
      interaction_type: data?.interaction_type ?? undefined,
      user_id: this.getUserId(),
    };

    if (data.email_hash) {
      outout.email_hash = data.email_hash;
    }

    return outout;
  }

  getViewName() {
    return router?.history?.current?.name ?? undefined;
  }

  getViewId() {
    const content_view = this.getViewName();
    const params = router?.history?.current?.params ?? undefined;

    if (content_view == null || params == null) return undefined;

    switch (content_view) {
      case VIEW.CAMPAIGNS_FOLDERS:
        return params?.folder_id ?? undefined;
      case VIEW.CREATIVES:
        return params?.campaign_id ?? undefined;
      case VIEW.CREATIVES_FOLDERS:
        return params?.folder_id ?? undefined;
      case VIEW.CREATIVE:
        return params?.campaign_id ?? undefined;
      case VIEW.CREATIVE_FOLDER:
        return params?.folder_id ?? undefined;
      case VIEW.FLIGHT:
        return params?.flight_id ?? undefined;
    }
  }

  getUserId() {
    return UserService?.get()?.user_id ?? undefined;
  }
}

export default new GoogleAnalytics();
