import $http from '@master/Services/HttpService';

import WhitelabelService from '@master/Services/WhitelabelService';
import IntercomService from '@master/Services/IntercomService';
import { clone, validateURL, loadHotjarTracking, loadUserGuiding } from '@helpers/Global';
import { TIMEOUT, USER_ROLE, PERMISSION, USER_FLAGS, ADMIN_TOGGLE, APPS, DEFAULT_DATE_FORMAT } from '@master/constants';

const $user = {
  user: null,
  foo: 'foo',

  // it will allow testing UI without user having sdk account
  // cannot test routes since won't be stored in local storage
  sdk_mode: false,

  // keep it as object to make it reactive
  admin: {
    toggle: false,
  },

  // user.options shortcut
  options: {},

  _authorize: null,
  _resolvers: [],
  _user_is_active: true,

  _welcome_back_triggers: new Map(),
  _inactivity_timer: null,

  init(cb = null) {
    const path = 'v2/user/me';
    $http
      .get(path, { notification: false }, 'auth_me')
      .then(response => {
        this.set(response);
        this._resolveAuth(true);
        if (cb != null) {
          cb();
        }
      })
      .catch(() => {
        this.reset();
        this._resolveAuth(false);

        // clear console after 401
        /* eslint-disable-next-line */
        if (process.env.NODE_ENV === 'production' && typeof console.clear === 'function') {
          console.clear();
        }
      });
    this.initActivityTimer();
  },

  // used on profile page cancel, resets view object
  get() {
    return clone(this.user);
  },

  send() {
    for (const cb of this._resolvers) {
      cb(this.get());
    }
  },

  isAuthenticated() {
    const promise = new Promise(resolve => {
      this._authorize = resolve;
    });

    if (this.user != null) {
      this._resolveAuth(true);
    } else {
      this.init();
    }
    return promise;
  },

  _resolveAuth(status) {
    if (this._authorize != null) {
      this._authorize(status);
      this._authorize = null;
    }
  },

  logout(appid) {
    let redirect = $http.getEndpoint() + 'auth/logout';
    const params = new URLSearchParams();
    if (process.env.NODE_ENV === 'development') {
      params.append('env', process.env.NODE_ENV);
    }

    // add ref for public apps to put user back where it was
    const public_apps = [APPS.PREVIEW, APPS.ADSPECS];
    if (public_apps.includes(appid)) {
      params.append('ref', window.location.href);
    }

    if (params.size > 0) {
      redirect += `?${params.toString()}`;
    }

    window.location.href = redirect;
  },

  /**
   * Called after logout or lock
   * Setback some defaults
   * Clear 3rd party app details here if needed
   */
  reset() {
    this.user = null;
    this.initIntercom();
    this.send();
  },

  set(obj) {
    if (this.user == null) {
      this.user = {};
    }

    for (let key in obj) {
      this.user[key] = obj[key];
    }

    this.options = this.user.options ?? {};
    this.updateGlobals();

    // Optionally load all external libraries
    try {
      this.initIntercom();
      this.initBugsnag();
      this.initHotjar();
      this.initUserGuiding();
    } catch (e) {
      console.error(e);
    }

    window.onbeforeunload = null;
    this.send();
  },

  update(data, notification = true) {
    const path = 'auth/me';
    $http.put(path, data, { notification }).then(response => {
      this.set(response);
    });
  },

  /**
   * @param cb - callback function
   * @param vnode - vue vnode, to remove subscription on destory hook (references to this, inside component, `.subscribe(cb, this)`)
   */
  subscribe(cb, vnode = null) {
    if (vnode != null && typeof vnode.$once === 'function') {
      vnode.$once('hook:beforeDestroy', () => {
        let index = this._resolvers.indexOf(cb);
        this._resolvers.splice(index, 1);
      });
    }
    this._resolvers.push(cb);
    cb(this.get());
  },

  initIntercom() {
    IntercomService.init(this.user);
  },

  initBugsnag() {
    if (this.user != null) {
      window._bugsnag.setUser(this.user.user_id);
      let user_roles = [this.user.user_type];
      const group = this.getGroup();
      if (group) {
        user_roles.push(group.role.name);
        window._bugsnag.addMetadata('company', 'id', group.group_id);
        window._bugsnag.addMetadata('company', 'country', group.country);
      }
      window._bugsnag.addMetadata('user', 'roles', user_roles);
    }
  },

  initHotjar() {
    // ignore nexd users
    const group = this.getGroup();
    const ignored_groups = ['bHQAjRTT0Xug'];

    if (!group || ignored_groups.includes(group.group_id)) return;

    if (process.env.VUE_APP_ENV === 'prd') {
      loadHotjarTracking(this.user);
    }
  },

  initUserGuiding() {
    // ignore nexd users
    const group = this.getGroup();
    const ignored_groups = ['bHQAjRTT0Xug'];

    if (!group || ignored_groups.includes(group.group_id)) return;

    if (process.env.VUE_APP_ENV === 'prd') {
      loadUserGuiding(this.user);
    }
  },

  isTourActive() {
    // some intercom logic here to determine if the tour is active or not
    return false;
  },

  getPreviewUrl() {
    const whitelabel_uri = this.getGroup()?.meta?.whitelabel_uri;
    if (whitelabel_uri != null) {
      return validateURL(whitelabel_uri) + '/';
    }
    return window.__nexd.preview;
  },

  getGroup() {
    return this.user?.group ?? null;
  },

  // probably deprecated mediabuy helper, this should only b info for admin users?
  groupHasActiveCreditCard() {
    const group = this.getGroup();
    return group?.meta?.has_active_cc ?? false;
  },

  getGroupId() {
    const group = this.getGroup();
    return group?.group_id ?? null;
  },

  getGroupTimezone() {
    const group = this.getGroup();
    return group?.billing_info?.analytics_timezone ?? 'UTC';
  },

  getDateFormat() {
    const group = this.getGroup();
    return group?.meta?.date_format ?? DEFAULT_DATE_FORMAT;
  },

  getCurrency() {
    const group = this.getGroup();
    return group?.billing_info?.currency ?? 'EUR';
  },

  canChangePlan() {
    const group = this.getGroup();
    if (group?.subscription) {
      // basic, free, solo, team, company
      const plans = ['aqUnXzA4iwUx', 'b4wUUnGFketB', 'BwEAbYXNIvyC', 'eTJdZcJTvCXl', 'difgypANOIZe'];
      return plans.includes(group.subscription.plan_id);
    }
    return false;
  },

  isEnterpriseUser() {
    const group = this.getGroup();
    if (group?.subscription) {
      return group.subscription.plan_id === 'OuK4HirThi3P';
    }
    return false;
  },

  isFreePlanUser() {
    const group = this.getGroup();
    if (group?.subscription) {
      return group.subscription.plan_id === 'b4wUUnGFketB';
    }
    return false;
  },

  isBasicPlanUser() {
    const group = this.getGroup();
    if (group?.subscription) {
      return group.subscription.plan_id === 'aqUnXzA4iwUx';
    }
    return false;
  },

  isFreeOrBasicPlanUser() {
    return this.isFreePlanUser() || this.isBasicPlanUser();
  },

  isIntegrationPlanUser() {
    const group = this.getGroup();
    if (group?.subscription) {
      return group.subscription.plan_id === 'DoXw78DtAKOB';
    }
    return false;
  },

  isPayAsYouGoUser() {
    const group = this.getGroup();
    if (group?.subscription) {
      return group.subscription.plan_id === 'tXOP0ddX72BE';
    }
    return false;
  },

  // is browser frames enabled by default, default is true;
  // Activision Blizzard Media is only whitelabel that has it turned off
  isBrowserFrameEnabled() {
    const group = this.getGroup();
    const whitelabel = WhitelabelService.get();

    return whitelabel?.show_browser_frame ?? group?.meta?.show_browser_frame ?? true;
  },

  // TODO
  // should be soon ticket, makring it down for a reminder
  // group features should be actually be checked against the target obj creator group
  // not current user group features
  // but before ticket lets keep this that way, since its not a big issue
  hasGroupFeature(name) {
    const group = this.getGroup();
    return group?.features?.includes(`${name}_feature`);
  },

  isSocialApproved() {
    return this.isInstreamExpert() === false;
  },

  isResponsiveApproved() {
    if (this.isInstreamExpert()) {
      return false;
    }
    return this.hasGroupFeature('responsive');
  },

  isAdvertiserFeatureAllowed() {
    return !this.canChangePlan();
  },

  canExportAsVideo() {
    // disabled because its an unfinisehed feature with a broken backend
    return false;
    return this.hasGroupFeature('exportasvideo');
  },

  canArchiveCreative() {
    return this.hasGroupFeature('archive_creative');
  },

  isCustomSplashEnabled() {
    return this.hasGroupFeature('custom_ad_splash');
  },

  canUseOptimizerApp() {
    return this.hasGroupFeature('optimizer');
  },

  canUseCreativeComments() {
    return this.hasGroupFeature('creative_comment');
  },

  canReplaceDevtoolAssets() {
    return this.hasGroupFeature('replace_devtool_assets');
  },

  // return impression type of the group, if no info default impression type is 0 (impressions)
  getImpressionType(fallback = true) {
    const group = this.getGroup();
    if (group?.impression_type != null) {
      return group.impression_type;
    }
    if (fallback) {
      return 0;
    }
    return null;
  },

  requiresRegistrationFinalize() {
    return this.user?.flags?.includes(USER_FLAGS.NEW_USER);
  },

  hasTrialSubscription() {
    return this.user?.flags?.includes(USER_FLAGS.SUBSCRIPTION_TRIAL);
  },

  hasSubscriptionEnded() {
    return this.user?.flags?.includes(USER_FLAGS.SUBSCRIPTION_EXPIRED);
  },

  hasGoogleIntegration() {
    return this.user?.flags?.includes(USER_FLAGS.GOOGLE_INTEGRATION);
  },

  hasMicrosoftIntegration() {
    return this.user?.flags?.includes(USER_FLAGS.MICROSOFT_INTEGRATION);
  },

  removeFlag(flag) {
    if (!this.user?.flags) return;
    const index = this.user.flags.indexOf(flag);
    if (index === -1) return;
    this.user.flags.splice(index, 1);
    this.send();
  },

  hasTrialEnded() {
    return this.hasTrialSubscription() && this.hasSubscriptionEnded();
  },

  isNexdAdmin() {
    return this.user?.level >= 120;
  },

  isSuperAdmin() {
    return this.user?.level >= 127;
  },

  isSDKUser() {
    if (this.sdk_mode) {
      return this.sdk_mode;
    }
    return this.user?.sdk_account ?? false;
  },

  toggleSDKMode() {
    this.sdk_mode = !this.sdk_mode;
    this.send();
  },

  isGroupAdmin() {
    const group = this.getGroup();
    return group?.role.role_id === USER_ROLE.ADMIN;
  },

  havePermission(permission) {
    const group = this.getGroup();
    const permissions = group?.role?.permission ?? 0;

    // if role has admin permission, all permissions are true
    if ((permissions & PERMISSION.ADMINISTRATOR) == PERMISSION.ADMINISTRATOR) {
      return true;
    }

    // check if role has permission bit
    return (permissions & permission) == permission;
  },

  adminMode() {
    return this.getSetting(ADMIN_TOGGLE) === true;
  },

  canUseDevtools() {
    return this.user?.level >= 50;
  },

  isInstreamExpert() {
    const group = this.getGroup();
    return group?.role_id === USER_ROLE.INSTREAM_EXPERT;
  },

  getSetting(key, fallback = null) {
    if (key === ADMIN_TOGGLE && !this.isNexdAdmin()) {
      return false;
    }

    const keys = key.split('.');
    let value = this.options;
    for (let i = 0; i < keys.length; i++) {
      const k = keys[i];
      if (value[k] == null) {
        if (i < keys.length - 1) {
          value[k] = {};
        } else {
          value[k] = fallback;
        }
      }
      value = value[k];
    }
    return value;
  },

  setSetting(key, input) {
    const keys = key.split('.');
    let value = this.options;
    for (let i = 0; i < keys.length; i++) {
      const k = keys[i];
      if (value[k] == null) {
        if (i < keys.length - 1) {
          value[k] = {};
        } else {
          value[k] = input;
        }
      } else {
        if (i === keys.length - 1) {
          value[k] = input;
        }
      }
      value = value[k];
    }
    this.update({ options: this.options }, false);
    this.updateGlobals();
  },

  updateGlobals() {
    this.admin.toggle = this.getSetting(ADMIN_TOGGLE);
  },

  isActive() {
    return this._user_is_active;
  },

  triggerWhenActive(key, callback) {
    this._welcome_back_triggers.set(key, callback);
  },

  initActivityTimer() {
    // ignore when called multiple times
    if (this._inactivity_timer) return;

    const resetTimer = _ => {
      this._user_is_active = true;

      window.clearTimeout(this._inactivity_timer);
      this._inactivity_timer = setTimeout(_ => {
        this._user_is_active = false;
      }, TIMEOUT.THIRTY_MINUTES);

      for (const [key, callback] of this._welcome_back_triggers.entries()) {
        if (typeof callback === 'function') {
          callback();
        }
        this._welcome_back_triggers.delete(key);
      }
    };

    resetTimer();
    window.addEventListener('keydown', resetTimer, false);
    window.addEventListener('mousemove', resetTimer, false);
  },
};

export default $user;
