<template lang="pug">
CreativeRow.stretch.p-24(
  v-if="!showing_html5_import"
  label="Content:"
  :tooltip="{value: 'You can choose our predefined layouts and add assets to it.'}"
)
  template(v-slot:content)
    //- vast info
    template(v-if="isVast()")
      CreativeLayoutBlockVAST(
        :creative="creative"
        @showVASTImport="$emit('showVASTImport')"
      )

    //- html5 info
    template(v-else-if="isHTML5()")
      div.flex-grow
        div.template-name {{ creative.imported ? 'Imported HTML5' : '3rd party URL' }}
        div.template-description {{ creative.imported ? 'An old-fashioned interactive banner' : 'Showing 3rd party website' }}
        template(v-if="creative.settings.html5.third_party_enabled")
          a.fs-12(:href="creative.settings.html5.third_party_url" target="_blank") {{ creative.settings.html5.third_party_url }}
      div.self-align-end
        div
          Buttons(align="left")
            Button(
              :label="html5 ? 'Replace source' : 'Replace imported HTML5'"
              type="primary"
              :outline="true"
              @click="showHTML5Import"
            )
            Button(
              v-if="creative.imported && creative?.imported_zip != null && $user.isNexdAdmin()"
              label="Download HTML5 Zip"
              type="primary"
              :outline="true"
              @click="downloadHTML5"
            )
              template(v-slot:prefix)
                IconTag

    //- layout info
    template(v-else-if="template != null")
      div.flex-grow
        div.template-name.flex.flex-align-center {{ template.name }}
          img(
            v-if="best_for_desktop_layout"
            :src="$cdn + 'dist/assets/cm/warning.svg'"
            alt="warning"
            v-tooltip="{value: 'This layout is not performing well on mobile devices, so we recommend to only traffic it to desktop devices.'}"
          )
          img(
            v-if="isDeprecatedLayout()"
            :src="$cdn + 'dist/assets/cm/warning.svg'"
            alt="warning"
            v-tooltip="{value: 'This creative has a deprecated version of the layout. Use change layout to update the layout being used.'}"
          )

        //- template description or social block
        div.template-description(v-if="!has_social_widget") {{ template.description }}

        //- social block
        div.mt-16.mb-16(v-else)
          div(v-if="social_token_error")
            div.flex.flex-align-center
              img(alt="warning" :src="$cdn + 'dist/assets/cm/warning.svg'")
              div.color-warning.fs-14 Facebook token has been revoked. Login again to re-link this creative
            FacebookConnect.mt-16(ref="facebook" :label="false" @tokenReceived="handleFacebookTokenChange")
          div.col-1(v-else-if="loading")
            Loading
          div.social-widget-live-info(v-else-if="social.id != null && social.permalink_url != null")
            Thumbnail(:asset="social" :small="true")
            a(:href="social.permalink_url" target="_blank" rel="noreferrer")
              span.fs-14 {{ social.message || 'Post has no message' }}
              i.nexd-icon-32-external-link(aria-hidden="true")

        //- reimport social row
        div.row(v-if="can_reimport_social && social.last_import != null")
          div.col.flex.flex-align-center
            Button(type="link" label="Reimport" :loading="loading" @click="reimportSocial")
              template(v-slot:prefix)
                IconRedo
            span.ml-16.color-gray-600.fs-14 Last import: {{ social.last_import | DateFilter({ time: true }) }}

        //- layout additional info such as demo assets, specs etc.

        Buttons.flex-grow(v-if="!is_sdk_user" align="left")
          template(v-for="link of template_links")
            Button.fs-12(
              :key="link.href"
              type="link"
              :href="link.href"
              :label="link.label"
            )
              template(v-slot:prefix)
                IconDownload(v-if="link.icon === 'download'")
                IconPicture(v-else-if="link.icon === 'picture'")
                IconReport(v-else-if="link.icon === 'report'")

      //- change layout button
      div.self-align-end(v-if="!is_new && layout_gallery_visible")
        Button(
          label="Change Layout"
          type="primary"
          :outline="true"
          :disabled="layout_gallery_disabled"
          @click="$emit('openLayoutGallery')"
        )
      //- change layout button if new
      div.self-align-end(v-else-if="is_new")
        Button(
          v-if="layout_gallery_visible"
          label="Change Layout"
          type="primary"
          :outline="true"
          :disabled="layout_gallery_disabled"
          @click="$emit('openLayoutGallery')"
        )

    //- choose layout button if no layout is selected
    div.flex-grow(v-else)
      Buttons(align="left")
        Button(
          v-if="!html5"
          label="Choose Layout"
          type="primary"
          :disabled="layout_gallery_disabled"
          :animate="true"
          @click="$emit('openLayoutGallery')"
        )
        Button(
          v-else
          label="Import HTML5"
          type="primary"
          :animate="true"
          @click="showHTML5Import"
        )

    slot

  //- show global loading modal if re-importing social
  LoadingModal(
    v-if="show_loading_modal"
    head="Importing social banner"
    body="Please wait while files are uploaded and optimized for better performance"
  )
</template>

<script>
import TemplatesService from '@master/Services/TemplatesService';

import CreativeTraits from '@master/Traits/CreativeTraits.vue';
import SDKMixin from '@root/src/global/mixins/SDKMixin.vue';

import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';
import IconDownload from '@master/UI/Buttons/Icons/IconDownload.vue';
import IconPicture from '@master/UI/Buttons/Icons/IconPicture.vue';
import IconRedo from '@master/UI/Buttons/Icons/IconRedo.vue';
import IconReport from '@master/UI/Buttons/Icons/IconReport.vue';
import IconTag from '@master/UI/Buttons/Icons/IconTag.vue';
import Loading from '@master/UI/Loading.vue';
import LoadingModal from '@master/UI/LoadingModal.vue';

import FacebookConnect from '@cm/Views/Creatives/Cards/Social/FacebookConnect.vue';
import CreativeLayoutBlockVAST from '@cm/Views/Creatives/Creative/CreativeLayoutBlockVAST.vue';
import Thumbnail from '@master/UI/Thumbnail/Thumbnail.vue';
import CreativeRow from '@cm/Views/Creatives/Creative/CreativeRow.vue';

import { IMPORT } from '@master/constants';

export default {
  name: 'CreativeLayoutBlock',
  mixins: [CreativeTraits, SDKMixin],
  components: {
    Button,
    Buttons,
    IconDownload,
    IconPicture,
    IconRedo,
    IconReport,
    IconTag,
    Loading,
    LoadingModal,
    FacebookConnect,
    CreativeLayoutBlockVAST,
    Thumbnail,
    CreativeRow,
  },

  props: {
    creative: {
      type: Object,
      default: () => null,
    },

    active_import: String,

    is_new: {
      type: Boolean,
      default: false,
    },

    // lightbox 2nd state
    html5: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    template() {
      return this.creative?.layout ?? this.creative?.template ?? null;
    },

    template_specs_file() {
      return this.template?.specs?.specs_file ?? this.template?.specs_file ?? null;
    },

    template_psd_file() {
      return this.template?.specs?.psd_file ?? this.template?.psd_file ?? null;
    },

    template_demo_package() {
      return this.template?.specs?.demo_package ?? this.template?.demo_package ?? null;
    },

    template_links() {
      const links = [];

      if (this.template_specs_file != null) {
        links.push({
          href: this.template_specs_file,
          icon: 'report',
          label: 'Specs and examples',
          download: false,
        });
      }

      if (this.template_psd_file != null) {
        links.push({
          href: this.template_psd_file,
          icon: 'download',
          label: 'PSD template',
          download: true,
        });
      }

      if (this.template_demo_package != null) {
        links.push({
          href: this.template_demo_package,
          icon: 'picture',
          label: 'Demo assets',
          download: true,
        });
      }

      return links;
    },

    has_social_widget() {
      if (this.creative?.objects == null) return false;

      const key = Object.keys(this.creative.objects).find(_key => _key.includes('social_widget'));
      const social_widget = this.creative.objects[key];

      return social_widget?.settings?.page_id != null;
    },

    can_reimport_social() {
      return this.has_social_widget && !this.social_token_error;
    },

    best_for_desktop_layout() {
      return TemplatesService.bestForDesktop(this.creative.template);
    },

    layout_gallery_disabled() {
      return this.isVast() || this.isDynamicEndcard() || this.isProcessing();
    },

    layout_gallery_visible() {
      return !this.isDynamicEndcard();
    },

    showing_html5_import() {
      return this.active_import === IMPORT.HTML5;
    },
  },

  data() {
    return {
      IconTag,
      show_loading_modal: false,
      loading: false,
      social_token_error: false,
      social: {
        id: null,
        image_uri: null,
        message: null,
        last_import: null,
        permalink_url: null,
      },
    };
  },

  created() {
    if (this.has_social_widget) {
      this.$store.reset('social_counts');
      this.generateSocialObj();
    }

    // when opening lightbox 2nd state with a new creative, always show import 1st
    if (this.isHTML5() && !this.creative?.settings?.html5?.third_party_enabled && !this.creative?.imported) {
      this.showHTML5Import();
    }
  },

  methods: {
    async generateSocialObj(reset = false) {
      if (this.loading || !this.can_reimport_social) return;
      this.loading = true;

      if (reset) {
        this.show_loading_modal = true;
        const new_creative = await this.$http.put(`creative/${this.creative.creative_id}/social/reset`);

        for (const key in new_creative) {
          this.$set(this.creative, key, new_creative[key]);
        }

        this.$emit('change');
      }

      this.$http
        .get(`creative/${this.creative.creative_id}/social/details`, {
          notification: 'success',
        })
        .then(response => {
          this.setPostDetails(response);
        })
        .catch(() => {
          this.social_token_error = true;
        })
        .finally(() => {
          this.loading = false;
          this.show_loading_modal = false;
        });
    },

    async reimportSocial() {
      if (this.loading || !this.can_reimport_social) return;

      const confirm = await this.$confirm('Are you sure you want to reimport the content?', 'It will discard all changes that you made for the current creative.');

      if (confirm) return this.generateSocialObj(true);
    },

    handleFacebookTokenChange(state) {
      // if no token/login failed, ignore
      if (!state?.token) {
        return;
      }

      this.$http
        .post(`creative/${this.creative.creative_id}/social/connect`, {
          token: state.token,
        })
        .then(response => {
          this.social_token_error = false;
          this.setPostDetails(response);
        })
        .catch(() => {
          // trigger logout on failed attempt
          if (this.$refs.facebook != null) {
            this.$refs.facebook.logout();
          }
        });
    },

    setPostDetails(response) {
      const { post, widget } = response;
      this.social.id = post.id;
      this.social.image_uri = post.full_picture;
      this.social.message = post.message;
      this.social.permalink_url = post.permalink_url;
      this.social.last_import = widget.last_sync;

      // store social counts (likes, comments) to global store for replacing data with default when customer is switching auto syncing on.
      this.$store.set('social_counts', {
        comments: post.comments.summary.total_count,
        likes: post.likes.summary.total_count,
        reactions: post.reactions.summary.total_count,
      });
    },

    downloadHTML5() {
      if (this.creative?.imported_zip == null) return;
      window.open(this.creative.imported_zip, '_blank');
    },

    showHTML5Import() {
      this.$emit('showHTML5Import');
    },
  },
};
</script>
