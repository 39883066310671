<template lang="pug">
div.analytics-card(data-intercom-target="analytics-events-list")
  CardHeader(
    :title="label"
    tooltip="analytics.eventsListCard"
    :collapsed="collapsed"
    @collapse="collapseHandler"
  )
  div.card-body(v-if="data === null")
    Loading
  div(v-else :class="{ collapsed: collapsed }")
    div.table-scrollable
      table.table.analytics-table.fixed-header(
        aria-label="Events table"
        v-sortable-table="dataClone"
      )
        thead
          tr
            th(scope="col" data-key="category") Category
            th(scope="col" data-key="action.translation") Action
            th(scope="col" default-order data-key="count") Count
          tr(v-for="(row, index) in dataClone" :key="index")
            td {{ row.category }}
            td {{ row.action.translation }}
            td.hover-data(
              :data-hover-value="row.count_p + '%'"
              :style="{ '--p': row.count_p + '%' }"
            ) {{ row.count | NumberFilter }}
    div.footer Hover on data to see additional information.
</template>

<script>
import CardHeader from '@analytics/Views/Creative/Cards/Components/CardHeader.vue';
import { clone } from '@helpers/Global';
import Loading from '@master/UI/Loading.vue';

export default {
  name: 'EventsList',
  components: {
    CardHeader,
    Loading,
  },
  props: {
    data: Array,

    list: Object,

    creative: Object,

    label: {
      type: String,
      default: 'Full events list',
    },
  },
  data() {
    return {
      dataClone: null,
      collapsed: true,
      user: null,
    };
  },
  created() {
    this.$user.subscribe(user => {
      this.user = user;
    }, this);
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // data still loading
      if (this.data === null) {
        return;
      }
      this.dataClone = clone(this.data);
    },

    collapseHandler(status) {
      this.collapsed = status;
    },
  },
  watch: {
    data: {
      handler() {
        this.init();
      },
      deep: true,
    },
  },
};
</script>
