<template lang="pug">
Modal(size="modal-xs" title="Change password")
  template(v-slot:body)
    form.flex.flex-direction-column.row-gap-16
      input(type="text" hidden name="username" autocomplete="username email" :value="user.email")

      div(v-for="field in fields" :key="field.name")
        label.mb-4 {{ field.label }}&nbsp;
          sup.color-danger *
        Input(variant="password" :placeholder="field.label" v-model="input[field.name]" :autocomplete="field.autocomplete")

  template(v-slot:footer)
    Buttons
      Button(type="link-primary" label="Cancel" @click="close")
      Button(type="primary" :animate="true" label="Save" :disabled="!is_valid" :tooltip="{value: tooltip}" @click="changePassword")
</template>

<script>
import { trimQuery } from '@helpers/Global';

import Modal from '@master/Modals/Modal.vue';

import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';
import Input from '@master/UI/Input/Input.vue';

export default {
  name: 'ChangePasswordModal',

  components: {
    Button,
    Buttons,
    Input,
    Modal,
  },

  props: {
    user: Object,
  },

  computed: {
    is_valid() {
      return this.input.new === this.input.repeat && Object.values(this.input).every(value => trimQuery(value ?? '').length >= 8 && value.length <= 64);
    },

    tooltip() {
      if (this.input.new !== this.input.repeat) {
        return 'New password must match repeated password.';
      }

      return this.is_valid ? null : 'All passwords must be at least 6 characters long.';
    },
  },

  data() {
    return {
      input: {
        current: '',
        new: '',
        repeat: '',
      },

      fields: [
        { label: 'Current password', name: 'current', autocomplete: 'current-password' },
        { label: 'New password', name: 'new', autocomplete: 'new-password' },
        { label: 'Repeat new password', name: 'repeat', autocomplete: 'new-password' },
      ],
    };
  },

  methods: {
    changePassword() {
      if (this.is_valid) {
        this.$http.post('auth/password', this.input).then(() => {
          this.close();
        });
      }
    },

    close() {
      this.$emit('close');
    },
  },
};
</script>
