<template lang="pug">
div
  AssetUploadV2(
    ref="asset"
    :asset="slot"
    :parent="parent"
    :creative="creative"
    :key="slot._fullpath"
    :parent_slot="parent_slot"
    @change="change('asset')"
  )
</template>

<script>
import AssetUploadV2 from '@cm/Views/Creatives/Cards/Components/Asset/SlotV2.vue';

export default {
  name: 'CombinedAsset',

  components: {
    AssetUploadV2,
  },

  props: {
    creative: Object,
    parent: {
      type: Object,
      default: null,
    },
    parent_slot: {
      type: Object,
      default: null,
    },
  },

  computed: {
    slot() {
      if (this.parent_slot?.modules == null) return null;

      let image_module_slot;
      const image_module = this.parent_slot.modules.find(m => m.module_id === 'image');
      if (image_module != null) {
        image_module_slot = Object.values(image_module.slots)[0];
      }

      let video_module_slot;
      const video_module = this.parent_slot.modules.find(m => m.module_id === 'video');
      if (video_module != null) {
        video_module_slot = Object.values(video_module.slots).find(s => s.key !== 'endcard');
      }

      if (image_module_slot == null && video_module_slot != null) {
        return video_module_slot;
      } else if (image_module_slot != null) {
        image_module_slot._video = video_module_slot;
        return image_module_slot;
      }

      // probably DCO slot
      return null;
    },
  },

  methods: {
    change() {
      this.$emit('change');
    },
  },
};
</script>
