<template lang="pug">
div.social-external-import.row.flex-align-center.text-left(style="column-gap:16px")
  div.col.left-side
    span.fs-14 Enter a link to Facebook post:
  div.flex-grow.right-side
    input.form-control(placeholder="Insert URL" v-model="import_url")
  div.col
    Button(type="primary" label="GO" :animate="true" :disabled="loading" @click="importUrlHandler")
</template>

<script>
// examples:
// https://www.facebook.com/1768515716522612/posts/5255676407806508
// https://www.facebook.com/NEXDTech/posts/5255676407806508
// https://www.facebook.com/110293011536086/posts/110661011499286/
// https://www.facebook.com/NEXDtech/posts/pfbid025ZEnZasdoPTCbL9QN1jAWcNberj1SQ1GDSB9tR8bQVGtKH6bLE91DMo52mNexB2bl
// https://www.facebook.com/NEXDtech/videos/2720609504863982/

import { getUserFacebookPages, getFacebookPagePost } from '@helpers/Facebook';
import Button from '@master/UI/Buttons/Button.vue';

export default {
  name: 'ExternalImport',

  components: {
    Button,
  },

  props: {
    userID: String,
  },

  data() {
    return {
      import_url: '',
      loading: false,
    };
  },

  methods: {
    importUrlHandler() {
      if (this.loading) return;

      this.loading = true;

      const url = this.import_url.trim();

      if (url === '') {
        return this.importFailed();
      }

      let matches = url.match(/facebook\.\D{2,3}\/([^\/]+)\/(posts|videos)\/([\w]+)/i);
      if (matches && matches.length === 4) {
        const [, page_id, , post_id] = matches;
        return this.facebookUrlImport(page_id, post_id);
      }

      matches = url.match(/facebook\.\D{2,3}\/[^\/]+story_fbid=(\d+).+id=(\d+)/i);
      if (matches && matches.length === 3) {
        const [, post_id, page_id] = matches;
        return this.facebookUrlImport(page_id, post_id);
      }

      this.loading = false;
      this.importFailed();
    },

    async facebookUrlImport(page_id, post_id) {
      const data = await this.getPageToken(page_id, post_id);

      if (data == null) {
        this.loading = false;
        return;
      }

      this.$emit('select', {
        type: 'facebook',
        ...data,
      });
    },

    importFailed() {
      this.$alert('Sorry, we did not recognize this URL. <a href="http://support.nexd.com/en/articles/5844205-import-from-social-media#h_bb9adbb74d" rel="noreferrer" target="_blank">Read more</a>');
    },

    async getPageToken(page_id, post_id) {
      const validateFB = await this.$parent.validateFacebookConnectionStatus();

      if (validateFB == null) return null;

      const {
        authResponse: { accessToken, userID },
      } = validateFB;

      return getUserFacebookPages(userID, accessToken)
        .then(pages => {
          const page = pages.find(p => p.id === page_id || (p.username != null && p.username.toLowerCase() === page_id.toLowerCase()));

          if (!page) {
            this.$alert(
              'You will need permissions for the page that created the post you want to import from. <a href="http://support.nexd.com/en/articles/5844205-import-from-social-media#h_cb56cdbcb6" rel="noreferrer" target="_blank">Read more</a>',
              'Missing Facebook permission(s)',
            );
            return null;
          }

          // if import url had page username instead, overwrite preselection id with the real page id because you cant get post with the username
          return getFacebookPagePost(page.id, page.access_token, post_id)
            .then(post => {
              return {
                page_token: page.access_token,
                page_id: page.id,
                post_id: post.id,
              };
            })
            .catch(() => {
              this.$alert(
                'You will need permissions for the page that created the post you want to import from. <a href="http://support.nexd.com/en/articles/5844205-import-from-social-media#h_cb56cdbcb6" rel="noreferrer" target="_blank">Read more</a>',
                'Missing Facebook permission(s)',
              );
              return null;
            });
        })
        .catch(message => {
          this.$alert(message);
          return null;
        });
    },
  },
};
</script>
