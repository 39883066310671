<template lang="pug">
div.creative-card-details
  div

    //- select tag for export
    CreativeRow(
      label="Export as tag for:"
      :tooltip="{template: 'cm.platform'}"
    )
      template(v-slot:content)
        SearchSelect(
          v-if="dsp_options != null"
          :options="dsp_options"
          reference=".platform"
          v-model="creative.dsp"
          width="100%"
        )

    //- infeed video export
    CreativeRow(
      v-if="can_export_video && this.isInfeed()"
      label="Export as video for:"
      :tooltip="is_auto_animation_layout ? {template: 'cm.exportVideo'} : {}"
      :disabled="!is_auto_animation_layout"
      :disabled_tooltip="!is_auto_animation_layout ? {template: 'cm.exportVideoDisabled'} : null"
      :has_checkbox="true"
    )
      template(v-slot:checkbox)
        CustomCheckbox.mr-8(:disabled="!is_auto_animation_layout" :value="export_as_video_check" @change="exportVideo")
      template(v-slot:content)
        SearchSelect(
          :options="video_sizes"
          :search="false"
          :first="false"
          :disabled="!is_auto_animation_layout"
          :value="export_size"
          width="100%"
          @input="changeVideoExportSize"
        )
        //- export to video assets sizes warning
        img.col.flex.no-gutter-left(
          v-if="min_video_asset_size !== ''"
          alt="warning"
          :src="$cdn + 'dist/assets/cm/warning.svg'"
          v-tooltip="{value: `Minimum required size for assets: ${min_video_asset_size}`, class: 'warning'}"
        )

    div.mt-16(v-if="isCMCreative()")
      //- inApp support
      label.flex.flex-align-center(style="width:max-content")
        CustomCheckbox.mr-8(:value="in_app_support_check" @change="inAppSupport")
        span.color-black.cursor-pointer InApp Support
        i.nexd-icon-32-help(aria-hidden="true" v-tooltip="{template: 'cm.inappSupport'}")

      //- v2 packgen
      label.flex.flex-align-center(v-if="admin_toggle" style="width:max-content")
        CustomCheckbox.mr-8(v-model="creative.settings.use_v2_packgen" @change="saveSettings('use_v2_packgen', $event)")
        span.color-black.cursor-pointer Quick Update
        i.nexd-icon-32-help(aria-hidden="true" v-tooltip="{template: 'cm.v2packbuilder'}")
</template>

<script>
import AssetHelper from '@helpers/Asset';

import TemplatesService from '@master/Services/TemplatesService';
import CreativesService from '@master/Services/CreativesService';
import DSPService from '@master/Services/Cache/DSPService';
import CreativeTraits from '@master/Traits/CreativeTraits.vue';

import SearchSelect from '@master/UI/SearchSelect/SearchSelect.vue';
import CustomCheckbox from '@master/UI/CustomCheckbox.vue';

import CreativeRow from '@cm/Views/Creatives/Creative/CreativeRow.vue';

import { DSP } from '@master/constants';

export default {
  name: 'CreativeExport',
  mixins: [CreativeTraits],
  components: {
    SearchSelect,
    CustomCheckbox,
    CreativeRow,
  },

  props: {
    creative: {
      type: Object,
      default: () => null,
    },

    is_new: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    can_export_video() {
      return this.$user.canExportAsVideo();
    },

    is_auto_animation_layout() {
      if (this.creative?.template == null) return false;

      return TemplatesService.isAutoAnimationLayout(this.creative.template);
    },

    export_as_video_check() {
      return this.creative?.settings?.video_export?.enabled != null && this.creative.settings.video_export.enabled;
    },

    min_video_asset_size() {
      if (this.creative == null) return '';

      const min_sizes = AssetHelper.getVideoExportMinimumSize(this.creative);
      return `${min_sizes.width}x${min_sizes.height}`;
    },

    in_app_support_check() {
      return this.creative?.settings?.tag?.in_app_traffic != null && this.creative.settings.tag.in_app_traffic;
    },

    video_sizes() {
      if (this.creative == null) return {};

      const options = {
        '-1': 'Choose platform / size',
        x1: 'x1',
        x2: 'x2',
        'fb-1080': 'fb-1080',
        hq: 'hq',
        'landscape-16by9': 'landscape-16by9',
        'portrait-9by16': 'portrait-9by16',
      };

      for (const key in options) {
        options[key] = exportTypeFilter(key);
      }

      return options;
    },

    export_size() {
      if (this.creative?.settings?.video_export?.type == null) return '-1';

      return this.creative.settings.video_export.type;
    },
  },

  data() {
    return {
      admin_toggle: false,
      dsp_options: null,
    };
  },

  created() {
    this.$user.subscribe(user => {
      if (user != null) {
        this.admin_toggle = this.$user.adminMode();
      }
    }, this);

    DSPService.subscribe(dsps => {
      if (dsps == null) return;

      this.dsp_options = [];

      for (const dsp of dsps) {
        this.dsp_options.push({ value: dsp.slug, label: dsp.title });
      }
    }, this);
  },

  methods: {
    async dspChangeHandler(newval, oldval) {
      const old_dsp_title = await DSPService.get(oldval)?.title;

      // if googleads is selected, make multiple checks to validate if its allowed to change it
      if (newval === DSP.GOOGLEADS) {
        // google ads is not allowed for video creatives
        if (this.creative.has_video) {
          this.$alert('Google Ads platform does not allow video in ads from third parties. Please use only images or publish your creative via other platform', 'Google Ads does not allow video in ads');
          this.creative.dsp = oldval;
          this.creative.dsp_title = old_dsp_title;
          return;
        }

        // if dsp is changed to google ads, but assets list is loading (which might be video asset)
        // from parent because it will come from single creative component
        if (this.$parent?.$refs?.settings?.isLoading?.()) {
          this.$alert('Please wait until all assets are finished uploading.', 'Assets are still uploading');
          this.creative.dsp = oldval;
          this.creative.dsp_title = old_dsp_title;
          return;
        }
      }

      this.creative.dsp_title = await DSPService.get(newval)?.title;
      this.$user.setSetting('default.dsp', this.creative.dsp);
      CreativesService.export.requestTagUpdate(this.creative);
      this.save();
    },

    changeVideoExportSize(new_size = null) {
      if (this.creative?.settings?.video_export != null) {
        if (new_size == null) {
          new_size = this.creative.settings.video_export.type;
        }

        const sizes = AssetHelper.getVideoExportSize(this.creative, new_size);

        this.$set(this.creative.settings.video_export, 'type', new_size);
        this.$set(this.creative.settings.video_export, 'width', sizes.width);
        this.$set(this.creative.settings.video_export, 'height', sizes.height);

        this.save();
      }
    },

    exportVideo() {
      if (!this.is_auto_animation_layout) return;

      if (this.creative?.settings?.video_export == null) {
        this.$set(this.creative.settings.video_export, 'enabled', false);
      }

      this.$set(this.creative.settings.video_export, 'enabled', !this.creative.settings.video_export.enabled);

      this.save();
    },

    validateTagSettingKey(key) {
      if (this.creative.settings.tag == null) {
        this.$set(this.creative.settings, 'tag', {});
      }
      if (this.creative.settings.tag[key] == null) {
        this.$set(this.creative.settings.tag, key, false);
      }
    },

    inAppSupport() {
      this.validateTagSettingKey('in_app_traffic');
      this.$set(this.creative.settings.tag, 'in_app_traffic', !this.creative.settings.tag.in_app_traffic);
      if (this.is_new) return;
      this.saveTagSettings();
    },

    saveTagSettings() {
      CreativesService.export.requestTagUpdate(this.creative);
      this.saveSettings('tag', this.creative.settings.tag);
    },

    saveSettings(key, value) {
      const path = `creative/${this.creative.creative_id}/settings/${key}`;
      this.$http.put(path, { value });
    },

    save() {
      if (this.is_new) return;
      this.$emit('save');
    },

    exportTypeFilter(type) {
      const types = {
        '-1': 'Choose platform / size',
        x1: '@1x',
        x2: '@2x',
        'fb-1080': 'Square (1080x1080)',
        hq: 'High Quality',
        'landscape-16by9': 'Landscape 16:9 (1920x1080)',
        'portrait-9by16': 'Portrait 9:16 (1080x1920)',
      };

      if (this.creative != null) {
        const { width, height } = AssetHelper.getVideoExportSize(this.creative, type);

        // append export sizes that change with creative placement size
        types.x1 += ` (${width}x${height})`;
        types.x2 += ` (${width}x${height})`;
        types.hq += ` (${width}x${height})`;
      }

      return types[type] || type;
    },
  },

  watch: {
    'creative.dsp': {
      handler(newval, oldval) {
        this.dspChangeHandler(newval, oldval);
      },
    },
  },
};
</script>
