<template>
  <div class="charts-wrapper">
    <div class="single-chart" ref="chart-0" style="height: 280px" />
    <div class="single-chart" ref="chart-1" style="height: 280px" />
    <div v-if="labels == null" class="single-chart">
      <Loading />
    </div>
  </div>
</template>

<script>
import Loading from '@master/UI/Loading.vue';

import { TooltipContent } from '@analytics/Classes/TooltipContent';
import { colorOptions } from '@master/Services/AnalyticsAPPService';
import PointTooltip from '@analytics/Classes/PointTooltip';

import Chartist from '@libs/Chartist';

export default {
  name: 'CompareChart',
  components: {
    Loading,
  },
  props: {
    analytics: Object,
    labels: Array,
    selections: Object,
  },

  data() {
    return {
      admin_toggle: false,
      series_options: [
        {
          chartPadding: {
            left: 16,
            bottom: 0,
            right: 56,
          },
          axisX: {
            diagonal: false,
          },
          axisY: {
            type: Chartist.FixedScaleAxis,
            divisor: 5,
            low: 0,
          },
        },
        {
          chartPadding: {
            left: 56,
            bottom: 0,
            right: 16,
          },
          axisY: {
            type: Chartist.FixedScaleAxis,
            divisor: 5,
            low: 0,
            showGrid: false,
            position: 'end',
          },
          axisX: {
            showLabel: false,
          },
        },
      ],
    };
  },

  created() {
    this.$user.subscribe(user => {
      if (user != null) {
        this.admin_toggle = this.$user.adminMode();
      }
    }, this);
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      if (!this.analytics) return;

      // initial filter change happens before mounting, use timeout to wait
      this.loadChart();
    },

    async loadChart() {
      await this.$nextTick();

      if (this.labels == null) {
        // allow chart to load only after input data exists
        return;
      }

      const labels = this.labels || [];
      const series = this.selections.charts;

      for (const i in series) {
        const column = series[i];

        let options = this.series_options[i];

        if (column == null) {
          // if no filters has been selected, dont load chart
          return;
        } else if (column === 'engagement' || column === 'ctr' || column === 'viewability') {
          options.axisY.suffix = '%';
        } else {
          options.axisY.suffix = '';
        }

        let chart_series = [];

        for (const type in this.selections.types) {
          // skip on selected type
          if (!this.selections.types[type]) continue;

          for (const device in this.selections.devices) {
            // skip on selected device
            if (!this.selections.devices[device]) continue;

            const data = this.analytics[type][device][column];
            let chart_data = [];
            if (data != null) {
              // add tooltips to each datapoint
              for (let index = 0; index < data.length; index++) {
                const value = data[index];
                chart_data.push({
                  meta: TooltipContent({
                    data: this.analytics[type][device],
                    index,
                    main_series: column,
                    active_series: series,
                    admin: this.admin_toggle,
                    label: this.labels[index],
                  }),
                  value,
                });
              }
            }
            chart_series.push(chart_data);
          }
        }

        if (labels[0] != null && labels.length > 6 && labels[0].length > 10) {
          options.axisX.diagonal = true;
        }

        const chart = new Chartist.Line(
          this.$refs[`chart-${i}`],
          {
            labels,
            series: chart_series,
          },
          options,
        );

        // save horiozontal grid lines, to bind them to the point (visibility on hover)
        let lines = {};
        let debugTooltip = false;

        const drawHandler = context => {
          if (context.type === 'point' || context.type === 'line') {
            const colour = colorOptions[column] || 'blue-light';
            let parent = context.element.parent();
            parent.addClass(colour.class);
            parent.addClass('animate');
            if (context.type === 'point') {
              let tooltip = new PointTooltip(context, lines[context.index]);
              let newSVG = tooltip.init();
              context.element.replace(newSVG);
              if (debugTooltip === true) {
                debugTooltip = false;
                tooltip.show();
              }
            } else {
              try {
                const path = context.element.getNode();
                const length = Math.ceil(path.getTotalLength());
                parent.getNode().setAttribute('style', '--dataLength: ' + length);
              } catch (err) {
                /** element not yet rendered */
              }
            }
          } else if (context.type === 'grid') {
            if (context.element.classes().indexOf('ct-horizontal') !== -1) {
              lines[context.index] = context.element.getNode();
            }
          }
        };
        chart.on('draw', context => {
          drawHandler(context);
        });
      }
    },
  },

  watch: {
    selections: {
      handler() {
        this.init();
      },
      deep: true,
    },
    analytics: {
      handler() {
        this.init();
      },
    },
  },
};
</script>
