<script>
import StatusTraits from '@master/Traits/StatusTraits.vue';

export default {
  methods: {
    isDraft(status) {
      return StatusTraits.methods.isDraft(status ?? this.flight?.status);
    },
    isPaused(status) {
      return StatusTraits.methods.isPaused(status ?? this.flight?.status);
    },
    isLive(status) {
      return StatusTraits.methods.isLive(status ?? this.flight?.status);
    },
    isWaiting(status) {
      return StatusTraits.methods.isWaiting(status ?? this.flight?.status);
    },
    isFinished(status) {
      return StatusTraits.methods.isFinished(status ?? this.flight?.status);
    },
    isPublished(status) {
      return StatusTraits.methods.isPublished(status ?? this.flight?.status);
    },
    isExported(status) {
      return this.isPaused() || this.isLive() || this.isWaiting() || this.isFinished() || this.isPublished();
    },
    isArchived(status) {
      return StatusTraits.methods.isArchived(status ?? this.flight?.status);
    },
    isDuplicationInProgress(status) {
      return StatusTraits.methods.isDuplicationInProgress(status ?? this.flight?.status);
    },
    isImportInProgress(status) {
      return StatusTraits.methods.isImportInProgress(status ?? this.flight?.status);
    },
  },
};
</script>
