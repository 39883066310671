<template lang="pug">
div(:class="{[styles.sidebar]: true, [styles.opened]: opened}")
  div.backdrop(:class="styles.backdrop" @click="close")

  div(:class="styles.content")
    Loading(v-if="loading" :fill="true" :text="loading_text")

    section(:class="styles.header")
      h2 {{ title }}:
      div(:class="styles.actions")
        slot(name="actions")

    section(:class="styles.main")
      slot(name="content")

      div(:class="styles.campaign_details")
        slot(name="campaign_details")

    section(:class="styles.footer")
      div.extra-publish-info(v-if="timestamp != null")
        i.nexd-icon-16-check-mini(aria-hidden="true")
        span Last save: {{ timestamp | TimeDifferenceFilter }}
      Buttons.flex-grow.justify-self-end
        Button(v-if="can_open" type="primary" label="Open" @click="open")
        Button(v-else-if="$route.meta?.view === VIEW.GROUP_ASSETS" type="primary" label="Close" @click="close")
        Button(type="primary" :outline="true" label="Save" @click="close")
</template>

<script>
import styles from './SidebarContainer.module.scss';

import { VIEW } from '@master/constants';

import Buttons from '@master/UI/Buttons/Buttons.vue';
import Button from '@master/UI/Buttons/Button.vue';
import Loading from '@master/UI/Loading.vue';

export default {
  name: 'SidebarContainer',

  components: {
    Buttons,
    Button,
    Loading,
  },

  props: {
    title: {
      type: String,
      default: 'Settings',
    },

    path: {
      type: [String, Object],
      default: () => null,
    },

    timestamp: {
      type: Number,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    can_open: {
      type: Boolean,
      default: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    loading_text: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      styles,
      VIEW,

      opened: false,
    };
  },

  mounted() {
    setTimeout(_ => {
      this.opened = true;
    }, 50);
  },

  methods: {
    close() {
      this.opened = false;

      // bit bigger delay than the animation delay (200ms)
      setTimeout(_ => {
        this.$emit('close');
      }, 250);
    },

    open(e) {
      if (this.path != null) {
        this.$router.push(this.path).catch(() => {
          /** supress duplicate route warning */
        });
      }

      this.$emit('open', e);
    },
  },
};
</script>
