<template lang="pug">
div.col.no-gutter
  div.row.flex-align-center
    template(v-if="object.color != null")
      small.col-5 Color:
      ColorOption.full-width.col-6(v-model="object.color" @input="$emit('update')")
    div.col-2
    template(v-if="object.alpha != null")
      small.col-6 Opacity:
      StyleInput.col-5(v-model="object.alpha" :label="'%'" :default="1" :min="0" :max="1" :scale="100" @input="update($event, 'alpha')")
  div.row.flex-align-center.mt-8
    template(v-if="object.size != null")
      small.col-5 Size:
      StyleInput.col-6(:key="object.size" v-model="object.size" :label="'px'" :default="14" @input="update($event, 'size')")
    template(v-if="object.line_height != null")
      small.col-5 Leading:
      StyleInput.col-6(:key="object.line_height" v-model="object.line_height" :label="'px'" :default="16" @input="update($event, 'leading')")
</template>

<script>
import StyleInput from '@cm/UI/Inputs/StyleInput.vue';
import ColorOption from '@master/UI/ColorOption.vue';

export default {
  name: 'TextSettings',
  components: {
    ColorOption,
    StyleInput,
  },

  props: {
    object: Object,
  },

  methods: {
    update(value, key) {
      this.$set(this.object, key, value);
      this.$emit('update');
    },
  },
};
</script>
