<template lang="pug">
TestLayout(title="Sidebar Item")

  TestSection(subtitle="Default w/ path")
    div.container
      SidebarItem(
        label="Slots Test"
        path="/components/slot"
      )

  TestSection(subtitle="Default w/ icon w/ path")
    div.container
      SidebarItem(
        label="Slots Test"
        icon="nexd-icon-32-edit"
        path="/components/slot"
      )

  TestSection(subtitle="Default w/o path")
    div.container
      SidebarItem(
        label="Open Slots Test Click Event"
        @open="openSlotsTest"
      )

  TestSection(subtitle="Stateless")
    div.container
      SidebarItem(
        label="My Campaigns"
        :stateless="true"
      )

  TestSection(subtitle="Active")
    div.container
      SidebarItem(
        label="Sidebar Test"
        :active="true"
      )

  TestSection(subtitle="Disabled")
    div.container
      SidebarItem(
        label="Campaign Analytics"
        :disabled="true"
      )

  TestSection(subtitle="Loading")
    div.container
      SidebarItem(
        label="Flights"
        :loading="true"
      )

  TestSection(subtitle="Tooltip")
    div.container
      SidebarItem(
        label="Slot Test"
        path="/components/slot"
        icon="nexd-icon-32-arrow-right-small active"
        :tooltip="{ value: 'Hello World', position: 'right' }"
      )

  TestSection(subtitle="User Dashboard")
    div.container
      SidebarItem(
        label="Users"
        :userdashboard="true"
      )
        i.nexd-icon-32-add(aria-hidden="true")
        i.nexd-icon-16-sort(aria-hidden="true")
        i.nexd-icon-32-arrow-down-small(aria-hidden="true")

  TestSection(subtitle="User")
    div.container
      SidebarItem(
        label="Users"
        :usertab="true"
        path="/components/input"
        @remove="removeUser"
      )

  TestSection(subtitle="User Active")
    div.container
      SidebarItem(
        label="Users"
        :usertab="true"
        :active="true"
        @remove="removeUser"
      )

  div.wide
    TestLayout(title="")
      TestSection(subtitle="Spacer")
        div.container
          SidebarItem(
            label="Slots Test"
            path="/components/slot"
          )
          SidebarItem(
            label="Slots Test"
            :spacer="true"
            path="/components/slot"
          )
          SidebarItem(
            label="Sidebar Test"
            :spacer="true"
            :active="true"
          )

      TestSection(subtitle="Subitems container")
        div.container
          SidebarItem(
            label="Components"
            path="/components"
            icon="nexd-icon-32-creative"
          )
          SidebarSubitems
            SidebarItem(
              v-for="(label, path, index) of subitems"
              :key="path"
              :label="label"
              :path="`/components/${path}`"
              :subitem="true"
              :subchild="index === 2"
              :tooltip="index === 2 ? { value: label, position: 'right' } : null"
            )
</template>

<script>
import TestLayout from '@root/src/apps/components/Global/TestLayout.vue';
import TestSection from '@root/src/apps/components/Global/TestSection.vue';

import SidebarItem from '@master/UI/Sidebar/SidebarItem.vue';
import SidebarSubitems from '@master/UI/Sidebar/SidebarSubitems.vue';

export default {
  name: 'SidebarTest',

  components: {
    TestLayout,
    TestSection,

    SidebarItem,
    SidebarSubitems,
  },

  data() {
    return {
      subitems: {
        slot: 'Slot Test',
        button: 'Buttons Test',
        input: 'Input Test (Subchild | Quantum)',
        searchselect: 'Search Select Test',
        alert: 'Alert Test',
      },
    };
  },

  methods: {
    openSlotsTest() {
      this.$router.push('/components/slot');
    },

    removeUser() {
      this.$alert('User removed!');
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  border: 1px solid #e5ecf5;
  border-radius: 3px;
}

.wide {
  grid-column: 1 / -1;
}
</style>
