<template lang="pug">
ListItem(
  :to="creative.path"
  :classes="creative_dynamic_class"
  :draggable="true"
  @dragstart.stop="dragstart"
  @drag="dragScroll"
  @dragend="dragend"
)
  div.custom-td(@click.stop.prevent="")
    div.status(:class="creative_status" v-tooltip="{value: status, position: 'right'}")
    CustomCheckbox(
      :value="selected"
      :disabled="isProcessing()"
      :padded="true"
      @change="selection.toggle(creative)")
  div.custom-td
    CreativeThumbnail(v-if="!isProcessing()" :asset="creative" :small="true")
  div.custom-td
    span.title.fw-500(v-tooltip="{value: creative.name}") {{ creative.name }}
  div.custom-td.color-gray-800
    span {{ creative.type_string }}
  div.custom-td.color-gray-800
    span(v-if="isImportInProgress()") ...
    span(v-else v-tooltip="{value: creative.layout_name}") {{ creative.layout_name }}
  div.custom-td.color-gray-800
    span(v-if="isImportInProgress()") ...
    span(v-else-if="isSomeSortOfFullscreen()") Fullscreen
    span(v-else-if="isQuantum()") Multiple
    span(v-else) {{creative.width}} x {{creative.height}}
  div.custom-td.color-gray-800
    span(v-if="creative.dsp === 'None'") None
    span(v-else v-tooltip="{value: creative.dsp_title}") {{ creative.dsp_title }}
  template(v-if="isDuplicationInProgress()")
    div.custom-td.duplication-message Duplication in progress, please wait...
  template(v-else-if="isImportInProgress()")
    div.custom-td.duplication-message Importing in progress, please wait...
  template(v-else-if="is_live_with_google_ads")
    div.custom-td.google-ads-analytics.flex.column-gap-8
      img(:src="`${$cdn}dist/assets/cm/analytics-icon.svg`" :width="32" alt="analytics")
      span(@click.stop v-tooltip="{template: 'cm.creativeStatus_googleads'}") See creative analytics on&nbsp;
        a.color-cyan-blue.text-underline.fw-500(href="https://ads.google.com/aw/overview" target="_blank") Google Ads
  template(v-else)
    div.custom-td(v-for="(percentage, key) in analytics_keys")
      template(v-if="creative.status > 0 && creative.meta.analytics")
        div(v-if="percentage") {{ creative.meta.analytics[key] | PercentageFilter }}
        div(v-else) {{ creative.meta.analytics[key] | ShortNumberFilter }}
      div.color-gray-800(v-else) -
  div.custom-td
    i.nexd-icon-32-edit(
      v-if="!isProcessing()"
      aria-hidden="true"
      v-tooltip="{value: 'Edit'}"
      @click.stop.prevent="setActiveCreative")
    Loading.small(v-else style="width:max-content")
  CreativeQuickNavigation(v-if="!isProcessing()" :creative="creative")
</template>

<script>
import DraggableListItemMixin from '@root/src/global/mixins/DraggableListItemMixin.vue';

import Filters from '@master/Filters';
import { DSP, STATUS_CLASS } from '@master/constants';
import Selection from '@libs/Selection';

import CreativeService from '@master/Services/CreativeService';
import CreativesService from '@master/Services/CreativesService';

import CustomCheckbox from '@master/UI/CustomCheckbox.vue';
import Loading from '@master/UI/Loading.vue';

import CreativeListMixin from '@cm/Views/Creatives/Creative/CreativeListMixin.vue';
import CreativeQuickNavigation from '@cm/Views/Creatives/Creative/CreativeQuickNavigation.vue';
import ListItem from '@cm/UI/Global/ListItem.vue';
import CreativeThumbnail from '@master/UI/Thumbnail/CreativeThumbnail.vue';

export default {
  name: 'CreativeListItem',
  mixins: [CreativeListMixin, DraggableListItemMixin],
  components: {
    CustomCheckbox,
    Loading,
    CreativeQuickNavigation,
    ListItem,
    CreativeThumbnail,
  },

  props: {
    selection: {
      type: Selection,
      required: true,
    },
  },

  computed: {
    creative_dynamic_class() {
      let classes = [];
      if (this.is_active_creative) {
        classes.push('active');
      }
      if (this.isProcessing()) {
        classes.push('disabled');
      }
      return classes;
    },

    is_active_creative() {
      return this.active_creative?.creative_id === this.creative?.creative_id;
    },

    creative_status() {
      if (this.is_live_with_google_ads) return STATUS_CLASS.GOOGLE_ADS;
      if (this.isDraft()) return STATUS_CLASS.DRAFT;
      if (this.isWaiting()) return STATUS_CLASS.PENDING;
      if (this.isPaused()) return STATUS_CLASS.PAUSED;
      if (this.isLive()) return STATUS_CLASS.LIVE;
      if (this.isFinished()) return STATUS_CLASS.FINISHED;
      if (this.isArchived() || this.isProcessing()) return STATUS_CLASS.ARCHIVED;
      return '';
    },

    status() {
      if (this.is_live_with_google_ads) {
        return 'Exported for: Google Ads';
      }
      return Filters.StatusFilter(this.creative.status, false);
    },

    is_live_with_google_ads() {
      return this.creative.status > 0 && this.creative.dsp === DSP.GOOGLEADS;
    },

    selected() {
      return this.selection.has_items_selection && this.selection.selected_items.includes(this.creative.creative_id);
    },
  },

  data() {
    return {
      active_creative: null,
      analytics_keys: {
        impressions: false,
        engagement: true,
        ctr: true,
      },
    };
  },

  created() {
    this.checkProcessing();

    CreativesService.active.subscribe(creative => {
      this.active_creative = creative;
    }, this);

    this.prepareDraggableImage();
  },

  methods: {
    checkProcessing(cycle = 0) {
      if (!this.isProcessing()) return;

      const process_key = this.isDuplicationInProgress() ? 'duplicate' : 'import';
      this.$http
        .get(`public/processing/${process_key}/${this.creative.creative_id}`, {
          notification: false,
        })
        .then(response => {
          CreativesService.updateItems([response]);
        })
        .catch(({ code }) => {
          // retry on 408
          if (code === 408) {
            // should retry up to 10 minutes (1min per async wait cycle)
            if (cycle < 10) {
              this.checkProcessing(++cycle);
            }
          }
        });
    },

    dragstart(e) {
      if (this.isProcessing()) {
        e.preventDefault();
        return false;
      }

      e.dataTransfer.setData('action', 'add-to-folder');

      let payload = {
        creatives: [this.creative.creative_id],
        folders: [],
      };

      if (this.selected && this.selection.is_some_selected) {
        payload.creatives = this.selection.selected_items;
        payload.folders = this.selection.selected_folders;
      }

      const dragging_items = Object.values(payload).flat().length;

      e.dataTransfer.setData('application/payload', JSON.stringify(payload));
      e.dataTransfer.setDragImage(this.createDraggable(this.creative.name, dragging_items, e), 0, 0);

      this.$store.set('add_to_folder_folderids', payload.folders);
      this.$store.set('add_to_folder_in_progress', true);
    },

    dragend() {
      this.$store.reset('add_to_folder_in_progress');
      this.$store.reset('add_to_folder_folderids');
      this.removeDraggable();
    },

    selectionHandler(value) {
      this.creative._selected = value;
      this.$emit('selectionChange');
      CreativeService.set(this.creative);
    },

    setActiveCreative() {
      if (this.is_active_creative) return;
      CreativesService.active.set(this.creative);
    },
  },
};
</script>
