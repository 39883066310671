<template lang="pug">
TestLayout(title="Divider")

  TestSection(subtitle="Default")
    Divider

  TestSection(subtitle="W/ Text")
    Divider(text="OR")

  TestSection(subtitle="W/ Text & Solid")
    Divider(type="solid" text="OR")

  TestSection(subtitle="Card Divider")
    CardDivider

  TestSection(subtitle="Custom text color")
    Divider.color-danger(text="OR")

  TestSection(subtitle="Custom border color")
    CardDivider(style="--border-color: red")
</template>

<script>
import TestLayout from '@root/src/apps/components/Global/TestLayout.vue';
import TestSection from '@root/src/apps/components/Global/TestSection.vue';

import CardDivider from '@master/UI/Divider/CardDivider.vue';
import Divider from '@master/UI/Divider/Divider.vue';

export default {
  name: 'DividerTest',

  components: {
    TestLayout,
    TestSection,

    CardDivider,
    Divider,
  },
};
</script>
