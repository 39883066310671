<template lang="pug">
ImportCard(:active="true" :replace="replace != null")
  template(v-if="replace == null" v-slot:title) Import VAST XML files

  section
    small.lh-32 Paste your URLs here
      i.nexd-icon-32-help(aria-hidden="true" v-tooltip="{value: 'Insert links to existing VAST XML files or paste VAST XML here'}")

    ImportDropArea(:mime="['application/xml']" @change="fileChangeHandler")
      textarea(ref="textarea" v-model="urls")

  LoadingModal(
    v-if="loading"
    head="Importing VAST banners"
    body="Please wait while we import and optimize your VAST video for better performance"
    :percentage="timer_percentage"
  )

  template(v-slot:buttons)
    Button(type="link-primary" label="Cancel" @click="cancel")
    Button(type="primary" label="Import" :animate="true" :disabled="disabled" @click="changeHandler")
</template>

<script>
import { getBase64 } from '@helpers/Global';
import { newCreative } from '@helpers/Creative';
import { showAssetUploadErrors } from '@helpers/Alerts';

import ImportCard from '@cm/Views/Creatives/Cards/Import/ImportCard.vue';
import ImportDropArea from '@cm/Views/Creatives/Cards/Import/ImportDropArea.vue';

import Button from '@master/UI/Buttons/Button.vue';
import LoadingModal from '@master/UI/LoadingModal.vue';

import PercentageTimerMixin from '@root/src/global/mixins/PercentageTimerMixin.vue';

export default {
  name: 'VASTImport',
  mixins: [PercentageTimerMixin],

  components: {
    Button,
    ImportCard,
    ImportDropArea,
    LoadingModal,
  },

  props: {
    replace: Object,
  },

  computed: {
    disabled() {
      return this.loading === true || (this.files === null && this.urls.trim() === '');
    },
  },

  data() {
    return {
      creative: this.replace ?? null,
      files: null,
      loading: false,
      urls: '',
      content: null,
    };
  },

  created() {
    if (!this.replace) {
      newCreative({ type: 3, device: 2, imported: true }).then(creative => (this.creative = creative));
    }
  },

  mounted() {
    if (this.$refs?.textarea != null) {
      this.$refs.textarea?.focus();
    }
  },

  methods: {
    fileChangeHandler(event) {
      const { files, errors } = event;
      this.files = files;

      if (errors?.length > 0) {
        return showAssetUploadErrors(errors);
      }

      this.importFiles();
    },

    async cancel() {
      // reset all changes to original creative
      if (await this.$confirm('Are you sure?', 'All changes will be discarded')) {
        this.$emit('onRemove');
      }
    },

    importFiles() {
      if (this.disabled) return;

      this.loading = true;

      this.timerReset();

      let promises = [];
      for (const file of this.files) {
        promises.push(getBase64(file));
      }

      Promise.allSettled(promises).then(result => {
        const data = {
          campaign_id: this.$route.params.campaign_id ?? this.creative?.campaign_id ?? null,
          folder_id: this.$route.params.folder_id ?? null,
          import: [],
        };

        for (const { value } of result) {
          if (!value) continue;

          const obj = {
            filename: file.name,
            type: 'vast-file',
            data: value,
          };

          if (this.replace) {
            obj.creative_id = this.creative.creative_id;
          }

          data.import.push(obj);
        }

        this.postData(data);
      });
    },

    changeHandler() {
      if (this.disabled) return;

      if (this.urls.includes('<') === true) {
        this.content = this.urls;
        this.importContent();
        return;
      }

      this.importUrl();
    },

    importUrl() {
      if (this.disabled) return;

      let inserted_urls = this.urls.replace(/\n/g, ' ').split(' ');

      this.loading = true;

      const data = {
        campaign_id: this.$route.params.campaign_id ?? null,
        folder_id: this.$route.params.folder_id ?? null,
        import: [],
      };

      for (const url of inserted_urls) {
        if (String(url).length < 2) {
          // Fix issue if empty "urls"
          continue;
        }

        let obj = {
          type: 'vast-url',
          data: url,
        };

        if (this.replace) {
          obj.creative_id = this.creative.creative_id;
        }

        data.import.push(obj);
      }

      this.postData(data);
    },

    importContent() {
      if (this.content == null) return;

      this.loading = true;
      this.timerReset();

      let items = [];
      const index = items.push(null);
      const blob = new Blob([this.content], { type: 'application/xml' });
      getBase64(blob).then(b64_data => {
        const data = {
          campaign_id: this.$route.params.campaign_id ?? null,
          folder_id: this.$route.params.folder_id ?? null,
          import: [],
        };

        let obj = {
          type: 'vast-file',
          data: b64_data,
        };

        if (this.replace) {
          obj.creative_id = this.creative.creative_id;
        }

        data.import.push(obj);

        this.postData(data, items, index);
      });
    },

    postData(obj) {
      this.timerStart(obj.import.length, 10);

      this.$http
        .post('v2/import/async', obj)
        .then(response => {
          this.done(response);
        })
        .catch(() => {
          this.loading = false;
        })
        .finally(_ => {
          this.timerStop();
        });
    },

    done(items) {
      // just to show 100% for a sec
      setTimeout(() => {
        this.loading = false;

        // parent has different callbacks linekd with oncreate
        // on new import it has create handle (which accepts multiple items)
        // on reimport it has reimport handle (which accepts single item)
        if (this.replace) {
          this.$emit('onCreate', items[0]);
          return;
        }
        this.$emit('onCreate', items);
      }, 1000);
    },
  },
};
</script>
