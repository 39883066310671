<template>
  <div class="split-cards">
    <div class="analytics-card" data-intercom-target="analytics-engagement-rate">
      <CardHeader title="Engagement rate" tooltip="analytics.engagementCard" :no_border="true" />
      <div class="card-body no-top-border">
        <div v-if="data != null" class="left-block">
          <SmallCircleChart :value="data.values.total" :label="'Engagement'" />
          <div v-if="event_sections.length > 0" class="row">
            <template v-for="item of event_sections">
              <SummaryStats
                v-if="data.values.total != null"
                :class="[event_sections.length > max_items / 2 ? 'col-12' : 'col-24']"
                :key="item.key"
                :title="ana.get(item.key)"
                :value="item.value"
                :tooltip="item.tooltip"
                :comparedTo="summary.totals.impressions"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="analytics-card" data-intercom-target="analytics-interaction-timeline">
      <CardHeader title="Interaction timeline" tooltip="analytics.engagementInteractionCard" :no_border="true" />
      <div class="card-body no-top-border">
        <template v-if="data != null">
          <div class="row chart-header">
            <div class="col">
              <h1>{{ data.engagements.averageDwell | TimeFilter }}</h1>
              <span class="color-cyan-blue lh-32 fs-14">Exposure Time</span>
            </div>
            <div class="col-1"><!-- single col spacing --></div>
            <div class="col">
              <h1>{{ data.engagements.engagementImpressions | NumberFilter }}</h1>
              <span class="color-cyan-blue lh-32 fs-14">Engaged Impressions</span>
            </div>
          </div>
          <EngagementChart :dwell-time="data.engagements.averageDwell" :chart="data.engagements.chart" />
          <div v-if="data.engagements.chart.series != null" class="engagement-stats pl-40">
            <div class="stats-row inline">
              <span class="circle blue-dark" />
              <span>Exposure Time</span>
            </div>
            <div class="stats-row inline" v-for="(item, key) in data.engagements.chart.series.bar" :key="key">
              <span class="circle" :class="engagementColorMap[key]" />
              <span>{{ ana.get(key) }}</span>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import CardHeader from '@analytics/Views/Creative/Cards/Components/CardHeader.vue';
import SmallCircleChart from '@analytics/UI/Charts/SmallCircleChart.vue';
import EngagementChart from '@analytics/UI/Charts/Creative/EngagementChart.vue';
import SummaryStats from '@analytics/Views/Creative/Cards/Components/SummaryStats.vue';
import { engagementColorMap } from '@master/Services/AnalyticsAPPService';
import ana from '@master/Services/AnalyticsStringMapping';

export default {
  name: 'EventEngagement',
  components: {
    CardHeader,
    SmallCircleChart,
    EngagementChart,
    SummaryStats,
  },
  props: {
    data: Object,
    summary: Object,
  },
  computed: {
    event_sections() {
      // gather valid items with value > 0.01 (0.1%)
      let valid_items = [];
      for (const key in this.data.values) {
        if (key !== 'total' && !key.includes('[VTR]')) {
          const value = this.data.values[key];
          if (value > 0.001) {
            valid_items.push({ key, value, class: engagementColorMap[key], tooltip: ana.get().tooltips[key] });
          }
        }
      }

      let event_sections = [];
      for (const item of valid_items) {
        if (event_sections.length < this.max_items) {
          event_sections.push(item);
        }
      }

      return event_sections;
    },
  },

  data() {
    return {
      max_items: 10,
      engagementColorMap,
      ana,
    };
  },
};
</script>
