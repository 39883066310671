<template lang="pug">
Modal(size="modal-xs" title="Add a user")
  template(v-slot:body)
    form.flex.flex-direction-column.row-gap-16
      div(v-for="field in fields" :key="field.name")
        label.mb-4 {{ field.label }}&nbsp;
          sup.color-danger *
        Input(:variant="field.variant" :placeholder="`Enter ${field.label}`" v-model="input[field.name]" :autocomplete="field.autocomplete")

      div
        label.mb-4 Role&nbsp;
          sup.color-danger *
        SearchSelect(:options="roles" v-model="input.role_id")
  template(v-slot:footer)
    Buttons
      Button(type="link-primary" label="Cancel" @click="close(null)")
      Button(type="primary" label="Send Invite" :animate="true" :disabled="!is_valid" :loading="loading" :tooltip="{value: tooltip}" @click="invite")
</template>

<script>
import SearchSelect from '@master/UI/SearchSelect/SearchSelect.vue';
import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';
import Input from '@master/UI/Input/Input.vue';

import Modal from '@master/Modals/Modal.vue';

import { getRoles, trimQuery } from '@helpers/Global';
import { isValidEmail } from '@master/UI/Input/utils';

import { USER_ROLE } from '@master/constants';

export default {
  name: 'InviteUserModal',

  components: {
    SearchSelect,
    Button,
    Buttons,
    Input,
    Modal,
  },

  props: {
    group: Object,
  },

  computed: {
    is_valid() {
      const is_valid = Object.values(this.input).every(value => trimQuery(value ?? '').length > 0);

      if (!is_valid) {
        return false;
      }

      return isValidEmail(this.input.email);
    },

    tooltip() {
      if (!isValidEmail(this.input.email)) {
        return 'Please enter correct email.';
      }

      return this.is_valid ? null : 'All fields are required.';
    },
  },

  data() {
    return {
      loading: false,
      roles: getRoles(),

      input: {
        first_name: '',
        last_name: '',
        email: '',
        role_id: USER_ROLE.USER,
      },

      fields: [
        { label: 'First name', name: 'first_name', autocomplete: 'given-name', variant: 'empty' },
        { label: 'Last name', name: 'last_name', autocomplete: 'family-name', variant: 'empty' },
        { label: 'Email', name: 'email', autocomplete: 'email', variant: 'email' },
      ],
    };
  },

  methods: {
    invite() {
      if (!this.is_valid) {
        return;
      }

      this.loading = true;

      this.$http
        .post(`groups/${this.group.group_id}/users`, { user: this.input })
        .then(response => {
          this.close(response);
        })
        .catch(() => {
          /** suppress errors */
        })
        .finally(() => {
          this.loading = false;
        });
    },

    close(response = null) {
      this.$emit('close', response);
    },
  },
};
</script>
