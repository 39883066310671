<template lang="pug">
div(:class="styles.container")
  FlightStatement(
    v-for="(statement, index) of flight.statements"
    :key="index"
    :index="index"
    :statement="statement"
    :parent="flight.statements"
    :first="true"
    @change="change"
  )

  FlightFallback(
    v-if="flight.fallback != null"
    :statement="flight.fallback"
    :is_dco="flight.type === FLIGHTS.TYPE.TYPE_DCO"
    @change="change"
  )
</template>

<script>
import styles from './FlightStatements.module.scss';

import { FLIGHTS } from '@master/constants';

import FlightService from '@master/Services/FlightService';

import FlightFallback from '@cm/Views/Flights/Components/FlightStatements/FlightFallback/FlightFallback.vue';
import FlightStatement from '@cm/Views/Flights/Components/FlightStatements/FlightStatement/FlightStatement.vue';

export default {
  name: 'FlightStatements',

  components: {
    FlightFallback,
    FlightStatement,
  },

  data() {
    return {
      styles,
      FLIGHTS,

      flight: null,
    };
  },

  created() {
    FlightService.subscribe(data => {
      this.flight = data?.flight;
    }, this);
  },

  methods: {
    change() {
      FlightService.update(this.flight);
    },
  },
};
</script>
