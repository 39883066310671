<template lang="pug">
ListItem(
  :to="campaign.path"
  :classes="campaign_dynamic_class"
  :draggable="can_update"
  @dragstart.stop="dragStart"
  @drag="dragScroll"
  @dragend="dragEnd"
  v-tutorial="campaign.flags"
)
  div.custom-td
    div.status(:class="campaign_statuses" v-tooltip="{value: status, position: 'right'}")
    CustomCheckbox(v-if="can_update" :value="selected" :padded="true" @change="selection.toggle(campaign)")
  div.custom-td.fw-500
    div.overflow-ellipsis
      div.title.overflow-ellipsis(v-tooltip="{value: campaign.name}") {{ campaign.name }}
      div.mt-4.color-cyan-blue.fs-12.overflow-ellipsis(
        v-if="campaign?.keywords != null && campaign.keywords.length > 0"
        v-tooltip="{value: campaign.keywords.join(' / ')}"
      ) {{ campaign.keywords.join(' / ') }}
  div.custom-td.color-gray-800.column-gap-16.flex-justify-end
    i.nexd-icon-16-flights(v-if="campaign.flight_id != null" aria-hidden="true" v-tooltip="{value: 'Campaign has DCO tools set up'}")
    i.nexd-icon-16-shared-by(v-if="!is_owner" aria-hidden="true" v-tooltip="{value: campaign?.owner?.name != null ? campaign.owner.name : null}")
  div.custom-td.color-gray-800
    div {{ campaign.updated_on | DateFilter }}
  div.custom-td
    div {{ campaign?.meta?.creatives?.total || 0 }}
  template(v-for="(percentage, key) in analytics_keys")
    div.custom-td
      template(v-if="campaign?.meta?.analytics != null && campaign.status > 0")
        div(v-if="percentage") {{ campaign.meta.analytics[key] | PercentageFilter }}
        div(v-else) {{ campaign.meta.analytics[key] | ShortNumberFilter }}
      div.color-gray-800(v-else) -
  div.custom-td
    i.nexd-icon-32-edit(
      v-if="can_update"
      aria-hidden="true"
      v-tooltip="{value: 'Edit'}"
      @click.stop.prevent="setActiveCampaign"
    )
  CampaignQuickNavigation(:campaign="campaign")
</template>

<script>
import Filters from '@master/Filters';
import CampaignService from '@master/Services/CampaignService';
import CampaignMixins from '@cm/Views/Campaigns/Campaign/CampaignMixins.vue';
import DraggableListItemMixin from '@root/src/global/mixins/DraggableListItemMixin.vue';

import { STATUS, STATUS_CLASS } from '@master/constants';
import Selection from '@libs/Selection';

import CampaignQuickNavigation from '@cm/Views/Campaigns/Campaign/CampaignQuickNavigation.vue';
import CustomCheckbox from '@master/UI/CustomCheckbox.vue';
import ListItem from '@cm/UI/Global/ListItem.vue';

export default {
  name: 'Campaign',
  mixins: [CampaignMixins, DraggableListItemMixin],

  components: {
    CampaignQuickNavigation,
    CustomCheckbox,
    ListItem,
  },

  props: {
    campaign: {
      type: Object,
      default: () => null,
    },

    selection: {
      type: Selection,
      required: true,
    },

    keyword_filters: {
      type: Object,
      default: () => null,
    },
  },

  computed: {
    is_active_campaign() {
      return this.active_campaign?.campaign_id === this.campaign?.campaign_id;
    },

    campaign_dynamic_class() {
      let classes = [];
      if (this.is_active_campaign) {
        classes.push('active');
      }
      return classes;
    },

    campaign_statuses() {
      switch (this.campaign.status) {
        case STATUS.DRAFT:
          return STATUS_CLASS.DRAFT;
        case STATUS.PENDING:
          return STATUS_CLASS.PENDING;
        case STATUS.PAUSED:
          return STATUS_CLASS.PAUSED;
        case STATUS.LIVE:
          return STATUS_CLASS.LIVE;
        case STATUS.FINISHED:
        case STATUS.DEMO_FINISHED:
          return STATUS_CLASS.FINISHED;
        case STATUS.ARCHIVED:
          return STATUS_CLASS.ARCHIVED;
        default:
          return '';
      }
    },

    status() {
      return Filters.StatusFilter(this.campaign.status, false);
    },

    selected() {
      return this.selection.has_items_selection && this.selection.selected_items.includes(this.campaign.campaign_id);
    },
  },

  data() {
    return {
      active_campaign: null,
      analytics_keys: {
        impressions: false,
        engagement: true,
        ctr: true,
      },
    };
  },

  created() {
    this.prepareDraggableImage();

    CampaignService.active.subscribe(campaign => {
      this.active_campaign = campaign;
    }, this);
  },

  mounted() {
    if (this.is_new && this.$refs?.title) {
      this.$refs.title.focus();
    }
  },

  methods: {
    dragStart(e) {
      if (!this.can_update) {
        e.preventDefault();
        return false;
      }

      e.dataTransfer.setData('action', 'add-to-folder');

      let payload = {
        campaigns: [this.campaign.campaign_id],
        folders: [],
      };

      if (this.selected && this.selection.is_some_selected) {
        payload.campaigns = this.selection.selected_items;
        payload.folders = this.selection.selected_folders;
      }

      const dragging_items = Object.values(payload).flat().length;

      e.dataTransfer.setData('application/payload', JSON.stringify(payload));
      e.dataTransfer.setDragImage(this.createDraggable(this.campaign.name, dragging_items, e), 0, 0);

      this.$store.set('add_to_folder_folderids', payload.folders);
      this.$store.set('add_to_folder_in_progress', true);
    },

    dragEnd() {
      this.$store.reset('add_to_folder_in_progress');
      this.$store.reset('add_to_folder_folderids');
      this.removeDraggable();
    },

    async loadCampaignAnalytics() {
      if (this.campaign.status > 0) {
        await this.$nextTick();
        this.$http.put(`analytics/campaigns/${this.campaign.campaign_id}/quick`, {});
      }
    },

    async setActiveCampaign() {
      if (this.is_active_campaign) return;

      CampaignService.active.set(this.campaign);
      this.loadCampaignAnalytics();
    },
  },
};
</script>
