<template lang="pug">
div(:class="styles.container")
  div(:class="styles.drowdown")
    Dropdown(v-if="index !== 0 && condition.relation_type != null" :options="relation_types")
      template(v-slot:button="scope")
        span(:class="{[styles.relation_type]: true, [styles.opened]: scope.opened}") {{ condition.relation_type }}
          i.nexd-icon-32-arrow-down-small(aria-hidden="true")

  section(:class="styles.selector")
    section(:class="styles.selections")
      SearchSelect.bg-white(
        v-model="condition.key"
        :options="keys"
        :first="false"
        :search="false"
        @input="updateCondition(FLIGHT.KEYS.KEY)"
      )
      SearchSelect.bg-white(
        v-model="condition.op"
        :options="operators"
        :first="false"
        :search="false"
        width="parent"
        @input="updateCondition(FLIGHT.KEYS.OP)"
      )

      SearchSelectMultiple.bg-white(
        v-if="languages != null && condition.key === FLIGHT.TYPES.LANGUAGE"
        :value="condition.value"
        placeholder="Select Language(s)"
        :options="languages"
        @change="updateConditionValue($event, FLIGHT.TYPES.LANGUAGE)"
      )
      SelectTimeRange.bg-white(
        v-else-if="condition.key === FLIGHT.TYPES.TIME"
        :start="condition.value[0]"
        :end="condition.value[1]"
        :timezone="condition.value[2]"
        @change="updateConditionValue($event)"
      )
      SearchSelectGoogleLocations.bg-white(
        v-else-if="condition.key === FLIGHT.TYPES.LOCATION"
        :value="condition.value"
        @input="updateConditionValue($event, FLIGHT.TYPES.LOCATION)"
      )
      SelectDateRange.bg-white(
        v-else-if="condition.key === FLIGHT.TYPES.DATE"
        :start="condition.value[0]"
        :end="condition.value[1]"
        :timezone="condition.value[2]"
        @change="updateConditionValue([$event.start, $event.end, $event.timezone], FLIGHT.TYPES.DATE)"
      )
      input.form-control(
        v-else-if="condition.key === FLIGHT.TYPES.ZIP"
        v-model="postalcodes"
        v-key-down="keyboard_keys"
        placeholder="Insert or paste postal code(s)"
        @paste.prevent="handlePostalCodesFromExcel"
        @blur="updatePostalCodes"
      )
      div.color-gray-800(v-else) -

    section(:class="styles.icons")
      div(:class="styles.custom")
        Button(
          v-for="op of Object.keys(FLIGHT.SEPARATORS)"
          :key="op"
          type="cyan"
          :label="op"
          :outline="true"
          :disabled="loading?.[FLIGHT.LOADING.CONDITION] ?? false"
          :loading="updating_key === op"
          @click="addCondition(op)"
        )

      Button(type="link-accent" :disabled="loading?.[FLIGHT.LOADING.CONDITION] ?? false" :loading="updating_key === 'delete'" @click="removeCondition")
        template(v-slot:prefix)
          i.nexd-icon-32-delete
</template>

<script>
import styles from './FlightCondition.module.scss';

import { newCondition } from '@cm/Views/Flights/helpers/mocks';
import { KEYS, FLIGHT } from '@master/constants';
import FlightService from '@master/Services/FlightService';
import { getLanguages } from '@helpers/Global';

import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';
import Dropdown from '@master/UI/Dropdown.vue';
import SearchSelect from '@master/UI/SearchSelect/SearchSelect.vue';
import SearchSelectGoogleLocations from '@master/UI/SearchSelect/SearchSelectGoogleLocations.vue';
import SearchSelectMultiple from '@master/UI/SearchSelect/SearchSelectMultiple.vue';
import SelectDateRange from '@master/UI/SearchSelect/SelectDateRange.vue';
import SelectTimeRange from '@master/UI/SearchSelect/SelectTimeRange.vue';

export default {
  name: 'FlightCondition',

  components: {
    Button,
    Buttons,
    Dropdown,
    SearchSelect,
    SearchSelectGoogleLocations,
    SearchSelectMultiple,
    SelectDateRange,
    SelectTimeRange,
  },

  props: {
    statement: Object,
    condition: Object,

    index: {
      type: Number,
      default: 0,
    },

    loading: {
      type: Object,
      default: () => null,
    },
  },

  computed: {
    relation_types() {
      return Object.keys(FLIGHT.SEPARATORS).map(label => ({
        label,
        onclick: this.changeRelationType,
      }));
    },
  },

  data() {
    return {
      styles,

      FLIGHT,

      keys: {
        '-1': 'Select condition',
        [FLIGHT.TYPES.LOCATION]: 'Location',
        [FLIGHT.TYPES.ZIP]: 'Postal code',
        [FLIGHT.TYPES.LANGUAGE]: 'Language',
        [FLIGHT.TYPES.DATE]: 'Date range',
        [FLIGHT.TYPES.TIME]: 'Time range',
      },

      operators: {
        '-1': 'Select operator',
        ...FLIGHT.OPERATORS,
      },

      languages: null,
      postalcodes: '',

      updating_key: null,

      keyboard_keys: {
        [KEYS.ENTER]: () => this.addComma(KEYS.ENTER),
        [KEYS.SPACE]: () => this.addComma(KEYS.SPACE),
      },
    };
  },

  async created() {
    this.languages = await getLanguages();
  },

  methods: {
    addCondition(relation_type = null) {
      if (relation_type == null) return;

      if (!Array.isArray(this.statement?.conditions)) {
        this.$set(this.statement, 'conditions', []);
      }

      /** create new condition */
      const new_condition = newCondition({
        statement_id: this.statement.id,
        relation_type,
      });

      /** get previous and next conditions */
      const prev_condition = this.statement?.conditions?.[this.index];
      const next_condition = this.statement?.conditions?.[this.index + 1];

      /** update previous condition's next_id */
      if (prev_condition != null) {
        this.statement?.conditions.splice(this.index, 1, { ...prev_condition, next_id: new_condition.id });
      }

      /** update next statements's prev_id */
      if (next_condition != null) {
        this.statement?.conditions.splice(this.index + 1, 1, { ...next_condition, prev_id: new_condition.id });
      }

      /** add new statement */
      this.statement?.conditions.splice(this.index + 1, 0, new_condition);
      this.change();
    },

    async removeCondition() {
      const reset = this.statement?.conditions?.length === 1;

      if (!(await this.$confirm(`Are you sure you would like to ${reset ? 'reset' : 'delete'} this condition?`))) {
        return;
      }

      if (reset) {
        this.statement?.conditions?.splice(this.index, 1, newCondition({ statement_id: this.statement.id }));
        this.change();
        return;
      }

      /** get previous and next conditions */
      const prev_condition = this.statement?.conditions?.[this.index - 1] ?? null;
      const next_condition = this.statement?.conditions?.[this.index + 1] ?? null;

      /** update previous condition's next_id */
      if (prev_condition != null) {
        this.statement?.conditions.splice(this.index - 1, 1, { ...prev_condition, next_id: next_condition?.id ?? null });
      }

      /** update next statements's prev_id */
      if (next_condition != null) {
        this.statement?.conditions.splice(this.index + 1, 1, { ...next_condition, prev_id: prev_condition?.id ?? null });
      }

      /** remove condition */
      this.statement?.conditions.splice(this.index, 1);

      if (FlightService.hasValidId(this.statement?.id) && FlightService.hasValidId(this.condition?.id)) {
        this.$http.delete(`v2/flights/${this.statement.flight_id}/statements/${this.statement.id}/conditions/${this.condition.id}`).catch(() => {
          /** suppress errors */
        });
      }

      this.change();
    },

    changeRelationType({ label }) {
      this.$set(this.condition, 'relation_type', label);
      this.change();
    },

    updateCondition(key = null) {
      if (key == null) return;

      if (key === FLIGHT.KEYS.KEY) {
        this.$set(this.condition, 'value', []);
      }

      this.change();
    },

    updateConditionValue(new_values, type = null) {
      if (type === FLIGHT.TYPES.LOCATION) {
        new_values = new_values?.filter(item => !item?.every(element => element == null));
      }

      this.$set(this.condition, 'value', new_values);
      this.updateCondition(FLIGHT.KEYS.VALUE);
    },

    handlePostalCodesFromExcel(event) {
      this.postalcodes = '';
      this.postalcodes = event.clipboardData.getData('text').replace(/\n+/gm, ', ');
    },

    updatePostalCodes(event) {
      this.postalcodes = event.target.value.replace(/\s/gm, '').replace(/,+/gm, ', ');
      this.updateConditionValue(this.postalcodes.split(', '));
    },

    parsePostalCodes() {
      if (this.condition?.key === FLIGHT.TYPES.ZIP) {
        this.postalcodes = this.condition.value.join(', ');
      }
    },

    addComma(key = null) {
      const postalcodes = this.postalcodes.trim();

      if (this.condition.key !== FLIGHT.TYPES.ZIP || postalcodes === '' || postalcodes.charAt(postalcodes.length - 1) === ',') return;

      switch (key) {
        case KEYS.ENTER:
          this.postalcodes += ', ';
          break;
        case KEYS.SPACE:
          this.postalcodes += ',';
          break;
      }
    },

    change() {
      this.$emit('change');
    },
  },

  watch: {
    'condition.value': {
      handler() {
        this.parsePostalCodes();
      },
      immediate: true,
    },
  },
};
</script>
