<template lang="pug">
div(ref="modal")
  div.modal(:class="styles.modal")
    div.backdrop
    div.modal-content(:class="styles.content")
      div.modal-head(:class="styles.head")
        h1 Your trial has ended
        p We hope you experienced how Nexd can transform your advertising.
        p(v-if="is_group_admin") It's now time to add your credit card and continue building successful campaigns.
        p(v-else) It's now time to ask your organization admin to activate a subscription plan.
      div.modal-body(:class="styles.body")
        Button(
          v-if="is_group_admin"
          type="primary"
          label="Choose a paid plan"
          :animate="true"
          :block="true"
          @click="openBilling"
        )
        Button(
          v-else
          type="primary"
          label="Open profile"
          :animate="true"
          :block="true"
          @click="openProfile"
        )
</template>

<script>
import styles from './TrialEndScreen.module.scss';
import Modal from '@master/UI/Modal.vue';
import Button from '@master/UI/Buttons/Button.vue';
import { VIEW } from '@master/constants';

export default {
  name: 'TrialEndScreen',
  extends: Modal,

  components: {
    Button,
  },

  data() {
    return {
      styles,
      is_group_admin: false,
    };
  },

  created() {
    this.$user.subscribe(_ => {
      this.is_group_admin = this.$user.isGroupAdmin();
    }, this);
  },

  methods: {
    openBilling() {
      this.$router.push({ name: VIEW.BILLING }).catch(_ => {
        /** ignore duplicate route, when popup is triggered in current view */
      });
      this.$emit('close');
    },
    openProfile() {
      this.$router.push({ name: VIEW.PROFILE }).catch(_ => {
        /** ignore duplicate route, when popup is triggered in current view */
      });
      this.$emit('close');
    },
  },
};
</script>
