<template lang="pug">
Container(
  title="Billing"
  subtitle="Your past invoices"
)
  div(v-if="loading")
    Loading
  Invoices(v-else-if="invoices.length > 0")
    Invoice(
      v-for="invoice in invoices"
      :key="invoice.id"
      :billing_date="invoice.invoice.details.created_date"
      :invoice_number="invoice.invoice.details.no"
      :invoice_amount="invoice.invoice.details.sum"
      :invoice_currency="invoice.invoice.details.currency"
      :status="invoice.invoice.details.status"
      :url_download="invoice.invoice.pdf.url_download"
      :url_view="invoice.invoice.pdf.url_view"
    )
    Buttons(v-if="show_load_more" align="left")
      Button(type="link" label="Load more" :loading="loading_more" @click="loadMore")
  div(v-else) No invoices yet

  h2.mt-32.mb-8 Payment methods
  CreditCard(
    v-if="organization != null"
    :organization="organization"
  )
</template>

<script>
import BillingService from '@cm/Views/Billing/helpers/BillingService';

import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';
import Loading from '@master/UI/Loading.vue';

import CreditCard from '@cm/Views/Billing/Components/CreditCard/CreditCard.vue';
import Container from '@cm/Views/Billing/Components/Container/Container.vue';
import Invoice from '@cm/Views/Billing/Components/Invoices/Invoice.vue';
import Invoices from '@cm/Views/Billing/Components/Invoices/Invoices.vue';

export default {
  name: 'InvoicesCard',

  components: {
    Button,
    Buttons,
    CreditCard,
    Container,
    Invoice,
    Invoices,
    Loading,
  },

  data() {
    return {
      organization: null,

      loading: true,
      loading_more: false,

      invoices: [],
      limit: 5,
      offset: 0,
      show_load_more: true,
    };
  },

  created() {
    this.loadPayments();
  },

  methods: {
    async loadPayments() {
      this.loading = true;

      this.organization = await BillingService.getOrganization();

      if (this.organization?.group_id != null) {
        if (!Array.isArray(this.invoices)) {
          this.invoices = [];
        }

        this.requestPayments();
      }
    },

    requestPayments() {
      this.$http
        .get(`v2/group/${this.organization.group_id}/payments?invoices=true&offset=${this.offset}&limit=${this.limit}`)
        .then(response => {
          this.invoices.push(...response);

          if (response.length === 0) {
            this.show_load_more = false;
          }
        })
        .catch(() => {
          /** Failed to load invoices */
        })
        .finally(() => {
          this.loading = false;
          this.loading_more = false;
        });
    },

    loadMore() {
      this.offset += this.limit;

      if (this.organization?.group_id != null) {
        this.loading_more = true;
        this.requestPayments();
      }
    },
  },
};
</script>
