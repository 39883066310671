<template lang="pug">
div.tab-content
  Loading(v-if="!settings_loaded")
  div.options-list(v-else)
    template(v-if="!isVast()")
      OptionsFilters(:creative="creative" title="Global settings" :keys="options_keys.global" :arno="arno" @change="change")
      OptionsFilters(:creative="creative" title="Behaviour" :keys="options_keys.behaviour" :arno="arno" @change="change")

    OptionsFilters(v-if="isVideo() && creative.settings.vast != null" type="vast" :creative="creative" title="In-stream" :keys="options_keys.video" :arno="arno" @change="change")

    template(v-if="!isVast()")
      OptionsFilters(:creative="creative" title="Layout settings" :keys="options_keys.layout" :arno="arno" @change="change")
      OptionsFilters(
        :creative="creative"
        title="Gesture"
        :keys="options_keys.gesture"
        :extra="options_keys.gesture_extra"
        :type="'gesture'"
        :arno="arno"
        @change="change")
      OptionsFilters(:creative="creative" title="Close button" :keys="options_keys.close_button" :arno="arno" @change="change")
      OptionsFilters(:creative="creative" title="Lightbox" :keys="options_keys.lightbox_button" :arno="arno" @change="change")
      OptionsFilters(:creative="creative" title="Controls" :keys="options_keys.controls" :arno="arno" @change="change")
      OptionsFilters(v-if="creative.settings.tag != null" type="tag" :creative="creative" title="Sponsored" :keys="options_keys.sponsored" :arno="arno" @change="change")

      UIGroup.options-filters(v-if="admin_toggle" title="Admin Settings" :subtitle="true" :collapsed="true")
        div.option-items
          OptionsFilters(:creative="creative" title="General" :keys="options_keys.admin" :arno="arno" @change="change")
          OptionsFilters(:creative="creative" title="Tag" type="tag" :keys="options_keys.tag" :arno="arno" @change="change")

  UpsellingFooter(type="options")
</template>

<script>
import OptionsFilters from '../TemplateSettings/OptionsFilters.vue';
import UpsellingFooter from '@master/UI/UpsellingFooter.vue';
import CreativeTraits from '@master/Traits/CreativeTraits.vue';
import Loading from '@master/UI/Loading.vue';
import Button from '@master/UI/Buttons/Button.vue';
import UIGroup from '@master/UI/UIGroup.vue';

import TemplatesSettingsService from '@master/Services/TemplatesSettingsService';

export default {
  name: 'TabOptions',
  mixins: [CreativeTraits],
  components: {
    OptionsFilters,
    UpsellingFooter,
    Loading,
    Button,
    UIGroup,
  },
  props: {
    creative: Object,
    arno: Object,
  },

  data() {
    return {
      admin_toggle: false,
      settings_loaded: false,
      options_keys: {
        global: ['floating', 'bg_col', 'inactivitytime', 'edit_tag', 'splitscreen_reverse', 'tag_stay_in_container'],
        behaviour: [
          'template_speed',
          'loop_animate',
          'start_spin',
          'spin_speed',
          'auto_rotate',
          'turn_pause',
          'parallax_effect',
          'endcard_delay',
          'roller_scale_x',
          'roller_scale_y',
          'scroll_interaction',
          'play_after_scratch',
          'slide_duration',
          'teaser_intensity',
          'auto_animate',
          'auto_animate_type',
          'gestureSpeed',
        ],
        video: ['skip_ad', 'skipoffset'],
        layout: ['randomize_questions', 'randomize_choices', 'rtl_mode'],
        gesture: ['mobile', 'desktop', 'gesture'],
        gesture_extra: ['gesture_line_color', 'anim_gesture_img', 'gesture_animation_duration', 'slider_position', 'maxdrawtime', 'brush_scale'],
        sponsored: ['sponsored', 'sponsoredtop', 'sponsoredbottom'],
        admin: ['framework_version', 'outroll', 'sticky', 'logging', 'custom_attributes'],
        tag: ['bordered', 'anchor', 'paragraphpush'],
        close_button: ['custom_close'],
        lightbox_button: ['custom_gesture_lightbox', 'custom_close_lightbox'],
        controls: ['has_arrows'],
      },
    };
  },

  created() {
    this.$user.subscribe(() => {
      this.$set(this, 'admin_toggle', this.$user.adminMode());
    }, this);

    if (this.creative.settings == null) {
      this.$set(this.creative, 'settings', {});
    }

    TemplatesSettingsService.subscribe(_ => {
      this.settings_loaded = true;
    }, this);
  },

  methods: {
    change(reload_assets_library = false) {
      this.$emit('change', reload_assets_library);
    },
  },
};
</script>
