<script>
import AssetsLibraryService from '@master/Services/AssetsLibraryService';

import CreativeTraits from '@master/Traits/CreativeTraits.vue';
import AssetTraits from '@cm/Views/Creatives/Traits/AssetTraits.vue';

import { ASSET, DSP } from '@master/constants';

import AssetHelper from '@helpers/Asset';
import IconLoader from '@helpers/IconLoader';

export default {
  mixins: [CreativeTraits, AssetTraits],

  computed: {
    swapping_asset_ids() {
      return this.$store.get('swapping_asset_ids') ?? [];
    },

    swapping_asset() {
      return this.$store.get('swapping_asset');
    },

    swapping_asset_in_progress() {
      return this.$store.get('swapping_asset_in_progress') ?? false;
    },

    assets_library() {
      return this.$store.get('active_assets_library');
    },

    empty() {
      if (this.is_map) {
        return this.visible_active_asset == null;
      }

      return this.visible_active_asset == null || !this.has_asset_uri;
    },

    is_map() {
      return AssetHelper.isMap(this.asset.filename);
    },

    is_google_ads() {
      return this.creative.dsp === DSP.GOOGLEADS;
    },

    is_splitscreen_carousel() {
      return this.creative?.template?.template_base?.startsWith('splitCarouselVideo') ?? false;
    },

    is_overlay() {
      return AssetHelper.isOverlay(this.asset);
    },

    is_additional_overlay() {
      return this.is_overlay && this.additional;
    },

    is_logo() {
      return this.asset?.filename?.includes('custom_logo') ?? false;
    },

    is_cta() {
      return this.asset?.filename?.includes('custom_cta') ?? false;
    },

    is_responsive_panorama_overlay() {
      return this.isResponsivePanoramaOverlay(this.asset.asset_id);
    },

    is_video() {
      return AssetHelper.isVideo(this.visible_active_asset?.uri);
    },

    is_video_endcard() {
      return this.asset?.filename?.includes('endcard') ?? false;
    },

    is_swap_enabled() {
      return !this.is_map && !this.isQuantum() && !this.is_hotspot && !this.is_custom_splash;
    },

    is_hotspot() {
      return AssetHelper.isCustomHotspotMain(this.asset_id);
    },

    is_custom_splash() {
      return AssetHelper.isCustomSplash(this.asset_id);
    },

    can_use_arno() {
      return this.arno != null && this.is_splitscreen_carousel && !this.is_additional_overlay;
    },

    can_swap() {
      const original_mime = this.creative?.assets?.[this.swapping_asset?.asset_id]?.original_mime ?? this.creative?.additional_assets?.[this.swapping_asset.asset_id]?.original_mime ?? '';

      if (!AssetHelper.isAcceptedMime(this.accepted_mimes, original_mime)) {
        return false;
      }

      const type = this.creative.template.assets[this.asset.asset_id]?.type ?? this.creative.additional_assets[this.asset.asset_id]?.custom_type ?? ASSET.NORMAL;

      let can_swap = type === this.swapping_asset.type && !this.is_map;

      if (can_swap && !AssetHelper.isCustom(this.swapping_asset.asset_id) && !AssetHelper.isCustom(this.asset.asset_id)) {
        const width = this.creative.template.assets[this.asset.asset_id]?.width ?? 0;
        const height = this.creative.template.assets[this.asset.asset_id]?.height ?? 0;

        can_swap = width === this.swapping_asset.width && height === this.swapping_asset.height;
      }

      return can_swap;
    },

    has_settings() {
      if (this.empty || this.isVast() || this.isVpaid()) {
        return false;
      }

      return this.is_video || this.is_video_endcard || this.is_hotspot || this.is_custom_splash;
    },

    has_size_specific_assets() {
      return this.responsive_collection?.length > 0;
    },

    has_asset_uri() {
      return this.visible_active_asset?.uri != null && this.visible_active_asset?.uri !== '';
    },

    accepted_info_tooltip() {
      return `<div>
        <div>Accepted files: <span class="color-gray-500">(${this.file_input_accept_extensions})</span></div>
        <div>Size: <span class="color-gray-500">${this.asset_recommended_size.text}<span></div>
      <div>`;
    },

    tooltip_suffix() {
      if (this.sizeset != null) {
        return ` for ${this.sizeset}`;
      }
      return '';
    },

    video_export_1080() {
      if (this.creative?.settings?.video_export == null) return false;

      return this.creative.settings.video_export.enabled && ['fb-1080', 'landscape-16by9', 'portrait-9by16'].includes(this.creative.settings.video_export.type);
    },
  },

  data() {
    return {
      enable_slot_dragging: false,
      show_map_csv_modal: false,
      dsp_asset_slot_options: process.env.VUE_APP_asset_slot_options ?? null,
      map_asset_icon: IconLoader.url('map-asset.svg'),
      map_asset_uploaded_icon: IconLoader.url('map-asset-uploaded.svg'),
    };
  },

  methods: {
    showIcon(key) {
      if (this.dsp_asset_slot_options == null) return true;

      return this.dsp_asset_slot_options.includes(key);
    },

    notAcceptedError() {
      if (this.is_google_ads && AssetHelper.isVideo(this.library_upload_data.uri)) {
        this.$alert('Google Ads platform does not allow video in ads from third parties. Please use only images or publish your creative via other platform', 'Google Ads does not allow video in ads');
        return;
      }

      this.$alert('Please check the list of permitted file types and try again.', 'Sorry, that file type is not accepted');
    },

    async assetInUseConfirmation() {
      return this.$confirm('Are you sure you want to remove this asset from slot?');
    },

    confirmGlobalAssetChange() {
      return this.$confirm('Apply changes to all placements?', 'You are currently replacing the global asset for ' + this.asset.name + '. Do you' + '\n' + 'want to replace this asset for all placements?', {
        buttons: [
          { type: 'link-primary', label: 'Cancel', action: null },
          { type: 'primary', outline: true, label: 'Few', action: 'few' },
          { type: 'primary', label: 'All', action: 'all' },
        ],
      });
    },

    openMapCSVModal() {
      this.show_map_csv_modal = true;
    },

    closeMapCSVModal() {
      this.show_map_csv_modal = false;
    },

    componentChangeHandler(reload = false) {
      if (reload) {
        this.$emit('reload');
      } else {
        this.$emit('change');
      }
    },

    loadAssetLibrary() {
      if (this.creative.assets_library_id != null) {
        AssetsLibraryService.load();
      }
    },

    getMainAssetPlacement() {
      if (this.is_splitscreen_carousel && this.can_use_arno && this.is_overlay) {
        try {
          const size = AssetHelper.getSizeFromArno(this.arno, AssetHelper.getTemplateAssetForArno(this.asset), this.creative);

          this.cropper_options.placement.offset = {
            x: size.x,
            y: size.y * -1,
          };
        } catch (e) {
          // supress error thrown
          // use case -> user opened 3 year old "MVP" version of splitscreen carousel
          // if this function exits, we just ignore the main asset offset as in the layout wants
          return;
        }
      }
    },

    enableSlotDragging(e) {
      if (!this.is_swap_enabled) {
        e.preventDefault();
        return;
      }
      this.enable_slot_dragging = true;
    },

    disableSlotDragging() {
      this.enable_slot_dragging = false;
    },

    reloadSettings() {
      this.$http.get(`creative/${this.creative.creative_id}/settings`).then(settings_object => {
        this.$set(this.creative.settings, 'cta', settings_object.cta);
        this.$set(this.creative.settings, 'assets', settings_object.assets);
      });
    },
  },
};
</script>
