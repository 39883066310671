<template lang="pug">
div(ref="modal")
  div.modal
    div.backdrop
    div.modal-content
      div.modal-head
        div.modal-title Flight overview

      div.modal-body
        | Here you can see on overview of all creatives used in the flights.
        | Under each creative there are displayed rule blocks which show when each creative is shown.
        | At least one of the rule blocks must match in order for the creative to be shown.

      div.mt-32.flex.flex-align-center.flex-justify-center.flex-direction-column(
        v-if="!creatives.length"
      )
        img.mb-16(:src="`${this.$cdn}dist/nexd/imgs/buy_media_snail.svg`" style="width: 150px")
        span.fs-14.color-cyan-blue Nothing to show here yet.
        span.fs-14.color-cyan-blue Please add creatives to your flight to see overview.

      div.modal-body.mt-16(v-else)
        UIGroup(v-for="(creative, index) of creatives" :key="index" :title="creative.name" :subtitle="true" :collapsed="false")
          div.fs-14.mb-8 Priority: {{ creative.priority }}%
          pre.mt-4(v-for="(group, idx) of creative.conditions" :key="`${index}_${idx}`")
            p(v-for="(sentence, i) of group" :key="`${index}_${idx}_${i}`")
              | {{ getSentence(sentence, languages) }}

      div.modal-footer
        Buttons
          Button(type="link-primary" label="Close" @click="$emit('close')")
</template>

<script>
import { getCreativeConditions, getSentence } from '@cm/Views/Flights/helpers/preview';

import FlightService from '@master/Services/FlightService';
import { getLanguages } from '@helpers/Global';

import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';
import Modal from '@master/UI/Modal.vue';
import UIGroup from '@master/UI/UIGroup.vue';

export default {
  name: 'FlightPreview',
  mixins: [Modal],

  components: {
    Buttons,
    Button,
    UIGroup,
  },

  data() {
    return {
      getSentence,

      flight: null,

      languages: [],
      creatives: [],
    };
  },

  created() {
    FlightService.subscribe(async data => {
      this.flight = data?.flight;

      if (this.flight != null) {
        this.languages = await getLanguages();
        this.creatives = getCreativeConditions();
      }
    }, this);
  },
};
</script>
