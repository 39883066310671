<template lang="pug">
div#app-wrapper.flex.flex-direction-column.row-gap-16
  div.flex.flex-align-center.flex-justify-end.column-gap-16
    div.fs-14 Show data for:
    SearchSelect.bg-white(v-model="range" :options="ranges" :search="false" :disabled="disabled_selection" @change="changeRange" width="9rem")
    template(v-if="can_switch_group")
      div.fs-14 Select group:
      SearchSelect.bg-white(v-model="selected_group_id" :options="groups" :disabled="disabled_selection || loading_groups" @change="changeGroup" width="18rem")

  div(:class="styles.dashboard")
    TotalImpressions(
      :loading="loading"
      :impressions="meta?.impressions"
      :ranges="meta?.ranges"
    )

    TotalCarbonSaving(
      :loading="loading"
      :data="meta?.savetheplanet"
    )

    CardSmall(
      label="Avg CTR"
      :value="meta?.avg_ctr"
      :loading="loading"
    )

    TopLayouts(:range="range" :group="selected_group_id" @load="loading_layouts = $event")

    CardSmall(
      label="Avg engagement"
      :value="meta?.avg_engagement"
      :loading="loading"
    )

    TopCreatives(:range="range" :group="selected_group_id" @load="loading_creatives = $event")
    TopPosts
</template>

<script>
import styles from './Dashboard.module.scss';

import SearchSelect from '@master/UI/SearchSelect/SearchSelect.vue';
import Queue from '@libs/Queue';

import CardSmall from '@cm/Views/Dashboard/Components/CardSmall.vue';
import TotalCarbonSaving from '@cm/Views/Dashboard/Components/TotalCarbonSaving.vue';
import TopCreatives from '@cm/Views/Dashboard/Components/TopCreatives.vue';
import TotalImpressions from '@cm/Views/Dashboard/Components/TotalImpressions.vue';
import TopLayouts from '@cm/Views/Dashboard/Components/TopLayouts.vue';
import TopPosts from '@cm/Views/Dashboard/Components/TopPosts.vue';

const queue = new Queue();

export default {
  name: 'Dashboard',

  components: {
    CardSmall,
    SearchSelect,
    TotalCarbonSaving,
    TopCreatives,
    TotalImpressions,
    TopLayouts,
    TopPosts,
  },

  computed: {
    disabled_selection() {
      return this.loading || this.loading_layouts || this.loading_creatives;
    },
  },

  data() {
    return {
      styles,

      ranges: [
        { value: 'current_month', label: 'This month' },
        { value: 'last_month', label: 'Last month' },
        { value: 'current_year', label: 'This year' },
        { value: 'last_year', label: 'Last year' },
      ],
      range: null,

      loading_groups: false,
      groups: {},
      selected_group_id: null,

      loading: false,
      loading_layouts: false,
      loading_creatives: false,
      meta: null,

      can_switch_group: false,
    };
  },

  created() {
    this.$user.subscribe(user => {
      const range = this.$user.getSetting('dashboard.range', 'current_month');

      if (range !== this.range) {
        this.range = range;
        this.loadMeta();
      }

      if (user != null && this.$user.isNexdAdmin()) {
        this.can_switch_group = true;
        this.loadAdminGroups();
      }
    }, this);
  },

  methods: {
    loadAdminGroups() {
      this.loading_groups = true;

      queue
        .get('v2/admin/groups/simple')
        .then(groups => {
          this.groups = { '': 'My group' };

          for (const group_id in groups) {
            if (groups[group_id]?.trim() !== '') {
              this.groups[group_id] = groups[group_id];
            }
          }
        })
        .catch(() => {
          /** suppress errors */
        })
        .finally(() => {
          this.loading_groups = false;
        });
    },

    loadMeta() {
      this.loading = true;

      let path = `dashboard/meta?period=${this.range}`;

      if (this.selected_group_id && this.selected_group_id !== '') {
        path += `&group_id=${this.selected_group_id}`;
      }

      queue
        .get(path)
        .then(meta => {
          this.meta = meta;
        })
        .catch(() => {
          /** suppress errors */
        })
        .finally(() => {
          this.loading = false;
        });
    },

    changeRange({ value }) {
      this.$user.setSetting('dashboard.range', value);
      this.loadMeta();
    },

    changeGroup() {
      this.loadMeta();
    },
  },
};
</script>
