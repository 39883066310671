<template lang="pug">
div(:class="[styles.card, styles.card_medium]")
  span(:class="styles.label") Total impressions&nbsp;
    i.nexd-icon-16-help.pl-4(v-tooltip="{value: tooltip_total_impressions}" aria-hidden="true")
  Loading(v-if="loading")
  template(v-else)

    section.flex.flex-align-center.column-gap-16
      div(:class="[styles.title, { 'color-gray-600': component_current === 0 }]") {{ component_current | NumberFilter }}
      span.fw-500(v-if="component_percent !== 0" :class="[component_percent < 0 ? 'color-danger' : 'color-success']") {{ component_percent | PercentageFilter(2, 'always') }}

    section
      span.fs-14.color-cyan-blue Same time period year before&nbsp;
        i.nexd-icon-16-help.pl-4(v-tooltip="{value: tooltip_comparisson}" aria-hidden="true")
      div.fw-500.mt-4(:class="{ 'color-gray-600': component_last_year === 0 }") {{ component_last_year | NumberFilter }}
</template>

<script>
import styles from '../Dashboard.module.scss';

import Loading from '@master/UI/Loading.vue';

export default {
  name: 'TotalImpressions',

  components: {
    Loading,
  },

  props: {
    loading: {
      type: Boolean,
      default: true,
    },

    impressions: {
      type: Object,
      default: null,
      validator: value => 'current_period' in value && 'last_period' in value && 'diff' in value,
    },

    ranges: {
      type: Object,
      default: null,
      validator: value => 'current' in value && 'last' in value && Object.values(value).every(v => 'start' in v && 'end' in v && Object.values(v).every(_v => typeof _v === 'number')),
    },
  },

  computed: {
    component_current() {
      return this.impressions?.current_period ?? 0;
    },

    component_last_year() {
      return this.impressions?.last_period ?? 0;
    },

    component_percent() {
      return this.impressions?.diff ?? 0;
    },

    tooltip_total_impressions() {
      if (this.ranges == null) {
        return null;
      }

      return `Time period: ${this.getDateString(this.ranges.current.start)} - ${this.getDateString(this.ranges.current.end)}`;
    },

    tooltip_comparisson() {
      if (this.ranges == null) {
        return null;
      }

      return `Time period: ${this.getDateString(this.ranges.last.start)} - ${this.getDateString(this.ranges.last.end)}`;
    },
  },

  data() {
    return {
      styles,
    };
  },

  methods: {
    getDateString(date) {
      return this.$options.filters.DateFilter(date, { utc: true, format: 'MMM D, YYYY' });
    },
  },
};
</script>
