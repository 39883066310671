<template lang="pug">
TestLayout(title="Tooltip")

  template(v-slot:header)
    Button.mb-16(type="primary" label="Toggle tooltip" :outline="true" @click="toggle")

  TestSection(subtitle="template without :key")
    template(v-slot:tooltip)
      i.nexd-icon-32-help(aria-hidden="true" v-tooltip="{'template': moat_tooltip, position: 'right'}")

  TestSection(subtitle="template without :key")
    template(v-slot:tooltip)
      i.nexd-icon-32-help(aria-hidden="true" v-tooltip="{'template': state ? moat_tooltip : null, position: 'right'}")

  TestSection(subtitle="without :key")
    template(v-slot:tooltip)
      i.nexd-icon-32-help(aria-hidden="true" v-tooltip="{'value': tooltip_value, position: 'right'}")

  TestSection(subtitle="without :key")
    template(v-slot:tooltip)
      i.nexd-icon-32-help(aria-hidden="true" v-tooltip="{'value': state ? tooltip_value : null, position: 'right'}")

  TestSection(subtitle="template with :key (validate)")
    template(v-slot:tooltip)
      i.nexd-icon-32-help(:key="key" aria-hidden="true" v-tooltip="{'template': moat_tooltip, position: 'right'}")
    pre {{state}}
    pre {{moat_tooltip}}

  TestSection(subtitle="template with :key (validate)")
    template(v-slot:tooltip)
      i.nexd-icon-32-help(:key="key" aria-hidden="true" v-tooltip="{'template': state ? moat_tooltip : null, position: 'right'}")
    pre {{state}}
    pre {{state ? moat_tooltip : null}}

  TestSection(subtitle="with :key (validate)")
    template(v-slot:tooltip)
      i.nexd-icon-32-help(:key="key + '_2'" aria-hidden="true" v-tooltip="{'value': tooltip_value, position: 'right'}")
    pre {{state}}
    pre {{tooltip_value}}

  TestSection(subtitle="with :key (validate)")
    template(v-slot:tooltip)
      i.nexd-icon-32-help(:key="key + '_2'" aria-hidden="true" v-tooltip="{'value': state ? tooltip_value : null, position: 'right'}")
    pre {{state}}
    pre {{state ? tooltip_value : null}}
</template>

<script>
import TestLayout from '@root/src/apps/components/Global/TestLayout.vue';
import TestSection from '@root/src/apps/components/Global/TestSection.vue';

import Button from '@master/UI/Buttons/Button.vue';

export default {
  name: 'TooltipTest',

  components: {
    TestLayout,
    TestSection,
    Button,
  },

  computed: {
    moat_tooltip() {
      if (this.state) {
        return 'cm.moatTrackingLive';
      }
      return 'cm.moatTracking';
    },

    tooltip_value() {
      if (this.state) {
        return 'The state is true';
      }
      return 'The state is false';
    },
  },

  data() {
    return {
      state: true,
      key: 0,
    };
  },

  methods: {
    toggle() {
      this.state = !this.state;
      this.key++;
    },
  },
};
</script>
