<template lang="pug">
Card#flight.pt-16.pb-24.px-24.relative
  section.flex.flex-align-start.flex-justify-between
    h2 Current Priority Chart
    NotificationBox(width="300px" info="Calculations are updated every 6 hours. Please note that before the first optimization occurs, the creatives used must accumulate a minimum of 10,000 impressions.")

  div.mt-16.flex.flex-justify-between.column-gap-32
    Loading.fill(v-if="loading")
    template(v-else)
      div.priority-chart

      div.flex-grow(style="overflow-x: auto;")
        table.table.analytics-table.fixed-header(v-sortable-table="creatives" aria-label="Performance table")
          thead
            tr
              th(scope="col" data-key="name") Creative
              th(scope="col" data-key="priority", default-order) Weight
              th(scope="col" data-key="impressions") Impressions
              th(scope="col" data-key="engagement") Engagement
              th(scope="col" data-key="ctr") CTR

          tbody(v-if="creatives != null")
            tr(v-for="(creative, index) of creatives" :key="index")
              td.flex.flex-align-center.column-gap-8(style="max-width:15rem;")
                div(:class="creative.class")
                span.flex-grow.overflow-ellipsis {{ creative.name }}
                img(
                  v-if="removed_creatives.includes(creative.creative_id)"
                  :src="$cdn + 'dist/assets/cm/warning.svg'"
                  width="28"
                  v-tooltip="{value: 'This creative has been removed from the creative optimization settings but still exists in live. It will be removed with the next export.', class: 'simple warning'}"
                )
              td {{ (creative.priority / 100) | PercentageFilter }}
              td {{ creative.impressions ?? 0 | NumberFilter}}
              td {{ creative.engagement ?? 0 | PercentageFilter }}
              td {{ creative.ctr ?? 0 | PercentageFilter }}
</template>

<script>
import Chartist from '@libs/Chartist';
import FlightService from '@master/Services/FlightService';

import Loading from '@master/UI/Loading.vue';
import NotificationBox from '@master/UI/NotificationBox/NotificationBox.vue';

import Card from '@cm/Views/Flights/Components/Card.vue';

export default {
  name: 'DCOPriorityChart',

  components: {
    Card,
    Loading,
    NotificationBox,
  },

  computed: {
    flight_creative_ids() {
      return this.flight?.fallback?.creatives?.map(creative => creative.creative_id) ?? [];
    },

    removed_creatives() {
      return this.creatives?.filter(creative => !this.flight_creative_ids.includes(creative.creative_id))?.map(creative => creative.creative_id) ?? [];
    },
  },

  data() {
    return {
      flight: null,

      creatives: [],
      loading: false,
      loaded: false,
    };
  },

  created() {
    FlightService.subscribe(data => {
      this.flight = data?.flight;

      if (this.flight) {
        if (this.loaded) {
          this.initChart();
        } else {
          this.loadAnalytics();
        }
      }
    }, this);
  },

  methods: {
    async initChart() {
      await this.$nextTick();

      try {
        new Chartist.Pie(
          '.priority-chart',
          {
            series: this.creatives.map(creative => creative.priority),
          },
          {
            width: 200,
            height: 200,
            showLabel: false,
          },
        );
      } catch (_) {}
    },

    async loadAnalytics() {
      if (this.loaded || this.loading) {
        return;
      }

      this.loading = true;

      await this.$http
        .get(`v2/flights/${this.flight.id}/analytics/dco`)
        .then(response => {
          this.creatives = this.serializeData(Object.values(response.analytics));
          this.loaded = true;
        })
        .finally(() => {
          this.loading = false;

          // must be after loading is set to false
          // otherwise chart cannot find the target element, since tamplete has v-if & v-else
          if (this.loaded) {
            this.initChart();
          }
        });
    },

    serializeData(data) {
      if (!data?.length) {
        return;
      }

      return data
        .sort((a, b) => b.priority - a.priority)
        .map((creative, index) => ({
          creative_id: creative.creative_id,
          name: creative.name,
          priority: creative.priority,
          class: `table-series-${this.numberToAlphabet(index)}`,
          ctr: creative.ctr ?? 0,
          impressions: creative.impressions ?? 0,
          engagement: creative.engagement ?? 0,
        }));
    },

    numberToAlphabet(number) {
      return String.fromCharCode(97 + (number % 14));
    },
  },
};
</script>

<style lang="scss">
$map: (
  a: #ff9900,
  b: #66a6ff,
  c: #ff5b27,
  d: #006aff,
  e: #ffd012,
  f: #79288c,
  g: #f6e9f9,
  h: #ffa724,
  i: #3388ff,
  j: #ed187e,
  k: #eb5757,
  l: #dbffdb,
  m: #81ffb4,
  n: #0ecc0e,
  o: #ffa6fb,
);

@each $key, $value in $map {
  .ct-series-#{$key} .ct-slice-pie {
    fill: $value !important;
  }

  .table-series-#{$key} {
    width: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: $value;
  }
}
</style>
