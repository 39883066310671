<template lang="pug">
  div.form-group.mb-8
    div.row.flex-align-top(v-if="type === 'choice'" :draggable="dragging")
      div.col.no-gutter( :class="{'drag-dropbox': dragover}")
        i.nexd-icon-16-dots.pt-8.pb-8.move(@mousedown="$emit('dragStart', index)")
      div.col.flex-grow
        TextareaWithCounter(v-model="choice.text.value" placeholder="Insert text" @input="$emit('update')" :isInput="true" :suffix="true")
          template(v-slot:suffix)
            i.color-primary(:class="input_suffix" v-tooltip="{value: input_tooltip}")
          RemoveIcon(type="input" @click="$emit('remove', index)")
    template(v-else-if="type_other")
      TextareaWithCounter(v-model="choice.text.value" placeholder="Insert \"Other\" text" @input="$emit('update')" :isInput="true" :suffix="true")
        template(v-slot:suffix)
          i.nexd-icon-16-text-input.color-primary(v-tooltip="{value: 'Insert description text for “Other” field (max 90 char)'}")
        RemoveIcon(type="input" @click="$emit('remove', index)")
</template>

<script>
import TextareaWithCounter from '@master/UI/TextareaWithCounter.vue';
import RemoveIcon from '@master/UI/RemoveIcon.vue';

export default {
  name: 'Choice',
  components: {
    TextareaWithCounter,
    RemoveIcon,
  },
  props: {
    choice: Object,
    question: Object,
    type: String,
    index: Number,
    dragging: Boolean,
    dragover: Boolean,
  },

  computed: {
    type_other() {
      return this.type === 'other';
    },

    single_selection() {
      return this.question.type === 'single';
    },

    input_suffix() {
      if (this.single_selection) {
        return 'nexd-icon-16-single-choice';
      }
      return 'nexd-icon-16-multi-choice';
    },

    input_tooltip() {
      if (this.single_selection) {
        return 'Type in a choice text. Single selection lets the ad viewer choose only one choice.';
      }
      return 'Type in a choice text. Multi selection lets the ad viewer choose multiple choices.';
    },
  },

  created() {
    if (this.type_other && this.choice.text.value === '') {
      this.$set(this.choice.text, 'value', 'Other');
    }
  },
};
</script>
