<template lang="pug">
SidebarContainer(
  title="Creative Settings"
  :timestamp="creative.updated_on"
  @close="close"
  @open="open($event, true)"
)
  template(v-slot:content)
    div.label-group
      h4 Name:
    TextareaInput(
      ref="title"
      placeholder="Creative title"
      v-model="creative.name"
      @change="saveCreative"
    )

    hr
    div.label-group
      h4 Export as tag for:
    SearchSelect.mb-16(
      v-if="dsp_options != null"
      :options="dsp_options"
      reference=".platform"
      v-model="creative.dsp"
      @input="saveCreative"
    )

    div.expanded-state-buttons.flex.mb-16(v-if="isBinded()")
      Button(
        type="quantum"
        label="First state"
        :active="!show_expanded_cta"
        :block="true"
        :large="true"
        @click="show_expanded_cta = false"
      )
      Button(
        type="quantum"
        label="Second state"
        :active="show_expanded_cta"
        :block="true"
        :large="true"
        @click="show_expanded_cta = true"
      )

    template(v-if="creative?.settings?.cta && !show_expanded_cta")
      div.mb-16(v-if="!isBinded()")
        div.label-group
          h4 Default click action:
          i.nexd-icon-16-help.ml-8(aria-hidden="true" v-tooltip="{template: 'cm.ctaGlobal'}")
        TrackingRowHeader(tab="cta")
        CTARowComponent(
          :creative="creative"
          :global="true"
          @change="settingChangeHandler"
        )
      div.mb-16
        div.label-group
          h4 Creative tracking:
          i.nexd-icon-16-help.ml-8(aria-hidden="true" v-tooltip="{template: 'cm.trackingGlobal'}")
        TrackingRowHeader(tab="tracking")
        TrackingRowComponent(
          :creative="creative"
          :global="true"
          :actions="['all']"
          @change="settingChangeHandler"
        )

    template(v-if="creative?.expanded?.settings?.cta && show_expanded_cta")
      div.mb-16
        div.label-group
          h4 Default click action:
          i.nexd-icon-16-help.ml-8(aria-hidden="true" v-tooltip="{template: 'cm.ctaGlobal'}")
        TrackingRowHeader(tab="cta")
        CTARowComponent(
          :creative="creative.expanded"
          :global="true"
          @change="settingChangeHandler($event, creative.expanded)"
        )
      div.mb-16
        div.label-group
          h4 Creative tracking:
          i.nexd-icon-16-help.ml-8(aria-hidden="true" v-tooltip="{template: 'cm.trackingGlobal'}")
        TrackingRowHeader(tab="tracking")
        TrackingRowComponent(
          :creative="creative.expanded"
          :global="true"
          :actions="['all']"
          @change="settingChangeHandler($event, creative.expanded)"
        )
</template>

<script>
import CreativesService from '@master/Services/CreativesService';
import SearchService from '@master/Services/SearchService';
import DSPService from '@master/Services/Cache/DSPService';

import Button from '@master/UI/Buttons/Button.vue';
import SearchSelect from '@master/UI/SearchSelect/SearchSelect.vue';

import SidebarContainer from '@root/src/global/sidebar/edit/container/SidebarContainer.vue';

import TrackingRowHeader from '@cm/Views/Creatives/Cards/Components/Tracking/Components/TrackingRowHeader.vue';
import CTARowComponent from '@cm/Views/Creatives/Cards/Components/Tracking/CTA/CTARowComponent.vue';
import TrackingRowComponent from '@cm/Views/Creatives/Cards/Components/Tracking/CTA/TrackingRowComponent.vue';
import TextareaInput from '@cm/Views/Creatives/Cards/Components/Tracking/Components/TextareaInput.vue';

import CreativeListMixin from '@cm/Views/Creatives/Creative/CreativeListMixin.vue';

import { VIEW } from '@master/constants';

export default {
  name: 'CreativeSidebar',
  mixins: [CreativeListMixin],

  components: {
    Button,
    SearchSelect,
    TextareaInput,
    TrackingRowHeader,
    CTARowComponent,
    TrackingRowComponent,
    SidebarContainer,
  },

  data() {
    return {
      dsp_options: null,
      show_expanded_cta: false,
    };
  },

  created() {
    DSPService.subscribe(dsps => {
      if (dsps == null) return;
      this.dsp_options = [];
      for (const dsp of dsps) {
        this.dsp_options.push({ value: dsp.slug, label: dsp.title });
      }
    }, this);

    // list view does not load settings.cta, but its needed for the view
    if (!this.creative?.settings?.cta) {
      this.loadCTA();
    }
  },

  methods: {
    saveCreative() {
      const path = `v2/creative/${this.creative.creative_id}`;
      const obj = {
        name: this.creative.name,
        dsp: this.creative.dsp,
      };

      this.$http.put(path, obj, { notification: false }, 'creative_save').then(creative => {
        CreativesService.updateItems([creative]);

        if (this.$route.name === VIEW.SEARCH) {
          SearchService.active.set(this.creative);
        }
      });
    },

    loadCTA() {
      this.$http.get(`creative/${this.creative.creative_id}/settings/cta`).then(({ cta }) => {
        // make sure creative has settings key, on certain custom cases it might be missing
        if (!this.creative.settings) {
          this.$set(this.creative, 'settings', {});
        }
        this.$set(this.creative.settings, 'cta', cta);
        CreativesService.updateItems([this.creative]);
      });

      if (this.isBinded()) {
        this.loadBindedCTA();
      }
    },

    loadBindedCTA() {
      if (this.creative?.expanded?.creative_id == null) return;

      this.$http.get(`creative/${this.creative.expanded.creative_id}/settings/cta`).then(({ cta }) => {
        // make sure creative has settings key, on certain custom cases it might be missing
        if (!this.creative?.expanded?.settings) {
          this.$set(this.creative.expanded, 'settings', {});
        }
        this.$set(this.creative.expanded.settings, 'cta', cta);
        CreativesService.updateItems([this.creative]);
      });
    },

    close() {
      CreativesService.active.set(null);
    },

    settingChangeHandler(value, creative = null) {
      creative = creative ?? this.creative;

      this.$set(creative.settings, 'cta', value);
      CreativesService.updateItems([this.creative]);
      this.$http.put(`creative/${creative.creative_id}/settings/cta`, { value: value });
    },
  },
};
</script>
