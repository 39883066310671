<template lang="pug">
CollapsableCard(
  :opened="auto_open"
  :tooltip="object != null && object.settings != null ? object.settings.tooltip : null"
  :reset_btn_visible="object.data.questions.length > 0"
  :reset_btn_tooltip="{value: 'Reset question to default'}"
  :has_delete_btn="object.data.questions.length > 1"
  :indicator_label="indicator_label"
  :indicator_icon="indicator_icon"
  @reset_btn_click="resetQuestion"
  @delete_btn_click="removeQuestion"
)
  template(v-slot:header_title)
    h5 {{index + 1 | StringNumberFilter(true)}} Question
      span.color-red-primary.pl-2(v-if="index === 0") &nbsp;*
  template(v-slot:additional_header_content)
    TextareaWithCounter(v-model="question.question.text.value" :max_char="max_char" :placeholder="`Insert question text (max char ${max_char})`" @input="change")
  template(v-slot:collapsable_body)
    QuestionType.mb-24(:question="question" @update="$emit('update')")
    QuestionChoices(:question="question" @update="$emit('update')")
</template>

<script>
import QuestionType from './QuestionType.vue';
import QuestionChoices from './QuestionChoices.vue';
import CollapsableCard from '@cm_modules/CollapsableCard.vue';
import TextareaWithCounter from '@master/UI/TextareaWithCounter.vue';

import SurveyHelper from './Helper.js';

export default {
  name: 'Question',
  components: {
    QuestionChoices,
    QuestionType,
    CollapsableCard,
    TextareaWithCounter,
  },
  props: {
    object: Object,
    question: Object,
    index: Number,
  },
  data() {
    return {
      max_char: 300,
      auto_open: this.index === 0,
      indicator_label: 'Add choices',
      indicator_icon: 'nexd-icon-16-cog',
    };
  },
  methods: {
    removeQuestion() {
      this.$emit('remove', this.$vnode.key);
    },

    resetQuestion() {
      this.$set(this.object.data.questions, this.index, SurveyHelper.getQuestionBase());
      this.$emit('update');
    },

    change() {
      this.$set(this, 'auto_open', true);
      this.$emit('update'); // Since Requestlimiter will decline requests after each input - we can automatically save stuff whenever user stops typing
    },
  },
};
</script>
