<template lang="pug">
div#app-wrapper
  SecondaryNavbar(:wide="true")
    Button(v-if="!landing" type="link" label="Go Back" @click="goBack")
    div.flex-grow
    template(v-if="landing")
      Button(type="primary" label="Generate Ads" :disabled="generate_disabled" :tooltip="generate_tooltip" :animate="true" @click="generateAds")
    template(v-else)
      Button(type="primary" label="Dismiss All" :outline="true" @click="dismiss")
      Button(type="primary" label="Save Selection as Campaign" :animate="true" @click="save")

  div.relative(v-content)
    div(:class="styles.card")
      h1
        template(v-if="landing") AI Ad Creator
        template(v-else) Generated ads
      p.mt-12.color-gray-800.fs-14.lh-20
        template(v-if="landing")
          | With AI Ad creator, you can upload your image assets and explore different ad variations tailored to various layouts.
          | Regardless of the number of assets that you have available,
          | our platform intelligently crafts optimized variations for each layout that can hold all of the assets you have provided.
          br
          | In order to use AI Ad creator, upload your assets to the right slots and click generate.
          | AI Ad creator will then show you 6 different variations that can be generated with your assets.
          | If you don’t find any that fit your needs, you can always generate more.
        template(v-else)
          | We've generated these creatives. You can choose the ones you like and press "Generate more" to see other
          | variations and layouts that are available with the assets provided.
          | To proceed with the selection, press&nbsp;
          span.hover-primary(@click="save") "Save Selection as Campaign".

      CreativeRow.mt-24(v-if="landing" label="Size (px):" :tooltip="{template: 'cm.placementSize'}")
        template(v-slot:content)
          CreativeSize(
            :creative="creative"
          )

      section.mt-32.flex.flex-align-center.column-gap-16(v-if="!landing")
        label.flex.flex-align-center
          input(type="checkbox" :checked="all_selected" :data-partial="some_selected" @input="() => creator.toggleAllCreatives()")
          span.adcreator-preview-label Select all creatives
        div.flex-grow
        div.flex.flex-align-center.hover-primary(v-if="can_load_more_ads" :class="{'disabled': loading_more}" @click="generateMoreAds")
          i.nexd-icon-16-reload(aria-hidden="true")
          span.ml-8.fs-14 Generate More
        div.flex.flex-align-center.hover-primary(@click="() => creator.reload()")
          i.nexd-icon-16-reload(aria-hidden="true")
          span.ml-8.fs-14 Reload Ads
      hr

      template(v-if="landing")
        div(:class="styles.asset_containers")
          AssetContainer(v-model="files.main" :max="10" label="Upload Main Assets")
          AssetContainer(v-model="files.logo" label="Upload Logo")
          AssetContainer(v-model="files.cta" label="Upload CTA")
          AssetContainer(v-model="files.background" label="Upload Background Asset")

        Buttons.mt-24
          Button(type="primary" label="Generate Ads" :disabled="generate_disabled" :tooltip="generate_tooltip" :animate="true" @click="generateAds")

      div(v-else :class="styles.ads" ref="adcreator")

  LoadingModal(
    v-if="generating || loading"
    :head="generating ? 'AI is generating Ads' : 'Creating selected creatives'"
    :percentage="generating ? percentage : null"
  )
</template>

<script>
import styles from './AdCreator.module.scss';

import CreativeSizeService from '@master/Services/CreativeSizeService';

import AssetContainer from '@cm/Views/AdCreator/Components/AssetContainer.vue';
import CreativeRow from '@cm/Views/Creatives/Creative/CreativeRow.vue';

import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';
import CreativeSize from '@master/UI/CreativeSize/CreativeSize.vue';
import LoadingModal from '@master/UI/LoadingModal.vue';
import SecondaryNavbar from '@master/UI/SecondaryNavbar/SecondaryNavbar.vue';

import { appendScript } from '@helpers/Global';
import { VIEW } from '@master/constants';

export default {
  name: 'AdCreator',

  components: {
    AssetContainer,
    Button,
    Buttons,
    CreativeRow,
    CreativeSize,
    LoadingModal,
    SecondaryNavbar,
  },

  computed: {
    generate_tooltip() {
      if (!this.files.main.length) {
        return {
          value: 'To generate ads, please ensure you add at least one main asset.',
        };
      }

      if (this.has_warning) {
        return {
          value: 'To generate ads, please ensure you added correct width and height.',
        };
      }

      return null;
    },

    generate_disabled() {
      return this.has_warning || this.generating || !this.files.main.length;
    },
  },

  data() {
    return {
      styles,

      creative: {
        width: 300,
        height: 250,
      },

      files: {
        main: [],
        logo: [],
        cta: [],
        background: [],
      },

      landing: true,
      generating: false,
      percentage: 0.05,
      has_warning: false,
      output: null,

      all_selected: false,
      some_selected: false,

      ready: false,
      error: false,
      loading: false,

      creator_max_count: 9,
      creator: null,
      can_load_more_ads: false,
      loading_more: false,
    };
  },

  created() {
    CreativeSizeService.subscribe(state => {
      this.has_warning = state?.has_warning ?? false;
    }, this);

    this.loadAdCreator();
  },

  methods: {
    loadAdCreator() {
      if (window.AdCreator) {
        this.ready = true;
      } else {
        // for MVP use app version, correct would be to use CDN version as in GlobalServicePlugin
        const script = appendScript(`${this.$cdn}dist/AdCreator/adcreator.min.js`, this.$cdn_version ?? this.$version);
        script.onload = _ => {
          this.ready = true;
        };
        script.onerror = _ => {
          this.error = true;
        };
      }
    },

    async generateAds() {
      if (this.generate_disabled) {
        return;
      }

      this.landing = false;

      await this.$nextTick();

      this.generating = true;
      this.can_load_more_ads = false;

      const options = {
        width: this.creative.width,
        height: this.creative.height,
        path: `${this.$cdn}dist/AdCreator/`,
        teele: this.$teele,
        assets: this.files,
      };

      this.creator = new AdCreator(options, this.$refs.adcreator, window.Preview);
      this.creator.generate(this.creator_max_count).then(can_load_more_ads => {
        this.generating = false;
        this.can_load_more_ads = can_load_more_ads;
      });
      this.creator.onchange(checkboxes => {
        this.all_selected = checkboxes?.every(checkbox => checkbox.checked);
        this.some_selected = !this.all_selected && checkboxes?.some(checkbox => checkbox.checked);
      });
    },

    generateMoreAds() {
      if (!this.can_load_more_ads || !this.creator || this.loading_more) {
        return;
      }
      this.loading_more = true;
      this.creator.generateMore(this.creator_max_count).then(can_load_more_ads => {
        this.can_load_more_ads = can_load_more_ads;
        this.loading_more = false;
      });
    },

    goBack() {
      if (this.creator) {
        this.creator.unload();
      }
      this.landing = true;
    },

    dismiss() {
      this.goBack();

      this.files = {
        main: [],
        logo: [],
        cta: [],
        background: [],
      };
    },

    save() {
      const data = {
        width: this.creative.width,
        height: this.creative.height,
        creatives: [],
      };

      const jsons = this.creator.getSettingsSelected();

      if (!jsons?.length) {
        return this.$alert('Please select at least one creative');
      }

      for (const json of jsons) {
        const setting = json.settings.creatives[0].settings.ad;
        const creative = {
          template_id: setting.templateId,
          settings: {},
          assets: {},
        };

        const settings_to_handle = {
          floating: 'floating',
          teaserIntensity: 'teaser_intensity',
          sliderPosition: 'slider_position',
          templateSpeed: 'template_speed',
          vertical: 'vertical',
        };

        for (const key in settings_to_handle) {
          if (setting[key] != null) {
            creative.settings[settings_to_handle[key]] = setting[key];
          }
        }

        for (const asset of Object.values(setting.assets)) {
          creative.assets[asset.assetId] = asset.uri;
        }
        data.creatives.push(creative);
      }

      this.loading = true;
      const path = 'adcreator';
      this.$http
        .post(path, data)
        .then(({ campaign }) => {
          this.$router.push({
            name: VIEW.CREATIVES,
            params: { campaign_id: campaign.campaign_id },
          });
        })
        .catch(_ => {
          /** supress errors */
        })
        .finally(_ => {
          this.loading = false;
        });
    },
  },
};
</script>
