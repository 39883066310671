<template lang="pug">
BillingLayoutContainer(:sidebar="true")
  template(v-slot:navbar)
    Buttons.flex-grow(align="right")
      Button(
        type="link-primary"
        :label="cancel_button_label"
        @click="cancel"
      )
      Button(
        type="success"
        :animate="true"
        :label="main_button_label"
        :loading="loading_text != null || loading_preview"
        :disabled="main_button_disabled"
        :tooltip="main_button_tooltip"
        @click="proceed"
      )

  template(v-slot:sidebar)
    Cart(
      v-if="steps !== step"
      :currency="cart_payload?.currency"
      :subtotal="cart?.subtotal ?? 0"
      :total="cart?.total ?? 0"
      total_label="Total package cost"
      :saved="cart?.saving ?? 0"
    )
      template(v-slot:services)
        span Impressions cost
        span {{ formatValue(cart?.impressions?.full) }}

        template(
          v-for="feature of cart?.features"
        )
          span {{ feature.name }}
          span {{ formatValue(feature?.full, true) }}

      template(v-slot:vat v-if="organization != null")
        span VAT ({{ vat_percent * 100 }}%):
        span {{ formatValue(cart?.vat ?? 0, false, false) }}

      template(v-slot:estimated)
        div.mt-16.fw-500 Estimated unpaid amount
        div.fs-22.color-primary.mt-4 {{ formatValue(cart?.unpaid_total ?? 0, false, false) }}

    div(v-else style="width:17rem;")

  template(v-slot:content)
    PackageCalculator(
      v-show="step === 1"
      @update="updateCart"
    )
    BillingDetails(
      v-show="step === 2 && steps === 3"
    )
    Checkout(
      :checkout="checkout"
      :currency="cart_payload?.currency"
      v-show="steps === 2 ? step === 2 : step === 3"
    )

    LoadingModal(
      v-if="loading_text != null"
      :head="loading_text"
      body="Please wait, it can take a little bit time to process your purchase"
    )
</template>

<script>
import BillingService from '@cm/Views/Billing/helpers/BillingService';
import { formatPrice } from '@cm/Views/Billing/helpers';
import { createCart } from '@cm/Views/Billing/helpers/cart';
import { VIEW } from '@master/constants';

import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';
import LoadingModal from '@master/UI/LoadingModal.vue';

import BillingDetails from '@cm/Views/Billing/Components/BillingDetails/BillingDetails.vue';
import BillingLayoutContainer from '@cm/Views/Billing/Components/BillingLayoutContainer/BillingLayoutContainer.vue';
import Checkout from '@cm/Views/Billing/Components/Checkout/Checkout.vue';
import PackageCalculator from '@cm/Views/Billing/Components/PackageCalculator/PackageCalculator.vue';
import Cart from '@cm/Views/Billing/Components/Cart/Cart.vue';

export default {
  name: 'ChoosePackage',

  components: {
    BillingDetails,
    BillingLayoutContainer,
    Button,
    Buttons,
    Checkout,
    LoadingModal,
    PackageCalculator,
    Cart,
  },

  computed: {
    vat_percent() {
      return this.organization?.apply_vat ? 0.2 : 0;
    },

    main_button_label() {
      if ((this.step === 1 && this.steps === 2) || (this.step === 2 && this.steps === 3)) {
        return 'Proceed to checkout';
      }

      if (this.step === 1 && this.steps === 3) {
        return 'Proceed to billing details';
      }

      return 'Purchase';
    },

    main_button_disabled() {
      return (this.steps - this.step === 1 || this.steps === this.step) && this.cart?.unpaid_subtotal === 0;
    },

    main_button_tooltip() {
      if (this.main_button_disabled) {
        return { value: "You haven't made changes to your plan" };
      }

      return null;
    },

    cancel_button_label() {
      return this.step > 1 ? 'Back' : 'Cancel';
    },
  },

  data() {
    return {
      VIEW,

      organization: null,
      subscription: null,

      loading_text: null,
      loading_preview: false,

      trial_user: false,
      steps: 2,
      step: 1,

      cart_payload: null,
      checkout: null,
      cart: null,
    };
  },

  created() {
    this.$user.subscribe(async user => {
      if (user == null) return;

      this.organization = await BillingService.getOrganization();
      this.subscription = await BillingService.getSubscription();
    });
  },

  methods: {
    async updateCart(cart_payload) {
      this.cart_payload = cart_payload;

      const cart = await createCart(cart_payload);
      this.cart = cart;
    },

    formatValue(value, free = false, suffix = true) {
      return formatPrice(value, {
        currency: this.cart_payload?.currency,
        free,
        suffix: suffix ? '/mo' : null,
      });
    },

    askPurchaseConfirmation() {
      return this.$confirm('Confirm purchase', 'Are you sure you want to purchase this package?', {
        buttons: [
          { type: 'link-primary', label: 'Cancel', action: false },
          { type: 'primary', label: 'Purchase', action: true },
        ],
      });
    },

    cancel() {
      if (this.step === 1) {
        this.$router.push({ name: VIEW.BILLING });
      } else if (this.step === 2) {
        this.step = 1;
      } else if (this.step === 3) {
        this.step = 2;
      }
    },

    proceed() {
      if (this.steps - this.step === 1) {
        return this.getPreview();
      }

      if (this.step === this.steps) {
        return this.purchasePackage();
      }

      this.step = this.step + 1;
    },

    async purchasePackage() {
      if ((await this.askPurchaseConfirmation()) && this.cart_payload != null && this.organization != null) {
        this.loading_text = 'Processing payment';

        this.$http
          .post(`v2/group/${this.organization.group_id}/subscription`, this.cart_payload)
          .then(response => {
            if (response?.id != null) {
              BillingService.reloadSubscriptions();
              this.$notifications.add('success', 'Payment successfully done!');
              this.$router.push({ name: VIEW.BILLING });
            } else {
              this.$alert('Purchase failed for some reason', 'Oops!');
            }
          })
          .catch(() => {
            this.loading_text = null;
            this.$alert('Purchase failed for some reason', 'Oops!');
          });
      }
    },

    getPreview() {
      if (this.organization?.group_id != null) {
        this.loading_preview = true;

        this.$http
          .post(`v2/group/${this.organization.group_id}/subscription/preview`, this.cart_payload)
          .then(response => {
            this.checkout = response;
            this.step = this.step + 1;
          })
          .finally(() => {
            this.loading_preview = false;
          });
      }
    },
  },

  watch: {
    '$route.query.trial_user': {
      handler(trial_user) {
        this.trial_user = trial_user === true || trial_user === 'true';

        if (this.trial_user) {
          this.steps = 3;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
