<template lang="pug">
Modal(title="Disable Two-Factor Authentication" size="modal-sm")
  template(v-slot:body)
    ol.pl-16
      li.color-gray-800
        h3 Open your authenticator app.
      li.mt-24.color-gray-800
        h3 Enter the code generated by your authenticatior app.
        form(@submit.prevent="remove")
          Input.mt-8(v-model="mfa_code" placeholder="Enter the code")
  template(v-slot:footer)
    Buttons
      Button(type="link-primary" label="Cancel" :disabled="removing" @click="close")
      Button(type="success" label="Verify code" :loading="removing" :submit="true")
</template>

<script>
import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';
import Input from '@master/UI/Input/Input.vue';
import Modal from '@master/Modals/Modal.vue';

export default {
  name: 'Disable2FAModal',

  components: {
    Button,
    Buttons,
    Input,
    Modal,
  },

  data() {
    return {
      mfa_code: '',
      removing: false,
    };
  },

  methods: {
    close() {
      this.$emit('close');
    },

    remove() {
      if (this.removing) {
        return;
      }

      this.removing = true;

      this.$http
        .delete('mfa', { code: this.mfa_code })
        .then(response => {
          this.$user.set({ mfa_enabled: response.mfa_enabled });

          if (!response.mfa_enabled) {
            this.close();
            this.$alert('Two-factor authentication is removed from your account.', 'Two-factor authentication disabled', {
              image: 'success',
            });
          }
        })
        .catch(_ => {
          /** error msg handled by httpservice */
        })
        .finally(_ => {
          this.removing = false;
          this.mfa_code = '';
        });
    },
  },
};
</script>

<style scoped>
li::marker {
  font-weight: 500;
}
</style>
