var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data != null && _vm.data.length > 0)?_c('div',[(_vm.data.length > 1)?_c('div',{staticClass:"tabs outlined"},_vm._l((_vm.data),function(item,index){return _c('div',{key:index,staticClass:"tab video-tab",class:{ active: index === _vm.active_tab },on:{"click":function($event){return _vm.changeTab(index)}}},[_vm._v("\n      "+_vm._s(item.title)+"\n    ")])}),0):_vm._e(),_vm._v(" "),_c('div',{staticClass:"split-cards"},[_c('div',{staticClass:"analytics-card"},[_c('CardHeader',{attrs:{"title":"View through rate","tooltip":"analytics.videoThroughRateCard","no_border":true}}),_vm._v(" "),_c('div',{staticClass:"card-body no-top-border"},[(_vm.data == null)?_c('Loading'):(_vm.active_tab !== null)?_c('div',{staticClass:"vtr"},[_vm._l((_vm.circle_chart_values[_vm.active_tab]),function(item,key){return [_c('SummaryStats',{key:key,attrs:{"title":_vm.ana.get(item.key),"value":item.value,"total":item.total,"tooltip":key === 0
                  ? 'Percentage of impressions where user started watching the video'
                  : 'Percentage of impressions where user ' + (key === 4 ? 'watched video until the end' : 'watched at least ' + (key === 2 ? 'half of the video' : 25 * key + '% of the video')),"tooltipTotal":key === 0
                  ? 'Amount of impressions where user started watching the video'
                  : 'Amount of impressions where user ' + (key === 4 ? 'watched the video until the end' : 'watched at least ' + (key === 2 ? 'half of the video' : 25 * key + '% of the video')),"change":item.key === 'w100'}})]})],2):_vm._e()],1)],1),_vm._v(" "),_c('div',{staticClass:"analytics-card"},[_c('CardHeader',{attrs:{"title":"Video engagement","tooltip":"analytics.videoEngagementCard","no_border":true}}),_vm._v(" "),_c('div',{staticClass:"card-body no-top-border"},[(_vm.data == null)?_c('Loading'):_vm._e(),_vm._v(" "),(_vm.data != null && _vm.data.length > 0)?_c('div',[(_vm.active_tab !== null)?_c('div',{staticClass:"row chart-header"},[_c('div',{staticClass:"col"},[_c('h1',[_vm._v(_vm._s(_vm._f("NumberFilter")(_vm.data[_vm.active_tab].started)))])]),_vm._v(" "),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ value: 'Amount of impressions where user started watching the video' }),expression:"{ value: 'Amount of impressions where user started watching the video' }"}],staticClass:"col"},[_vm._m(0)]),_vm._v(" "),_c('div',{staticClass:"col-1"}),_vm._v(" "),_c('div',{staticClass:"col"},[_c('h1',[_vm._v(_vm._s(_vm._f("NumberFilter")(_vm.data[_vm.active_tab].completed)))])]),_vm._v(" "),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ value: 'Amount of impressions where user watched the video until the end' }),expression:"{ value: 'Amount of impressions where user watched the video until the end' }"}],staticClass:"col"},[_vm._m(1)])]):_vm._e(),_vm._v(" "),(_vm.active_tab != null)?[_c('VideoEngagementChart',{key:_vm.active_tab,attrs:{"total":_vm.data[_vm.active_tab].started,"chart":_vm.data[_vm.active_tab].chart}}),_vm._v(" "),(_vm.data[_vm.active_tab].chart != null)?_c('div',{staticClass:"engagement-stats",staticStyle:{"padding-left":"2.5rem"}},[_vm._m(2)]):_vm._e()]:_vm._e()],2):_vm._e()],1)],1)])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"color-cyan-blue lh-32 fs-14"},[_vm._v("Impressions"),_c('br'),_vm._v("Started watching")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"color-cyan-blue lh-32 fs-14"},[_vm._v("Impressions"),_c('br'),_vm._v("Completed watching")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stats-row inline"},[_c('span',{staticClass:"circle blue-dark"}),_vm._v("Impressions")])
}]

export { render, staticRenderFns }