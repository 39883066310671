<script>
import StatusTraits from '@master/Traits/StatusTraits.vue';

export default {
  methods: {
    isDraft(status) {
      return StatusTraits.methods.isDraft(status ?? this.folder.status);
    },
    isPaused(status) {
      return StatusTraits.methods.isPaused(status ?? this.folder.status);
    },
    isLive(status) {
      return StatusTraits.methods.isLive(status ?? this.folder.status);
    },
    isWaiting(status) {
      return StatusTraits.methods.isWaiting(status ?? this.folder.status);
    },
    isFinished(status) {
      return StatusTraits.methods.isFinished(status ?? this.folder.status);
    },
    isArchived(status) {
      return StatusTraits.methods.isArchived(status ?? this.folder.status);
    },
    isDuplicationInProgress(status) {
      return StatusTraits.methods.isDuplicationInProgress(status ?? this.folder.status);
    },
    isImportInProgress(status) {
      return StatusTraits.methods.isImportInProgress(status ?? this.folder.status);
    },
  },
};
</script>
