<template lang="pug">
SecondaryNavbar

  Buttons
    Button(
      v-if="flight != null"
      type="success"
      label="Export / View Tags"
      :active="show_export_modal"
      :disabled="disabled"
      @click="show_export_modal = true"
    )
    Button(
      v-if="!is_dco"
      type="primary"
      label="Quick Overview"
      :outline="true"
      :disabled="disabled"
      @click="show_overview = true"
    )
      template(v-slot:prefix)
        IconOverview

  div.flex-grow
  div
    template(v-if="timestamp != null")
      i.nexd-icon-16-check-mini.color-success.mr-4(aria-hidden="true")
      span.color-cyan-blue.fs-12 Last save: {{ timestamp }}
    Button.ml-16(
      type="primary"
      label="Save"
      :disabled="disabled"
      :loading="saving"
      @click="save"
    )
    Button.ml-16(
      type="warning"
      label="Discard"
      :outline="true"
      :disabled="saving || disabled || isExported()"
      :tooltip="discard_button_tooltip"
      @click="discard"
    )

  FlightExportModal(v-if="show_export_modal && flight != null" @export="$emit('export')" @close="show_export_modal = false")
  FlightPreview(v-if="flight != null && show_overview" @close="show_overview = false")
</template>

<script>
import { FIXED_ELEMENT, FLIGHTS } from '@master/constants';
import FlightTraits from '@master/Traits/FlightTraits.vue';

import FlightService from '@master/Services/FlightService';
import SaveService from '@master/Services/SaveService';

import FlightExportModal from '@cm/Views/Flights/Components/FlightExportModal/FlightExportModal.vue';
import FlightPreview from '@cm/Views/Flights/Components/FlightPreview.vue';

import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';
import IconOverview from '@master/UI/Buttons/Icons/IconOverview.vue';
import SecondaryNavbar from '@master/UI/SecondaryNavbar/SecondaryNavbar.vue';

export default {
  name: 'FlightNavbar',
  mixins: [FlightTraits],

  components: {
    Button,
    Buttons,
    FlightExportModal,
    FlightPreview,
    IconOverview,
    SecondaryNavbar,
  },

  computed: {
    disabled() {
      return this.isImportInProgress();
    },

    discard_button_tooltip() {
      if (this.isExported()) {
        if (this.is_dco) {
          return { value: 'Exported optimized creative tag cannot be deleted.' };
        }

        return { value: 'Exported flight cannot be deleted.' };
      }

      return null;
    },

    is_dco() {
      return this.flight?.type === FLIGHTS.TYPE.TYPE_DCO;
    },
  },

  data() {
    return {
      FIXED_ELEMENT,

      flight: null,

      show_export_modal: false,
      show_overview: false,
      timestamp: null,
      saving: false,
    };
  },

  created() {
    SaveService.flight.subscribe(({ timestamp, saving }) => {
      this.timestamp = timestamp;
      this.saving = saving;
    }, this);

    FlightService.subscribe(data => {
      this.flight = data?.flight;

      if (data?.publishing && !this.show_export_modal) {
        this.show_export_modal = true;
      }
    }, this);
  },

  methods: {
    async save() {
      FlightService.save();
    },

    async discard() {
      if (this.isExported()) {
        return;
      }

      if (await this.$confirm('Are you sure you want to discard this flight?')) {
        FlightService.discard();
      }
    },
  },
};
</script>
