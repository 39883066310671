<template lang="pug">
section.flex.flex-direction-column.row-gap-12
  div.flex.flex-align-center.flex-justify-between
    h2.fs-24 API Key
    Button(type="primary" label="Generate new API key" :animate="true" :disabled="api_tokens.length >= 3" @click="generateApiToken")
  div.flex.flex-align-center
    small.color-gray-800 You can generate up to 3 API keys, each valid for up to 1 year from creation.
    i.nexd-icon-32-info.color-gray-800(v-tooltip="{value: api_token_tooltip}")
  NotificationBox(
    v-if="show_notification"
    title="Make sure to copy your personal API key now."
    info="You won’t be able to see it again."
  )
  div.card.card-rounded(v-if="api_tokens.length > 0")
    div.card-body.dark-body.medium-body
      div.card-title.flex.flex-align-center
        span.fw-500.flex-grow Generated Keys
    ApiKeyRow(
      v-for="(api_key, index) in api_tokens"
      :key="index"
      :index="index"
      :token="api_key"
      @delete="deleteApiToken"
    )
</template>

<script>
import styles from '@cm/Views/Profile/Blocks/Components/ProfileCard/ProfileCard.module.scss';

import Button from '@master/UI/Buttons/Button.vue';
import NotificationBox from '@master/UI/NotificationBox/NotificationBox.vue';

import ApiKeyRow from '@cm/Views/Profile/Blocks/Components/ApiKeyRow.vue';

export default {
  name: 'ApiKeyCard',

  components: {
    ApiKeyRow,
    Button,
    NotificationBox,
  },

  data() {
    return {
      styles,
      api_tokens: [],
      api_token_tooltip: `Nexd ensures key rotation, reducing the window for compromised usage.
        In the event of an API key being compromised - delete the key and create a new one.
        We will notify you about the impending expiration 14 days before your key expires, encouraging you to generate a new key and replace it wherever your old key is used.`,
    };
  },

  created() {
    this.initializeApiTokens();
  },

  computed: {
    show_notification() {
      return this.api_tokens.some(obj => obj.visible);
    },
  },

  methods: {
    initializeApiTokens() {
      this.$http
        .get('auth/apikey')
        .then(tokens => {
          this.api_tokens = tokens;
        })
        .catch(_ => {
          /** error handled by the notifications */
        });
    },

    generateApiToken() {
      if (this.$user.user?.user_id == null) {
        return;
      }
      this.$http
        .post('auth/apikey')
        .then(token => {
          this.api_tokens = [token, ...this.api_tokens];
        })
        .catch(_ => {
          /** error handled by the notifications */
        });
    },

    async deleteApiToken(token) {
      if (token.id == null || !(await this.$confirm('Are you sure you want to delete this API token?'))) {
        return;
      }

      this.$http
        .delete(`auth/apikey/${token.id}`)
        .then(_ => {
          this.api_tokens = this.api_tokens.filter(t => {
            return t.id !== token.id;
          });
        })
        .catch(_ => {
          /** error handled by the notifications */
        });
    },
  },
};
</script>
