import ModulesService from '@master/Services/Cache/ModulesService';

export default {
  getChoiceBase() {
    return {
      text: { value: '', ...ModulesService.getModuleSettings('text') },
      background: {
        border: {},
      },
      cta: {
        action: 'url',
        uri: '',
      },
      impressiontracker: [{ action: 'ctr', uri: '' }],
    };
  },

  getQuestionBase() {
    return {
      _key: Math.floor(Math.random() * 1000000),
      question: {
        text: { value: '', ...ModulesService.getModuleSettings('text') },
      },
      type: 'single',
      choices: [this.getChoiceBase()],
      other: null,
      cta: {
        action: 'url',
        uri: '',
      },
      settings: {
        autosubmit: false,
        question_color: '#000000',
        background_color: '#000000', // Currently is obsolete
        font_size: 16,
        font_family: 'Arial',
        line_height: 16,
        alpha: 1,
      },
      impressiontracker: [{ action: 'impression', uri: '' }],
    };
  },

  getDefaultGlobalSettings() {
    return {
      question: {
        text: { ...ModulesService.getModuleSettings('text') },
      },
      choice: {
        text: { ...ModulesService.getModuleSettings('text'), ...{ color: '#FFFFFF' } }, // Overriding choice text default color
        background: {
          color: '#000000',
          alpha: 1,
          border: {
            color: '#000000',
            radius: 4,
          },
        },
      },
    };
  },
};
