export const TRACKING_OPTIONS = {
  impression: 'Impression',
  creativeView: 'In view',
};

export const VIDEO_TRACKING_OPTIONS = {
  play: 'Play',
  pause: 'Pause',
  mute: 'Mute',
  unmute: 'Unmute',
  'vtr-0': 'VTR-0',
  'vtr-25': 'VTR-25',
  'vtr-50': 'VTR-50',
  'vtr-75': 'VTR-75',
  'vtr-100': 'VTR-100',
  'ad-is-in-view': 'Ad in view',
  close: 'Close',
};

export const NAME_OPTIONS = {
  add: 'Add Text',
  replace: 'Replace text',
};

export const ADD_TEXT_OPTIONS = {
  before: 'Before',
  after: 'After',
};

export const PARAMETERS = {
  name: 'Creative name',
  cta: 'Click Action',
  tracking: 'Tracking',
  dsp: 'Platform (DSP)',
};
