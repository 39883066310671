<template lang="pug">
div(:class="styles.droparea")
  template(v-if="textarea")
    slot
    div.fs-14.color-gray-600(:class="styles.or") OR
  Dropzone(:class="{'small': textarea}" :loading="false" :mime="mime" :exclude="exclude" @change="$emit('change', $event)")
</template>

<script>
import styles from './Import.module.scss';

import Dropzone from '@cm/Views/Creatives/Cards/Components/Dropzone.vue';

export default {
  name: 'ImportDropArea',

  components: {
    Dropzone,
  },

  props: {
    mime: {
      type: Array,
      required: true,
    },

    exclude: {
      type: Array,
      default: () => [],
    },

    textarea: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      styles,
    };
  },
};
</script>
