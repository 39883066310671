<script>

import StatusTraits from '@master/Traits/StatusTraits.vue';

export default {
  methods: {
    isDraft(status) {
      if (status == null) {
        status = this.campaign.status;
      }
      return StatusTraits.methods.isDraft(status);
    },
    isPaused(status) {
      if (status == null) {
        status = this.campaign.status;
      }
      return StatusTraits.methods.isPaused(status);
    },
    isLive(status) {
      if (status == null) {
        status = this.campaign.status;
      }
      return StatusTraits.methods.isLive(status);
    },
    isWaiting(status) {
      if (status == null) {
        status = this.campaign.status;
      }
      return StatusTraits.methods.isWaiting(status);
    },
    isFinished(status) {
      if (status == null) {
        status = this.campaign.status;
      }
      return StatusTraits.methods.isFinished(status);
    },
    isDemoFinished(status) {
      if (status == null) {
        status = this.campaign.status;
      }
      return StatusTraits.methods.isDemoFinished(status);
    },
    isArchived(status) {
      if (status == null) {
        status = this.campaign.status;
      }
      return StatusTraits.methods.isArchived(status);
    },
    isDuplicationInProgress(status) {
      if (status == null) {
        status = this.campaign.status;
      }
      return StatusTraits.methods.isDuplicationInProgress(status);
    },
    isImportInProgress(status) {
      if (status == null) {
        status = this.campaign.status;
      }
      return StatusTraits.methods.isImportInProgress(status);
    }
  }
};
</script>
