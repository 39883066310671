<template>
  <div class="template-settings">
    <div v-if="size_warning" class="template-settings-warning">Please check placement size values</div>
    <Loading class="mt-30" v-if="requirements_loaded === false" />
    <div v-if="requirements_loaded === true" class="settings-tab-wrapper" :style="wrapper_style">
      <div class="settings-tab-content" :style="content_style">
        <div :class="{ visible: $store.get('active_tab') === 'assets' }" class="assets-list">
          <template v-for="(ui_group, ui_group_key) in tabs.assets">
            <UIGroup :collapsed="ui_group.settings != null ? ui_group.settings._collapsed : true" :key="ui_group_key" :title="ui_group.title">
              <TabAssets :creative="creative" :group="ui_group" />
            </UIGroup>
          </template>
        </div>

        <div :class="{ visible: $store.get('active_tab') === 'extras' }" class="assets-list">
          <template v-for="(ui_group, ui_group_key) in tabs.extra">
            <UIGroup :collapsed="ui_group.settings != null ? ui_group.settings._collapsed : true" :key="ui_group_key" :title="ui_group.title">
              <TabExtra :creative="creative" :group="ui_group" />
            </UIGroup>
          </template>
        </div>

        <div :class="{ visible: $store.get('active_tab') === 'options' }" class="assets-list">
          <template v-for="(ui_group, ui_group_key) in tabs.options">
            <TabOptions :creative="creative" :group="ui_group" :key="ui_group_key" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '@master/UI/Loading.vue';
import UIGroup from '@master/UI/UIGroup.vue';

import TabAssets from './Tabs/Assets.vue';
import TabExtra from './Tabs/Extra.vue';
import TabOptions from './Tabs/Options.vue';

import ModulesService from '@master/Services/Cache/ModulesService';
import LayoutsSettingsService from '@master/Services/LayoutsSettingsService';
import UIGroupsService from '@master/Services/Cache/UIGroupsService';
import CreativeSizeService from '@master/Services/CreativeSizeService';

import { clone } from '@helpers/Global';
import LayoutHelper from '@helpers/Layouts';

export default {
  name: 'ModularSettings',
  components: {
    UIGroup,
    TabAssets,
    TabExtra,
    TabOptions,
    Loading,
  },

  props: {
    creative: Object,
    isVast: {
      type: Boolean,
      default: false,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
    dcoFeed: {
      type: Array,
      default: null,
    },
    sizeset: {
      type: String,
      default: null,
    },
    floating: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    show_additional() {
      return this.isVast;
    },

    can_change_tab() {
      return !this.$parent.isFeed() && !this.isLoading() && !this.loading;
    },

    content_style() {
      let style = {};

      // will be rerendered if active tab changes (as well initially after mounted)
      if (this.active_tab != null) {
        let max = 0;

        // since vue disallows same ref keys (unless in loop), select all the assets-list classes from this element
        const tabs = this.$el.querySelectorAll('.assets-list');
        for (const el of tabs) {
          let height = el.clientHeight;
          if (height != null) {
            max = Math.max(height, max);
          }
        }

        // set the longes tab size as the wrapper min height
        style.minHeight = max + 'px';
      }
      return style;
    },

    wrapper_style() {
      let style = {};

      // will be rerendered if active tab changes (as well initially after mounted)
      if (this.active_tab != null) {
        if (this.floating) {
          // if the panel is floating (responsive overview tab), get the height of the parent element and add overflow scroll
          style.overflowY = 'auto';
          if (this.$parent?.$refs?.preview?.container_style != null) {
            style.maxHeight = parseInt(this.$parent.$refs.preview.container_style.minHeight, 10) - 40 + 'px';
          }
        }
      }
      return style;
    },
    requirements_loaded() {
      return this.ui_groups != null && this.layout != null && this.modules != null;
    },
  },

  data() {
    return {
      active_tab: null,
      active_feed_item_index: 0,
      layout: null,
      ui_groups: null,
      modules: null,
      tabs: {
        assets: {},
        extra: {},
        options: {},
      },
      size_warning: false,
    };
  },

  created() {
    CreativeSizeService.subscribe(state => {
      this.size_warning = state?.has_warning ?? false;
    });

    // Show layout
    UIGroupsService.subscribe(ui_groups => {
      this.$set(this, 'ui_groups', ui_groups);
      this.tryInitialization();
    }, this);

    LayoutsSettingsService.subscribe(
      this.creative.layout_id,
      layout => {
        this.$set(this, 'layout', layout);
        this.tryInitialization();
      },
      this,
    );

    ModulesService.subscribe(modules => {
      this.$set(this, 'modules', modules);
      this.tryInitialization();
    }, this);
  },

  mounted() {
    // set active tab as the first, so it would render the content height
    this.active_tab = 'assets';
  },

  methods: {
    uiGroupBase() {
      return {
        ui_group_key: null,
        title: null,
        description: null,
        tooltip: null,
        slots: [],
        modules: [],
        settings: null,
      };
    },
    tryInitialization() {
      if (this.requirements_loaded === true) {
        this.init();
      }
    },
    init() {
      LayoutHelper.load(this.layout);
      const grouper_groups = LayoutHelper.getUiGroups(this.layout.layout_id);

      let _assets_tab = {};
      let _extra_tab = {};
      let _options_tab = {};

      for (const k in grouper_groups) {
        if (k.startsWith('assets.')) {
          _assets_tab[k] = grouper_groups[k];
        } else if (k.startsWith('extra.')) {
          _extra_tab[k] = grouper_groups[k];
        } else if (k.startsWith('options.')) {
          _options_tab[k] = grouper_groups[k];
        } else {
          // All nogroups go to assets
          _assets_tab[k] = grouper_groups[k];
        }
      }

      this.orderAndSetTabItems('assets', _assets_tab);
      this.orderAndSetTabItems('extra', _extra_tab);
      this.orderAndSetTabItems('options', _options_tab);

      this.tabs.options.no_group = {
        title: null,
        settings: clone(this.layout.settings),
      };
    },

    orderAndSetTabItems(tab_id, tmp_items) {
      if (this.tabs[tab_id] == null) {
        return null; // Nothing to do
      }

      // Clean up no_group content if empty
      if (tmp_items['no_group'] != null && tmp_items['no_group'].slots.length < 1 && tmp_items['no_group'].modules.length < 1 && tmp_items['no_group'].settings == null) {
        delete tmp_items['no_group'];
      }

      // Ordering modules or slots
      tmp_items = this.sortSlotAndModuleItems(tmp_items);

      if (tmp_items.no_group != null) {
        this.tabs[tab_id].no_group = tmp_items.no_group;
      }
      for (const uid in this.ui_groups) {
        for (const id in tmp_items) {
          if (uid === id) {
            this.tabs[tab_id][id] = tmp_items[id];
          }
        }
      }
    },

    sortItems(items) {
      items.sort((a, b) => {
        return a.order > b.order ? 1 : -1;
      });
    },

    sortSlotAndModuleItems(items) {
      for (const k in items) {
        if (items[k].slots != null && items[k].slots.length > 0) {
          this.sortItems(items[k].slots); // Sort layout slots
        } else if (items[k].modules != null && items[k].modules.length > 0) {
          this.sortItems(items[k].modules); // sort modules
          for (const mk in items[k].modules) {
            if (items[k].modules[mk].slots != null && items[k].modules[mk].slots.length > 0) {
              this.sortItems(items[k].modules[mk].slots); // Sort module slots
            }
          }
        }
      }
      return items;
    },

    isExtra(module) {
      return ['cta', 'logo', 'countdown_widget', 'custom_effect'].includes(module);
    },

    isOptions(module) {
      return [''].includes(module);
    },

    isImageVideo(slot) {
      return slot.modules.indexOf('image') > -1 || slot.modules.indexOf('video') > -1;
    },

    isLoading() {
      if (this.$refs?.['assets-primary']?.$refs != null) {
        const assets = this.$refs['assets-primary'].$refs.asset || [];
        for (const asset of assets) {
          if (asset.loading !== null) {
            return true;
          }
        }
      }
      if (this.$refs?.['assets-optional']?.$refs != null) {
        const assets = this.$refs['assets-optional'].$refs.asset || [];
        for (const asset of assets) {
          if (asset.loading !== null) {
            return true;
          }
        }
      }
      if (this.$refs?.['assets-extras']?.$refs != null) {
        const assets = this.$refs['assets-extras'].$refs.asset || [];
        for (const asset of assets) {
          if (asset.loading !== null) {
            return true;
          }
        }
      }
      if (this.$refs?.['assets-optional-background']?.$refs != null) {
        const assets = this.$refs['assets-optional-background'].$refs.asset || [];
        for (const asset of assets) {
          if (asset.loading !== null) {
            return true;
          }
        }
      }
      return false;
    },

    change() {
      this.$emit('change');
    },

    settingsChange(reload_assets_library = false) {
      if (reload_assets_library) {
        this.$emit('assetsChange');
      }
    },

    assetChangeHandler(type = 'asset') {
      if (type === 'asset') {
        this.$emit('assetsChange');
      } else if (type === 'dco') {
        this.$emit('DCOChange');
      } else {
        this.$emit('change');
      }
    },

    assetReloadChangeHandler() {
      // aöoas for reload emits
      this.assetChangeHandler();
    },

    nextFeedItem() {
      if (this.dcoFeed != null) {
        this.active_feed_item_index++;
        if (this.dcoFeed.length - 1 < this.active_feed_item_index) {
          this.active_feed_item_index = 0;
        }
      }
    },

    previousFeedItem() {
      if (this.dcoFeed != null) {
        this.active_feed_item_index--;
        if (this.active_feed_item_index < 0) {
          this.active_feed_item_index = this.dcoFeed.length - 1;
        }
      }
    },
  },
};
</script>
