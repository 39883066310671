<template lang="pug">
Modal.creatives-bulk-action(title="Bulk Edit Creatives" :loading="loading" :class="{'modal-unset-overflow': parameter !== 'tracking'}")
  template(v-slot:body)
    div
      div.mb-16.fs-14.color-gray-800 Choose the parameter you wish to edit for the selected creatives.
      SearchSelect(
        v-model="parameter"
        :options="parameters"
        :search="false"
        width="200px"
      )
      template(v-if="parameter !== 'null'")
        hr

        //- EDIT NAME
        section.table.name-inputs(v-if="parameter === 'name'")
          SearchSelect(
            v-model="name_option"
            :options="NAME_OPTIONS"
            :search="false"
            width="200px"
          )
          template(v-if="name_option === 'add'")
            div.form-group.mb-0
              input.form-control(
                placeholder="Insert text"
                v-model="add_text"
              )
            SearchSelect(
              v-model="add_text_option"
              :options="ADD_TEXT_OPTIONS"
              :search="false"
            )
          div.table.full-width-row(v-else-if="name_option === 'replace'")
            section.table.full-width-row
              span.lh-32 Find text:
              div.form-group.mb-0
                input.form-control(
                  placeholder="Insert text you want to find"
                  v-model="find_text"
                )
            section.table.full-width-row
              span.lh-32 Replace with:
              div.form-group.mb-0
                input.form-control(
                  placeholder="Insert text you want to replace text with"
                  v-model="replace_text"
                )
          div.full-width-row(v-if="example_creative")
            span.color-gray-800 Example:&nbsp;
            | {{ example_text }}

        //- EDIT CTA
        section(v-if="parameter === 'cta'")
          span.fs-14.full-width-row Default click action&nbsp;
            i.nexd-icon-16-help(aria-hidden="true" v-tooltip="{template: 'cm.ctaGlobal'}")
          div.table.mt-12.mb-4
            span.color-gray-800 Type
            span.color-gray-800 Output
          div.table
            SearchSelect.input-prefix(
              v-model="cta.action"
              :options="CTA_OPTIONS"
              :search="false"
              width="100%"
              @input="CTAactionChangeHandler"
            )
            div.form-group.mb-0.flex.flex-direction-column.row-gap-8
              TextareaInput(
                v-if="cta.action === 'url'"
                :placeholder="cta_output_placeholder"
                v-model="cta.uri"
                :url="true"
                @change="change"
              )
              Input(
                v-if="['call', 'sms'].includes(cta.action)"
                :key="cta.action"
                v-model="cta.uri"
                :placeholder="cta_output_placeholder"
                variant="phone"
                @change="change"
              )
              Input(
                v-if="['mailto'].includes(cta.action)"
                :key="cta.action"
                v-model="cta.uri"
                :placeholder="cta_output_placeholder"
                @change="change"
              )
              TextareaWithCounter(
                v-if="cta.action === 'sms'"
                v-model="cta.sms_body"
                :max_char="140"
                :isInput="true"
                placeholder="Insert predefined SMS body here. Max 140 characters"
              )
              template(v-if="cta.action === 'calendar'")
                TextareaInput(
                  :placeholder="cta_output_placeholder"
                  v-model="cta.title"
                  :url="false"
                  @change="change"
                )
                div
                  span.color-gray-800 Description
                  TextareaInput.mt-4(
                    placeholder="Enter description"
                    v-model="cta.description"
                    :url="false"
                    @change="change"
                  )
                div
                  span.color-gray-800 Location
                  TextareaInput.mt-4(
                    placeholder="Enter address or link"
                    v-model="cta.location"
                    :url="false"
                    @change="change"
                  )
                div
                  span.color-gray-800 Date and time
                  SelectDateRange.mt-4(
                    :start="cta.start"
                    :end="cta.end"
                    :timezone="cta.timezone"
                    :placeholder="cta.start | DateRangeFilter(cta.end, {utc: true})"
                    :options="{ time: true }"
                    style="width: max-content;"
                    @change="calendarChangeHandler"
                  )
              template(v-if="cta.action === 'map'")
                TagInputLocations(ref="tag_input_locations" @add="addLocationHandler")
                div(v-if="cta.uri.length > 0")
                  span.color-gray-800 Map locations
                  div.location-row(v-for="(location, index) in cta.uri" :key="index")
                    div.flex-grow.overflow-ellipsis {{location.address}}
                    i.nexd-icon-32-close.fs-16.color-danger.cursor-pointer(aria-hidden="true" @click="removeLocation(index)")
                div.flex.flex-align-center.column-gap-16
                  span Focus on the closest
                  Toggle(v-model="cta.focus_closest" @input="")

        //- EDIT TRACKING
        section(v-if="parameter === 'tracking'")
          div.table
            span.lh-24 Edit trackers separately&nbsp;
              i.nexd-icon-16-help(aria-hidden="true" v-tooltip="{value: `When enabled, you can modify each selected creatives' trackers individually. Turn off to apply the same changes to all trackers at once.`}")
            Toggle(v-model="edit_trackers_separately" @change="loadCreativesSettings")
          div.mt-16.mb-24.flex.flex-align-center.column-gap-16(v-if="edit_trackers_separately" style="grid-column: span 2;")
            span Event
            SearchSelect(v-model="global_tracker" :options="global_tracking_options" :search="false" width="200px" @change="updateCreativeTrackers")
          div.table.mt-16.mb-8(v-else)
            span Creative tracking&nbsp;
              i.nexd-icon-16-help(aria-hidden="true" v-tooltip="{template: 'cm.trackingGlobal'}")
          template(v-if="edit_trackers_separately")
            div.table.px-4
              span.color-gray-800 Creative:
              span.color-gray-800 Tracker:
            hr.my-4
            section.table.row-gap-0
              div.full-width-row.table.tracking.row-gap-8(v-for="(creative, creative_id) in creatives_trackers")
                span.lh-32.overflow-ellipsis(v-tooltip="{value: creative.name}") {{ creative.name }}
                div.flex.flex-direction-column.row-gap-4
                  div.flex.flex-align-center.column-gap-16(
                    v-for="(tracker, index) of creative.impressiontracker"
                    :key="index"
                  )
                    div.form-group.mb-0.flex-grow.with-remove-icon
                      TextareaInput(
                        placeholder="Insert tracking pixel URL (incl. macro)"
                        v-model="tracker.uri"
                        :disabled="is_global_video_tracking_option && !creative.has_video"
                        :url="true"
                        v-tooltip="{value: is_global_video_tracking_option && !creative.has_video ? 'Chosen event is not avaliable for this creative.' : null}"
                        @change="parseCreativeTrackersExcelAndChange(creative_id, index)"
                      )
                      RemoveIcon(v-if="!is_global_video_tracking_option || creative.has_video" @click="removeCreativeImpressionTracker(creative_id, index)")
                    i.nexd-icon-16-plus.add(v-if="!is_global_video_tracking_option || creative.has_video" aria-hidden="true" @click="addCreativeImpressionTracker(creative_id)")
                    div.ml-16(v-else)
          template(v-else)
            div.table.mb-4
              span.color-gray-800 Event:
              span.color-gray-800 Tracker:
            div.table.row-gap-4
              template(v-for="(tracker, index) of impressiontrackers")
                SearchSelect.input-prefix(
                  v-model="tracker.action"
                  :options="global_tracking_options"
                  :search="false"
                  width="100%"
                )
                div.flex.flex-align-center.column-gap-16
                  div.form-group.mb-0.flex-grow.with-remove-icon
                    TextareaInput(
                      placeholder="Insert tracking pixel URL (incl. macro)"
                      v-model="tracker.uri"
                      :url="true"
                      @change="parseTrackersExcelAndChange(index)"
                    )
                    RemoveIcon(@click="removeImpressionTracker(index)")
                  i.nexd-icon-16-plus.add(aria-hidden="true" @click="addImpressionTracker")

        //- EDIT DSP
        section.table.custom(v-if="parameter === 'dsp'")
          span.lh-32 Export as tag for:
          SearchSelect(v-model="dsp" :options="dsp_options")

        //- EDIT FW VERSION
        section.full-width-row.mb-8(v-if="parameter === 'framework_version'")
          span.fs-14 Update framework version

      template(v-if="parameter === 'tracking'")
        hr
        div.mb-16.fs-14.color-gray-800 Choose if you want to append or replace the impression trackers.
        div.fs-14 Choose action:
        label.mt-4.flex.flex-align-center.column-gap-4
          input(type="radio" v-model="append_trackers" :value="false" @change="append_trackers = false")
          span Replace already existing trackers
        label.mt-4.flex.flex-align-center.column-gap-4
          input(type="radio" v-model="append_trackers" :value="true" @change="append_trackers = true")
          span Add trackers to already existing trackers
        template(v-if="has_expandable_creatives")
          hr
          div.mb-16.fs-14.color-gray-800 Selection includes some expandable creative(s). Choose for which state the tracker will be added.
          div.fs-14 Choose state:
          label.mt-4.flex.flex-align-center.column-gap-4
            input(type="radio" v-model="state" :value="1" @change="state = 1")
            span First State
          label.mt-4.flex.flex-align-center.column-gap-4
            input(type="radio" v-model="state" :value="2" @change="state = 2")
            span Second State

  template(v-slot:footer)
    Buttons
      Button(type="link-primary" label="Cancel" :disabled="loading" @click="close")
      Button(type="primary" :label="action_button_label" :animate="true" :disabled="loading || parameter === 'null'" @click="save")
</template>

<script>
import moment from 'moment';

import { NAME_OPTIONS, ADD_TEXT_OPTIONS, TRACKING_OPTIONS, VIDEO_TRACKING_OPTIONS, PARAMETERS } from './options';
import { CTA_OPTIONS } from '@master/constants';
import { parseTrackerInput, validateURL, validatePhone } from '@helpers/Global';

import DSPService from '@master/Services/Cache/DSPService';
import CreativesService from '@master/Services/CreativesService';

import CreativeTraits from '@master/Traits/CreativeTraits.vue';

import Buttons from '@master/UI/Buttons/Buttons.vue';
import Button from '@master/UI/Buttons/Button.vue';
import Input from '@master/UI/Input/Input.vue';
import Modal from '@master/Modals/Modal.vue';
import RemoveIcon from '@master/UI/RemoveIcon.vue';
import SearchSelect from '@master/UI/SearchSelect/SearchSelect.vue';
import SelectDateRange from '@master/UI/SearchSelect/SelectDateRange.vue';
import TagInputLocations from '@master/UI/TagInputLocations.vue';
import TextareaWithCounter from '@master/UI/TextareaWithCounter.vue';
import Toggle from '@master/UI/Toggle.vue';

import TextareaInput from '@cm/Views/Creatives/Cards/Components/Tracking/Components/TextareaInput.vue';

export default {
  name: 'CreativesBulkActionModal',

  mixins: [CreativeTraits],

  components: {
    Button,
    Buttons,
    Input,
    Modal,
    RemoveIcon,
    SearchSelect,
    SelectDateRange,
    TagInputLocations,
    TextareaInput,
    TextareaWithCounter,
    Toggle,
  },

  props: {
    creative_ids: {
      type: Array,
      default: () => null,
    },

    creatives: Array,
  },

  computed: {
    parameters() {
      if (!this.admin_toggle) {
        return PARAMETERS;
      }

      return {
        ...PARAMETERS,
        framework_version: 'Framework version',
      };
    },

    action_button_label() {
      if (this.parameter === 'framework_version') {
        return 'Update fw version';
      }
      return 'Apply';
    },

    global_tracking_options() {
      if (!this.edit_trackers_separately || !Object.values(this.creatives_trackers).some(cre => cre.has_video)) {
        return TRACKING_OPTIONS;
      }

      return {
        ...TRACKING_OPTIONS,
        ...VIDEO_TRACKING_OPTIONS,
      };
    },

    is_global_video_tracking_option() {
      return Object.keys(VIDEO_TRACKING_OPTIONS).includes(this.global_tracker);
    },

    cta_output_placeholder() {
      switch (this.cta.action) {
        case 'url':
          return 'Insert click-out URL (landing page URL, click-tracker or click tag)';
        case 'call':
        case 'sms':
          return '+155512345';
        case 'calendar':
          return 'Insert title';
        case 'mailto':
          return 'Insert email address';
        default:
          return '';
      }
    },

    example_creative() {
      return this.creatives?.[0] ?? null;
    },

    example_text() {
      const creative = this.example_creative;

      if (!creative) {
        return '';
      }

      const name = creative.name;

      // add text before or after
      if (this.name_option === 'add') {
        if (this.add_text_option === 'before') {
          return `${this.add_text}${name}`;
        }
        return `${name}${this.add_text}`;
      }

      // replace
      if (this.name_option === 'replace' && this.find_text !== '' && this.replace_text !== '') {
        return name.replace(this.find_text, this.replace_text);
      }

      // fallback
      return name;
    },

    has_expandable_creatives() {
      return this.creatives?.some(creative => this.isBinded(creative.type));
    },
  },

  data() {
    return {
      CTA_OPTIONS,
      NAME_OPTIONS,
      ADD_TEXT_OPTIONS,

      parameter: 'name',
      state: 1,
      append_trackers: false,
      edit_trackers_separately: false,
      global_tracker: 'impression',
      settings_loaded: false,

      name_option: 'add',
      add_text: '',
      add_text_option: 'before',
      find_text: '',
      replace_text: '',

      dsp: '',
      dsp_options: [],

      admin_toggle: true,

      cta: { action: 'url', uri: '' },
      impressiontrackers: [{ action: 'impression', uri: '' }],
      creatives_trackers: {},

      loading: false,
    };
  },

  created() {
    DSPService.subscribe(dsps => {
      if (dsps?.length > 0) {
        for (const dsp of dsps) {
          this.dsp_options.push({ value: dsp.slug, label: dsp.title });
        }
      }
    }, this);

    this.$user.subscribe(user => {
      if (user != null) {
        this.admin_toggle = this.$user.adminMode();
      }
    }, this);
  },

  methods: {
    CTAactionChangeHandler() {
      const keys_to_delete = ['uri', 'sms_body', 'title', 'location', 'description', 'start', 'end', 'dateStart', 'dateEnd', 'timezone', 'focus_closest'];
      // remove all keys when changin action
      for (const key of keys_to_delete) {
        if (this.cta[key] != null) {
          this.$delete(this.cta, key);
        }
      }

      if (this.cta.action === 'url') {
        this.$set(this.cta, 'uri', '');
      } else if (this.cta.action === 'call') {
        this.$set(this.cta, 'uri', '');
      } else if (this.cta.action === 'sms') {
        this.$set(this.cta, 'uri', '');
        this.$set(this.cta, 'sms_body', '');
      } else if (this.cta.action === 'calendar') {
        this.$set(this.cta, 'title', '');
        this.$set(this.cta, 'location', '');
        this.$set(this.cta, 'description', '');
        this.$set(this.cta, 'start', moment.utc().add(14, 'days').set('hour', 12).set('minutes', 0).unix());
        this.$set(this.cta, 'end', moment.utc().add(14, 'days').set('hour', 13).set('minutes', 0).unix());
        this.$set(this.cta, 'timezone', 'UTC');
      } else if (this.cta.action === 'map') {
        this.$set(this.cta, 'uri', []);
        this.$set(this.cta, 'focus_closest', true);
      }
    },

    calendarChangeHandler({ start, end, timezone }) {
      this.$set(this.cta, 'start', start);
      this.$set(this.cta, 'end', end);
      this.$set(this.cta, 'timezone', timezone);
    },

    addImpressionTracker() {
      this.addTrackers(this.impressiontrackers);
    },

    removeImpressionTracker(index) {
      this.removeTrackers(this.impressiontrackers, index);
    },

    removeTrackers(trackers, index) {
      if (trackers.length > 1) {
        trackers.splice(index, 1);
      } else {
        this.$set(trackers[index], 'uri', '');
      }
    },

    addTrackers(trackers) {
      trackers.push({ action: 'impression', uri: '' });
    },

    parseTrackers(trackers, index) {
      const uris = parseTrackerInput(trackers?.[index]?.uri);

      if (uris.length === 0) {
        return;
      }

      if (uris.length === 1) {
        trackers[index].uri = uris[0];
        return;
      }

      // remove current value
      trackers.splice(index, 1);

      // push new items from parser
      for (const uri of uris) {
        trackers.push({
          action: 'impression',
          uri,
        });
      }
    },

    parseTrackersExcelAndChange(index) {
      this.parseTrackers(this.impressiontrackers, index);
    },

    addLocationHandler(location) {
      if (!Array.isArray(this.cta.uri)) {
        this.$set(this.cta, 'uri', []);
      }
      this.cta.uri.push(location);
      this.$refs['tag_input_locations'].focusInput();
    },

    removeLocation(index) {
      this.cta.uri.splice(index, 1);
    },

    change() {
      if (this.cta.action === 'url' && this.cta.uri !== '') {
        this.cta.uri = validateURL(this.cta.uri);
      }

      if ((this.cta.action === 'call' || this.cta.action === 'sms') && this.cta.uri !== '') {
        this.cta.uri = validatePhone(this.cta.uri);
      }
    },

    generatePayload() {
      let data = {
        creative_ids: this.creative_ids,
      };

      if (this.parameter === 'name') {
        if (this.name_option === 'add') {
          data['name'] = {
            replace: false,
            text: this.add_text,
            before: this.add_text_option === 'before',
          };
        } else if (this.name_option === 'replace') {
          data['name'] = {
            replace: true,
            text: this.find_text,
            replace_text: this.replace_text,
          };
        }
      }

      if (this.parameter === 'cta') {
        data['settings'] = {
          cta: this.cta,
        };
      }

      if (this.parameter === 'tracking') {
        data['settings'] = {
          cta: {
            impressiontracker: this.impressiontrackers,
          },
        };

        data['options'] = {
          state: this.state,
        };

        if (this.append_trackers) {
          data['options']['append'] = this.append_trackers;
        }
      }

      if (this.parameter === 'dsp') {
        data['dsp'] = this.dsp;
      }

      if (this.parameter === 'framework_version') {
        data['framework_version'] = 'latest';
      }

      return data;
    },

    save() {
      if (this.parameter === 'tracking' && this.edit_trackers_separately) {
        return this.saveCreativesTrackers();
      }

      if (this.loading) {
        return;
      }

      this.loading = true;

      this.$http
        .put(`campaigns/${this.$route.params.campaign_id}/creatives/bulk`, this.generatePayload())
        .then(_ => {
          // name and dsp option requires to load new data
          if (['name', 'dsp'].includes(this.parameter)) {
            CreativesService.load();
          }

          this.updateExportNotificationHandler();
        })
        .finally(() => this.finishSave());
    },

    finishSave() {
      this.loading = false;
      this.close();
    },

    close() {
      this.$emit('close');
    },

    updateExportNotificationHandler() {
      if (['dsp', 'cta', 'tracking'].includes(this.parameter)) {
        for (const creative of this.creatives) {
          CreativesService.export.requestTagUpdate(creative);
        }
      }
    },

    updateCreativeTrackers() {
      for (const creative_id in this.creatives_trackers) {
        for (const tracker of this.creatives_trackers[creative_id].impressiontracker) {
          this.$set(tracker, 'action', this.global_tracker);
        }
      }
    },

    addCreativeImpressionTracker(creative_id) {
      this.addTrackers(this.creatives_trackers[creative_id].impressiontracker);
    },

    removeCreativeImpressionTracker(creative_id, index) {
      this.removeTrackers(this.creatives_trackers[creative_id].impressiontracker, index);
    },

    parseCreativeTrackersExcelAndChange(creative_id, index) {
      this.parseTrackers(this.creatives_trackers[creative_id].impressiontracker, index);
    },

    generateCreativesPayload() {
      let creatives = {};

      for (const creative_id in this.creatives_trackers) {
        const creative = this.creatives_trackers[creative_id];
        if (this.is_global_video_tracking_option && !creative.has_video) {
          continue;
        }
        creatives[creative_id] = creative.impressiontracker;
      }

      return {
        append: this.append_trackers,
        creatives,
      };
    },

    saveCreativesTrackers() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      this.$http
        .put(`campaigns/${this.$route.params.campaign_id}/creatives/bulk/tracking`, this.generateCreativesPayload())
        .then(() => this.updateExportNotificationHandler())
        .finally(() => this.finishSave());
    },

    loadCreativesSettings() {
      if (!this.edit_trackers_separately || this.settings_loaded) {
        return;
      }

      this.$http
        .post(`campaigns/${this.$route.params.campaign_id}/creatives/bulk/has_video`, {
          creative_ids: this.creatives?.map(cre => cre.creative_id),
        })
        .then(settings => {
          for (const creative of this.creatives) {
            this.$set(this.creatives_trackers, creative.creative_id, {
              name: creative.name,
              has_video: settings[creative.creative_id],
              impressiontracker: [
                {
                  action: this.global_tracker,
                  uri: '',
                },
              ],
            });
          }

          this.settings_loaded = true;
        });
    },
  },
};
</script>
