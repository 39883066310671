<template>
  <div class="analytics-card" data-intercom-target="analytics-performance-list">
    <CardHeader title="Performance data" tooltip="analytics.performanceCard" :collapsed="collapsed" @collapse="collapseHandler" />
    <div v-if="data === null" class="card-body">
      <Loading />
    </div>
    <div v-if="data !== null" :class="{ collapsed: collapsed === true }">
      <div class="table-scrollable">
        <table class="table analytics-table fixed-header" aria-label="Performance table" v-sortable-table="dataClone">
          <thead>
            <tr>
              <th scope="col" default-order data-key="date">Date</th>
              <th scope="col" data-key="impressions">Impressions</th>
              <th scope="col" v-if="viewability_enabled === true" data-key="viewable.value">Viewable impr.</th>
              <th scope="col" data-key="ctr.percentage">CTR</th>
              <th scope="col" data-key="engagement.percentage">Engagement</th>
              <th scope="col" data-key="dwell">Exposure Time</th>
            </tr>
          </thead>
          <tbody v-if="data !== null">
            <tr v-for="(row, index) in dataClone" :key="index">
              <td>{{ row.date }}</td>
              <td>{{ row.impressions | NumberFilter }}</td>
              <td v-if="viewability_enabled === true" class="hover-data" :data-hover-value="row.viewable.percentage + '%'" :style="{ '--p': row.viewable.percentage + '%' }">
                {{ row.viewable.value | NumberFilter }}
              </td>
              <td class="hover-data" :data-hover-value="row.ctr.value" :style="{ '--p': row.ctr.percentage + '%' }">
                {{ (row.ctr.percentage / 100) | PercentageFilter }}
              </td>
              <td class="hover-data" :data-hover-value="row.engagement.value" :style="{ '--p': row.engagement.percentage + '%' }">
                {{ (row.engagement.percentage / 100) | PercentageFilter }}
              </td>
              <td>{{ row.dwell | TimeFilter }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="footer">Hover on data to see additional information.</div>
    </div>
  </div>
</template>

<script>
import CardHeader from '@analytics/Views/Creative/Cards/Components/CardHeader.vue';
import Loading from '@master/UI/Loading.vue';
import { clone } from '@helpers/Global';
import { Controller } from '@master/Services/AnalyticsAPPService';

export default {
  name: 'PerformanceList',
  components: {
    CardHeader,
    Loading,
  },
  props: {
    data: Array,
  },
  data() {
    return {
      dataClone: null,
      collapsed: true,
      viewability_enabled: true,
    };
  },

  mounted() {
    this.init();
  },
  methods: {
    init() {
      // data still loading
      if (this.data === null) {
        return;
      }
      this.dataClone = clone(this.data);

      const viewability_enabled = Controller.isViewabilityEnabled();
      if (viewability_enabled === false) {
        this.viewability_enabled = false;
      } else if (this.dataClone.length > 0) {
        const fst = this.dataClone[0];
        this.viewability_enabled = fst.viewable != null;
      }
    },

    collapseHandler(status) {
      this.collapsed = status;
    },
  },

  watch: {
    data: {
      handler() {
        this.init();
      },
      deep: true,
    },
  },
};
</script>
