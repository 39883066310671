<template lang="pug">
div#app-wrapper.full-width
  CreativesNavbar(
    v-if="!not_found"
    :creatives="creatives"
    :folders="folders"
    :selection="selection"
    :disable_import="disable_import"
    :disbale_new_creative="dummy_creative != null"
    :breadcrumbs="breadcrumbs"
    :show_unarchive_btn="show_unarchive_btn"
    :show_duplicate_btn="show_duplicate_btn"
    @createPlacement="createPlacement"
  )

  StickyHeader(v-if="!not_found" :key="is_sticky_header_visible")
    Breadcrumb(v-if="breadcrumbs != null" :items="breadcrumbs")
    template(v-if="is_sticky_header_visible")
      div.flex-grow
      SearchSelectSteps(
        v-model="active_filter"
        :options="filters"
        :opts="active_filter_options"
        :search="false"
        filter="Filter"
        @update="activeFilterChangeHandler"
      )
      SearchInput.plain(v-model="query" @input="openSearch")

    //- creatives table header with sorting
    template(v-slot:table_header)
      div#creatives-table(v-if="!items_empty")
        ListHeader(:service="CreativesService" :selection="selection" :select_all_disabled="select_all_disabled")

  div#creatives-list(v-content)
    CampaignCreativeNotFound(v-if="not_found" :type="$route?.params?.folder_id != null ? 'folder' : 'campaign'")
    Loading(v-else-if="items_loading")
    NoListItem(v-else-if="items_empty" type="creatives")

    template(v-else)
      div.custom-tbody

        //- Imports
        template(v-if="show_import != null")
          VASTImport(v-if="show_import === IMPORT.VAST" @onCreate="importCreated" @onRemove="hideImport")
          QuantumImport(v-else-if="show_import === IMPORT.QUANTUM" @onCreate="importCreated" @onRemove="hideImport")
          HTML5Import(v-else-if="show_import === IMPORT.HTML5" @onCreate="importCreated" @onClose="hideImport")
          ExcelImport(v-else-if="show_import === IMPORT.EXCEL" @onCreate="importDone" @onRemove="hideImport")
          SocialImport(v-else-if="show_import === IMPORT.SOCIAL" @onCreate="create" @onRemove="hideImport" @onImport="importDone")

        //- new creative
        CreativeBase(v-if="dummy_creative" :creative="dummy_creative" @showImport="showImport" @cancel="hideNewCreative" @create="create")

        template(v-for="(item, index) of items")
          Folder(
            v-if="item.item_type === LISTITEM.FOLDER"
            ref="folder"
            :key="index"
            :selection="selection"
            :folder="item"
          )

          CreativeListItem(
            v-else-if="item.item_type === LISTITEM.CREATIVE"
            :key="item.creative_id"
            :creative="item"
            :selection="selection"
          )
      div.loading-more-creatives(v-if="loading_more_items && can_load_more")
        Loading
      UpsellingFooter.text-center(v-if="creatives != null && creatives.length > 0" type="creatives" v-observe-visibility="loadMoreItems")
  LoadingModal(
    v-if="loading_head != null"
    :head="loading_head"
    body="Please wait until creative is created. It can take a while."
  )
  EditorSidebar
</template>

<script>
import { getNewCreativeOptions, newCreative } from '@helpers/Creative';
import Selection from '@libs/Selection';
import FilterSelection from '@libs/FilterSelection';

import CampaignService from '@master/Services/CampaignService';
import CreativesService from '@master/Services/CreativesService';
import TemplatesService from '@master/Services/TemplatesService';
import FilterService from '@master/Services/FilterService';

import SearchInput from '@master/UI/Input/SearchInput.vue';
import SearchSelectSteps from '@master/UI/SearchSelect/SearchSelectSteps.vue';
import LoadingModal from '@master/UI/LoadingModal.vue';
import UpsellingFooter from '@master/UI/UpsellingFooter.vue';
import Loading from '@master/UI/Loading.vue';
import CampaignCreativeNotFound from '@master/UI/CampaignCreativeNotFound.vue';
import StickyHeader from '@master/UI/StickyHeader/StickyHeader.vue';

import EditorSidebar from '@root/src/global/sidebar/edit/EditorSidebar.vue';

import CreativesNavbar from '@cm/Views/Creatives/Components/CreativesNavbar.vue';
import CreativeListItem from '@cm/Views/Creatives/Creative/CreativeListItem.vue';
import CreativeBase from '@cm/Views/Creatives/Creative/CreativeBase.vue';
import NoListItem from '@cm/UI/Global/NoListItem.vue';
import Breadcrumb from '@cm/UI/Global/Breadcrumb.vue';
import ListHeader from '@cm/UI/Global/ListHeader.vue';
import Folder from '@cm/Views/Creatives/Folder/Folder.vue';

import VASTImport from '@cm/Views/Creatives/Cards/VASTImport.vue';
import QuantumImport from '@cm/Views/Creatives/Cards/QuantumImport.vue';
import HTML5Import from '@cm/Views/Creatives/Cards/HTML5Import.vue';
import ExcelImport from '@cm/Views/Creatives/Cards/ExcelImport.vue';
import SocialImport from '@cm/Views/Creatives/Cards/Social/SocialImportV2.vue';

import CreativeTraits from '@master/Traits/CreativeTraits.vue';
import StatusTraits from '@master/Traits/StatusTraits.vue';

import { IMPORT, LISTITEM } from '@master/constants';

export default {
  name: 'CreativesView',

  mixins: [CreativeTraits, StatusTraits],

  components: {
    EditorSidebar,
    SearchInput,
    SearchSelectSteps,
    LoadingModal,
    CreativesNavbar,
    CreativeListItem,
    VASTImport,
    QuantumImport,
    HTML5Import,
    ExcelImport,
    SocialImport,
    CreativeBase,
    Breadcrumb,
    UpsellingFooter,
    Loading,
    NoListItem,
    CampaignCreativeNotFound,
    Folder,
    ListHeader,
    StickyHeader,
  },

  computed: {
    folders() {
      if (!this.items) return this.items;
      return this.items.filter(item => item.item_type === LISTITEM.FOLDER);
    },

    creatives() {
      if (!this.items) return this.items;
      return this.items.filter(item => item.item_type === LISTITEM.CREATIVE);
    },

    items_loading() {
      return this.items == null;
    },

    items_empty() {
      /* no creatives have been added to campaign */
      if (this.has_creation_in_progress) return false;

      return !this.items?.length;
    },

    has_creation_in_progress() {
      return this.dummy_creative || this.show_import;
    },

    select_all_disabled() {
      return this.items_loading || this.items_empty;
    },

    is_sticky_header_visible() {
      return !this.items_empty;
    },

    active_filter_options() {
      switch (this.active_filter) {
        case 'layouts':
          return this.layout_options;
        case 'status':
          return this.status_options;
        case 'types':
          return this.type_options;
        default:
          return [];
      }
    },

    show_unarchive_btn() {
      if (!this.creatives?.length) return false;

      /** if all creatives are archieved in the view */
      if (this.creatives.every(creative => this.isArchived(creative?.status))) {
        return true;
      }

      /** if all selected creatives are archieved */
      return this.selection?.selected_items?.every(creative_id => {
        const c = this.creatives.find(creative => creative.creative_id === creative_id);
        if (c == null) return false;
        return this.isArchived(c?.status);
      });
    },

    show_duplicate_btn() {
      if (!this.creatives?.length) return false;

      return !this.selection?.selected_items?.some(creative_id => {
        const c = this.creatives.find(creative => creative.creative_id === creative_id);
        if (c == null) return false;
        return !c.actions.can_duplicate;
      });
    },

    filters() {
      return [
        { value: 'layouts', label: 'Layout' },
        { value: 'status', label: 'Status' },
        { value: 'types', label: 'Ad Type' },
      ];
    },
  },

  data() {
    return {
      IMPORT,
      LISTITEM,
      CreativesService,

      campaign: null,
      items: null,
      breadcrumbs: null,
      not_found: false,

      selection: new Selection(),
      filter_selection: new FilterSelection(),

      user: null,
      show_import: null,
      disable_import: false,

      dummy_creative: null,
      loading_head: null,
      loading_more_items: false,
      can_load_more: true,

      // options
      layout_options: [],
      status_options: [],
      type_options: [],

      //filters
      query: '',
      active_filter: null,
    };
  },

  created() {
    // reset any info in the cmapaignservice
    CampaignService.reset();

    TemplatesService.load();

    FilterService.load();
    FilterService.subscribe(state => {
      if (state == null) return;

      this.layout_options = state['creatives.layout'];
      this.status_options = state.status;
      this.type_options = state['creatives.type'];
    }, this);

    this.$user.subscribe(user => {
      this.user = user;
    }, this);

    CreativesService.subscribe(state => {
      this.campaign = state?.campaign;
      this.items = state?.items;
      this.breadcrumbs = state?.breadcrumbs;
      this.can_load_more = state?.can_load_more;
      this.not_found = state?.not_found === true;

      this.selection.data = this.items;
      this.handleCampaignNotFound();

      if (state != null) {
        this.setupSidebar();
      }
    }, this);

    // must be after creatives service subscription
    this.loadCreatives();

    if (this.$route?.params?.new_creative != null) {
      this.createPlacement(this.$route.params.new_creative);
    }
  },

  methods: {
    loadCreatives() {
      this.items = null;
      CreativesService.load();
    },

    loadMoreItems(is_visible) {
      if (!is_visible || this.loading_more_items) return;

      this.loading_more_items = true;
      CreativesService.loadMore();
    },

    handleCampaignNotFound() {
      if (!this.not_found) return;

      this.resetSidebar();
    },

    setupSidebar() {
      if (!this.$sidebar) return;
      this.resetSidebar();

      if (this.breadcrumbs == null) return;

      this.$sidebar.setAnalytics(this.campaign?.analytics_url ?? false);
      this.$sidebar.setPreview(this.breadcrumbs?.[this.breadcrumbs.length - 1]?.preview_url ?? false, this.folders?.length > 0);
      this.$sidebar.setFlight({ visible: true });

      if (this.campaign?.actions?.can_update) {
        this.$sidebar.setEdit('Campaign Settings', () => {
          CampaignService.active.set(this.campaign);
        });
      }
    },

    async resetSidebar() {
      if (!this.$sidebar) return;
      this.$sidebar.reset();

      if (this.breadcrumbs && this.breadcrumbs.length > 1) {
        const breadcrumb = this.breadcrumbs[this.breadcrumbs.length - 2];
        this.$sidebar.setBack(breadcrumb.label, breadcrumb.url);
      } else {
        this.$sidebar.setBack('Campaigns list', '/');
      }
    },

    showImport(key) {
      this.show_import = key;
    },

    hideImport() {
      this.show_import = null;
      this.disable_import = false;
    },

    importCreated(creatives) {
      CreativesService.addItems(creatives);
      this.selection.unselectAll();
      this.hideImport();
    },

    importDone(creatives) {
      CreativesService.addItems(creatives);
      this.selection.unselectAll();
      this.hideImport();

      if (creatives.length === 1) {
        this.$router
          .push({
            name: 'creative',
            params: { creative_id: creatives[0].creative_id },
          })
          .catch(_ => {
            /** supress duplicate route error */
          });
      }
    },

    newDummyCreative(type) {
      let options = getNewCreativeOptions(type);
      options.folder_id = this.$route?.params?.folder_id;

      newCreative(options).then(creative => {
        this.dummy_creative = creative;
        if (this.isVast(this.dummy_creative.type)) {
          this.create();
        }
      });
    },

    createPlacement(type = 'infeed') {
      if (Object.values(IMPORT).includes(type)) {
        this.disable_import = true;
        return this.showImport(type);
      }

      this.newDummyCreative(type);
    },

    async hideNewCreative() {
      if (!(await this.$confirm('Are you sure?', 'All changes will be discarded'))) {
        this.$ga?.e({
          element_name: 'creating creative cancellation reverted',
        });

        return;
      }
      this.$ga?.e({
        element_name: 'creating creative cancellation confirmed',
      });
      this.dummy_creative = null;
    },

    create(creative) {
      // if vast is selected from quick menu, then use dummy creative
      if (creative == null) {
        creative = this.dummy_creative;
      }

      this.loading_head = 'Creating new creative';

      if (this.show_import != null) {
        this.hideImport();
      }

      const version = creative.layout_id != null && creative.template_id == null ? 'v2/' : '';
      const path = `${version}campaigns/${this.$route.params.campaign_id}/creatives`;

      this.$http
        .post(path, creative)
        .then(new_creative => {
          CreativesService.addItem(new_creative);

          this.$ga?.e({
            element_name: 'creative saved/created automatically',
          });

          this.$router
            .push({
              name: 'creative',
              params: { creative_id: new_creative.creative_id },
            })
            .catch(_ => {
              /** supress duplicate route error */
            });
        })
        .finally(() => {
          this.loading_head = null;
          this.dummy_creative = null;
        });
    },

    updateFilterService(filters) {
      if (filters == null) return;

      this.filter_selection.select(this.active_filter, filters);

      this.openSearch();
    },

    openSearch() {
      const params = { ...this.filter_selection.getParams() };
      if (this.query.trim() !== '') {
        params.search = [this.query.trim()];
      }

      const query = {};
      for (const key in params) {
        query[key] = params[key].join(',');
      }

      this.$router.push({ name: 'search', query });
    },

    activeFilterChangeHandler(filters) {
      if (this.active_filter == null) return;
      this.updateFilterService(filters);
    },

    clearSearch() {
      this.query = '';
      this.loadCreatives();
    },
  },
};
</script>
