<script>
import TemplatesService from '@master/Services/TemplatesService';
import AssetHelper from '@helpers/Asset';

import { clone } from '@helpers/Global';

export default {
  name: 'CropperMixins',

  data() {
    return {
      Cropper: this.$cropper(),
      cropper_settings: null,
      overlays_inside_placement: this.isTemplateInterscroller(),
    };
  },

  created() {
    this.Cropper.beforeShow = (done, cropper) => {
      this.initializeBackgroundSettingsForCropper(cropper);
      this.cropperBackgroundHandler(done, cropper, this.default_asset_id);

      // hide opening croppingtool text bit later, after the fade
      setTimeout(() => this.removeLoading(), 500);
    };

    this.Cropper.afterCropperReady = cropper => {
      this.initializeBackgroundSettingsForCropper(cropper);
    };
  },

  methods: {
    initCropper() {
      this.Cropper.onsave = (settings, auto_crop = false) => {
        this.cropper_settings = settings;
        this.cropperSave(auto_crop);
      };

      this.Cropper.oncancel = () => {
        this.resetConvertUploadData?.();
        this.removeLoading?.();
      };

      this.Cropper.init(this.cropper_options, this.creative);
    },

    initializeBackgroundSettingsForCropper(cropper) {
      if (cropper == null || !this.is_new || this.creative?.template == null) return;

      const template_default_white_bg = TemplatesService.isDefaultAssetColorWhite(this.creative.template);
      if ((template_default_white_bg && this.isMain()) || this.isBackground()) {
        cropper.BackgroundController.setType('bg-color');
      }
    },

    cropperBackgroundHandler(done, cropper, active_asset_id, vnode = this) {
      if (this.isQuantum() || this.singleLayerAsset(active_asset_id)) {
        return done();
      }

      AssetHelper.createCropperBackground(
        vnode,
        layers => {
          AssetHelper.addCropperBackgroundLayers(cropper, layers, this.asset);
          done();
        },
        active_asset_id,
      );
    },

    cropperSave(auto_crop = false) {
      if (this.is_new) {
        if (this.library_upload_data != null) {
          this.uploadFromLibrary();
        } else if (this.convert_upload_data != null) {
          this.assets_library.assetSlotConvertHandler(this.convert_upload_data, this.handleLibraryUpload);
        } else {
          const file = this.Cropper.getFile();

          if (file) {
            file._callback = this.handleLibraryUpload;
            this.assets_library.assetSlotUploadHandler(file);
          }
        }
      } else {
        this.saveAsset(auto_crop);
      }
    },

    getCropperSettings() {
      return clone(this.cropper_settings);
    },
  },
};
</script>
