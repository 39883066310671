<template lang="pug">
TestLayout(title="Sliders")

  TestSection(subtitle="10 - 100 Low/High")
    Slider(v-model="quality" :options="{min: 10, min_text: 'Low', max: 100, max_text: 'High', step: 5}")
    pre {{ quality }}

  TestSection(subtitle="0 - 100 % Decimal")
    Slider(v-model="percentage" :options="{min: 0, min_text: '0%', max: 1, max_text: '100%', step: .1, scale: 100}")
    pre {{ percentage }}

  TestSection(subtitle="0 - 100 %")
    Slider(v-model="percentage2" :options="{min: 0, min_text: '0%', max: 100, max_text: '100%', step: 10}")
    pre {{ percentage2 }}

  TestSection(subtitle="0.1 - 5 Slow/Fast")
    Slider(v-model="speed" :options="{ min: 0.1, max: 5, step: 0.1, min_text: 'Slow', max_text: 'Fast'}")
    pre {{ speed }}

  TestSection(subtitle="With input suffix ms")
    Slider(v-model="quality" :input="true" :input_suffix="'ms'" :options="{min: 10, max: 100, step: 5}")
    pre {{ quality }}

  TestSection(subtitle="With null v-model")
    Slider(v-model="null_value" :input="true" :input_suffix="'ms'" :options="{min: 10, max: 100, step: 5}")
    pre {{ null_value }}

  TestSection(subtitle="disabled")
    Slider(v-model="null_value" :input="true" :input_suffix="'ms'" :options="{min: 10, max: 100, step: 5, min_text: 'Slow', max_text: 'Fast'}" :disabled="true")
    pre {{ null_value }}
</template>

<script>
import TestLayout from '@root/src/apps/components/Global/TestLayout.vue';
import TestSection from '@root/src/apps/components/Global/TestSection.vue';

import Slider from '@master/UI/Slider.vue';

export default {
  name: 'SliderTest',

  components: {
    TestLayout,
    TestSection,
    Slider,
  },

  data() {
    return {
      quality: 50,
      percentage: 0.7,
      percentage2: 70,
      speed: 0.3,
      null_value: null,
    };
  },
};
</script>
