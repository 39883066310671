<template lang="pug">
SlotContainer(:asset="asset" :loading_text="loading_text" @drop="drop")
  template(v-slot:input)
    input(type="file" ref="asset-input" :accept="asset.mime" @change="uploadFile" @click="clearInput")

  template(v-slot:thumbnail)
    Thumbnail(:asset="asset")

  template(v-slot:first_row)
    div.fs-14.fw-500 {{ asset_name }}&nbsp;
      span.color-danger *

  template(v-slot:icons)
    i.nexd-icon-32-upload.hover-primary(aria-hidden="true" v-tooltip="{value: 'Replace asset'}" @click="selectFile")

  template(v-slot:third_row)
    SlotTextRow(title="File size" :text="asset.filesize | FileSize")

  template(v-slot:fourth_row)
    SlotTextRow(title="File dimensions" :text="`${asset.width}x${asset.height}`")
</template>

<script>
import styles from './Slot.module.scss';

import AssetHelper from '@helpers/Asset';
import Library from '@libs/AssetLibrary';

import SlotContainer from './SlotContainer';
import SlotTextRow from './SlotTextRow';

import Thumbnail from '@master/UI/Thumbnail/Thumbnail.vue';

export default {
  name: 'SlotDevtoolAsset',

  components: {
    SlotContainer,
    SlotTextRow,

    Thumbnail,
  },

  props: {
    creative: Object,
    asset: Object,
  },

  computed: {
    asset_id() {
      return this.asset?.asset_id;
    },

    asset_name() {
      return this.asset?.name ?? this.asset?.filename;
    },
  },

  data() {
    return {
      styles,

      library: new Library(),

      admin_toggle: false,
      loading_text: null,
    };
  },

  created() {
    this.$user.subscribe(user => {
      if (user != null) {
        this.admin_toggle = this.$user.adminMode();
      }
    }, this);

    this.initAssetsLibrary();
  },

  methods: {
    initAssetsLibrary() {
      this.library.assets = Object.values(this.creative.assets ?? {});
      this.library.setPath(`v2/creative/${this.creative.creative_id}/assets`);
    },

    drop(e) {
      this.replaceAsset(e.dataTransfer.files[0]);
    },

    selectFile() {
      this.$refs?.['asset-input']?.click();
    },

    uploadFile(e) {
      this.replaceAsset(e.target.files[0]);
    },

    async replaceAsset(file) {
      this.loading_text = 'Processing asset...';
      const metadata = await AssetHelper.getImageDimensions(file);

      if (metadata.width !== this.asset.width || metadata.height !== this.asset.height) {
        this.loading_text = null;
        return this.$alert(`The dimensions of the new asset do not match the original asset. Please upload asset with width of ${this.asset.width}px and height of ${this.asset.height}px.`, 'Could not replace asset');
      }

      const asset_extension = this.asset_name.split('.').pop();
      const file_extension = file.name.split('.').pop();

      if (asset_extension !== file_extension) {
        this.loading_text = null;
        return this.$alert(`The file type of the new asset does not match the original asset. Please upload asset with file type of ${this.asset.mime}.`, 'Could not replace asset');
      }

      const new_file = new File([file], this.asset_name);

      this.loading_text = 'Uploading asset...';
      const assets = await this.library.fileChangeHandler({ files: [new_file], errors: [] }, true);

      for (const asset of assets ?? []) {
        this.$set(this.creative.assets, asset.asset_id, asset);
      }

      this.initAssetsLibrary();
      this.clearInput();
      this.loading_text = null;
    },

    clearInput() {
      // clear so user can select the same file again
      if (this.$refs?.['asset-input']) {
        this.$refs['asset-input'].value = '';
      }
    },
  },
};
</script>
