<template lang="pug">
div.card-body.asset-upload-wrapper(v-if="settings != null")
  div.flex-grow

    div.row.mb-16(v-if="settings.autoplay != null || settings.loop != null")
      div.col-13
        div.row.flex-align-center
          div.col-10
            label.inline Autoplay
          div.col
            Toggle( v-model="settings.autoplay" :disabled="autoplay_toggle_disabled" @input="changeHandler")
      div.col-11
        div.row.flex-align-center
          div.col-8
            label.inline Loop
          div.col
            Toggle( v-model="settings.loop" @input="changeHandler")

    div.row.mb-16(v-if="settings.audio != null || settings.video_controls != null")
      div.col-13
        div.row.flex-align-center
          div.col-10
            label.inline Audio button
          div.col
            Toggle( v-model="settings.audio" @input="changeHandler")
      div.col-11
        div.row.flex-align-center
          div.col-8
            label.inline Controls
          div.col
            Toggle( v-model="settings.video_controls" :disabled="video_control_toggle_disabled" @input="changeHandler")

    div.row(v-if="settings.duration != null")
      template(v-if="is_custom_splash")
        div.col-12
          label.inline Display Time
        div.col.flex-grow
          Input(v-model="settings.duration" v-number-input suffix="ms" @blur="changeHandler" style="width: 100px;")
      template(v-else)
        div.col-12
          label.inline Video end card duration
          i.nexd-icon-16-help( aria-hidden="true" v-tooltip="{'value': 'Specify how long the end card image is shown at the end of the video'}")
        div.col.flex-grow
          Slider(v-model="settings.duration" :options="{min: 1, max: 15, step: 1, unit: 'sec'}" @change="changeHandler")

    div.row(v-if="settings.fade_in_time != null")
      div.col-12
        label.inline Video end card fade duration
        i.nexd-icon-16-help( aria-hidden="true" v-tooltip="{'value': 'Specify the time it takes for the end card to smoothly fade over the previous content in milliseconds'}")
      div.col.flex-grow
        Slider(v-model="settings.fade_in_time" :options="{min: 0, max: 1500, step: 100, unit: 'ms'}" @change="changeHandler")

    div.row(v-if="settings.below_overlays != null")
      div.col-12
        label.inline Keep overlays visible
        i.nexd-icon-16-help( aria-hidden="true" v-tooltip="{'value': 'Keeps the added overlays also visible during the endcard'}")
      div.col.flex-grow
        Toggle(v-model="settings.below_overlays" @input="changeHandler")

    OptionsBlockRow.col(
      v-if="settings?.gesture_id != null"
      label="Pin gesture:"
      :asset="true"
      align="top"
      tooltip="This is the element that, when clicked, opens the hot spot media. You can upload a custom asset or choose from predefined assets in the dropdown menu."
    )
      CustomAsset(
        :creative="creative"
        :asset_id="settings.gesture_id"
        :disabled="false"
      )
      em.mt-4.fs-12.color-gray-800 Accepted files:&nbsp;
        span.color-gray-500 ({{ file_extensions }})
      em.mt-4.fs-12.color-gray-800 Size:&nbsp;
        span.color-gray-500 Max: placement size {{creative.width * 2}}x{{creative.height * 2}}

    OptionsBlockRow.col.mt-24(
      v-if="settings?.gesture_animation?.type != null"
      label="Pin animation:"
      align="center"
      tooltip="Select an animation for the pin asset. If you prefer it to be static, choose \"None.\""
    )
      SearchSelect(
        :options="{fade: 'Fade', pulse: 'Pulse', '': 'None'}"
        :search="false"
        v-model="settings.gesture_animation.type"
        width="8rem"
        @input="changeHandler"
      )

    OptionsBlockRow.col.mt-24(
      v-if="settings?.close_id != null"
      label="Close icon:"
      :asset="true"
      align="top"
      tooltip="This is the element that, when clicked, closes the hot spot media. You can upload a custom asset or choose from predefined assets in the dropdown menu."
    )
      CustomAsset(
        :creative="creative"
        :asset_id="settings.close_id"
        :disabled="false"
      )
      em.mt-4.fs-12.color-gray-800 Accepted files:&nbsp;
        span.color-gray-500 ({{ file_extensions }})
      em.mt-4.fs-12.color-gray-800 Size:&nbsp;
        span.color-gray-500 Max: placement size {{creative.width * 2}}x{{creative.height * 2}}

    OptionsBlockRow.col.mt-24(
      v-if="settings?.settings?.background_color != null"
      label="Background color:"
      align="center"
      tooltip="Choose the background color that will appear beneath the hot spot media."
    )
      ColorOption.col-10(v-model="settings.settings.background_color" @input="changeHandler")

    OptionsBlockRow.col.mt-24(
      v-if="settings?.settings?.background_alpha != null"
      label="Background opacity:"
      align="center"
      tooltip="Select the opacity level for the background color. 0% represents complete transparency, while 100% is fully opaque."
    )
      Slider(v-model="settings.settings.background_alpha" :options="{min: 0, max: 1, step: .1, max_text: '100%', min_text: '0%', unit: '%', scale: 100}" @change="changeHandler")

    template( v-if="admin_toggle === true && !is_video_endcard && !is_custom_splash")
      hr
      small.mb-16 Admin options

      div.row.mb-16(v-if="settings.fullscreen != null || settings.internal_audio != null")
        div.col-12
          div.row.flex-align-center
            div.col-12
              label.inline Fullscreen btn
            div.col
              Toggle( v-model="settings.fullscreen" @input="changeHandler")
        div.col-1
        div.col-11
          div.row.flex-align-center
            div.col-12
              label.inline Int. audio
              i.nexd-icon-16-help( aria-hidden="true" v-tooltip="{'value': 'Select when you want that audio track is not extracted from video (results in bigger video file size)'}")
            div.col
              Toggle( v-model="settings.internal_audio" @input="changeHandler('crop')")

      div.row(v-if="settings.loop_limit != null || settings.click_action != null")
        div.col-12
          label.mb-4 Loop count
          input.form-control(
            type="number"
            v-model="settings.loop_limit"
            @input="changeHandler"
            :reference="'loop_limit'"
            min="0" max="30" v-positive-number-input)
        div.col-1
        div.col-11
          label.mb-4 Click action
          SearchSelect(
            :options="{'': 'None', audio: 'Audio', video: 'Play/Pause', fullscreen: 'Fullscreen'}"
            :search="false"
            v-model="settings.click_action"
            @input="changeHandler")
</template>

<script>
import AssetHelper from '@helpers/Asset';
import CreativeTraits from '@master/Traits/CreativeTraits.vue';

import SearchSelect from '@master/UI/SearchSelect/SearchSelect.vue';
import Toggle from '@master/UI/Toggle.vue';
import Slider from '@master/UI/Slider.vue';
import Input from '@master/UI/Input/Input.vue';
import ColorOption from '@master/UI/ColorOption.vue';

import CustomAsset from '@cm/Views/Creatives/Cards/Components/TemplateSettings/CustomAsset.vue';
import OptionsBlockRow from '@cm/Views/Creatives/Cards/Components/TemplateSettings/OptionsBlockRow.vue';
import StyleInput from '@cm/UI/Inputs/StyleInput.vue';
import CustomVideoControlAsset from '@cm/Views/Creatives/Cards/Components/TemplateSettings/CustomVideoControlAsset.vue';

/**
 * @deprecated by SettingsAsset.vue - kept only for layout system (in SlotV2)
 */
export default {
  name: 'AssetSettings',
  mixins: [CreativeTraits],
  components: {
    SearchSelect,
    Toggle,
    CustomVideoControlAsset,
    Slider,
    Input,
    ColorOption,
    StyleInput,
    CustomAsset,
    OptionsBlockRow,
  },

  props: {
    creative: Object,
    asset_id: String,
    crop_settings: {
      type: Object,
      default: null,
    },
  },

  computed: {
    is_video_endcard() {
      return this.creative?.assets?.[this.asset_id]?.filename?.includes('endcard');
    },

    settings() {
      const settings = this.creative.settings?.assets?.[this.asset_id] ?? {};
      return Object.keys(settings).length > 0 ? settings : null;
    },

    autoplay_toggle_disabled() {
      return !this.settings.video_controls;
    },

    video_control_toggle_disabled() {
      return !this.settings.autoplay;
    },

    is_custom_splash() {
      return AssetHelper.isCustomSplash(this.asset_id);
    },
  },

  data() {
    return {
      admin_toggle: false,
      file_extensions: AssetHelper.mimeToExtension(AssetHelper.getImageMimes().join(', ')),
    };
  },

  created() {
    this.$user.subscribe(() => {
      this.admin_toggle = this.$user.adminMode();
    }, this);
  },

  methods: {
    changeHandler(option = null) {
      let obj = {
        value: this.creative.settings.assets,
        options: {},
      };

      if (option === 'crop') {
        obj.options.crop = [this.asset_id];
      }

      const path = `creative/${this.creative.creative_id}/settings/assets`;
      this.$http.put(path, obj).then(response => {
        if (response?.assets?.[this.asset_id]) {
          for (const setting_key in response.assets[this.asset_id]) {
            this.$set(this.settings, setting_key, response.assets[this.asset_id][setting_key]);
          }
        }
        // call change with reload asset library arg
        this.$parent.componentChangeHandler(true);
      });
    },

    customVideoAssetChangeHandler() {
      // call change with reload asset library arg
      this.$parent.componentChangeHandler(true);
    },
  },
};
</script>
