<template lang="pug">
ImportCard(:active="true")
  template(v-slot:title) Import multiple placements from Excel / CSV

  section
    small.lh-32 Upload a filled Excel or CSV template
      i.nexd-icon-32-help(v-if="!is_sdk_user" aria-hidden="true" v-tooltip="{template: 'cm.excelImport'}")

    ImportDropArea(:textarea="false" :mime="['text/csv', 'application/xls']" @change="fileChangeHandler")

  LoadingModal(
    v-if="show_loading"
    head="Uploading spreadsheet…"
    body="Please wait while we import your placements"
    :percentage="timer_percentage"
  )

  template(v-slot:buttons)
    Button(type="link-primary" label="Cancel" @click="cancel")
</template>

<script>
import { getBase64 } from '@helpers/Global';
import { newCreative } from '@helpers/Creative';
import { showAssetUploadErrors } from '@helpers/Alerts';

import PercentageTimerMixin from '@root/src/global/mixins/PercentageTimerMixin.vue';
import SDKMixin from '@root/src/global/mixins/SDKMixin.vue';

import ImportCard from '@cm/Views/Creatives/Cards/Import/ImportCard.vue';
import ImportDropArea from '@cm/Views/Creatives/Cards/Import/ImportDropArea.vue';

import Button from '@master/UI/Buttons/Button.vue';
import LoadingModal from '@master/UI/LoadingModal.vue';

export default {
  name: 'ExcelImport',
  mixins: [PercentageTimerMixin, SDKMixin],

  components: {
    Button,
    ImportCard,
    ImportDropArea,
    LoadingModal,
  },

  data() {
    return {
      creative: null,
      files: null,
      show_loading: false,
      loaded_creatives: [],
    };
  },

  created() {
    newCreative({ type: 0, device: 92, imported: true }).then(creative => (this.creative = creative));
  },

  methods: {
    fileChangeHandler(event) {
      const { files, errors } = event;
      this.files = files;

      if (errors?.length > 0) {
        return showAssetUploadErrors(errors);
      }

      this.importFiles();
    },

    async cancel() {
      // reset all changes to original creative
      if (await this.$confirm('Are you sure?', 'All changes will be discarded')) {
        this.$emit('onRemove');
      }
    },

    importFiles() {
      if (this.files?.length > 0) {
        this.show_loading = true;

        this.timerReset();

        let promises = [];

        for (const file of this.files) {
          promises.push(getBase64(file));
        }

        Promise.allSettled(promises).then(result => {
          const obj = {
            import: [],
          };

          for (const { value } of result) {
            if (!value) continue;

            obj.import.push({
              campaign_id: this.$route.params.campaign_id,
              filename: file.name,
              type: 'excel',
              data: value,
            });
          }

          this.loaded_creatives = [];

          this.timerStart(obj.import.length, 1);

          this.$http
            .post('campaigns/import', obj)
            .then(response => {
              this.loaded_creatives = response.campaigns[0].creatives;
              this.close();
            })
            .catch(() => {
              this.show_loading = false;
            })
            .finally(_ => {
              this.timerStop();
            });
        });
      }
    },

    close() {
      this.show_loading = false;
      this.$emit('onCreate', this.loaded_creatives);
    },
  },
};
</script>
