<template lang="pug">
div.asset-slot
  AssetUploadV2(
    ref="asset"
    :asset="item"
    :creative="creative"
    :key="$vnode.key"
    :parent="parent"
    :parent_slot="parent_slot"
  )
</template>

<script>
// For quick solution - let's use copy of old asset upload and modify it
import AssetUploadV2 from '@cm/Views/Creatives/Cards/Components/Asset/SlotV2.vue';

export default {
  name: 'AssetSlot',

  components: {
    AssetUploadV2,
  },

  props: {
    creative: Object,
    item: Object,
    parent: {
      type: Object,
      default: null,
    },
    parent_slot: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {};
  },

  created() {
    this.applyParentParamsForAsset();
  },

  methods: {
    applyParentParamsForAsset() {
      if (this.parent_slot != null) {
        this.item.width = this.determineNewSize(this.item.width, this.parent_slot.width);
        this.item.height = this.determineNewSize(this.item.height, this.parent_slot.height);
        this.item.settings = {
          ...this.item.settings,
          ...this.parent_slot.settings,
        };
      }
    },

    determineNewSize(self, parent = null) {
      if (parent == null) {
        return self;
      }
      const parent_prefix = parent.substr(0, 1);
      const self_prefix = self.substr(0, 1);

      const parent_nr = parseInt(parent.substr(1), 10);
      const self_nr = parseInt(self.substr(1), 10);

      if (parent_prefix === '=') {
        // Will keep parent size
        return parent;
      } else if (parent_prefix === '<' && self_prefix === '<') {
        return '<' + (parent_nr / 100) * (self_nr / 100) * 100;
      } else if (parent_prefix === '+' && self_prefix === '+') {
        return '+' + Math.min(parent_nr, self_nr);
      } else if (parent_prefix === '+') {
        return parent;
      } else if (self_prefix === '+') {
        return self;
      }
    },
  },
};
</script>
