<template lang="pug">
SettingsContainer(v-if="settings != null" title="Map settings")

  div.flex.flex-align-center
    img.mr-8(
      :src="$cdn + 'dist/assets/cm/warning.svg'"
      alt=""
    )
    span.fs-14.color-cyan-blue Location accuracy depends on the permissions the user has given to the app and the ad itself.

  SettingsRow(
    v-if="settings?.map_style != null"
    label="Map style"
    tooltip="Choose how the map should look like: greyscale, classic or night mode"
  )
    div.row.map-asset-mode-wrap
      template(v-for="(style, key) in map_styles")
        div.map-asset-mode(
          :class="{'selected': settings.map_style === key}"
          :style="{'background-image': `url('${$cdn}dist/assets/${style}.png')`}"
          v-tooltip="{value: key.charAt(0).toUpperCase() + key.slice(1)}"
          @click="setSetting('map_style', key)"
        )
          div.map-asset-overlay

  SettingsRow(
    v-if="settings?.map_show_four_markers != null"
    label="Legend"
    tooltip="Choose whether to show none, one or three nearest locations on the map"
    align="top"
  )
    div.flex.flex-direction-column.row-gap-4
      CustomCheckbox(
        v-for="(key, marker) in map_markers"
        :key="key"
        :radio="true"
        :value="settings.map_show_four_markers === key"
        @change="setSetting('map_show_four_markers', key)"
      )
        span.ml-8 {{ marker }}

  SettingsRow(
    v-if="settings?.map_direction_btn != null"
    label="Directions icon"
    tooltip="Choose whether to show an icon on the map that opens a map app with directions"
  )
    Toggle(v-model="settings.map_direction_btn" @input="mapUpdateSettings")

  SettingsRow(
    v-if="settings?.map_unit != null"
    label="Distance units"
    tooltip="Choose whether to show the locations distance in kilometers or miles"
    align="top"
  )
    div.flex.flex-direction-column.row-gap-4
      CustomCheckbox(
        v-for="(unit, key) in map_units"
        :key="key"
        :radio="true"
        :value="settings.map_unit === key"
        @change="setSetting('map_unit', key)"
      )
        span.ml-8 {{ unit }}
</template>

<script>
import RequestLimiter from '@libs/RequestLimiter';

import CustomCheckbox from '@master/UI/CustomCheckbox.vue';
import Toggle from '@master/UI/Toggle.vue';

import OptionsBlockRow from '@cm/Views/Creatives/Cards/Components/TemplateSettings/OptionsBlockRow.vue';
import SettingsContainer from '@cm/Views/Creatives/Cards/Components/Asset/Settings/SettingsContainer.vue';
import SettingsRow from '@cm/Views/Creatives/Cards/Components/Asset/Settings/SettingsRow.vue';

export default {
  name: 'SettingsMap',

  components: {
    CustomCheckbox,
    OptionsBlockRow,
    SettingsContainer,
    SettingsRow,
    Toggle,
  },

  props: {
    creative: {
      type: Object,
      required: true,
    },

    asset_id: {
      type: String,
      required: true,
    },
  },

  computed: {
    settings() {
      return this.creative?.assets?.[this.asset_id]?.settings ?? null;
    },

    map_styles() {
      return {
        greyscale: 'map-mode-black-and-white',
        default: 'map-mode-normal',
        night: 'map-mode-night',
      };
    },

    map_markers() {
      return {
        'Do not show': 0,
        'Only closest location': 1,
        'Closest + 2 nearest locations': 2,
      };
    },

    map_units() {
      return {
        metric: 'km',
        imperial: 'miles',
      };
    },
  },

  methods: {
    setSetting(key, value) {
      if (key == null || value == null) {
        return;
      }

      this.$set(this.creative?.assets?.[this.asset_id].settings, key, value);
      this.mapUpdateSettings();
    },

    mapUpdateSettings() {
      RequestLimiter.hook(
        `map-settings-${this.asset_id}`,
        () => {
          this.$http
            .post(`creatives/${this.creative.creative_id}/assets/mapSettings`, {
              asset_id: this.asset_id,
              ...(this.creative?.assets?.[this.asset_id]?.settings ?? {}),
            })
            .then(() => {
              this.$emit('change');
            });
        },
        1000,
      );
    },
  },
};
</script>
