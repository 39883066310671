<template lang="pug">
Group(v-if="start != null" label="Date range:" :tooltip="{template: 'analytics.dateRangeTime', position: 'bottom'}")
  SelectDateRange(
    :start="parseInt(start)"
    :end="parseInt(end)"
    :options="{timezone: false}"
    @change="dateFilterHandler"
  )
  NotificationBox(
    v-if="!is_sdk_user"
    info="The timezone selection has been moved to Organization settings."
    href="https://support.nexd.com/en/articles/9265415-analytics-timezone-selection-move"
  )
</template>

<script>
import moment from 'moment';

import AnalyticsService from '@master/Services/AnalyticsService';
import { filters } from '@master/Services/AnalyticsAPPService.js';

import SDKMixin from '@root/src/global/mixins/SDKMixin.vue';

import Group from '@analytics/UI/Sidebar/Components/Group.vue';

import NotificationBox from '@master/UI/NotificationBox/NotificationBox.vue';
import SelectDateRange from '@master/UI/SearchSelect/SelectDateRange.vue';

export default {
  name: 'SidebarFilters',

  mixins: [SDKMixin],

  components: {
    Group,
    NotificationBox,
    SelectDateRange,
  },

  data() {
    return {
      filters,

      start: null,
      end: null,
    };
  },

  created() {
    AnalyticsService.subscribe(data => {
      const public_creative = AnalyticsService.getPublicCreative();

      if (data != null || public_creative != null) {
        this.init(data ?? public_creative);
      }
    }, this);
  },

  methods: {
    init(obj) {
      // get timestamp with seconds, as it would come in backend
      if (obj?.ended_on == null) {
        this.$set(obj, 'ended_on', Math.round(new Date().getTime() / 1000));
      }

      // if end start date is missing, set start the same as end
      if (obj?.started_on == null && obj?.ended_on != null) {
        this.$set(obj, 'started_on', obj.ended_on);
      }

      this.initDates(obj);
    },

    dateFilterHandler({ start, end, start_day }) {
      filters.start = start;
      filters.end = end;
      filters.start_week = start_day;

      this.updateDates();
    },

    initDates(item) {
      this.start = null;
      this.end = null;

      if (filters?.start == null && item?.started_on != null) {
        filters.start = moment
          .utc(item.started_on * 1000)
          .subtract(1, 'day')
          .startOf('day')
          .unix();
      }

      if (filters?.end == null && item?.started_on != null) {
        filters.end = moment
          .utc(item.ended_on * 1000)
          .endOf('day')
          .unix();
      }

      this.updateDates();
    },

    updateDates() {
      if (filters?.start != null) {
        this.start = filters.start;
      }

      if (filters?.end != null) {
        this.end = filters.end;
      }
    },
  },
};
</script>
