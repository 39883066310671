<template lang="pug">
div(ref="modal" v-if="flight != null")
  div.modal
    div.backdrop
    div.modal-content
      div.modal-head
        div.modal-title Tag Manager

      div.modal-body(v-if="publishing")
        div.publish-notif-content
          div.publish-notif-img
            img(:src="$cdn + 'dist/assets/cm/publishing-progress.svg'" alt="publishing")
          div.publish-notif-label Exporting...
          div It takes just a few moments

      div.modal-body(v-else)
        div.mb-16(v-if="has_live_dsps")
          | You can download one file for all platforms or a separate file for each platform.
          | As the next step set up the tag(s) in your ad delivery platform(s) to start receiving impressions.
          | To learn more, here’s a&nbsp;
          a(href="https://support.nexd.com/en/articles/2965516-work-with-ad-tags" target="_blank") short introduction to working with ad tags,&nbsp;
          a(href="https://support.nexd.com/en/articles/3172184-using-nexd-tags-and-understanding-them" target="_blank") an overview of NEXD’s ad tags&nbsp;
          span and a&nbsp;
          a(href="https://support.nexd.com/en/articles/5885779-tag-set-up-for-different-platforms-and-macros" target="_blank") guide for setting the tags up in different platforms.
        div.mb-16(v-else) Published platforms will be updated, unpublished platforms exported.

        div.scrollable-list
          UIGroup(:key="flight.id" :title="flight.name" :subtitle="true" :collapsable="false" :quantum="has_live_dsps && !has_vast")
            template(v-slot:quantum)
              div(:class="styles.icon" @click.stop="downloadTags")
                i.nexd-icon-32-tag.ml-8(aria-hidden="true")
                span.fs-14 All tags
              a(:class="styles.icon" @click.stop="viewTags")
                i.nexd-icon-32-preview.ml-8(aria-hidden="true")
                span.fs-14 View tags

            section(:class="styles.flights")
              Card(v-if="dsp_library != null" v-for="dsp of flight?.settings?.dsps" :key="dsp" :class="styles.flight")

                div.fw-500 {{ dsp_library?.[dsp] ?? dsp }}

                div(:class="styles.details")
                  span.color-gray-800.py-8(v-if="flight?.live?.settings?.export_type == null || !flight?.live?.settings?.dsps?.includes(dsp)") Not Exported
                  i.nexd-icon-32-share(v-else-if="has_vast" :class="styles.icon" aria-hidden="true" v-tooltip="{value: 'Copy to clipboard'}" @click="copyTagToClipboard(dsp, 'XML Link')")

                  template(v-else)
                    i.nexd-icon-32-copy-to-clipboard(aria-hidden="true" @click="copyTagToClipboard(dsp)" v-tooltip="{value: 'Copy to clipboard'}")
                    i.nexd-icon-32-tag(aria-hidden="true" @click="downloadTag(dsp)" v-tooltip="{value: 'Download tag'}")
                    div(:class="{[styles.tag_button]: true, [styles.active]: show_tag === dsp}" @click="toggleTagDisplay(dsp)")
                      span View Tag
                      IconDropdown

                div(v-if="show_tag === dsp" :class="styles.tag")
                  div.bg-gray-100
                    pre {{ tag == null ? 'Loading..' : tag }}
                    Buttons.p-16
                      Button(type="link" label="Copy to clipboard" @click="copyTagToClipboard(dsp)")
                        template(v-slot:prefix)
                          i.nexd-icon-32-copy-to-clipboard(aria-hidden="true")

      div.modal-footer
        Buttons
          Button(type="link-primary" label="Close" @click="$emit('close')")
          Button(v-if="show_publish_button" type="primary" :label="export_btn_label" :disabled="publishing" @click="publish")
</template>

<script>
import styles from './FlightExportModal.module.scss';

import DSPService from '@master/Services/Cache/DSPService';
import FlightService from '@master/Services/FlightService';

import { FLIGHT } from '@master/constants';
import { setClipboard } from '@helpers/Global';
import Queue from '@libs/Queue';

import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';
import IconDropdown from '@master/UI/Buttons/Icons/IconDropdown.vue';
import IconTag from '@master/UI/Buttons/Icons/IconTag.vue';
import Modal from '@master/UI/Modal.vue';
import UIGroup from '@master/UI/UIGroup.vue';

import Card from '@cm/Views/Flights/Components/Card.vue';

export default {
  name: 'FlightExportModal',
  mixins: [Modal],

  components: {
    Button,
    Buttons,
    Card,
    IconDropdown,
    IconTag,
    UIGroup,
  },

  computed: {
    has_live_dsps() {
      return this.flight?.live?.settings?.dsps?.length > 0;
    },

    has_vast() {
      return this.flight?.live?.settings?.export_type === FLIGHT.EXPORT_TYPES.XML;
    },

    is_all_exported() {
      return this.flight?.settings?.dsps?.every(dsp => this.flight?.live?.settings?.dsps?.includes(dsp));
    },

    export_btn_label() {
      if (this.has_live_dsps) {
        return this.is_all_exported ? 'Update' : 'Export & Update';
      }

      return 'Export';
    },
  },

  data() {
    return {
      styles,
      queue: new Queue(),

      flight: null,

      dsp_library: null,
      publishing: false,
      show_publish_button: true,
      show_tag: null,
      tag: null,
    };
  },

  created() {
    DSPService.subscribe(dsps => {
      if (dsps == null) return;

      this.dsp_library = dsps.reduce((dsp_library, dsp) => {
        dsp_library[dsp.slug] = dsp.title;
        return dsp_library;
      }, {});
    }, this);

    FlightService.subscribe(data => {
      if (this.publishing && !data?.publishing) {
        this.show_publish_button = false;
      }

      this.flight = data?.flight;
      this.publishing = data?.publishing;
    }, this);
  },

  methods: {
    async publish() {
      FlightService.publish()
        .then(() => {
          this.$emit('export');
        })
        .catch(error => {
          this.$alert(error.message);
        });
    },

    toggleTagDisplay(dsp = null) {
      if (this.show_tag === dsp) {
        this.show_tag = null;
        this.tag = null;
      } else {
        this.show_tag = dsp;
      }

      if (this.show_tag != null) {
        this.getTag(this.show_tag);
      }
    },

    async getTag(dsp) {
      try {
        this.tag = await this.queue.get(`v2/flights/${this.flight.id}/tag/${dsp}`);
      } catch (_) {
        this.tag = null;
      }
    },

    async copyTagToClipboard(dsp, message = 'Tag') {
      await this.getTag(dsp);

      if (this.tag != null) {
        setClipboard(this.tag)
          .then(_ => this.$notifications.add('primary', `${message} copied to clipboard`))
          .catch(error => this.$notifications.add('warning', error));
      }
    },

    downloadTag(dsp) {
      if (dsp == null) {
        return;
      }

      this.$http
        .get(`v2/flights/${this.flight.id}/tag/${dsp}/download`)
        .then(() => {
          /** downlaod handled by this.$http */
        })
        .catch(() => this.$notifications.add('error', 'Could not download tag'));
    },

    downloadTags() {
      if (!this.has_live_dsps) {
        return;
      }

      this.$http
        .get(`v2/flights/${this.flight.id}/tags/download`)
        .then(() => {
          /** downlaod handled by this.$http */
        })
        .catch(() => this.$notifications.add('error', 'Could not download tags'));
    },

    viewTags() {
      if (this.flight?.live?.tag_url != null) {
        window.open(this.flight.live.tag_url, '_blank');
      }
    },
  },
};
</script>
