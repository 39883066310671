<template>
  <div>
    <div class="charts-wrapper">
      <div v-if="chart.series != null" class="single-chart" ref="chart-bar" style="height: 280px;"/>
      <div v-if="chart.series != null" class="single-chart" ref="chart-line" style="height: 280px;"/>
      <div class="single-chart">
        <Loading v-if="chart.labels == null" />
      </div>
    </div>
    <div v-if="chart.series != null" class="engagement-stats" style="padding-left: 2.5rem;"></div>
  </div>
</template>

<script>
import Filters from '@master/Filters';

import Chartist from '@libs/Chartist';
import PointTooltip from '@analytics/Classes/PointTooltip';
import BarTooltip from '@analytics/Classes/BarTooltip';
import Loading from '@master/UI/Loading.vue';
import { engagementColorMap } from '@master/Services/AnalyticsAPPService';
import ana from '@master/Services/AnalyticsStringMapping';

function TooltipContent (value = 0.01, impressions = 0) {
  let numberFilter = Filters.NumberFilter;
  let percentageFilter = Filters.PercentageFilter;
  return `<div>
    <h1 class="value">${percentageFilter(value)}<small class="text-nowrap">(${numberFilter(impressions)} impr.)</small></h1>
  </div>`;
}

function BarTooltipContent (type, value = 0.01, impressions = 0, seconds = 0) {
  let numberFilter = Filters.NumberFilter;
  let percentageFilter = Filters.PercentageFilter;
  return `<div class="ct-bar-tooltip">
    <div class="title">Based on Engaged Impressions</div>
    <div class="ct-row">
      <div class="label">${ana.get(type)} initiated</div>
      <div class="value">
        <div>${numberFilter(impressions)}</div>
        <small class="text text-nowrap thin">times</small>
      </div>
    </div>
    <div class="ct-row">
      <div class="label">on average at</div>
      <div class="value">
        <div>${seconds} sec</div>
      </div>
    </div>
    <div class="ct-row">
      <div class="label">This event was in</div>
      <div class="value">
        <div>${percentageFilter(value > 1 ? 1 : value)}</div>
        <small>of engaged impressions</small>
      </div>
    </div>
  </div>`;
}
export default {
  name: 'EngagementChart',
  components: {
    Loading
  },
  props: {
    chart: Object,
    dwellTime: Number
  },
  data () {
    return {
      engagementColorMap,
      ana
    };
  },
  mounted () {
    this.loadChart();
  },
  methods: {
    loadChart () {
      // initial filter change happens before mounting, use timeout to wait
      this.$nextTick(() => {
        if (this.chart.labels == null || this.chart.series == null) {
          // allow chart to load only after input data exists
          return;
        }
        const labels = this.chart.labels;
        const series = this.chart.series;
        const meta = this.chart.meta;
        const dwell_index = Math.round(this.dwellTime / 1000) - 1;

        let high = Math.max(...series.line);
        if (series.bar != null) {
          for (const type in series.bar) {
            high = Math.max(...series.bar[type], high);
          }
          // bars has high as max high of series
          // for (const type in series.bar) {
          //   for (const index in series.bar[type]) {
          //     if (series.bar[type][index] !== 0) {
          //       series.bar[type][index] = high;
          //     }}
          // }
        }

        const seriesOptions = {
          line: {
            chartPadding: {
              left: 16,
              bottom: 0,
              right: 56,
            },
            axisY: {
              type: Chartist.FixedScaleAxis,
              divisor: 5,
              low: 0,
              high,
            },
          },
          bar: {
            chartPadding: {
              left: 56,
              bottom: 0,
              right: 16
            },
            axisY: {
              type: Chartist.FixedScaleAxis,
              divisor: 5,
              low: 0,
              high,
              entireTableHighBar: true,
              showGrid: false,
              position: 'end',
              labelInterpolationFnc: (n, a, b, c, range) => {
                return n * 100 / range.max + '%';
              }
            },
            axisX: {
              showGrid: false,
              showLabel: false
            }
          }
        };

        for (const series_type in series) {
          const original_data = series[series_type];
          if (original_data != null) {
            const options = seriesOptions[series_type];
            let chartData = [];
            let colorMap = [];
            if (series_type === 'line') {
              // if no filters has been selected, dont load chart
              // add tooltip info
              for (let index = 0; index < original_data.length; index++) {
                const value = original_data[index];
                // no meta yet for each bar event
                chartData.push({ meta: TooltipContent(meta[index].value, value), value });
              }
            } else if (series_type === 'bar') {
              // add barchart series for each event type
              for (const type in original_data) {
                const type_chart = original_data[type];
                let array = [];
                for (const index in type_chart) {
                  const value = type_chart[index];
                  if (value !== 0) {
                    const percentage = value / series['line'][0];
                    array.push({ meta: BarTooltipContent(type, percentage, value, parseInt(index, 10).toFixed(2)), value });
                    colorMap.push(engagementColorMap[type]);
                  } else {
                    array.push(value);
                  }
                }
                chartData.push(array);
              }
            }

            let chart;
            if (series_type === 'line') {
              chart = new Chartist.Line(this.$refs['chart-line'], {
                labels,
                series: [chartData]
              }, options);
            } else if (series_type === 'bar') {
              chart = new Chartist.Bar(this.$refs['chart-bar'], {
                labels,
                series: chartData
              }, options);
            }

            // let type = this.selections[i];
            // save horiozontal grid lines, to bind them to the point (visibility on hover)
            let lines = {};
            let drawHandler = (context) => {
              if (context.type === 'point' || context.type === 'line' || context.type === 'bar') {
                let color = 'red';
                if (series_type === 'bar') {
                  color = colorMap[context.seriesIndex] || 'blue-light';
                } else if (series_type === 'line') {
                  color = 'blue-dark';
                }
                let parent = context.element.parent();
                parent.addClass(color);
                parent.addClass('animate');

                if (context.type === 'point') {
                  let tooltip = new PointTooltip(context, lines[context.index]);
                  let newSVG = tooltip.init();
                  context.element.replace(newSVG);
                } else {
                  try {
                    let path = context.element.getNode();
                    let length = Math.ceil(path.getTotalLength());
                    if (series_type === 'line') {
                      parent.getNode().setAttribute('style', '--dataLength: ' + length);
                    } else if (context.type === 'bar') {
                      if (context.value.y !== 0) {
                        let tooltip = new BarTooltip(context);
                        let newSVG = tooltip.init();
                        context.element.replace(newSVG);
                      }
                    }
                  } catch (err) {
                    // element not yet rendered
                  }
                }
              } else if (context.type === 'grid') {
                if (context.element.classes().indexOf('ct-horizontal') !== -1) {
                  lines[context.index] = context.element.getNode();
                  if (context.index === dwell_index) {
                    lines[context.index].classList.add('yellow-primary');
                    lines[context.index].style.opacity = 1;
                  }
                }
              }
            };
            chart.on('draw', (context) => { drawHandler(context); });
          }
        }
      });
    }
  },
  watch: {
    chart: {
      handler () {
        // when any selection changes, try to reload chart
        this.loadChart();
      },
      deep: true
    }
  }
};
</script>
