<template lang="pug">
div(ref="modal")
  div.modal
    div.backdrop
    div.modal-content.creative-library-modal(:style="`height: ${container_height}px;`")

      //- Templates
      section.creative-library-templates-list
        SearchInput.mb-16(
          ref="search"
          v-model="query"
          :delay="0"
          placeholder="Search layout"
        )
        TemplatesList(ref="templatesList" :creative="creative" :query="query" @change="templateSelectionChangeHandler")

      //- Preview layout
      section.creative-library-modal-preview

        //- Header
        div.creative-library-modal-preview-header
          template(v-if="!is_sdk_user")
            div.fs-14.color-gray-800(v-if="show_device_toggle") Preview creative in:
              DeviceDropdown(v-model="show_preview_in" @input="changePreview" :disabled="!selected_creative")
            div.flex-grow.flex.flex-align-center.flex-justify-end.column-gap-16
              Button(type="link" label="Preview" :disabled="!preview_url" @click="openPreview")
                template(v-slot:prefix)
                  IconPreview
              Button(type="link" label="Copy link" :disabled="!preview_url" @click="copyUrl")
                template(v-slot:prefix)
                  IconLink
              QRCodeModal.cm(label="QR Code" :disabled="preview_url == null" :url="preview_url")
                a(
                  v-if="preview_url != null"
                  :href="preview_url"
                  title="QR-code"
                  download="qr-image"
                  target="_blank"
                ) Open large QR Code in new tab
              PSDDemoDownload(
                v-if="has_template_psds || has_template_demo_assets"
                :template="selected_template"
                :creative_card="false"
                :vertical="vertical"
                :disabled="!selected_template || !preview_url"
              )

        //- Preview devices w/ info
        div.creative-library-modal-preview-content(:key="selected_creative?.creative_id")
          div.creative-library-modal-preview-devices
            template(v-if="selected_creative && !resizing")

              //- Responsive preview
              template(v-if="isQuantum(selected_creative.type)")
                div.preview-mobile.responsive(:key="primary_device_size.width" :style="`--primary-width: ${primary_device_size.width}px`")
                  LivePreview(
                    ref="preview"
                    :creative="selected_creative"
                    :poll="false"
                    :options="{query_params: ['sw=300', 'sh=250'], force_placement_device: 0}"
                    :height="primary_device_size.height"
                  )
                div.preview-desktop.responsive(:key="primary_device_size.width" :style="`--primary-width: calc(100% - ${primary_device_size.width}px)`")
                  LivePreview(
                    ref="preview"
                    :creative="selected_creative"
                    :poll="false"
                    :key="selected_creative.name"
                    :height="primary_device_size.height - 40"
                    :options="{force_placement_device: 1, splashes: {'leaderboard': '970x250', 'infeedDesktop': '300x250'}}"
                  )

              //- Preview for all types except responsive
              template(v-else)
                div(
                  :key="primary_device_size.width"
                  :class="[isMobile(preview_options.force_placement_device) ? 'preview-mobile' : 'preview-desktop']"
                  :style="`--primary-width: ${primary_device_size.width}px`"
                )
                  LivePreview(
                    ref="preview"
                    :key="`${preview_options.force_placement_device}_${Date.now()}`"
                    :creative="selected_creative"
                    :poll="false"
                    :height="primary_device_size.height"
                    :options="preview_options"
                  )
                div.mockup(
                  :key="mockup_device_size.width"
                  :class="[isMobile(preview_options.force_placement_device) ? 'preview-desktop' : 'preview-mobile']"
                  :style="`--mockup-width: ${mockup_device_size.width}px`"
                  @click="changeCreativePreview"
                )
                  LivePreview(
                    ref="preview"
                    :key="`${preview_options.force_placement_device}_${Date.now()}`"
                    :creative="selected_creative"
                    :poll="false"
                    :height="mockup_device_size.height"
                    :options="{force_placement_device: isMobile(preview_options.force_placement_device) ? 1 : 0, splashes: { main: 'main' }}"
                  )

          GyroController.layout-gallery(
            v-if="selected_template && show_gyrotool && show_preview_in === 0 && isMobile(preview_options.force_placement_device) && !desktop_layout"
            :key="selected_template?.template_id"
            ref="gyro"
            @ready="initGyro"
          )

          div.template-info-content(v-if="selected_template != null")
            h2.mb-8
              span {{ selected_template.name }}
              img(
                v-if="desktop_layout || (notifications != null && notifications.length > 0)"
                :src="$cdn + 'dist/assets/cm/warning.svg'"
                alt=""
                v-tooltip="desktop_layout ? {value: warning_text} : {template: 'cm.gyroWarning'}"
                style="margin-bottom: -0.5rem;"
              )
            p.template-description.wide {{ selected_template.description }}
            a(
              v-if="selected_template.specs_file != null && !is_sdk_user"
              :href="selected_template.specs_file || null"
            ) Find out more from nexd.com

      //- Footer
      Buttons
        Button(type="link-primary" label="Cancel" @click="close")
        Button(
          data-intercom-target="use-layout-button"
          type="primary"
          :label="creative == null ? 'Select' : 'Use'"
          :disabled="selected_template == null || (isSkin() && !is_admin)"
          @click="saveSelection"
        )
</template>

<script>
import TemplatesService from '@master/Services/TemplatesService';
import { setClipboard } from '@helpers/Global';

import Modal from '@master/UI/Modal.vue';
import CreativeTraits from '@master/Traits/CreativeTraits.vue';
import SDKMixin from '@root/src/global/mixins/SDKMixin.vue';

import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';
import IconLink from '@master/UI/Buttons/Icons/IconLink.vue';
import IconPreview from '@master/UI/Buttons/Icons/IconPreview.vue';
import SearchInput from '@master/UI/Input/SearchInput.vue';
import DeviceDropdown from '@master/UI/DeviceDropdown.vue';
import QRCodeModal from '@master/UI/QRCodeModal.vue';
import GyroController from '@master/UI/GyroController.vue';
import LivePreview from '@master/UI/LivePreview/LivePreview.vue';

import TemplatesList from '@cm/Views/Creatives/Modals/Components/TemplatesList.vue';
import PSDDemoDownload from '@cm/Views/Creatives/Cards/Components/PSDDemoDownload.vue';
import { TEMPLATE_FEATURE } from '@master/constants';

const MOBILE_DEVICE_WIDTH = 640;
const MOBILE_DEVICE_HEIGHT = 1334;

export default {
  name: 'LayoutLibrary',
  mixins: [Modal, CreativeTraits, SDKMixin],

  components: {
    SearchInput,
    DeviceDropdown,
    QRCodeModal,
    GyroController,
    LivePreview,
    TemplatesList,
    PSDDemoDownload,
    Button,
    Buttons,
    IconLink,
    IconPreview,
  },

  props: {
    creative: Object,
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  computed: {
    desktop_layout() {
      return TemplatesService.bestForDesktop(this.selected_template);
    },

    has_template_psds() {
      if (this.selected_template == null) return false;
      return this.selected_template.different_psds != null && this.selected_template.different_psds.length > 0;
    },

    has_template_demo_assets() {
      if (this.selected_template == null) return false;

      return this.selected_template.different_demo_packs != null && this.selected_template.different_demo_packs.length > 0;
    },

    vertical() {
      return this.selected_template?.name?.includes('vertical');
    },

    is_large() {
      return this.browser_width >= 1640 && this.browser_height >= 1000;
    },

    primary_device_size() {
      const HEIGHT = 500;
      const WS_HEIGHT = 600;

      if (this.isMobile(this.preview_options.force_placement_device)) {
        if (this.is_large) {
          return {
            height: WS_HEIGHT,
            width: (WS_HEIGHT * MOBILE_DEVICE_WIDTH) / MOBILE_DEVICE_HEIGHT,
          };
        }

        return {
          height: HEIGHT,
          width: (HEIGHT * MOBILE_DEVICE_WIDTH) / MOBILE_DEVICE_HEIGHT,
        };
      }

      if (this.is_large) {
        return {
          width: WS_HEIGHT * 1.5,
          height: WS_HEIGHT,
        };
      }

      return {
        width: HEIGHT * 1.5,
        height: HEIGHT,
      };
    },

    mockup_device_size() {
      const HEIGHT = 200;
      const WS_HEIGHT = 300;

      if (!this.isMobile(this.preview_options.force_placement_device)) {
        if (this.is_large) {
          return {
            height: WS_HEIGHT,
            width: (WS_HEIGHT * MOBILE_DEVICE_WIDTH) / MOBILE_DEVICE_HEIGHT,
          };
        }

        return {
          height: HEIGHT,
          width: (HEIGHT * MOBILE_DEVICE_WIDTH) / MOBILE_DEVICE_HEIGHT,
        };
      }

      if (this.is_large) {
        return {
          width: WS_HEIGHT * 2,
          height: WS_HEIGHT,
        };
      }

      return {
        width: HEIGHT * 2,
        height: HEIGHT,
      };
    },

    show_device_toggle() {
      return this.isInfeed();
    },
  },

  data() {
    const default_options = {
      placement: true,
      functions: true,
      type: true,
    };

    return {
      query: '',

      selected_template: null,
      selected_creative: null,

      preview_url: null,
      show_gyrotool: false,
      notifications: [],
      warning_text: 'This layout is not performing well on mobile devices, so we recommend to only traffic it to desktop devices.',
      show_preview_in: 0,

      gallery_options: {
        placement: this.options.placement != null ? this.options.placement : default_options.placement,
        functions: this.options.functions != null ? this.options.functions : default_options.functions,
        type: this.options.type != null ? this.options.type : default_options.type,
      },
      preview_options: {
        force_placement_device: null,
      },

      container_height: Math.round((window.innerHeight * 0.9) / 10) * 10,
      browser_width: window.innerWidth,
      browser_height: window.innerHeight,
      resizing: false,
      resize_timeout: null,
      is_admin: false,
    };
  },

  created() {
    window.addEventListener('resize', this.resizeHandler, false);

    this.$user.subscribe(user => {
      if (user != null) {
        this.is_admin = this.$user.adminMode();
      }
    }, this);
  },

  mounted() {
    if (this.$refs?.search?.$refs?.input) {
      this.$refs.search.$refs.input.focus();
    }
  },

  destroyed() {
    window.clearTimeout(this.resize_timeout);
    window.removeEventListener('resize', this.resizeHandler, false);
  },

  methods: {
    resizeHandler() {
      this.resizing = true;
      window.clearTimeout(this.resize_timeout);
      this.resize_timeout = window.setTimeout(() => {
        this.browser_width = window.innerWidth;
        this.browser_height = window.innerHeight;
        this.resizing = false;
      }, 500);
    },

    templateSelectionChangeHandler(obj) {
      this.preview_url = obj.preview_url;
      this.selected_template = obj.template;
      this.selected_creative = null;
      this.show_gyrotool = false;

      if (this.selected_template != null) {
        if (this.selected_template.notifications != null) {
          this.notifications = this.selected_template.notifications;
        } else {
          this.notifications = [];
        }

        if (this.selected_template.keywords != null && this.selected_template.keywords.indexOf(TEMPLATE_FEATURE.GYRO) !== -1) {
          this.show_gyrotool = true;
        }

        if (this.selected_template.demo_creatives.length > 0) {
          this.selected_creative = this.selected_template.demo_creatives[0];

          if (this.isVpaid()) {
            this.show_preview_in = 1;
          } else {
            this.show_preview_in = this.selected_template.preview_device;
          }

          this.preview_options.force_placement_device = this.show_preview_in;
        }
      }

      this.$emit('templateselect', this.selected_template);
    },

    creativeSelectionHandler(value) {
      // always show gyrotool on custom creatives
      this.show_gyrotool = true;
      this.preview_url = value.preview_url;
      this.selected_creative = value.creative || null;
    },

    saveSelection() {
      this.$emit('close', this.selected_template);
    },

    saveCreativeSelection() {
      this.selected_creative.ad_creative = true;
      this.$emit('close', this.selected_creative);
    },

    close() {
      this.$emit('close', null);
    },

    changePreview(input) {
      if (this.selected_creative != null) {
        this.preview_options.force_placement_device = input;
      }
    },

    changeCreativePreview() {
      if (this.preview_options.force_placement_device === 0) {
        this.preview_options.force_placement_device = 1;
      } else {
        this.preview_options.force_placement_device = 0;
      }

      this.show_preview_in = this.preview_options.force_placement_device;
    },

    openPreview() {
      if (this.preview_url != null) {
        window.open(this.preview_url, '_blank');
      }
    },

    copyUrl() {
      if (this.preview_url != null) {
        const url = this.preview_url;

        setClipboard(url)
          .then(_ => {
            this.$notifications.add('primary', 'Preview URL copied to clipboard');
          })
          .catch(error => {
            this.$notifications.add('warning', error);
          });
      } else {
        // maybe we could eliminate copy link before selection? added this as a fallback for current situation
        this.$alert('Please select template first');
      }
    },

    initGyro() {
      if (this.$refs.preview != null) {
        this.$refs.preview.initGyro();
      }
    },
  },
};
</script>
