<template lang="pug">
div
  SlotDevtoolAsset(
    v-for="asset in assets"
    :key="asset.asset_id"
    :asset="asset"
    :creative="creative"
  )
</template>

<script>
import SlotDevtoolAsset from '@cm/Views/Creatives/Cards/Components/Asset/Slot/SlotDevtoolAsset.vue';

export default {
  name: 'CustomCreativeAssetsTab',

  props: {
    creative: Object,
  },

  components: {
    SlotDevtoolAsset,
  },

  computed: {
    assets() {
      return Object.values(this.creative.assets ?? {}).filter(asset => asset.original_asset_id != null);
    },
  },
};
</script>
