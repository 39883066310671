<template lang="pug">
div.charts-wrapper
  div.single-chart(ref="chart-0" style="height: 280px;")
  div.single-chart(ref="chart-1" style="height: 280px;")
  div.single-chart(v-if="!labels")
    Loading
</template>

<script>
import { TooltipContent } from '@analytics/Classes/TooltipContent';
import { colorOptions } from '@master/Services/AnalyticsAPPService';
import PointTooltip from '@analytics/Classes/PointTooltip';
import Loading from '@master/UI/Loading.vue';

import Chartist from '@libs/Chartist';

export default {
  name: 'SummaryChart',
  components: {
    Loading,
  },
  props: {
    chart: Object,
    series: Array,
  },
  data() {
    return {
      admin_toggle: false,
      labels: null,
      averages: null,
      data: null,
      series_options: [
        {
          chartPadding: {
            left: 16,
            bottom: 0,
            right: 56,
          },
          axisX: {
            diagonal: false,
          },
          axisY: {
            type: Chartist.FixedScaleAxis,
            divisor: 5,
            low: 0,
          },
        },
        {
          chartPadding: {
            left: 56,
            bottom: 0,
            right: 16,
          },
          axisY: {
            type: Chartist.FixedScaleAxis,
            divisor: 5,
            low: 0,
            showGrid: false,
            position: 'end',
          },
          axisX: {
            showLabel: false,
          },
        },
      ],
    };
  },

  created() {
    this.$user.subscribe(() => {
      this.admin_toggle = this.$user.adminMode();
    }, this);
  },

  methods: {
    init() {
      this.labels = this.chart.labels;
      this.averages = this.chart.averages;
      this.data = this.chart.series;
      this.loadChart();
    },

    async loadChart() {
      // initial filter change happens before mounting, use timeout to wait
      await this.$nextTick();

      // allow chart to load only after input data exists
      if (!this.labels) return;

      const labels = this.labels || [];
      const series = this.series;

      // var to check if avg CTR is already added
      // let ctrAdded = false;
      for (let i = 0; i < series.length; i++) {
        const type = series[i];
        let options = this.series_options[i];
        if (type == null) {
          // if no filters has been selected, dont load chart
          return;
        } else if (type === 'engagement' || type === 'ctr' || type === 'viewability') {
          options.axisY.suffix = '%';
        } else {
          options.axisY.suffix = '';
        }

        const original_data = this.data[type];
        let chart_data = [];
        // if data is null, the analytics data is still loading, skip to to draw grid only without any errors
        if (original_data != null) {
          // add tooltip info
          for (let index = 0; index < original_data.length; index++) {
            const value = original_data[index];
            chart_data.push({
              meta: TooltipContent({
                data: this.data,
                index,
                main_series: type,
                active_series: this.series,
                admin: this.admin_toggle,
                label: this.chart.tooltips[index],
              }),
              value,
            });
          }
        }

        const chart_series = [chart_data];

        // if data is null, the analytics data is still loading, skip to to draw grid only without any errors
        // if (original_data != null && ctrAdded === false) {
        //   if (type === 'engagement' || type === 'ctr') {
        //     chart_series.push(this.averages.ctr);
        //     ctrAdded = true;
        //   }
        // }

        if (labels[0] != null && labels.length > 6 && labels[0].length > 10) {
          options.axisX.diagonal = true;
        }
        const chart = new Chartist.Line(
          this.$refs[`chart-${i}`],
          {
            labels,
            series: chart_series,
          },
          options,
        );

        // save horiozontal grid lines, to bind them to the point (visibility on hover)
        let lines = {};
        let debugTooltip = false;

        const drawHandler = context => {
          if (context.seriesIndex === 1) {
            // series index 1 can only be on average lines
            if (context.type === 'point') {
              let parent = context.element.parent();
              parent.addClass('ct-dashed');
              parent.addClass('yellow-primary');
            }
          } else if (context.type === 'point' || context.type === 'line') {
            let colour = colorOptions[type] || 'blue-light';
            let parent = context.element.parent();
            parent.addClass(colour.class);
            parent.addClass('animate');
            if (context.type === 'point') {
              let tooltip = new PointTooltip(context, lines[context.index]);
              let newSVG = tooltip.init();
              context.element.replace(newSVG);
              if (debugTooltip === true) {
                debugTooltip = false;
                tooltip.show();
              }
            } else {
              try {
                let path = context.element.getNode();
                let length = Math.ceil(path.getTotalLength());
                parent.getNode().setAttribute('style', '--dataLength: ' + length);
              } catch (err) {
                // element not yet rendered
              }
            }
          } else if (context.type === 'grid') {
            if (context.element.classes().indexOf('ct-horizontal') !== -1) {
              lines[context.index] = context.element.getNode();
            }
          }
        };
        chart.on('draw', context => {
          drawHandler(context);
        });
      }
    },
  },

  watch: {
    chart: {
      handler() {
        this.init();
      },
      deep: true,
      immediate: true,
    },

    series: {
      handler() {
        this.init();
      },
      deep: true,
    },
  },
};
</script>
