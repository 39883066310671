<template lang="pug">
div.tracking-row-component(:class="{'global': global}")

  //- Asset column
  div.tracking-row-column(v-if="asset_id != null")
    Thumbnail(:asset="asset" :small="true")
    div.name {{ name }}
  div.tracking-row-column.column(v-else-if="object != null" :class="{'indent': isOption}")
    div.name {{ name }}
    div.name.fs-12 "{{ user_value }}"

  //- Type/Output inline grid
  div.tracking-row-inline-grid

    //- Type column
    div.tracking-row-column.mb-0
      SearchSelect.input-prefix(:options="global_url_options" :search="false" v-model="cta.action" width="100%" @input="CTAactionChangeHandler")

    //- Output column
    div.cta-row-column.form-group.mb-0
      TextareaInput(
        v-if="['url', 'lightbox'].includes(cta.action)"
        :placeholder="cta_output_placeholder"
        v-model="cta.uri"
        :url="true"
        @change="change"
      )
      Input(
        v-if="['call', 'sms'].includes(cta.action)"
        :key="cta.action"
        v-model="cta.uri"
        :placeholder="cta_output_placeholder"
        variant="phone"
        @change="change"
      )
      TextareaWithCounter(
        v-if="cta.action === 'sms'"
        v-model="cta.sms_body"
        :max_char="140"
        :isInput="true"
        placeholder="Insert predefined SMS body here. Max 140 characters"
        @change="change"
      )
      Input(
        v-if="cta.action === 'mailto'"
        :key="cta.action"
        v-model="cta.uri"
        :placeholder="cta_output_placeholder"
        @change="change"
      )

      template(v-if="cta.action === 'calendar'")
        TextareaInput(
          :placeholder="cta_output_placeholder"
          v-model="cta.title"
          :url="false"
          @change="change"
        )
        div(v-if="cta.description != null")
          span Description
          TextareaInput.mt-4(
            placeholder="Enter description"
            v-model="cta.description"
            :url="false"
            @change="change"
          )
        div(v-if="cta.location != null")
          span Location
          TextareaInput.mt-4(
            placeholder="Enter address or link"
            v-model="cta.location"
            :url="false"
            @change="change"
          )
        div
          span Date and time
          SelectDateRange.mt-4(
            :start="cta.start"
            :end="cta.end"
            :timezone="cta.timezone"
            :placeholder="cta.start | DateRangeFilter(cta.end, {utc: true})"
            :options="{ time: true }"
            style="max-width: 18rem;"
            @change="calendarChangeHandler"
          )

      template(v-if="cta.action === 'map'")
        TagInputLocations.tracking-input(ref="tag_input_locations" @add="addLocationHandler")
        div.locations-list(v-if="cta.uri.length > 0")
          span.color-gray-800 Map locations
          div.locations-list.mt-8
            div.flex.flex-align-center(v-for="(location, index) in cta.uri" :key="index")
              div.flex-grow.overflow-ellipsis {{location.address}}
              i.nexd-icon-32-close.cursor-pointer.fs-16.ml-16(aria-hidden="true" @click="removeLocation(index)")
        div.flex.flex-align-center.column-gap-16
          span Focus on the closest
          Toggle(v-model="cta.focus_closest" @input="change")

      VueEditor(v-if="cta.action === 'popup'" v-model="content" :editorToolbar="customToolbar" @text-change="popupContentChangeHandler")
</template>

<script>
import Toggle from '@master/UI/Toggle.vue';
import TagInputLocations from '@master/UI/TagInputLocations.vue';
import SearchSelect from '@master/UI/SearchSelect/SearchSelect.vue';
import Thumbnail from '@master/UI/Thumbnail/Thumbnail.vue';
import TextareaInput from '@cm/Views/Creatives/Cards/Components/Tracking/Components/TextareaInput.vue';
import TextareaWithCounter from '@master/UI/TextareaWithCounter.vue';
import Input from '@master/UI/Input/Input.vue';
import SelectDateRange from '@master/UI/SearchSelect/SelectDateRange.vue';

import CreativeTraits from '@master/Traits/CreativeTraits.vue';
import { clone, validateURL, validatePhone, isURL } from '@helpers/Global';

import RequestLimiter from '@libs/RequestLimiter';
import moment from 'moment';
import { Quill, VueEditor } from 'vue2-editor';
import { CTA_OPTIONS } from '@master/constants';

export default {
  name: 'CTARowComponent',
  extends: CreativeTraits,

  components: {
    Toggle,
    TagInputLocations,
    SearchSelect,
    Thumbnail,
    TextareaInput,
    VueEditor,
    Input,
    TextareaWithCounter,
    SelectDateRange,
  },

  props: {
    creative: Object,
    name: String,
    user_value: {
      type: String,
      default: '',
    },
    global: {
      type: Boolean,
      default: false,
    },
    sizeset: {
      type: String,
      default: null,
    },
    asset_id: {
      type: String,
      default: null,
    },
    object: {
      type: Object,
      default: null,
    },
    object_id: {
      type: String,
      default: null,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
    isOption: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      trackers_root: null,
      tracker_parent: null,
      cta: null,

      content: '',
      customToolbar: [
        // Example -> https://github.com/davidroyer/vue2-editor/blob/master/src/helpers/default-toolbar.js
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline'],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ color: [] }],
        ['link'],
        ['clean'],
      ],

      quill_imported: false,
      initial_editor_content: true,
    };
  },

  computed: {
    cta_output_placeholder() {
      switch (this.cta.action) {
        case 'url':
          return 'Insert click-out URL (landing page URL, click-tracker or click tag)';
        case 'lightbox':
          return 'Insert page URL';
        case 'call':
        case 'sms':
          return '+155512345';
        case 'calendar':
          return 'Insert title';
        case 'mailto':
          return 'Insert email address';
        default:
          return '';
      }
    },

    is_sizeset_existing() {
      return this.sizeset != null && this.creative?.settings?.cta?.quantum?.[this.sizeset]?.impressiontracker != null;
    },

    asset() {
      if (this.asset_id == null) {
        return null;
      }
      if (this.creative.assets[this.asset_id] != null) {
        return this.creative.assets[this.asset_id];
      } else if (this.creative.additional_assets[this.asset_id] != null) {
        return this.creative.additional_assets[this.asset_id];
      } else {
        // can be responsive, check for the id without the sizeset suffix
        const clean_asset_id = this.asset_id.replace(/_(\d+x\d+)/i, '');
        if (this.creative.assets[clean_asset_id] != null) {
          return this.creative.assets[clean_asset_id];
        }
      }
      return null;
    },

    global_url_options() {
      let global_url_options = {};
      if (this.actions != null) {
        if (this.actions.includes('all')) {
          global_url_options = {
            ...CTA_OPTIONS,
          };

          if (this.actions.includes('popup')) {
            global_url_options['popup'] = 'Pop-up';
          }
        }
      } else if (this.global) {
        global_url_options = {
          ...CTA_OPTIONS,
        };
      }

      if (this.isExpandable()) {
        // when expanded first state is opened, only have global url option as to go to second state
        global_url_options.state = 'To second state';
      } else if (this.isExpanded) {
        // when second state is opened, add state option to the global list isntead
        global_url_options.state = 'To first state';
      }
      return global_url_options;
    },
  },

  created() {
    this.validateTrackers();
    this.validateCTA();
    this.initEditor();
  },

  mounted() {
    if (this.cta.action == null) {
      this.$set(this.cta, 'action', 'url');
      this.CTAactionChangeHandler();
    }
  },

  methods: {
    init() {
      // root object is what being sent on changes to the parent, to be saved
      // this will be cloned from the original data
      this.trackers_root = this.getTrackersRootObject();
      // parent object can be either the root, global cta or asset cta
      this.trackers_parent = this.getTrackersParentObject(this.trackers_root);
      // object for the UI
      this.cta = this.getTrackersCTAObject(this.trackers_parent);
    },

    initEditor() {
      if (this.cta.action !== 'popup') return;

      if (!this.quill_imported) {
        const AlignStyle = Quill.import('attributors/style/align');
        const BackgroundStyle = Quill.import('attributors/style/background');
        const ColorStyle = Quill.import('attributors/style/color');
        const DirectionStyle = Quill.import('attributors/style/direction');
        const FontStyle = Quill.import('attributors/style/font');
        const SizeStyle = Quill.import('attributors/style/size');

        Quill.register(AlignStyle, true);
        Quill.register(BackgroundStyle, true);
        Quill.register(ColorStyle, true);
        Quill.register(DirectionStyle, true);
        Quill.register(FontStyle, true);
        Quill.register(SizeStyle, true);

        this.quill_imported = true;
      }

      this.createPopupContent();
    },

    async createPopupContent() {
      if (this.cta.action !== 'popup') return;

      let uri = this.cta.uri;

      if (uri === '' || !isURL(uri) || this.object_id == null) return;

      const cache_bust = Math.round(Math.random() * 10000);
      uri += `?_=${cache_bust}`;

      this.$http.get(uri, { withCredentials: false, raw: true }).then(content => {
        this.content = content;
      });
    },

    popupContentChangeHandler() {
      if (this.initial_editor_content) {
        this.initial_editor_content = false;
        return;
      }

      // throttle save on content changes
      RequestLimiter.hook(`popup-save-${this.asset_id}`, () => {
        this.savePopupContent();
      });
    },

    savePopupContent() {
      if (this.cta.uri === '' && this.content === '') return;

      // encode and convert to b64
      const content = encodeURIComponent(this.content);
      const element_type = this.object != null ? 'objects' : 'assets';
      // Since object_id is always present, let's use this.
      const path = `v2/creative/${this.creative.creative_id}/${element_type}/${this.object_id}/file`;

      this.$http.put(path, { content }).then(({ uri }) => {
        if (isURL(uri) && this.cta.uri !== uri) {
          // update info in the UI
          this.cta.uri = uri;
          this.change();
        }
      });
    },

    getTrackersRootObject() {
      // layout system
      if (this.object != null) {
        return clone(this.object);
      }
      return clone(this.creative.settings.cta);
    },

    getTrackersParentObject(root) {
      // layout system, root is the parent
      if (this.object != null) {
        return root;
      }

      // template system
      if (root == null) {
        this.$set(root, 'cta', {
          action: 'url',
          uri: '',
          impressiontracker: [],
          assets: {},
        });
      }

      // asset specific
      if (this.asset != null && root.assets != null) {
        // if asset specific is missing, add it
        if (root.assets[this.asset_id] == null) {
          this.$set(root.assets, this.asset_id, {
            action: 'url',
            uri: '',
            impressiontracker: [],
          });
        }
        return root.assets[this.asset_id];
      }

      // global objects
      if (this.global) {
        // responsive
        if (this.is_sizeset_existing && root?.quantum?.[this.sizeset] != null && root.quantum[this.sizeset]) {
          return root.quantum[this.sizeset];
        }

        // normal, global tracjers
        return root;
      }
      return null;
    },

    getTrackersCTAObject(parent = null) {
      if (!parent) return null;

      // objects have separate cta key
      if (this.object != null) {
        return parent.cta;
      }

      // templates hold the cta info in the parent
      return parent;
    },

    CTAactionChangeHandler() {
      let cta = this.cta;
      const type = cta.action;

      const keys_to_delete = ['uri', 'sms_body', 'title', 'location', 'description', 'start', 'end', 'dateStart', 'dateEnd', 'timezone', 'focus_closest', 'width', 'height'];
      // remove all keys when changin action
      for (const key of keys_to_delete) {
        if (cta[key] != null) {
          this.$delete(cta, key);
        }
      }

      if (type === 'url') {
        this.$set(cta, 'uri', '');
      } else if (type === 'call') {
        this.$set(cta, 'uri', '');
      } else if (type === 'sms') {
        this.$set(cta, 'uri', '');
        this.$set(cta, 'sms_body', '');
      } else if (type === 'calendar') {
        this.$set(cta, 'title', '');
        this.$set(cta, 'location', '');
        this.$set(cta, 'description', '');
        this.$set(cta, 'start', moment.utc().add(14, 'days').set('hour', 12).set('minutes', 0).unix());
        this.$set(cta, 'end', moment.utc().add(14, 'days').set('hour', 13).set('minutes', 0).unix());
        this.$set(cta, 'timezone', 'UTC');
      } else if (type === 'map') {
        this.$set(cta, 'uri', []);
        this.$set(cta, 'focus_closest', true);
      } else if (type === 'state') {
        if (this.creative.type === 10 || this.creative.type === 11) {
          // 1st state button refers to 2nd state
          this.$set(cta, 'index', '1');
        } else if (this.isExpanded) {
          // 2nd state state button refers to 1st state
          this.$set(cta, 'index', '0');
        }
      } else if (type === 'popup') {
        this.$set(cta, 'uri', '');
        this.initEditor();
      }
      this.change();
    },

    addLocationHandler(location) {
      if (!Array.isArray(this.cta.uri)) {
        this.$set(this.cta, 'uri', []);
      }
      this.cta.uri.push(location);
      this.change();
      this.$refs['tag_input_locations'].focusInput();
    },

    removeLocation(index) {
      this.cta.uri.splice(index, 1);
      this.change();
    },

    change() {
      // send the trakcers root
      // for layouts its the object
      // for templates its the creative.settings.cta
      if (this.cta.action === 'url' && this.cta.uri !== '') {
        // should always have array output with that one url at [0]
        this.cta.uri = validateURL(this.cta.uri);
      } else if (this.cta.action === 'call' || this.cta.action === 'sms') {
        if (this.cta.uri !== '') {
          this.cta.uri = validatePhone(this.cta.uri);
        }
      }
      this.$emit('change', this.trackers_root);
    },

    calendarChangeHandler({ start, end, timezone }) {
      this.$set(this.cta, 'start', start);
      this.$set(this.cta, 'end', end);
      this.$set(this.cta, 'timezone', timezone);
      this.change();
    },

    validateTrackers() {
      if (this.asset != null) {
        if (!Object.keys(this.creative.settings.cta.assets).includes(this.asset_id)) {
          this.creative.settings.cta.assets = {
            ...this.creative.settings.cta.assets,
            [this.asset_id]: { action: 'url', uri: '', impressiontracker: [] },
          };
        }
      }
    },

    validateCTA() {
      if (this.cta.uri == null) return;

      if (this.cta.action === 'map') {
        if (!Array.isArray(this.cta.uri)) {
          this.$set(this.cta, 'uri', []);
        }
        return;
      }

      if (typeof this.cta.uri !== 'string') {
        this.$set(this.cta, 'uri', '');
      }
    },
  },

  watch: {
    'creative.settings.cta': {
      handler() {
        this.init();
      },
      deep: true,
      immediate: true,
    },

    object: {
      handler() {
        this.init();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
