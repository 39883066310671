<template lang="pug">
div
  div.row.col.mb-8.mt-14
    h4 Text:
  TextSettings(:object="object.settings" @update="$emit('update', $event)")
</template>

<script>
import TextSettings from './TextSettings.vue';

export default {
  name: 'TextSlot',
  components: {
    TextSettings,
  },
  props: {
    object: {
      type: Object,
      default: null,
    },
  },
};
</script>
