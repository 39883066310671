import Subscriber from '@libs/Subscriber';
import { TEMPLATE_FEATURE } from '@master/constants';

class TemplatesService extends Subscriber {
  async subscribe(cb, vnode = null) {
    if (this.data == null) {
      await this.load();
    }

    super.subscribe(cb, vnode);
  }

  load() {
    return super.load('v2/templates/full', {}, true);
  }

  async get(id) {
    if (this.data == null) {
      await this.load();
    }

    return this.data.items?.find(template => {
      const unique_id = template.template_id ?? template.layout_id;
      return unique_id === id;
    });
  }

  isSocialLayout(layout) {
    return ['OMWj6CCb', 'S2eLDUFJ'].includes(layout?.layout_id);
  }

  bestForDesktop(template) {
    return this.#hasKeyword(template, TEMPLATE_FEATURE.BEST_FOR_DESKTOP);
  }

  isAutoAnimationLayout(template) {
    return this.#hasKeyword(template, TEMPLATE_FEATURE.AUTO_ANIMATION);
  }

  isPopular(template) {
    return this.#hasKeyword(template, TEMPLATE_FEATURE.POPULAR);
  }

  isNew(template) {
    return this.#hasKeyword(template, TEMPLATE_FEATURE.NEW);
  }

  isDefaultAssetColorWhite(template) {
    return this.#hasKeyword(template, TEMPLATE_FEATURE.DEFAULT_BG_WHITE);
  }

  #hasKeyword(template, key) {
    /**
     * @description Currently layout keywords are arrays ("[a,b]") and template keywords are strings ("a,b")
     */
    if (!template?.keywords?.length) {
      return false;
    }

    if (Array.isArray(template.keywords)) {
      return template.keywords.some(keyword => keyword.toLowerCase() === key);
    }

    return template.keywords.toLowerCase().includes(key);
  }
}

export default new TemplatesService();
