<template lang="pug">
div(:class="[styles.card, styles.card_small]")
  span(:class="styles.label") {{ label }}

  Loading.small(v-if="loading")
  div(
    v-else
    :class="[styles.stat, { 'color-gray-600': !value || value === 0 }]"
  ) {{ value | PercentageFilter }}
</template>

<script>
import styles from '../Dashboard.module.scss';

import Loading from '@master/UI/Loading.vue';

export default {
  name: 'CardSmall',

  components: {
    Loading,
  },

  props: {
    label: {
      type: String,
      required: true,
    },

    value: {
      type: Number,
      default: 0,
    },

    loading: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      styles,
    };
  },
};
</script>
