<template lang="pug">
div.flex.flex-align-center( v-if="option.value != null")
  label.inline
    input(
      type="radio"
      :checked="checked"
      :value="option.value"
      @click="valueChangeHandler(option.value)")
    span {{option.label | CapitalizeFirstLetterFilter}}
div.flex.flex-align-center(v-else-if="option.range != null")
  label.inline
    input(
      type="radio"
      :checked="checked"
      @click="rangeSelectionHandler")
    span {{option.label | CapitalizeFirstLetterFilter}}
  SearchSelect(
    v-if="range_options"
    :options="range_options"
    :value="current_range_value"
    :search="false"
    :disabled="range_selection_disabled"
    width="160px"
    @input="valueChangeHandler"
  )
</template>

<script>
import SearchSelect from '@master/UI/SearchSelect/SearchSelect.vue';

export default {
  name: 'RadioButtonDropdown',
  components: {
    SearchSelect,
  },

  props: {
    // can be either millisecond value or "never"
    value: [Number, String],
    option: Object,
  },

  computed: {
    checked() {
      if (this.option.value != null) {
        return this.value === this.option.value;
      } else if (this.range_options != null) {
        return this.range_options.find(item => item.value === this.value);
      }
      return false;
    },

    range_options() {
      if (!this.option.range) return null;

      const options = [];

      for (let i = this.option.range.min; i <= this.option.range.max; i += this.option.range.step) {
        let label = (i * (this.option.range?.scale ?? 1)).toString();
        if (this.option.range.unit) {
          label += ` ${this.option.range.unit}`;
        }

        options.push({
          label,
          value: i,
        });
      }

      return options;
    },

    range_selection_disabled() {
      return this.range_options.find(item => item.value === this.value) == null;
    },

    current_range_value() {
      // if the option is checked aka the value is in the range
      if (this.checked) return this.value;

      // if non range option is selected, have the placeholder as default
      return this.range_options[0].value;
    },
  },

  methods: {
    valueChangeHandler(value) {
      this.$emit('input', value);
    },

    rangeSelectionHandler() {
      // set the value as the default
      this.$emit('input', this.current_range_value);
    },
  },
};
</script>
