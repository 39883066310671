<template lang="pug">
CampaignSidebar(v-if="active_campaign" ref="sidebar" :campaign="active_campaign")
FolderSidebar(v-else-if="active_folder" ref="sidebar" :folder="active_folder")
CreativeSidebar(v-else-if="active_creative" ref="sidebar" :creative="active_creative")
AssetSidebar(v-else-if="active_asset" ref="sidebar" :asset="active_asset")
</template>

<script>
import FolderSidebar from './folder/FolderSidebar.vue';
import CampaignSidebar from './campaign/CampaignSidebar.vue';
import CreativeSidebar from './creative/CreativeSidebar.vue';
import AssetSidebar from './asset/AssetSidebar.vue';

import FolderService from '@master/Services/FolderService';
import CampaignService from '@master/Services/CampaignService';
import CreativesService from '@master/Services/CreativesService';
import GroupAssetsLibraryService from '@master/Services/GroupAssetsLibraryService';

export default {
  name: 'EditorSidebar',

  components: {
    FolderSidebar,
    CampaignSidebar,
    CreativeSidebar,
    AssetSidebar,
  },

  data() {
    return {
      active_campaign: null,
      active_folder: null,
      active_creative: null,
      active_asset: null,

      keyHandler: e => {
        if (e.key === 'Escape') {
          this.$refs?.sidebar?.close();
        }
      },
    };
  },

  created() {
    // on create, reset actives since view changed
    CampaignService.active.set(null);
    FolderService.active.set(null);
    CreativesService.active.set(null);

    CampaignService.active.subscribe(campaign => {
      this.active_campaign = campaign;
    }, this);

    FolderService.active.subscribe(folder => {
      this.active_folder = folder;
    }, this);

    CreativesService.active.subscribe(creative => {
      this.active_creative = creative;
    }, this);

    GroupAssetsLibraryService.active.subscribe(asset => {
      this.active_asset = asset;
    }, this);

    window.addEventListener('keydown', this.keyHandler);
  },

  destroyed() {
    window.removeEventListener('keydown', this.keyHandler);
  },
};
</script>
