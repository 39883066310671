<template lang="pug">
div(v-if="creative.settings != null")
  div.flex.flex-align-center
    span(v-if="$store.get('quantum_state') === 'overview'") Default
    span(v-else) {{ $store.get('quantum_state') }}
    span &nbsp;click action
    i.nexd-icon-16-help.ml-8(aria-hidden="true" v-tooltip="{template: 'cm.ctaGlobal'}")
  TrackingRowHeader
  template(v-if="creative.settings.cta != null")
    CTARowComponent(
      :creative="creative"
      :isExpanded="isExpanded"
      :global="true"
      :sizeset="sizeset"
      @change="change('cta', $event, null)"
    )
  UIGroup.mt-24(v-if="!isVast() && (creative.template != null || creative.layout != null)" :title="ui_group_title" :collapsed="collapsed" tooltip="cm.ctaAssetSpecific")
    template(v-if="CTA_items.size > 0")
      TrackingRowHeader(:global="false")
      template(v-for="(item, index) in CTA_items")
        CTARowComponent(
          :key="item.asset_id || item.object_id"
          :creative="creative"
          :isExpanded="isExpanded"
          :asset_id="item.asset_id"
          :object="item.object || null"
          :object_id="item.asset_id || item.object_id"
          :name="item.name || `Overlay ${index + 1}`"
          :user_value="item.user_value"
          :actions="item.actions"
          @change="change(item.object_id || 'cta', $event, item)"
        )
        CTARowComponent(
          v-for="(child, child_indx) in item.children"
          :key="`${item.object_id}_${child_indx}`"
          :creative="creative"
          :object="child.object"
          :object_id="`${item.object_id}_${child_indx}`"
          :name="child.name"
          :user_value="child.user_value"
          :actions="child.actions"
          :isOption="true"
          @change="change(item.object_id || 'cta', $event, child)"
        )
    AssetSpecificError(v-else)
</template>

<script>
import AssetTraits from '@cm/Views/Creatives/Traits/AssetTraits.vue';
import CreativeTraits from '@master/Traits/CreativeTraits.vue';

import { clone } from '@helpers/Global';
import LayoutHelper from '@helpers/Layouts';
import { ASSET } from '@master/constants';

import UIGroup from '@master/UI/UIGroup.vue';
import CTARowComponent from '@cm/Views/Creatives/Cards/Components/Tracking/CTA/CTARowComponent.vue';
import AssetSpecificError from '@cm/Views/Creatives/Cards/Components/Tracking/Components/AssetSpecificError.vue';
import TrackingRowHeader from '@cm/Views/Creatives/Cards/Components/Tracking/Components/TrackingRowHeader.vue';

import RequestLimiter from '@libs/RequestLimiter';
import CreativesService from '@master/Services/CreativesService';

export default {
  name: 'CTATab',
  mixins: [CreativeTraits],
  extends: AssetTraits,
  components: {
    CTARowComponent,
    AssetSpecificError,
    TrackingRowHeader,
    UIGroup,
  },
  props: {
    creative: Object,
    // on two state layouts, parent is first state, otherwise parent === creative
    parent: Object,

    isExpanded: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      CTA_items: [],
      collapsed: !this.$user.isEnterpriseUser(),
    };
  },

  created() {
    this.init();
  },

  computed: {
    sizeset() {
      if (this.$store.get('quantum_state') !== 'overview') {
        return this.$store.get('quantum_state');
      }
      return null;
    },

    ui_group_title() {
      let title = '';

      if (this.$store.get('quantum_state') === 'overview') {
        title = 'Asset';
      } else {
        title = `${this.$store.get('quantum_state')} asset`;
      }

      title += ' specific click actions';
      return title;
    },

    force_show_assets() {
      return this.creative?.template?.template_base === 'split';
    },
  },

  methods: {
    init() {
      this.CTA_items = [];

      // VAST creatives have no asset specific clicks
      if (this.isVast()) return;

      this.constructCTAAssets();
      this.constructCTAObjects();
    },

    constructCTAAssets() {
      if (this.hasLayout()) {
        this.CTA_items = this.getLayoutCTAAssets();
      } else {
        this.CTA_items = this.getTemplateCTAAssets();
      }
    },

    constructCTAObjects() {
      // Some MVP new layouts assets values/names logic
      if (this.creative.objects != null) {
        let added_root_elements = [];
        for (const object_id in this.creative.objects) {
          const rootslot = LayoutHelper.getLayoutItemRootSlot(this.creative.layout_id, object_id);
          // If root has already been added to list, we are not adding it again
          if (rootslot?.cta_actions == null || rootslot.cta_actions.length < 1 || added_root_elements.indexOf(rootslot.slot_id) !== -1 || this.creative?.objects?.[object_id]?.data?.value == null) {
            continue;
          }
          added_root_elements.push(rootslot.slot_id);
          if (this.creative.objects[object_id].data.cta == null) {
            this.creative.objects[object_id].data.cta = {
              action: 'url',
              uri: '',
            };
          }
          this.CTA_items.add({
            object_id: object_id,
            name: rootslot.name || 'N/A',
            object: this.creative.objects[object_id].data,
            actions: rootslot.cta_actions || null,
            user_value: this.creative.objects[object_id].data.value,
            children: [],
          });
        }
      }
    },

    getLayoutCTAAssets() {
      let items = [];
      for (const asset_id in this.creative.assets) {
        let asset = LayoutHelper.getLayoutItemRootSlot(this.creative.layout_id, asset_id);
        // Layout global module assets don't have root, so have to check just by item
        if (asset == null) {
          asset = LayoutHelper.getLayoutItem(this.creative.layout_id, asset_id);
        }
        if (asset?.cta_actions?.length > 0) {
          items.push({ ...this.creative.assets[asset_id], name: asset.name, actions: asset.cta_actions });
        }
      }
      return new Set(items);
    },

    getTemplateCTAAssets() {
      if (this.creative.template == null) return;
      let CTA_items = [];
      let main_assets = [];

      let main_template_assets_count = 0;

      for (const asset_id in this.creative.template.assets) {
        // clone template asset obj, since we modify it and dont need it to modify the real values used elsewhere
        const asset = clone(this.creative.template.assets[asset_id]);

        let real_asset_id = asset_id;

        // if size specifc sizeset is chosen, check for assets with that sizeset as well
        if (this.$store.get('quantum_state') !== 'overview') {
          real_asset_id += `_${this.$store.get('quantum_state')}`;

          // give that template asset an id with sizeset suffix, so we would send the correct cta object to the asset cta component
          asset.asset_id = real_asset_id;
        }

        if (this.isOverlay(asset.type) && this.isAdditional(asset.required)) {
          // globals have required 2 and type 2
          // be sure asset is uploaded before adding it tot he list
          if (this.creative.assets[real_asset_id] != null && this.creative.settings.cta.assets[real_asset_id] != null) {
            CTA_items.push({
              asset_id: asset.asset_id,
              name: asset.name || null,
              actions: ['all', 'popup'],
            });
          }
        } else if (asset.required < 2 && asset.cta_enabled === true) {
          // only count ones with required 1
          if (asset.required === 1) {
            main_template_assets_count++;
          }

          // be sure asset is uploaded before adding it tot he list
          if ((this.creative.assets[real_asset_id] != null || this.creative.assets[asset_id] != null) && this.creative.settings.cta.assets[real_asset_id] != null) {
            // special case, if endcard is uploaded show that in the tracking panel
            if (this.isEndcard(asset)) {
              CTA_items.push({
                asset_id: asset.asset_id,
                name: asset.name || null,
                actions: ['all'],
              });
            } else {
              main_assets.push({
                asset_id: asset.asset_id,
                name: asset.name || null,
                actions: ['all'],
              });
            }
          }
        } else {
          // on any errors, check which assets are excluded
          // console.log(asset.name, asset.required, asset.cta_enabled);
        }
      }

      for (const asset_id in this.creative.additional_assets) {
        // all additionals exept custom gesture
        if (!this.creative.additional_assets[asset_id].custom && this.creative.additional_assets[asset_id].uri) {
          const asset = this.creative.additional_assets[asset_id];

          let actions = ['all'];
          if (!asset.custom && asset.custom_type === ASSET.OVERLAY) {
            actions.push('popup');
          }

          CTA_items.push({
            asset_id: asset_id,
            name: asset.name || null,
            actions,
          });
        }
      }

      // only add main assets to the list, if theres more than 1 required asset
      if (main_template_assets_count > 1 || this.force_show_assets) {
        CTA_items = main_assets.concat(CTA_items);
      }

      return new Set(CTA_items);
    },

    change(id, data, item) {
      const keys_to_delete = ['uri', 'sms_body', 'title', 'location', 'description', 'start', 'end', 'dateStart', 'dateEnd', 'timezone', 'focus_closest', 'width', 'height'];
      const allowed = ['action', ...keys_to_delete];

      // instantly set object values
      if (id === 'cta') {
        let target = this.creative.settings[id];
        let src = data;
        if (item?.asset_id != null) {
          target = target.assets[item.asset_id];
          src = src.assets[item.asset_id];
        }

        // duplicate from CTARowComponent until reacitivty n modularity issue resolved
        for (const key of keys_to_delete) {
          if (target[key] != null) {
            this.$delete(target, key);
          }
        }

        for (const key in src) {
          // make sure not to update anything else in the root objects
          if (allowed.includes(key)) {
            this.$set(target, key, src[key]);
          }
        }

        // responsive case
        if (data.quantum != null) {
          this.$set(target, 'quantum', data.quantum);
        }
      } else if (item.object != null && data.cta != null) {
        // can be any object or a child of an object
        // update the original reference given to the row component

        if (item.object.cta == null) {
          this.$set(item.object, 'cta', {});
        }
        for (const key in data.cta) {
          // make sure not to update anything else in the root objects
          if (allowed.includes(key)) {
            this.$set(item.object.cta, key, data.cta[key]);
          }
        }
      }

      RequestLimiter.hook(`trackingtab_objects.${id}`, () => {
        if (id === 'cta') {
          this.$http.put(`creative/${this.creative.creative_id}/settings/${id}`, { value: this.creative.settings[id] });
        } else {
          this.$http.put(`v2/creative/${this.creative.creative_id}/objects/${id}`, this.creative.objects[id]);
        }
      });

      CreativesService.export.requestTagUpdate(this.parent);
    },
  },

  watch: {
    'creative.assets': {
      handler() {
        this.init();
      },
    },
    'creative.objects': {
      handler() {
        this.init();
      },
    },
  },
};
</script>
