<template lang="pug">
div
  slot(name="logo")
  div#content-wrapper
    div#title
      h1#magic-title
        | Reset password
      h5 Create a new password

    form#form(@submit.prevent="submit")
      div.flex.flex-direction-column.row-gap-4
        label Password
        Input(
          v-model="password"
          variant="password"
          placeholder="password"
          :medium="true"
          :error_msg="errors.password"
          @error="error => errors.password = error"
        )
        label Confirm Password
        Input(
          ref="input_confirm_password"
          v-model="password_confirm"
          variant="password"
          placeholder="confirm password"
          :medium="true"
          :error_msg="errors.confirm_password"
          @error="handleConfirmPasswordError"
        )

      Buttons(align="center")
        Button(
          v-if="!form_success"
          :type="'login-primary'"
          :label="'Reset password'"
          :block="true"
          :medium="true"
          :loading="loading"
          :disabled="!has_valid_data"
          :submit="true"
        )
        Button(type="link-light" label="Back to login" @click="openLogin")
</template>

<script>
import Buttons from '@master/UI/Buttons/Buttons.vue';
import Button from '@master/UI/Buttons/Button.vue';
import Input from '@master/UI/Input/Input.vue';

export default {
  name: 'ResetPassword',

  components: {
    Input,
    Buttons,
    Button,
  },

  computed: {
    has_data() {
      return this.password.length > 0 && this.password_confirm.length > 0;
    },

    has_valid_data() {
      return this.has_data && this.password === this.password_confirm && Object.values(this.errors).every(error => error == null);
    },
  },

  data() {
    return {
      password: '',
      password_confirm: '',

      errors: {
        password: null,
        confirm_password: null,
      },

      form_success: false,
      loading: false,
    };
  },

  methods: {
    openLogin() {
      this.$parent.open('login');
    },

    submit() {
      if (!this.has_valid_data) {
        return;
      }

      this.loading = true;

      this.$http
        .put(`v3/auth/password/reset/${this.$route.params.token}`, { password: this.password })
        .then(_ => {
          this.form_success = true;
          this.showSuccessPopup();
        })
        .catch(_ => {})
        .finally(_ => {
          this.loading = false;
        });
    },

    handleConfirmPasswordError(error) {
      if (!this.$refs.input_confirm_password.hasFocus()) {
        if (error == null && this.password !== this.password_confirm) {
          this.errors.confirm_password = "Confirm doesn't match original password";
          return;
        }
      }

      this.errors.confirm_password = error;
    },

    async showSuccessPopup() {
      const response = await this.$confirm('You can now login with your new password.', '', {
        buttons: [
          {
            type: 'link-primary',
            label: 'Cancel',
            action: false,
          },
          {
            type: 'primary',
            label: 'Login',
            action: true,
          },
        ],
        image: 'success',
      });

      if (response) {
        this.openLogin();
      }
    },
  },
};
</script>
