<template lang="pug">
TestLayout(:flex="true" title="Buttons")
  TestSection(subtitle="Buttons Filled")
    Buttons(align="left")
      Button(v-for="(label, type) in types" :key="type" :type="type" :label="label")

  TestSection(subtitle="Buttons Filled Disabled")
    Buttons(align="left")
      Button(v-for="(label, type) in types" :key="type" :type="type" :label="label" :disabled="true")

  TestSection(subtitle="Buttons Outlined")
    Buttons(align="left")
      Button(v-for="(label, type) in types" :key="type" :type="type" :label="label" :outline="true")

  TestSection(subtitle="Buttons Outlined Disabled")
    Buttons(align="left")
      Button(v-for="(label, type) in types" :key="type" :type="type" :label="label" :outline="true" :disabled="true")

  TestSection(subtitle="Buttons w/ Position Aware Animation")
    Buttons(align="left")
      Button(type="primary" label="Primary" :animate="true")
      Button(type="success" label="Success" :animate="true")
      Button(type="warning" label="Warning" :animate="true")
      Button(type="media-buy" label="Media Buy" :animate="true")
      Button(type="primary" label="Disabled" :animate="true" :disabled="true")

  TestSection(subtitle="Buttons w/ Icon")
    Buttons(align="left")
      Button(type="primary" label="New Campaign" :animate="true")
        template(v-slot:prefix)
          IconAdd
      Button(type="primary" label="New Campaign" :animate="true")
        template(v-slot:prefix)
          IconDropdown
      Button(type="primary" label="New Campaign" :animate="true")
        template(v-slot:prefix)
          IconFilter
      Button(type="primary" label="New Campaign" :animate="true")
        template(v-slot:suffix)
          IconFolder
      Button(type="primary" label="New Campaign" :animate="true")
        template(v-slot:prefix)
          IconSearch
      Button(type="primary" label="New Campaign" :animate="true")
        template(v-slot:prefix)
          IconTag

  TestSection(subtitle="Buttons as Link")
    template(v-slot:tooltip)
      Toggle.ml-8(v-model="loading_link")
    Buttons(align="left")
      Button(type="link" label="Link" :loading="loading_link")
      Button(type="link-primary" label="Link Primary" :loading="loading_link")
      Button(type="link-accent" label="Link Accent" :loading="loading_link")
      Button(type="link" label="w/ Icon" :loading="loading_link")
        template(v-slot:prefix)
          IconFolder
      Button(type="link-primary" label="Primary w/ icon" :loading="loading_link")
        template(v-slot:suffix)
          IconFolder
      Button(type="link-accent" label="Accent w/ icon" :loading="loading_link")
        template(v-slot:suffix)
          IconFolder
      Button(type="link" label="Disabled" :disabled="true" :loading="loading_link")
      Button(type="link-primary" label="Primary Disabled" :disabled="true" :loading="loading_link")
      Button(type="link-accent" label="Accent Disabled" :disabled="true" :loading="loading_link")

  TestSection(subtitle="Buttons Loading")
    template(v-slot:tooltip)
      Toggle.ml-8(v-model="loading")
    Buttons(align="left")
      Button(type="primary" label="Primary" :loading="loading")
      Button(type="media-buy" label="Media Buy" :outline="true" :loading="loading")
      Button(type="success" label="Animated" :animate="true" :loading="loading")
      Button(type="secondary" label="Secondary" :outline="false" :loading="loading")
        template(v-slot:prefix)
          IconMediaBuy
      Button(type="dropdown" label="Export" :loading="loading")
        template(v-slot:suffix)
          IconDropdown
      Button(type="filter" label="Filter" :loading="loading")
        template(v-slot:prefix)
          IconFilter

  TestSection(subtitle="Buttons inside Dropdown")
    template(v-slot:tooltip)
      Toggle.ml-8(v-model="active")
    Buttons(align="left")
      Button(type="primary" :outline="true" label="Dropwdown Button" :active="active")
      Button(type="dropdown" label="New Folder" :active="active")
        template(v-slot:prefix)
          IconFolder
      Button(type="dropdown" label="Export" :active="active" :flip="true")
        template(v-slot:suffix)
          IconDropdown
      Button(type="filter" label="Type" :active="active")
        template(v-slot:prefix)
          IconFilter

  TestSection(subtitle="Buttons Quantum")
    Buttons(align="left")
      Button(type="quantum" label="Global size" :active="active_quantum === 0" :large="true" @click="active_quantum = 0")
      Button(type="quantum" label="300x250" :active="active_quantum === 1" :large="true" @click="active_quantum = 1")
      Button(type="quantum" label="336x280" :active="active_quantum === 2" :large="true" @click="active_quantum = 2")

  TestSection(subtitle="Buttons as Block")
    Buttons(align="left")
      Button(type="primary" label="Primary Block Button" :block="true")

  TestSection(subtitle="Buttons as Large Block")
    Buttons(align="left")
      Button(type="primary" label="Primary Block Button Large" :block="true" :large="true")
      Button(type="primary" label="Primary Outline Block Button Large" :outline="true" :block="true" :large="true")

  TestSection(subtitle="Buttons Icons")
    Buttons(align="left")
      Button(type="primary" label="Primary" v-for="icon of icons" :key="icon")
        template(v-slot:prefix)
          component(:is="icon")

  TestSection(subtitle="Buttons Pill Shape")
    Buttons(align="left")
      Button(v-for="(label, type) in types" :key="type" :type="type" :label="label" :pill="true")

  TestSection(subtitle="Button inside paragraph")
    div Lorem ipsum dolor sit amet, consectetur adipiscing elit.&nbsp;
      Button(type="link" label="Read more" :text="true")

  TestSection(subtitle="Button inside paragraph disabled")
    div.fs-14 Lorem ipsum dolor sit amet, consectetur adipiscing elit.&nbsp;
      Button(type="link" label="Read more" :text="true" :disabled="true")

  TestSection(subtitle="Button with description")
    div.flex.column-gap-16
      ButtonWithDescription.flex-grow(label="Add overlay element" description="You can add 4 more overlay elements")
      ButtonWithDescription.flex-grow(label="Add animation" :large="false" description="You can add 6 more animations")
</template>

<script>
import TestLayout from '@root/src/apps/components/Global/TestLayout.vue';
import TestSection from '@root/src/apps/components/Global/TestSection.vue';

import Buttons from '@master/UI/Buttons/Buttons.vue';
import Button from '@master/UI/Buttons/Button.vue';
import ButtonWithDescription from '@master/UI/Buttons/ButtonWithDescription.vue';
import IconAdd from '@master/UI/Buttons/Icons/IconAdd.vue';
import IconAnalytics from '@master/UI/Buttons/Icons/IconAnalytics.vue';
import IconArrowRight from '@master/UI/Buttons/Icons/IconArrowRight.vue';
import IconCampaign from '@master/UI/Buttons/Icons/IconCampaign.vue';
import IconCog from '@master/UI/Buttons/Icons/IconCog.vue';
import IconDashboard from '@master/UI/Buttons/Icons/IconDashboard.vue';
import IconDownload from '@master/UI/Buttons/Icons/IconDownload.vue';
import IconDropdown from '@master/UI/Buttons/Icons/IconDropdown.vue';
import IconEdit from '@master/UI/Buttons/Icons/IconEdit.vue';
import IconFeedback from '@master/UI/Buttons/Icons/IconFeedback.vue';
import IconFilter from '@master/UI/Buttons/Icons/IconFilter.vue';
import IconFlights from '@master/UI/Buttons/Icons/IconFlights.vue';
import IconFolder from '@master/UI/Buttons/Icons/IconFolder.vue';
import IconGallery from '@master/UI/Buttons/Icons/IconGallery.vue';
import IconHelp from '@master/UI/Buttons/Icons/IconHelp.vue';
import IconLibrary from '@master/UI/Buttons/Icons/IconLibrary.vue';
import IconLink from '@master/UI/Buttons/Icons/IconLink.vue';
import IconLocked from '@master/UI/Buttons/Icons/IconLocked.vue';
import IconMediaBuy from '@master/UI/Buttons/Icons/IconMediaBuy.vue';
import IconPicture from '@master/UI/Buttons/Icons/IconPicture.vue';
import IconPreview from '@master/UI/Buttons/Icons/IconPreview.vue';
import IconRedo from '@master/UI/Buttons/Icons/IconRedo.vue';
import IconReload from '@master/UI/Buttons/Icons/IconReload.vue';
import IconReport from '@master/UI/Buttons/Icons/IconReport.vue';
import IconSearch from '@master/UI/Buttons/Icons/IconSearch.vue';
import IconSpecs from '@master/UI/Buttons/Icons/IconSpecs.vue';
import IconTag from '@master/UI/Buttons/Icons/IconTag.vue';
import IconVideo from '@master/UI/Buttons/Icons/IconVideo.vue';
import Toggle from '@master/UI/Toggle.vue';

export default {
  name: 'ButtonTest',

  components: {
    TestLayout,
    TestSection,

    Buttons,
    Button,
    ButtonWithDescription,
    IconAdd,
    IconAnalytics,
    IconArrowRight,
    IconCampaign,
    IconCog,
    IconDashboard,
    IconDownload,
    IconDropdown,
    IconEdit,
    IconFeedback,
    IconFilter,
    IconFlights,
    IconFolder,
    IconGallery,
    IconHelp,
    IconLibrary,
    IconLink,
    IconLocked,
    IconPicture,
    IconMediaBuy,
    IconPreview,
    IconRedo,
    IconReload,
    IconReport,
    IconSearch,
    IconSpecs,
    IconTag,
    IconVideo,
    Toggle,
  },

  data() {
    return {
      loading: false,
      loading_link: false,
      active: false,
      active_quantum: 0,

      icons: [
        'IconAdd',
        'IconAnalytics',
        'IconArrowRight',
        'IconCampaign',
        'IconCog',
        'IconDashboard',
        'IconDownload',
        'IconDropdown',
        'IconEdit',
        'IconFeedback',
        'IconFilter',
        'IconFlights',
        'IconFolder',
        'IconGallery',
        'IconHelp',
        'IconLibrary',
        'IconLink',
        'IconLocked',
        'IconMediaBuy',
        'IconPicture',
        'IconPreview',
        'IconRedo',
        'IconReload',
        'IconReport',
        'IconSearch',
        'IconSpecs',
        'IconTag',
        'IconVideo',
      ],

      types: {
        primary: 'Primary',
        secondary: 'Secondary',
        success: 'Success',
        warning: 'Warning',
        danger: 'Danger',
        update: 'Update',
        'media-buy': 'Media Buy',
        cyan: 'Cyan',
        banner: 'Banner',
      },
    };
  },
};
</script>
