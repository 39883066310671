<template lang="pug">
TestLayout(title="Slots")

  div.mb-16.fs-14.color-warning(style="grid-column:1/-1")
    | Since this is for markup test, most functionality is not working.
    | For example, you can't upload new assets or save settings.

  TestSection(subtitle="Default Slot" style="grid-column: span 2;")
    AssetSlot(
      :creative="creative"
      :template_asset="asset_empty"
      :arno="null"
    )

  TestSection(subtitle="Filled Slot" style="grid-column: span 2;")
    AssetSlot(
      :creative="creative"
      :template_asset="asset_filled"
      :arno="null"
    )

  TestSection(subtitle="Default Map Slot" style="grid-column: span 2;")
    AssetSlot(
      :creative="creative"
      :template_asset="asset_map_empty"
      :arno="null"
    )

  TestSection(subtitle="Filled Map Slot" style="grid-column: span 2;")
    AssetSlot(
      :creative="creative"
      :template_asset="asset_map_filled"
      :arno="null"
    )

  TestSection(subtitle="Quantum Filled Multiple" style="grid-column: span 2;")
    AssetSlot(
      :creative="creative"
      :template_asset="asset_quantum_multiple"
      :arno="null"
    )

  TestSection(subtitle="Additional Empty" style="grid-column: span 2;")
    AssetSlot(
      :creative="creative"
      :template_asset="asset_empty"
      :additional="true"
      :arno="null"
    )

  TestSection(subtitle="Video Filled" style="grid-column: span 2;")
    AssetSlot(
      :creative="creative"
      :template_asset="asset_filled_video"
      :arno="null"
    )

  TestSection(subtitle="Video endcard Filled" style="grid-column: span 2;")
    AssetSlot(
      :creative="creative"
      :template_asset="asset_filled_video_endcard"
      :arno="null"
    )

  TestSection(subtitle="Hotspot filled" style="grid-column: span 2;")
    AssetSlot(
      :creative="creative"
      :template_asset="asset_filled_hotspot"
      :additional="true"
      :arno="null"
    )

  TestSection(subtitle="Polite load image filled" style="grid-column: span 2;")
    AssetSlot(
      :creative="creative"
      :template_asset="asset_ad_splash"
      :arno="null"
    )

  TestSection(subtitle="Global animations settings" style="grid-column: span 2;")
    SettingsContainer.mb-16(v-if="timeline?.enabled" :columns="1" :background="true" :group="false")
      h4 Global animations settings:
      SettingsRow(
        label="Loop"
        tooltip="Enable looping to repeat the animations you set below, so that the animations would still continue after the first cycle is done"
      )
        Toggle(v-model="timeline.iterations" :on="0" :off="1" @change="timeline.update()")
      SettingsRow(
        label="Animation length"
        tooltip="Choose how many seconds one cycle of animations should take."
      )
        Slider(v-model="timeline.duration" :input="true" :input_suffix="'s'" :options="{ min: 1000, max: 30000, step: 100, scale: 0.001 }" @change="timeline.update()")

  TestSection(subtitle="Slot with timeline" style="grid-column: span 2;")
    AssetSlot(
      :creative="creative"
      :template_asset="asset_overlay_logo"
      :arno="null"
      :timeline="timeline"
    )

  TestSection(subtitle="Slot with loading" style="grid-column: span 2;")
    AssetSlot(
      ref="loading_slot"
      :creative="creative"
      :template_asset="asset_overlay_logo"
      :arno="null"
    )
</template>

<script>
import TestLayout from '@root/src/apps/components/Global/TestLayout.vue';
import TestSection from '@root/src/apps/components/Global/TestSection.vue';

import Slider from '@master/UI/Slider.vue';
import Toggle from '@master/UI/Toggle.vue';

import AssetSlot from '@cm/Views/Creatives/Cards/Components/Asset/Slot/Slot.vue';
import SettingsContainer from '@cm/Views/Creatives/Cards/Components/Asset/Settings/SettingsContainer.vue';
import SettingsRow from '@cm/Views/Creatives/Cards/Components/Asset/Settings/SettingsRow.vue';
import Timeline from '@cm/Views/Creatives/Cards/Components/Asset/Animations/Timeline';

const CDN_URI = 'https://cdn-lcl.nexd.dev';
const EFS_URI = 'https://data-euw1-lcl.nexd.dev';

export default {
  name: 'SlotTest',

  components: {
    TestLayout,
    TestSection,

    Slider,
    Toggle,

    AssetSlot,
    SettingsContainer,
    SettingsRow,
  },

  data() {
    const template_asset_base = {
      description: 'This media is shown on the second carousel card.',
      width: '<84',
      height: '<84',
      x: null,
      y: null,
      position: 'relative',
      type: 0,
      required: 1,
    };

    const creative = {
      assets: {
        Es7xaQtXfoqX: {
          saved_asset_id: 'XudLcmexNIypTEav0T4OkqGrrFYJoT1r',
          asset_id: 'Es7xaQtXfoqX',
          original_asset_id: '0lNc3mmhkPkjiqMK0JKDVezgRY1jdrLc',
          filesize: 1946,
          original_url: `${CDN_URI}/dist/nexd/imgs/icecream-alert.svg`,
          original_name: '271739135_110657098166344_5222767733188035355_n.png',
          image_uri: `${CDN_URI}/dist/nexd/imgs/icecream-alert.svg`,
          filename: '2.png',
          uri: `${CDN_URI}/dist/nexd/imgs/icecream-alert.svg`,
        },
        Es7xaQtX1oqX: {
          settings: {
            map_show_four_markers: 0,
            map_style: 'default',
            options: {
              delimiter: ';',
              start_row_index: 1,
              column_selections: [0, 1, 2, 3, 4],
              additional_column_selections: ['none', 'none'],
            },
            map_direction_btn: false,
            map_unit: 'metric',
          },
        },
        As7xaQtX1oqX: {
          saved_asset_id: 'XudLcmexNIypTEav0T4OkqGrrFYJoT1r',
          asset_id: 'As7xaQtX1oqX',
          original_asset_id: '0lNc3mmhkPkjiqMK0JKDVezgRY1jdrLc',
          filesize: 1946,
          original_url: `${CDN_URI}/dist/nexd/imgs/icecream-alert.svg`,
          original_name: 'ice-cream-alert.png',
          image_uri: `${CDN_URI}/dist/nexd/imgs/icecream-alert.svg`,
          filename: '2.png',
          uri: `${CDN_URI}/dist/nexd/imgs/icecream-alert.svg`,
        },
        As7xaQtX1oqX_300x250: {
          saved_asset_id: 'XudLcmexNIypTEav0T4OkqGrrFYJoT1r',
          asset_id: 'As7xaQtX1oqX_300x250',
          original_asset_id: '0lNc3mmhkPkjiqMK0JKDVezgRY1jdrLc',
          filesize: 2946,
          original_url: `${CDN_URI}/dist/nexd/imgs/icecream-success.svg`,
          original_name: 'ice-cream-success.png',
          image_uri: `${CDN_URI}/dist/nexd/imgs/icecream-success.svg`,
          filename: '2.png',
          width: 250,
          height: 300,
          uri: `${CDN_URI}/dist/nexd/imgs/icecream-success.svg`,
        },
        GwBetLf1VE71: {
          saved_asset_id: 'XudLcmexNIypTEav0T4OkqGrrFYJoT1r',
          asset_id: 'GwBetLf1VE71',
          original_asset_id: '0lNc3mmhkPkjiqMK0JKDVezgRY1jdrLc',
          filesize: 2946,
          original_url: `${CDN_URI}/dist/nexd/imgs/icecream-success.svg`,
          original_name: 'ice-cream-success.mp4',
          image_uri: `${CDN_URI}/dist/nexd/imgs/icecream-success.svg`,
          filename: '2.png',
          width: 250,
          height: 300,
          uri: `${EFS_URI}/ads/creatives/cseV4gBGOCvnxmzf/2.mp4`,
        },
        GwBetLf12E71: {
          saved_asset_id: 'XudLcmexNIypTEav0T4OkqGrrFYJoT1r',
          asset_id: 'GwBetLf12E71',
          original_asset_id: '0lNc3mmhkPkjiqMK0JKDVezgRY1jdrLc',
          filesize: 2946,
          original_url: `${CDN_URI}/dist/nexd/imgs/icecream-success.svg`,
          original_name: 'ice-cream-success.png',
          image_uri: `${CDN_URI}/dist/nexd/imgs/icecream-success.svg`,
          filename: 'endcard.png',
          width: 250,
          height: 300,
          uri: `${EFS_URI}/ads/creatives/cseV4gBGOCvnxmzf/endcard.png`,
        },
        custom_hs_GwBetNf12E71_main: {
          saved_asset_id: 'XudLcmexNIypTEav0T4OkqGrrFYJoT1r',
          asset_id: 'custom_hs_GwBetNf12E71_main',
          original_asset_id: '0lNc3mmhkPkjiqMK0JKDVezgRY1jdrLc',
          filesize: 2946,
          original_url: `${CDN_URI}/dist/nexd/imgs/icecream-success.svg`,
          original_name: 'ice-cream-success.png',
          image_uri: `${CDN_URI}/dist/nexd/imgs/icecream-success.svg`,
          filename: '2.png',
          width: 250,
          height: 300,
          uri: `${EFS_URI}/ads/creatives/cseV4gBGOCvnxmzf/endcard.png`,
        },
        logo: {
          saved_asset_id: 'XudLcmexNIypTEav0T4OkqGrrFYJoT1r',
          asset_id: 'logo',
          original_asset_id: '0lNc3mmhkPkjiqMK0JKDVezgRY1jdrLc',
          filesize: 2946,
          original_url: `${CDN_URI}/dist/nexd/imgs/icecream-success.svg`,
          original_name: 'ice-cream-success.png',
          image_uri: `${CDN_URI}/dist/nexd/imgs/icecream-success.svg`,
          filename: 'logo.png',
          width: 250,
          height: 300,
          uri: `${EFS_URI}/ads/creatives/cseV4gBGOCvnxmzf/endcard.png`,
        },
        ad_splash: {
          saved_asset_id: 'XudLcmexNIypTEav0T4OkqGrrFYJoT1r',
          asset_id: 'ad_splash',
          original_asset_id: '0lNc3mmhkPkjiqMK0JKDVezgRY1jdrLc',
          filesize: 2946,
          original_url: `${CDN_URI}/dist/nexd/imgs/icecream-success.svg`,
          original_name: 'ice-cream-success.png',
          image_uri: `${CDN_URI}/dist/nexd/imgs/icecream-success.svg`,
          filename: 'ad_splash.jpg',
          width: 250,
          height: 300,
          uri: `${EFS_URI}/ads/creatives/cseV4gBGOCvnxmzf/ad_splash.jpg`,
        },
      },
      additional_assets: {},
      creative_id: 'cseV4gBGOCvnxmzf',
      height: 250,
      name: 'New creative',
      device: 0,
      settings: {
        assets: {
          GwBetLf1VE71: {
            autoplay: true,
            loop: true,
            audio: false,
            video_controls: false,
            internal_audio: true,
            fullscreen: false,
            click_action: null,
            button_scale: 0.5,
            button_click_area_scale: 1.5,
            loop_limit: 0,
            transparency: {
              enabled: false,
              type: 'chroma',
              chroma: '#00FF00',
              similarity: 0.5,
              smoothness: 0.1,
              spill: 0.1,
            },
          },
          GwBetLf12E71: {
            duration: 3,
            fade_in_time: 300,
            below_overlays: true,
            scale: 1,
          },
          custom_hs_GwBetNf12E71_main: {
            settings: {
              background_alpha: 0.7,
              background_color: '#000000',
            },
            gesture_id: 'custom_hs_Yyjr1NY_pin',
            gesture_animation: {
              type: 'fade',
            },
            close_id: 'custom_hs_Yyb76jr1NY_close',
          },
          ad_splash: {
            duration: 1000,
          },
        },
        floating: false,
      },
      template_id: 'C15WF6',
      template: {
        assets: {
          GwBetLf1VE7P: {
            ...template_asset_base,
            asset_id: 'GwBetLf1VE7P',
            name: 'First media',
            filename: '1.jpg,1.png,1.mp4,1.svg',
          },
          Es7xaQtXfoqX: {
            ...template_asset_base,
            asset_id: 'Es7xaQtXfoqX',
            name: 'Second media',
            filename: '2.jpg,2.png,2.mp4,2.svg',
          },
          Es7xaQtX1oqX: {
            ...template_asset_base,
            asset_id: 'Es7xaQtX1oqX',
            name: 'Map Data',
            filename: 'map_data.json',
          },
          Es7xaQtX1oqQ: {
            ...template_asset_base,
            asset_id: 'Es7xaQtX1oqQ',
            name: 'Map Data',
            filename: 'map_data.json',
          },
          As7xaQtX1oqX: {
            ...template_asset_base,
            asset_id: 'As7xaQtX1oqX',
            name: 'Second media',
            filename: '2.jpg,2.png,2.mp4,2.svg',
          },
          As7xaQtX1oqX_300x250: {
            ...template_asset_base,
            asset_id: 'As7xaQtX1oqX_300x250',
            name: 'Second media',
            filename: '2.jpg,2.png,2.mp4,2.svg',
          },
          GwBetLf1VE71: {
            ...template_asset_base,
            asset_id: 'GwBetLf1VE71',
            name: 'Second media',
            filename: '2.jpg,2.png,2.mp4,2.svg',
          },
          GwBetLf12E71: {
            ...template_asset_base,
            asset_id: 'GwBetLf12E71',
            name: 'Video end card',
            filename: 'endcard.jpg,endcard.png,endcard.svg',
          },
          custom_hs_GwBetNf12E71_main: {
            ...template_asset_base,
            asset_id: 'custom_hs_GwBetNf12E71_main',
            name: 'Hotspot media',
            filename: '2.jpg,2.png,2.mp4,2.svg',
          },
          logo: {
            ...template_asset_base,
            asset_id: 'logo',
            name: 'Logo',
            position: 'absolute',
            type: 2,
            filename: 'logo.jpg,logo.png,logo.mp4,logo.svg',
          },
          ad_splash: {
            ...template_asset_base,
            asset_id: 'ad_splash',
            name: 'Polite Load Image',
            position: 'absolute',
            type: 2,
            filename: 'logo.jpg',
          },
        },
      },
      thumbnail_url: `${EFS_URI}/ads/creatives/cseV4gBGOCvnxmzf/splash.jpg?fbf11a015cb59a2db959e6b41d7a3d95`,
      width: 300,
    };

    const timeline = new Timeline(creative);
    timeline.disableUpdates();

    // create logo animation section and enable it
    const animations = timeline.get('logo');
    animations.setStatus(true);

    return {
      creative,

      asset_filled: creative.template.assets['Es7xaQtXfoqX'],
      asset_empty: creative.template.assets['GwBetLf1VE7P'],
      asset_map_empty: creative.template.assets['Es7xaQtX1oqQ'],
      asset_map_filled: creative.template.assets['Es7xaQtX1oqX'],
      asset_quantum_multiple: creative.template.assets['As7xaQtX1oqX'],
      asset_filled_video: creative.template.assets['GwBetLf1VE71'],
      asset_filled_video_endcard: creative.template.assets['GwBetLf12E71'],
      asset_filled_hotspot: creative.template.assets['custom_hs_GwBetNf12E71_main'],
      asset_overlay_logo: creative.template.assets['logo'],
      asset_ad_splash: creative.template.assets['ad_splash'],

      timeline,
    };
  },

  mounted() {
    this.$refs?.loading_slot?.setLoadingText('Uploading');
  },
};
</script>
