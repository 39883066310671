import { VIEW } from '@master/constants';

import AdCreator from '@cm/Views/AdCreator/AdCreator.vue';
import Campaigns from '@cm/Views/Campaigns/CampaignView.vue';
import ClickOutPage from '@master/UI/ClickOutPage/ClickOutPage.vue';
import Creative from '@cm/Views/Creatives/Creative/Creative.vue';
import Creatives from '@cm/Views/Creatives/CreativesView.vue';
import Dashboard from '@cm/Views/Dashboard/Dashboard.vue';
import Flight from '@cm/Views/Flights/Flight/Flight.vue';
import Group from '@cm/Views/Profile/Views/Group.vue';
import Profile from '@cm/Views/Profile/Views/Profile.vue';
import SDKNewCreative from '@cm/Views/Creatives/SDKNewCreative.vue';
import SearchView from '@cm/Views/Search/SearchView.vue';

const default_meta = {
  app_name: 'Campaign Manager',
  app_key: 'campaign-manager',
  requires_auth: true,
  show_navbar: true,
  validate_adblock: true,
  show_sidebar: false,
  intercom: true,
  sdk_client: false,
};

export default [
  {
    path: '/',
    name: VIEW.CAMPAIGNS,
    component: Campaigns,
    meta: { ...default_meta, ...{ show_sidebar: true, view: VIEW.CAMPAIGNS } },
  },
  {
    path: '/d/:active_item_id',
    name: VIEW.CAMPAIGNS_USER,
    component: Campaigns,
    meta: { ...default_meta, ...{ show_sidebar: true, view: VIEW.CAMPAIGNS } },
  },
  {
    path: '/f/:folder_id',
    name: VIEW.CAMPAIGNS_FOLDERS,
    component: Campaigns,
    meta: { ...default_meta, ...{ show_sidebar: true, view: VIEW.CAMPAIGNS } },
  },
  {
    path: '/c/:campaign_id',
    name: VIEW.CREATIVES,
    component: Creatives,
    meta: { ...default_meta, ...{ show_sidebar: true, view: VIEW.CREATIVES } },
  },
  {
    path: '/c/:campaign_id/f/:folder_id',
    name: VIEW.CREATIVES_FOLDERS,
    component: Creatives,
    meta: { ...default_meta, ...{ show_sidebar: true, view: VIEW.CREATIVES } },
  },
  {
    path: '/c/:campaign_id/:creative_id',
    name: VIEW.CREATIVE,
    component: Creative,
    meta: { ...default_meta, ...{ show_sidebar: true, view: VIEW.CREATIVE, sdk_client: true } },
  },
  {
    path: '/f/:folder_id/:creative_id',
    name: VIEW.CREATIVE_FOLDER,
    component: Creative,
    meta: { ...default_meta, ...{ show_sidebar: true, view: VIEW.CREATIVE } },
  },
  {
    path: '/c/:campaign_id/flights/:flight_id',
    name: VIEW.FLIGHT,
    component: Flight,
    meta: { ...default_meta, ...{ show_sidebar: true, view: VIEW.FLIGHT } },
  },
  {
    path: '/create',
    name: 'legacy-creatives',
    redirect: to => {
      if (to.query.cid != null) {
        return '/c/' + to.query.cid;
      }
      return '/';
    },
  },
  {
    path: '/s',
    name: VIEW.SEARCH,
    component: SearchView,
    meta: { ...default_meta, ...{ show_sidebar: true, view: VIEW.SEARCH } },
  },
  {
    path: `/${VIEW.PROFILE}`,
    name: VIEW.PROFILE,
    component: Profile,
    meta: { ...default_meta, ...{ show_sidebar: true, view: VIEW.PROFILE } },
  },
  {
    path: `/${VIEW.ORGANIZATION}/:group_id?`,
    alias: '/g/:group_id?',
    name: VIEW.ORGANIZATION,
    component: Group,
    meta: { ...default_meta, ...{ show_sidebar: true, view: VIEW.PROFILE } },
  },
  {
    path: '/CLICKURL',
    name: 'CLICKURL',
    component: ClickOutPage,
    meta: { ...default_meta },
  },
  {
    path: '/home',
    name: VIEW.DASHBOARD,
    component: Dashboard,
    meta: { ...default_meta, ...{ show_sidebar: true, view: VIEW.DASHBOARD } },
  },
  {
    path: '/adcreator',
    name: VIEW.AD_CREATOR,
    component: AdCreator,
    meta: { ...default_meta, ...{ show_sidebar: true, view: VIEW.AD_CREATOR, sdk_client: true } },
  },
  {
    path: '/sdk/c/:campaign_id',
    name: VIEW.SDK_CREATE,
    component: SDKNewCreative,
    meta: { ...default_meta, ...{ view: VIEW.SDK_CREATE, sdk_client: true } },
  },
  {
    path: '/duplicate/:creative_id',
    name: VIEW.DUPLICATE,
    component: Campaigns,
    meta: { ...default_meta, ...{ show_sidebar: true, view: VIEW.CAMPAIGNS, sdk_client: false } },
  },
  {
    path: '*',
    redirect: { name: VIEW.DASHBOARD },
  },
];
