<template lang="pug">
div.campaign-card-icons(:class="{'hidden': is_new}")
  i.nexd-icon-32-duplicate(aria-hidden="true" v-tooltip="{value: 'Duplicate'}" @click="duplicateCampaign")
  template(v-if="can_update")
    i.nexd-icon-32-unarchive(v-if="campaign.status === 8" aria-hidden="true" v-tooltip="{value: 'Unarchive'}" @click="unarchiveCampaign")
    i.nexd-icon-32-archive1(v-else aria-hidden="true" v-tooltip="{value: 'Archive'}" @click="archiveCampaign")
  i.nexd-icon-32-tag(v-if="can_download_tag" aria-hidden="true" v-tooltip="{value: 'Download tag'}" @click="downloadTag")
  i.nexd-icon-32-delete(v-if="campaigns_view && can_delete" aria-hidden="true" v-tooltip="{value: 'Delete'}" @click="deleteCampaign")
  i.nexd-icon-32-preview(v-if="has_creatives" aria-hidden="true" v-tooltip="{value: 'Preview'}" @click="previewCampaign")
  LoadingModal(v-if="show_loading" head="Duplicating campaign..." body="Please wait while we copy your campaign settings and files to a new campaign. This may take some time ...")
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import CampaignMixins from '@cm/Views/Campaigns/Campaign/CampaignMixins.vue';
import CampaignService from '@master/Services/CampaignService';

import LoadingModal from '@master/UI/LoadingModal.vue';

export default {
  name: 'CampaignSiderbarIcons',
  mixins: [clickaway, CampaignMixins],
  components: {
    LoadingModal,
  },

  computed: {
    campaigns_view() {
      return this.$route.name === 'campaigns';
    },
  },

  props: {
    campaign: {
      type: Object,
    },
  },

  data() {
    return {
      show_loading: false,
    };
  },

  methods: {
    async deleteCampaign() {
      if (!this.can_delete) return;

      if (await this.$confirm('Are you sure you want to delete this campaign?')) {
        this.$http.delete(`campaigns/${this.campaign.campaign_id}`).then(() => {
          CampaignService.removeItems([this.campaign.campaign_id]);
        });
      }
    },

    downloadTag() {
      if (!this.can_download_tag) return;
      this.$http.get(`campaigns/${this.campaign.campaign_id}/tag`).then(() => {
        /** downlaod handled by dataservice */
      });
    },

    archiveCampaign() {
      this.$http.post(`campaigns/${this.campaign.campaign_id}/archive`).then(response => {
        CampaignService.updateItems([response]);
      });
    },

    unarchiveCampaign() {
      this.$http.post(`campaigns/${this.campaign.campaign_id}/unarchive`).then(response => {
        CampaignService.updateItems([response]);
      });
    },

    async duplicateCampaign() {
      if (await this.$confirm('Are you sure you want to duplicate this campaign and all its creatives?')) {
        this.show_loading = true;

        this.$http.post('campaigns/duplicate', { campaign_id: this.campaign.campaign_id }).then(campaign => {
          CampaignService.addItem(campaign);

          setTimeout(() => {
            this.show_loading = false;
            CampaignService.active.set(null);
          }, 1000);
        });
      }
    },

    previewCampaign() {
      if (!this.campaign.preview_url) return;
      window.open(this.campaign.preview_url, '_blank');
    },
  },
};
</script>
