<template lang="pug">
SlotContainer(
  :asset="asset"
  :draggable="enable_slot_dragging"
  :empty="empty"
  :additional="additional"
  :loading_text="loading_text"
  @drop="drop"
)
  template(v-slot:input)
    input(type="file" ref="asset-input" :accept="file_input_accept_extensions" @change="uploadFile")

  template(v-slot:thumbnail)
    Thumbnail(
      v-if="is_map"
      bg="unset"
      :asset="{image_uri: creative?.assets?.[default_asset_id] == null ? map_asset_icon : map_asset_uploaded_icon }"
    )
    Thumbnail(
      v-else-if="!empty"
      :asset="visible_active_asset"
      @mousedown="enableSlotDragging"
      @mouseup="disableSlotDragging"
    )
    Thumbnail(v-else)

  template(v-slot:first_row)
    input(
      v-if="additional"
      ref="asset-rename"
      :value="asset.name"
      @focusout="endAssetRenaming"
      @keydown.enter="endAssetRenaming"
      @keydown.esc="resetAssetRenaming"
    )
    div.fs-14.fw-500(v-else) {{ template_asset_name }}&nbsp;
      span.color-danger(v-if="asset?.required === 1") *&nbsp;
      i.nexd-icon-16-help(v-if="asset?.description != null" v-tooltip="{value: asset.description}" aria-hidden="true")

  template(v-slot:icon)
    i.nexd-icon-32-upload(v-if="empty" aria-hidden="true" @click="selectFile")
    i.nexd-icon-32-help-big(v-else-if="!is_map" aria-hidden="true" v-tooltip="{value: accepted_info_tooltip}")

  template(v-slot:second_row v-if="sizeset == null && has_size_specific_assets")
    i.nexd-icon-16-arrow-up(aria-hidden="true" @click="previousFromCollection")
    span
      span {{ counter + 1 }}&nbsp;
      span / {{ responsive_collection.length + 1 }}
    i.nexd-icon-16-arrow-up(aria-hidden="true" @click="nextFromCollection")

  template(v-slot:third_row)
    SlotTextRow(v-if="empty || is_map" title="Accepted files" :text="file_input_accept_extensions")
    SlotTextRow(v-else title="File size" :text="visible_active_asset.filesize | FileSize")

  template(v-slot:fourth_row)
    SlotTextRow(v-if="is_map" href="https://cdn.nexd.com/dist/support-site/map_locations.csv" text="Download locations template")
    SlotTextRow(v-else-if="empty" title="Size" :text="asset_recommended_size.text")
    SlotTextRow(
      v-else
      :key="asset_name"
      title="File name"
      :text="asset_name"
      v-tooltip="asset_name?.length >= 20 ? {value: asset_name} : null"
    )

  template(v-slot:icons)
    i.nexd-icon-16-counter-clockwise(
      v-if="(has_size_specific_assets && counter > 0) || (sizeset != null && !use_alternative_asset && creative?.assets?.[real_asset_id] != null)"
      v-tooltip="{value: 'Reset to Global'}"
      aria-hidden="true"
      @click="removeAsset(active_collection_responsive_asset_id != null ? active_collection_responsive_asset_id : null, 'clear_asset')"
    )
    i.nexd-icon-16-upload(
      v-if="showIcon('upload_new')"
      v-tooltip="{value: `Upload new${tooltip_suffix}`}"
      aria-hidden="true"
      @click="selectFile"
    )
    i.nexd-icon-16-duplicate(
      v-if="is_hotspot"
      v-tooltip="{value: `Duplicate${tooltip_suffix}`}"
      aria-hidden="true"
      @click="duplicate"
    )
    i.nexd-icon-16-cog(
      v-if="!is_map && showIcon('upload_new')"
      v-tooltip="{value: `Change${tooltip_suffix} optimization`}"
      aria-hidden="true"
      @click="editAssetOptimization(active_collection_responsive_asset_id != null ? active_collection_responsive_asset_id : null)"
    )
    i.nexd-icon-16-edit(
      v-if="is_map"
      v-tooltip="{value: `Edit${tooltip_suffix}`}"
      aria-hidden="true"
      @click="mapSlotEditHandler"
    )
    i.nexd-icon-16-edit(
      v-else-if="showIcon('edit')"
      v-tooltip="{value: `Edit${tooltip_suffix}`}"
      aria-hidden="true"
      @click="editAsset"
    )
    i.nexd-icon-16-drag-and-drop(
      v-if="is_swap_enabled"
      v-tooltip="{value: 'Drag and drop'}"
      aria-hidden="true"
      @mousedown="enableSlotDragging"
      @mouseup="disableSlotDragging"
    )
    i.nexd-icon-16-delete(
      v-if="showIcon('delete')"
      v-tooltip="{value: additional ? `Clear${tooltip_suffix}` : `Remove${tooltip_suffix}`}"
      aria-hidden="true"
      @click="deleteAsset"
    )

  template(v-slot:settings)
    div(v-if="additional" :class="styles.remove_slot" @click="removeAsset()")
      i.nexd-icon-16-close-small(aria-hidden="true" v-tooltip="{value: 'Delete additional slot'}")

    SettingsAsset(
      v-if="has_settings"
      :creative="creative"
      :asset_id="default_asset_id"
      :crop_settings="cropper_options"
      :arno="arno"
      @change="componentChangeHandler"
    )
    SettingsAnimation(
      v-if="!empty && is_overlay && timeline && !isVast()"
      :animations="timeline.get(asset_id)"
    )
    SettingsMap(
      v-if="is_map"
      :creative="creative"
      :asset_id="default_asset_id"
      @change="componentChangeHandler(false)"
    )
    AssetDebug(
      v-if="admin_toggle"
      :asset="asset"
      :recommended="asset_recommended_size"
      :options="cropper_options"
      :uploaded="visible_active_asset"
    )
    AssetOptimizeModal(
      v-if="optimize_target != null"
      :creative="creative"
      :target="optimize_target"
      :asset="asset"
      @save="saveAssetOptimization"
      @cancel="closeAssetOptimization"
    )
    MapCSVModal(
      v-if="show_map_csv_modal"
      :creative="creative"
      :asset="asset"
      :input="map_slot_data"
      @drop="mapSlotFileChangeHandler"
      @change="componentChangeHandler(false)"
      @close="closeMapCSVModal"
    )
</template>

<script>
import styles from './Slot.module.scss';
import SlotContainer from './SlotContainer.vue';
import SlotMixins from './SlotMixins.vue';
import SlotTextRow from './SlotTextRow.vue';

import CropperMixins from '@master/Mixins/CropperMixins.vue';
import { ASSET } from '@master/constants';

import { searchLongStringsAndBreak } from '@helpers/Global';
import AssetHelper from '@helpers/Asset';
import Upload from '@helpers/Upload';

import AssetOptimizeModal from '@master/UI/AssetOptimizeModal.vue';

import AssetDebug from '@cm/Views/Creatives/Cards/Components/Asset/Debug.vue';
import Thumbnail from '@master/UI/Thumbnail/Thumbnail.vue';
import MapCSVModal from '@cm/Views/Creatives/Modals/MapCSVModal.vue';
import SettingsAsset from '@cm/Views/Creatives/Cards/Components/Asset/Settings/SettingsAsset.vue';
import SettingsAnimation from '@cm/Views/Creatives/Cards/Components/Asset/Settings/SettingsAnimation.vue';
import SettingsMap from '@cm/Views/Creatives/Cards/Components/Asset/Settings/SettingsMap.vue';

import Timeline from '@cm/Views/Creatives/Cards/Components/Asset/Animations/Timeline';

export default {
  name: 'AssetSlot',
  mixins: [SlotMixins, CropperMixins],

  components: {
    SlotContainer,
    SlotTextRow,

    AssetDebug,
    AssetOptimizeModal,
    Thumbnail,
    MapCSVModal,
    SettingsAsset,
    SettingsAnimation,
    SettingsMap,
  },

  props: {
    template_asset: Object,
    creative: Object,
    arno: Object,

    additional: {
      type: Boolean,
      default: false,
    },

    sizeset: {
      type: String,
      default: null,
    },

    timeline: {
      type: Timeline,
      default: null,
    },
  },

  computed: {
    asset() {
      return this.template_asset;
    },

    asset_id() {
      return this.asset?.asset_id;
    },

    template_asset_name() {
      let name = this.asset?.name;

      if (this.sizeset == null && this.has_size_specific_assets) {
        if (this.counter === 0) {
          name = `Global ${name}`;
        } else {
          name = `${this.active_collection_responsive_asset_id.split('_')[1]} ${name}`;
        }
      }

      return name;
    },

    asset_name() {
      if (this.visible_active_asset?.original_name != null) {
        return searchLongStringsAndBreak(this.visible_active_asset?.original_name, 20);
      }

      return null;
    },

    sizeset_asset_id() {
      return this.sizeset == null ? null : `${this.asset_id}_${this.sizeset}`;
    },

    default_asset_id() {
      if (this.creative?.assets?.[this.sizeset_asset_id] != null) {
        return this.sizeset_asset_id;
      }

      return this.asset_id;
    },

    real_asset_id() {
      return this.sizeset_asset_id ?? this.asset_id;
    },

    use_alternative_asset() {
      return this.sizeset != null && this.asset.alternative != null && this.creative.assets[this.default_asset_id] == null && this.creative.assets[this.asset.alternative.asset_id] != null;
    },

    responsive_collection() {
      let collection = [];
      for (const asset_id in this.creative.assets) {
        if (this.asset.asset_id !== asset_id && asset_id.includes(this.asset.asset_id) && this.creative.assets[asset_id].image_uri != null) {
          collection.push(this.creative.assets[asset_id]);
        }
      }
      return collection;
    },

    active_collection_responsive_asset_id() {
      if (this.sizeset == null && this.responsive_collection?.[this.counter - 1] != null) {
        return this.responsive_collection[this.counter - 1].asset_id;
      }
      return null;
    },

    force_responsive_overlay() {
      // forcing overlays are only for quantum size specific views
      if (this.sizeset != null || this.active_collection_responsive_asset_id != null) {
        // only cta type of assets or single panorama overlay main asset will be forced into overlay position
        return (this.isOverlay() || this.isResponsivePanoramaOverlay(this.asset_id)) && this.asset.position === 'relative';
      }
      return false;
    },

    visible_active_asset() {
      let asset_id = this.default_asset_id;

      if (this.active_collection_responsive_asset_id != null) {
        // if responsive overview has selection, use this as active asset
        asset_id = this.active_collection_responsive_asset_id;
      } else if (this.use_alternative_asset) {
        // if we have alternative asset to show in the slot, use this
        asset_id = this.asset.alternative.asset_id;
      }

      return this.creative?.assets?.[asset_id] ?? this.creative?.additional_assets?.[asset_id] ?? null;
    },

    cropper_options() {
      let options = {
        grid: 9,
        clip: {
          horizontal: false,
          vertical: false,
        },
        cropSize: {
          width: 0,
          height: 0,
        },
        anchor: 'center',
        position: this.asset.position || 'relative',
        asset_name: this.asset.name,
      };

      if (this.force_responsive_overlay || AssetHelper.isOverlay(this.asset)) {
        options.clip.horizontal = true;
        options.clip.vertical = true;
        options.grid = 16;

        // overlays are always absolute, will overwrite the custom logo and cta relative position for quantums as well
        options.position = 'absolute';

        if (this.asset.default_position != null) {
          if (this.asset.default_position === 'top') {
            options.reset_transform = { unit: '%', t: -1 };
          } else if (this.asset.default_position === 'bottom') {
            options.reset_transform = { unit: '%', b: 1 };
          } else {
            options.reset_behaviour = this.asset.default_position;
          }
        }

        if (this.sizeset != null) {
          // not sure why DB holds default position for responsive assets, but removing the ransform default, because they are being set by the sizeset specific rules
          options.reset_transform = null;
          options.reset_behaviour = null;
          const responsive_type = AssetHelper.getResponsiveType(this.cropper_options_additions.placement.size.width, this.cropper_options_additions.placement.size.height);

          options.responsive_type = responsive_type;

          // cta / logo size for 2 & 0 = full width, logo center-top cta center-bottom
          // cta / logo size for 3 = 1/3 width, logo left-center, cta right-center
          // cta / logo size for 1 = 1/3 width, logo left-top cta left-bottom
          if (responsive_type === 1) {
            if (this.is_logo) {
              options.reset_behaviour = ['third'];
              options.reset_transform = { unit: '%', t: -1, l: -1 };
            } else if (this.is_cta) {
              options.reset_behaviour = ['third'];
              options.reset_transform = { unit: '%', b: 1, l: -1 };
            } else if (this.is_responsive_panorama_overlay) {
              options.reset_behaviour = ['fit'];
              options.reset_transform = { unit: '%', l: -1 };
            }
          } else if (responsive_type === 3) {
            if (this.is_logo) {
              options.reset_behaviour = ['third'];
              options.reset_transform = { unit: '%', l: -1 };
            } else if (this.is_cta) {
              options.reset_behaviour = ['third'];
              options.reset_transform = { unit: '%', r: 1 };
            } else if (this.is_responsive_panorama_overlay) {
              options.reset_behaviour = ['fit'];
            }
          } else if (this.is_logo) {
            options.reset_behaviour = ['contain'];
            options.reset_transform = { unit: '%', t: -1 };
          } else if (this.is_cta) {
            options.reset_behaviour = ['contain'];
            options.reset_transform = { unit: '%', b: 1 };
          } else if (this.is_responsive_panorama_overlay) {
            options.reset_behaviour = ['fit'];
          }
        }
      } else if (this.creative?.template?.template_base === 'scrollToZoom') {
        // special scroll to zoom
        options.clip.horizontal = true;
        options.clip.vertical = true;
      }

      if (this.sizeset != null && this.isBackground(this.asset?.type)) {
        options.scaling_behaviour = 'cover';
      }

      return { ...options, ...this.cropper_options_additions };
    },
  },

  data() {
    return {
      styles,

      admin_toggle: false,
      is_new: false,
      loading_text: null,
      accepted_mimes: '',
      file_input_accept_extensions: '',
      cropper_options_additions: {},
      convert_upload_data: null,
      library_upload_data: null,
      asset_recommended_size: {
        text: 'error finding suggested size',
        size: {
          width: 0,
          height: 0,
        },
      },
      map_slot_data: null,
      optimize_target: null,
      counter: 0,
      disable_duplicate: false,
    };
  },

  created() {
    this.$user.subscribe(user => {
      if (user != null) {
        this.admin_toggle = this.$user.adminMode();
      }

      this.generateMimes();
    }, this);
  },

  methods: {
    generateMimes() {
      const accepted_mimes = AssetHelper.getAcceptedMimes(
        this.asset.filename,
        this.is_google_ads || this.is_video_endcard, // exclude_video
        this.asset.type === ASSET.SPLASH, // exact
      );

      if (this.is_map) {
        this.accepted_mimes = accepted_mimes.other;
        this.file_input_accept_extensions = AssetHelper.mimeToExtension(this.accepted_mimes);
        return;
      }

      this.accepted_mimes = [accepted_mimes.image, accepted_mimes.video].filter(Boolean).join(', ');

      this.file_input_accept_extensions = [AssetHelper.mimeToExtension(accepted_mimes.image), AssetHelper.mimeToExtension(accepted_mimes.video)].filter(Boolean).join(', ');

      this.getRecommendedDimensions();
    },

    getRecommendedDimensions() {
      let placement_width = this.creative.width;
      let placement_height = this.creative.height;
      let force_width = null;
      let force_height = null;

      if (this.active_collection_responsive_asset_id != null || this.sizeset != null) {
        if (this.sizeset != null) {
          [placement_width, placement_height] = this.sizeset.split('x');
        } else if (this.active_collection_responsive_asset_id != null) {
          // abcde_300x250
          const [, sizeset] = this.active_collection_responsive_asset_id.split('_');
          [placement_width, placement_height] = sizeset.split('x');
        }
        placement_width = parseInt(placement_width, 10);
        placement_height = parseInt(placement_height, 10);

        // overwrite asset width and height for background assets (x2 size)
        if (this.isBackground()) {
          force_width = `=${placement_width * 2}`;
          force_height = `=${placement_height * 2}`;
        }
      }

      // real dimensions for the asset cropping
      let sizes;

      if (this.can_use_arno && !this.asset.filename.includes('ad_splash')) {
        try {
          sizes = AssetHelper.getSizeFromArno(this.arno, AssetHelper.getTemplateAssetForArno(this.asset), this.creative);
        } catch (e) {
          // supress error thrown
          // use case -> user opened 3 year old "MVP" version of splitscreen carousel
          // if this function exits, the UI will have error in slot saying "cannot find suggested size" which is fine ig
          return;
        }
      } else {
        // some small hacks for handling additional asset sizes
        if (this.additional && this.asset.custom_type != null) {
          if (this.asset.custom_type === ASSET.OVERLAY) {
            // additional overlays will always have <100x<100 size
            force_width = '<100';
            force_height = '<100';
          } else if (this.asset.custom_type === ASSET.NORMAL) {
            // because getSize function takes additional asset object for additionals, it doesnt have template asset size description
            // set force sizes that are set prior in AssetsTabAdditionalFeed by the 1st feed asset
            force_width = this.asset.width;
            force_height = this.asset.height;
          }
        }

        sizes = AssetHelper.getSize(this.creative, this.asset.asset_id, {
          force_overlay: this.force_responsive_overlay,
          placement_width,
          placement_height,
          force_width,
          force_height,
        });
      }

      const is_panorama = sizes.panorama;

      this.$set(this.cropper_options_additions, 'cropSize', sizes.dimensions);

      // fix as arno has no size info for overlays
      if (this.can_use_arno && sizes.overlay) {
        this.$set(this.cropper_options_additions, 'cropSize', {
          width: this.creative.width,
          height: this.creative.height,
        });
      }

      this.$set(this.cropper_options_additions, 'scale', sizes.scale);
      this.$set(this.asset_recommended_size, 'size', sizes.recommended);

      if (this.additional && this.asset.custom_type != null && this.asset.custom_type === ASSET.OVERLAY) {
        this.$set(this.asset_recommended_size, 'text', 'Up to placement size');
      } else if (is_panorama) {
        this.$set(this.asset_recommended_size, 'text', `Max: ${parseInt(this.asset_recommended_size.size.width, 10)} x ${parseInt(this.asset_recommended_size.size.height, 10)} px`);
      } else if (this.force_responsive_overlay || AssetHelper.isOverlay(this.asset)) {
        if (this.isFullscreen() && this.asset_recommended_size.size.height >= 854) {
          this.$set(this.asset_recommended_size, 'text', `Max: ${this.asset_recommended_size.size.width} x ${this.asset_recommended_size.size.height} px`);
        } else {
          this.$set(this.asset_recommended_size, 'text', 'Up to placement size');
        }
      } else if (this.video_export_1080) {
        const min_sizes = AssetHelper.getVideoExportMinimumSize(this.creative);
        this.$set(this.asset_recommended_size, 'text', `Min: ${min_sizes.width}x${min_sizes.height} px`);
      } else {
        this.$set(this.asset_recommended_size, 'text', `Min: ${this.asset_recommended_size.size.width}x${this.asset_recommended_size.size.height} px`);
      }

      // add placement layer to cropper depending on the creative size
      // let total_ad_width = placement_width;
      // let total_ad_height = placement_height;
      let placement = {
        width: placement_width,
        height: placement_height,
      };

      // handle cases where placement size is not actually the same what user chooses
      if (this.isMobile()) {
        if (this.isInfeed() || this.isInterscroller()) {
          const ar = placement.height / placement.width;
          placement.width = sizes.total.width;
          placement.height = placement.width * ar;
          placement = AssetHelper.validateDimensions(placement);
        }
      } else if (this.isQuantum()) {
        placement.width = sizes.total.width;
        placement.height = sizes.total.height;
      }

      // tell cropper about where to put the placement layer and how big is the ad itself
      // 90% of cases adsize = placement, but this will handle exceptions for interscroller

      let offset_x;
      let offset_y;

      offset_x = this.asset.x || 0;
      offset_y = this.asset.y || 0;

      // set asset offset from arno if possible
      if (this.can_use_arno && !sizes.overlay) {
        offset_x = sizes.x || 0;
        offset_y = sizes.y || 0;
      }

      const half_asset_width = this.cropper_options_additions.cropSize.width / 2;
      const half_asset_height = this.cropper_options_additions.cropSize.height / 2;

      // special case for asset x and y, if they are -1 or 1, they are positioned on top or bottom (like overlays)
      if (offset_x === 1) {
        offset_x = sizes.total.width / 2 - half_asset_width;
      } else if (offset_x === -1) {
        offset_x = -sizes.total.width / 2 + half_asset_width;
      }

      if (offset_y === 1) {
        offset_y = sizes.total.height / 2 - half_asset_height;
      } else if (offset_y === -1) {
        offset_y = -sizes.total.height / 2 + half_asset_height;
      }

      this.$set(this.cropper_options_additions, 'placement', {
        size: placement,
        adsize: sizes.total,
        offset: {
          x: offset_x,
          y: offset_y,
        },
      });

      this.$set(this.cropper_options_additions, 'wrapper', {
        size: sizes.wrapper,
      });
    },

    drop(e) {
      this.disableSlotDragging();

      if (this.show_map_csv_modal) return;

      const original_data = e.dataTransfer.getData('application/original-asset');

      if (original_data != null && original_data !== '') {
        if (e.dataTransfer.getData('action') === 'slot-swap') {
          this.swapSlots(JSON.parse(original_data));
          return;
        }

        this.library_upload_data = JSON.parse(original_data);

        if (AssetHelper.isAcceptedMime(this.accepted_mimes, this.library_upload_data.mime)) {
          // if responsive has size specific assets and global is being replaced/re-uploaded
          if (this.sizeset == null && this.has_size_specific_assets && this.active_collection_responsive_asset_id == null) {
            this.upload_from_library = true;
            this.uploadFile(e);
          } else {
            this.fromAssetLibrary();
          }
        } else {
          this.notAcceptedError();
        }

        return;
      }

      if (this.$refs['asset-input'] == null) {
        return;
      }

      const file = e.dataTransfer.files[0];
      const file_list = new DataTransfer();

      for (let file_item of e.dataTransfer.files) {
        file_list.items.add(file_item);
      }

      if (file != null && AssetHelper.isAcceptedMime(this.accepted_mimes, file.type, file.name)) {
        if (this.is_map) {
          this.mapSlotFileChangeHandler(file);
          return;
        }

        this.is_new = true;
        this.initCropper();
        this.$refs['asset-input'].files = file_list.files;
        this.$refs['asset-input'].dispatchEvent(new Event('change'));

        return;
      }

      this.notAcceptedError();
    },

    swapSlots(dropped_asset_info) {
      if (dropped_asset_info.asset_id === this.asset.asset_id || !this.is_swap_enabled || !this.can_swap) return;

      const assets = [dropped_asset_info.asset_id, this.asset.asset_id];

      this.$store.set('swapping_asset_ids', assets);

      this.$http
        .put(`creative/${this.creative.creative_id}/assets/swap`, { assets })
        .then(response => {
          // only overwrite returned data that actually is related to the change
          this.$set(this.creative, 'assets', response.assets);
          this.$set(this.creative, 'additional_assets', response.additional_assets);
          this.$set(this.creative.settings, 'cta', response.settings.cta);
          this.$set(this.creative.settings, 'assets', response.settings.assets);
          this.$set(this.creative.settings, 'animations', response.settings.animations);
          if (this.timeline) {
            this.timeline.setup(this.creative.settings.animations?.timeline?.animations);
          }
        })
        .finally(() => {
          this.$store.reset('swapping_asset_ids');
        });
    },

    async inputChangeHandler(e) {
      const file = e.target.files[0];

      if (file == null) {
        return;
      }

      if (this.is_google_ads && AssetHelper.isVideo(file.type)) {
        return this.$alert('Google Ads platform does not allow video in ads from third parties. Please use only images or publish your creative via other platform', 'Google Ads does not allow video in ads');
      }

      this.setLoadingText();

      if (e.converted == null) {
        if (file.type === 'image/gif') {
          this.setLoadingText('Converting GIF');
          this.convertFile(file);
          return;
        } else if (file.type === 'video/quicktime') {
          this.setLoadingText('Converting MOV');
          this.convertFile(file);
          return;
        } else if (AssetHelper.isVideo(file.type)) {
          const valid = await AssetHelper.isVideoSupported(file);
          if (!valid) {
            this.setLoadingText('Converting unsupported video');
            this.convertFile(file);
            return;
          }
        }
      }

      if (!(await AssetHelper.isValidFile(file))) {
        this.removeLoading();
        return;
      }

      this.setLoadingText('Opening cropper');
      if (!(await this.Cropper.inputChangeHandler(e))) {
        // if cropper.init returns false, it means there was video length error
        this.removeLoading();
      }
    },

    convertFile(file) {
      const handler = new Upload(file);

      handler.upload(({ done, info, error, msg }) => {
        if (msg != null) {
          this.setLoadingText(msg);
        }

        if (done) {
          this.removeLoading();
          this.convert_upload_data = info;
          this.getMainAssetPlacement();
          this.initCropper();

          const lazyload = {
            width: this.convert_upload_data.width,
            height: this.convert_upload_data.height,
          };
          this.Cropper.newFromURL(this.convert_upload_data.url, lazyload);
        }

        if (error?.message != null) {
          this.removeLoading();
          this.$alert(error.message);
        }
      });
    },

    fromAssetLibrary() {
      this.is_new = true;

      if (this.is_map) {
        this.mapSlotFileChangeHandler(this.library_upload_data);
        return;
      }

      this.getMainAssetPlacement();
      this.setLoadingText('Opening cropper');
      this.initCropper();

      const lazyload = {
        width: this.library_upload_data.width,
        height: this.library_upload_data.height,
        image: this.library_upload_data.image_uri,
      };
      this.Cropper.newFromURL(this.library_upload_data.uri, lazyload);
    },

    selectFile() {
      this.is_new = true;

      if (this.is_map) {
        this.map_slot_data = null;
        this.openMapCSVModal();
        return;
      }

      this.getMainAssetPlacement();
      this.initCropper();
      this.$refs?.['asset-input']?.click();
    },

    saveAsset(notification = true, reload_settings = false) {
      let save_asset_id = this.real_asset_id;
      if (this.active_collection_responsive_asset_id != null) {
        save_asset_id = this.active_collection_responsive_asset_id;
      }

      const settings = this.getCropperSettings();
      this.setLoadingText('Optimizing');

      this.$http
        .put(`creatives/${this.creative.creative_id}/assets/${save_asset_id}`, { crop: true, settings }, { notification })
        .then(response => {
          // map response values for asset
          const selected_asset = this.creative.assets[save_asset_id] || this.creative.additional_assets[save_asset_id];
          if (selected_asset != null) {
            for (const key in response) {
              this.$set(selected_asset, key, response[key]);
            }
          }
        })
        .finally(() => {
          this.removeLoading();

          // reload asset and CTA settings
          if (reload_settings) {
            this.reloadSettings();
          }

          this.loadAssetLibrary();
        });
    },

    deleteAsset() {
      if (this.additional || this.sizeset != null) {
        this.clearAsset(this.active_collection_responsive_asset_id);
      } else {
        this.removeAsset(this.active_collection_responsive_asset_id);
      }
    },

    async removeAsset(new_id = null, action = null, confirm_remove = true) {
      if (action == null && confirm_remove) {
        if (!(await this.assetInUseConfirmation())) {
          return;
        }
      }

      const removable_asset_id = new_id ?? this.real_asset_id;

      // mark assets to be deleted, but dont let the componetns be removed
      // doing this before the request, so we can disable the buttons to disable deleting 2ce in a row
      if (this.additional && this.creative?.additional_assets?.[removable_asset_id]) {
        this.$set(this.creative.additional_assets[removable_asset_id], '_deleted', true);
      } else if (this.creative?.assets?.[removable_asset_id]) {
        this.$set(this.creative.assets[removable_asset_id], '_deleted', true);
      }

      // make the real request to remove the asset
      this.$http.delete(`creative/${this.creative.creative_id}/assets/${removable_asset_id}`).then(response => {
        if (this.counter > 1) {
          this.counter--;
        }

        // execute real delete from UI objects
        if (this.additional) {
          this.$delete(this.creative.additional_assets, removable_asset_id);
        } else {
          this.$delete(this.creative.assets, removable_asset_id);
        }

        this.removeHotspotAdditionalAssets(removable_asset_id);
        this.loadAssetLibrary();

        if (response.has_video != null) {
          this.$set(this.creative, 'has_video', response.has_video);
        }

        if (this.creative?.settings?.assets.hasOwnProperty(removable_asset_id)) {
          this.$delete(this.creative.settings.assets, removable_asset_id);
        }
      });
    },

    async clearAsset(new_id = null) {
      if (!(await this.assetInUseConfirmation())) {
        return;
      }

      const clear_asset_id = new_id ?? this.real_asset_id;

      this.$http
        .put(`creatives/${this.creative.creative_id}/assets/${clear_asset_id}/clear`)
        .then(response => {
          if (this.additional) {
            this.$set(this.creative.additional_assets, clear_asset_id, response);
          } else {
            this.$set(this.creative.assets, clear_asset_id, response);
          }

          if (this.counter > 1) {
            this.counter--;
          }

          this.removeHotspotAdditionalAssets(clear_asset_id);
          this.loadAssetLibrary();

          if (this.creative?.settings?.assets.hasOwnProperty(clear_asset_id)) {
            this.$delete(this.creative.settings.assets, clear_asset_id);
          }
        })
        .finally(_ => {
          // just because we cannot modify the clear endpoint (because of DSP app using it)
          // and theres no reason to make v2 clear (but might?)
          // just call simple creative get to get the has_video param update
          this.$http.get(`v2/creative/${this.creative.creative_id}`).then(response => {
            if (response.has_video != null) {
              this.$set(this.creative, 'has_video', response.has_video);
            }
          });
        });
    },

    renameAsset() {
      let rename = this.real_asset_id;
      if (this.active_collection_responsive_asset_id != null) {
        rename = this.active_collection_responsive_asset_id;
      }

      this.setLoadingText('Saving');
      this.$http
        .put(`creatives/${this.creative.creative_id}/assets/${rename}`, {
          name: this.asset.name,
        })
        .then(() => {
          this.componentChangeHandler(true);
        })
        .finally(() => {
          this.removeLoading();
        });
    },

    editAsset() {
      this.is_new = false;

      let selected_asset = null;
      if (this.use_alternative_asset) {
        selected_asset = this.creative.assets[this.asset.alternative.asset_id];
      } else {
        selected_asset = this.creative.assets[this.default_asset_id] || this.creative.additional_assets[this.default_asset_id];
      }

      if (this.active_collection_responsive_asset_id != null) {
        selected_asset = this.creative.assets[this.active_collection_responsive_asset_id];
      }

      if (selected_asset == null) return;

      this.setLoadingText('Opening cropper');
      this.getMainAssetPlacement();
      this.initCropper();

      let settings = selected_asset.settings;

      // if this responsive asset is not in the list, use no initial setitngs so cropepr would act like it would be the new asset
      if ((this.isOverlay() || this.isResponsivePanoramaOverlay() || this.isBackground()) && this.creative.assets[this.real_asset_id] == null) {
        settings = null;
      }

      const lazyload = {
        width: selected_asset.original_width,
        height: selected_asset.original_height,
        image: selected_asset.original_image_uri,
      };
      this.Cropper.editAsset(selected_asset.original_url, settings, lazyload);

      if (this.default_asset_id !== this.real_asset_id) {
        this.is_new = true;
        let original_asset_id = null;
        if (this.use_alternative_asset) {
          original_asset_id = this.creative.assets[this.asset.alternative.asset_id].original_asset_id;
        } else {
          original_asset_id = this.creative.assets[this.asset.asset_id].original_asset_id;
        }

        // make it like it would be asset lib upload, so it would act like a new file added
        this.library_upload_data = {
          original_asset_id,
        };
      }
    },

    handleLibraryUpload(asset, start = false) {
      if (start) {
        this.setLoadingText('Uploading');
      } else {
        this.library_upload_data = asset;
        this.uploadFromLibrary();
      }
    },

    uploadFromLibrary() {
      if (!this.library_upload_data?.original_asset_id) return;

      this.setLoadingText('Uploading');

      this.upload(
        {
          original_asset_id: this.library_upload_data.original_asset_id,
        },
        {
          type: this.library_upload_data.mime,
          width: this.library_upload_data.width,
          height: this.library_upload_data.height,
        },
      );
    },

    async uploadFile(e) {
      if (this.sizeset == null && this.has_size_specific_assets && this.active_collection_responsive_asset_id == null) {
        const choose_action = await this.confirmGlobalAssetChange();

        if (choose_action == null) return;

        if (choose_action === 'all') {
          for (const asset of this.responsive_collection) {
            this.removeAsset(asset.asset_id, null, false);
          }
        }

        if (this.upload_from_library) {
          this.fromAssetLibrary();
        } else {
          this.inputChangeHandler(e);
        }

        return;
      }

      this.inputChangeHandler(e);
    },

    upload(obj) {
      // if done via new upload, use text finalizing while files are being moved and hide percentage
      // especially for larger vid files thay will stay at 100% for a long time
      if (obj.upload_id != null) {
        this.setLoadingText('Finalizing');
      }

      let options = {
        notification: true,
      };

      // disable upload notification when uploading library asset
      if (this.library_upload_data != null) {
        options.notification = 'error';
      }

      let upload_asset_id = this.real_asset_id;
      if (this.active_collection_responsive_asset_id != null) {
        upload_asset_id = this.active_collection_responsive_asset_id;
      }

      this.$http
        .post(`creatives/${this.creative.creative_id}/assets/${upload_asset_id}`, obj, options)
        .then(response => {
          if (this.additional) {
            this.$set(this.creative.additional_assets, response.asset_id, response);
          } else {
            this.$set(this.creative.assets, response.asset_id, response);
          }

          if (AssetHelper.isVideo(response.uri)) {
            this.$set(this.creative, 'has_video', true);
          }

          // after upload, also save asset to crop it
          this.saveAsset(true, true);
        })
        .catch(_ => {
          // set upload to null only on catch
          // because this value will be re-assigned on saveAsset function inside .then pipe
          this.removeLoading();
        })
        .finally(_ => {
          this.library_upload_data = null;
          this.resetConvertUploadData();
        });
    },

    async mapSlotFileChangeHandler(file) {
      this.closeMapCSVModal();
      await this.$nextTick();
      this.map_slot_data = {
        new: file,
      };
      this.openMapCSVModal();
    },

    mapSlotEditHandler() {
      this.map_slot_data = {
        edit: {
          library_id: this.creative.assets_library_id,
          original_asset_id: this.creative.assets[this.default_asset_id].original_asset_id,
        },
      };
      this.openMapCSVModal();
    },

    editAssetOptimization(asset_id = null) {
      let optimizable_asset_id = this.real_asset_id;
      if (asset_id != null) {
        optimizable_asset_id = asset_id;
      }

      this.setLoadingText('Opening optimizer');
      // try to set target as optimized asset from assets or additionals, fallback final would be the original (global for responsives)

      let target = this.creative.assets[optimizable_asset_id] || this.creative.additional_assets[optimizable_asset_id];

      // if no real target found, but is responsive specific sizeset view
      if (target == null && this.sizeset != null) {
        // use global asset as target
        target = this.creative.assets[this.asset.asset_id];
        // overwrite the global asset obj id with the size specific view, so the backend would know its responsive asset
        target.asset_id = optimizable_asset_id;
      }
      this.optimize_target = target;
    },

    saveAssetOptimization() {
      this.removeLoading();
      this.closeAssetOptimization();
      this.componentChangeHandler(true);
    },

    closeAssetOptimization() {
      this.removeLoading();
      this.optimize_target = null;
    },

    nextFromCollection() {
      if (this.counter < this.responsive_collection.length) {
        this.counter++;
      } else if (this.counter === this.responsive_collection.length) {
        this.counter = 0;
      }
      this.getRecommendedDimensions();
    },

    previousFromCollection() {
      if (this.counter > 0) {
        this.counter--;
      } else if (this.counter === 0) {
        this.counter = this.responsive_collection.length;
      }
      this.getRecommendedDimensions();
    },

    endAssetRenaming(e) {
      if (e.target.value.trim().length === 0) {
        return this.resetAssetRenaming();
      }

      if (this.asset.name === this.$refs['asset-rename'].value) {
        return;
      }

      this.asset.name = this.$refs['asset-rename'].value;
      this.renameAsset();
    },

    resetAssetRenaming() {
      this.$refs['asset-rename'].value = this.asset.name;
    },

    removeLoading() {
      this.loading_text = null;

      if (this.$refs?.['asset-input']?.value != null) {
        this.$refs['asset-input'].value = '';
      }
    },

    setLoadingText(text = 'Processing') {
      this.loading_text = text;
    },

    resetConvertUploadData() {
      this.convert_upload_data = null;
    },

    duplicate() {
      if (!this.is_hotspot || this.creative?.creative_id == null || this.disable_duplicate) return;

      this.disable_duplicate = true;

      this.$http
        .post(`creatives/${this.creative.creative_id}/assets/${this.real_asset_id}/duplicate`)
        .then(response => {
          for (const asset_id in response) {
            this.$set(this.creative.additional_assets, asset_id, response[asset_id]);
          }
          this.reloadSettings();
        })
        .catch(() => {
          /** errors handled by service */
        })
        .finally(() => {
          this.disable_duplicate = false;
        });
    },

    removeHotspotAdditionalAssets(asset_id) {
      if (!AssetHelper.isCustomHotspotMain(asset_id)) {
        return;
      }

      const close = asset_id.replace('_main', '_close');
      const pin = asset_id.replace('_main', '_pin');

      for (const id of [close, pin]) {
        if (id in this.creative?.additional_assets) {
          this.$delete(this.creative.additional_assets, id);
        }
      }
    },
  },

  watch: {
    'creative.dsp': {
      handler() {
        this.generateMimes();
      },
    },
    'creative.settings.cta.enabled': {
      handler() {
        this.getRecommendedDimensions();
      },
    },
    'creative.settings.floating': {
      handler() {
        this.getRecommendedDimensions();
      },
    },
    'creative.height': {
      handler() {
        this.getRecommendedDimensions();
      },
    },
    'creative.width': {
      handler() {
        this.getRecommendedDimensions();
      },
    },
  },
};
</script>
