<template lang="pug">
div(:class="styles.empty_group_assets_library")
  img.mb-16(:src="`${$cdn}dist/assets/empty-team-library.svg`")
  strong Collect your assets here for your whole team to easily access while making new creatives
  span
    | You can just drag and drop your files here or&nbsp;
    Button(type="link" label="choose files" :text="true" :disabled="disabled" @click="$emit('choose')")
</template>

<script>
import styles from './GroupAssetsComponents.module.scss';

import Button from '@master/UI/Buttons/Button.vue';

export default {
  name: 'EmptyGroupAssetsLibrary',

  components: {
    Button,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      styles,
    };
  },
};
</script>
