<template lang="pug">
div(ref="modal")
  div.modal
    div.backdrop
    div.modal-content
      div.modal-head
        div.modal-title Import flight data

      div.modal-body
        div.mb-8.color-gray-800 Automate creating a Flight by importing rules from a spreadsheet.&nbsp;
          a(href="http://support.nexd.com/en/articles/8321040-release-notes-flights-layout-updates-lightbox-september-2023" target="_blank") Read more
        div.mb-16
          a(href="https://specs.nexd.com/import/Flight_template.xlsx" download) Download spreadsheet template
        label.lh-32 Upload a filled Excel or CSV template
        div
          div.flex.flex-align-center.flex-justify-center(v-if="show_loading" style="min-height: 200px;")
            Loading(text="Importing Excel file" :subtext="loading_label")
          Dropzone(v-else :loading="false" :mime="['text/csv', 'application/xls', 'application/xlsx']" @change="fileChangeHandler")

      div.modal-footer
        Buttons
          Button(type="link-primary" label="Cancel" @click="$emit('close')")
</template>

<script>
import { poll } from '@helpers/Global';
import Upload from '@helpers/Upload';

import FlightService from '@master/Services/FlightService';

import Dropzone from '@cm/Views/Creatives/Cards/Components/Dropzone.vue';

import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';
import Loading from '@master/UI/Loading.vue';
import Modal from '@master/UI/Modal.vue';

export default {
  name: 'FlightImportModal',
  mixins: [Modal],

  components: {
    Button,
    Buttons,
    Dropzone,
    Loading,
  },

  data() {
    return {
      flight: null,

      show_loading: false,
      loading_label: 'Importing the flight could take a little time, you can already close the modal',
      progress_messages: [
        "Just a few more bits and bytes... We're nearly there.",
        "Assembling the information puzzle...Hold tight, we're close.",
        'Data crunching, patience appreciated!',
        'Counting 1s and 0s...',
        "Hang on! We're almost there.",
      ],
      file: null,
    };
  },

  created() {
    FlightService.subscribe(data => {
      this.flight = data?.flight;
    }, this);
  },

  methods: {
    fileChangeHandler(event) {
      const { files } = event;

      if (files?.length > 1) {
        this.$alert('You can only upload one file at a time.');
        return;
      }

      this.file = files[0];

      this.uploadFile();
    },

    notifyAboutProgress() {
      this.loading_label = this.progress_messages[Math.floor(Math.random() * this.progress_messages.length)];
    },

    uploadFile() {
      if (this.file != null && this.$route?.params?.flight_id != null) {
        this.show_loading = true;

        const handler = new Upload(this.file);
        handler.upload(({ done, upload_id, error }) => {
          if (done) {
            this.importFile(upload_id);
          }

          if (error?.message != null) {
            this.$alert(error.message);
          }
        });
      }
    },

    importFile(upload_id = null) {
      if (upload_id == null) {
        throw new Error('Upload ID is required for import');
      }

      this.$http
        .post(`v2/flights/${this.$route.params.flight_id}/import/async`, {
          upload_id,
        })
        .then(async response => {
          if (response?.status != null) {
            this.update(response.status);
          }

          if (response?.process_id == null) return;

          response = await poll({
            path: `v2/flights/${this.$route.params.flight_id}/import/poll`,
            data: response,
            options: { notification: true },
            ms: 1000,
            callback: this.notifyAboutProgress,
          });

          if (response.flight == null) return;

          if (response?.error) {
            this.update(response?.flight?.status);
          } else {
            FlightService.update(response.flight);
            this.$emit('close', true);
          }
        })
        .catch(_ => {
          /** suppress errors */
        })
        .finally(() => {
          this.show_loading = false;
        });
    },

    update(status) {
      FlightService.update({
        ...this.flight,
        status,
      });
    },
  },
};
</script>
