<template lang="pug">
div.mt-32
  section.px-24
    div.fw-500.mb-8 Optimization Metric
    div.fs-14.color-gray-800 Select your optimization metric based on which the display of your best-performing creatives will gradually increase.

  section.px-24.mt-16.flex.column-gap-24
    DCOActionCard(
      title="CTR",
      description="Optimize creative display based on highest click-through rate (CTR)"
      icon="nexd-icon-32-ctr"
      :secondary="true"
      :active="flight?.fallback?.optimization_metric === FLIGHTS.OPTIMIZATION_METRIC.CTR"
      @click="selectOptimization(FLIGHTS.OPTIMIZATION_METRIC.CTR)"
    )
    DCOActionCard(
      title="Engagement",
      description="Optimize creative display based on highest engagement value"
      icon="nexd-icon-32-engagement"
      :secondary="true"
      :active="flight?.fallback?.optimization_metric === FLIGHTS.OPTIMIZATION_METRIC.ENGAGEMENT"
      @click="selectOptimization(FLIGHTS.OPTIMIZATION_METRIC.ENGAGEMENT)"
    )

  CardDivider.mt-32

  section.px-24
    FlightStatements
</template>

<script>
import { FLIGHTS } from '@master/constants';

import FlightService from '@master/Services/FlightService';

import DCOActionCard from '@cm/Views/Flights/Components/DCOActionCard/DCOActionCard.vue';
import FlightStatements from '@cm/Views/Flights/Components/FlightStatements/FlightStatements.vue';

import CardDivider from '@master/UI/Divider/CardDivider.vue';

export default {
  name: 'DCOOptimization',

  components: {
    CardDivider,
    DCOActionCard,
    FlightStatements,
  },

  data() {
    return {
      FLIGHTS,

      flight: null,
    };
  },

  created() {
    FlightService.subscribe(data => {
      this.flight = data?.flight;
    }, this);
  },

  methods: {
    selectOptimization(value) {
      if (this.flight?.fallback) {
        this.flight.fallback.optimization_metric = value;
        FlightService.update(this.flight);
      }
    },
  },
};
</script>
