<template lang="pug">
div#login
  Background
  div#login-body
    router-view.login-content
      Logo(slot="logo")
  audio(v-if="music != null" ref="music" style="display: none; opacity: 0; position: fixed; z-index: -1;" autoplay loop)
    source(:src="music" type="audio/mp3")
</template>

<script>
import Background from '@login/Components/Background/Background.vue';
import Logo from '@login/Components/Logo.vue';

export default {
  name: 'LoginAPP',

  components: {
    Background,
    Logo,
  },

  data() {
    return {
      loading: false,
      form_success: false,
      email: '',
      music: null,
    };
  },

  methods: {
    open(name, query = null) {
      this.$router.push({ name, query });
    },
  },
};
</script>
