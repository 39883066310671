<template lang="pug">
div.row(v-if="creative_card")
  Dropdown.col(v-if="has_template_psds" :options="psd_dropdown_options" :disabled="disabled")
    template(v-slot:button="scope")
      Button(type="link" label="PSD template" :disabled="disabled")
        template(v-slot:prefix)
          IconDownload
  Dropdown.col(v-if="has_template_demo_assets" :options="demo_assets_dropdown_options" :disabled="disabled")
    template(v-slot:button="scope")
      Button(type="link" label="Demo assets" :disabled="disabled")
        template(v-slot:prefix)
          IconPicture
Dropdown(v-else :options="psd_dropdown_options.concat(demo_assets_dropdown_options)" position="right" :disabled="disabled")
  template(v-slot:button="scope")
    Button(type="link" label="Download" :disabled="disabled")
      template(v-slot:prefix)
        IconDownload
</template>

<script>
import Dropdown from '@master/UI/Dropdown.vue';
import Button from '@master/UI/Buttons/Button.vue';
import IconDownload from '@master/UI/Buttons/Icons/IconDownload.vue';
import IconPicture from '@master/UI/Buttons/Icons/IconPicture.vue';

import { mixin as clickaway } from 'vue-clickaway';

export default {
  name: 'PSDDemoDownload',
  mixins: [clickaway],
  components: {
    Dropdown,
    Button,
    IconDownload,
    IconPicture,
  },
  props: {
    template: Object,
    creative_card: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    has_template_psds() {
      return this.psd_dropdown_options.length > 0;
    },

    has_template_demo_assets() {
      return this.demo_assets_dropdown_options.length > 0;
    },
  },

  data() {
    return {
      psd_dropdown_options: [],
      demo_assets_dropdown_options: [],
    };
  },

  methods: {
    init() {
      this.psd_dropdown_options = [];
      this.demo_assets_dropdown_options = [];

      if (!this.template) return;

      if (this.template.different_psds) {
        for (const psd of this.template.different_psds) {
          if (this.vertical === psd.vertical) {
            const item = {
              analytics: { download: 'PSD_template' },
              icon: 'nexd-icon-32-download',
              label: `PSD for ${psd.type === 0 ? 'infeed' : 'fullscreen'} ${psd.device === 0 ? 'mobile' : 'desktop'}`,
              onclick: () => {
                window.open(psd.link, 'download');
              },
            };
            this.psd_dropdown_options.push(item);
          }
        }
      }

      if (this.template.different_demo_packs) {
        for (const asset of this.template.different_demo_packs) {
          if (this.vertical === asset.vertical) {
            const item = {
              analytics: { download: 'demo_package' },
              icon: 'nexd-icon-32-picture',
              label: `Assets for ${asset.type === 0 ? 'infeed' : 'fullscreen'} ${asset.device === 0 ? 'mobile' : 'desktop'}`,
              onclick: () => {
                window.open(asset.link, 'download');
              },
            };
            this.demo_assets_dropdown_options.push(item);
          }
        }
      }
    },
  },

  watch: {
    template: {
      handler() {
        this.init();
      },
      immediate: true,
    },
  },
};
</script>
