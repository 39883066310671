<template lang="pug">
div(v-if="show_invoice" :class="styles.invoice")
  div(:class="styles.invoice_content")
    span {{ formatted_billing_date }}
    a(:href="url_view" target="_blank") {{ formatted_invoice_number }}
    span {{ formatted_invoice_amount }}
    span.text-capitalize {{ status }}
      div(:class="{ [styles.dot]: true, [styles[createStatusClass(status)]]: true }")
    Button.fs-16(
      type="link"
      label="Download"
      :disabled="url_download == null"
      @click="downloadInvoice"
    )
</template>

<script>
import styles from './Invoice.module.scss';

import { formatDate, formatPrice } from '@cm/Views/Billing/helpers';

import Button from '@master/UI/Buttons/Button.vue';

export default {
  name: 'Invoice',

  components: {
    Button,
  },

  props: {
    billing_date: {
      type: Number,
      default: null,
    },

    invoice_number: {
      type: String,
      default: null,
    },

    invoice_amount: {
      type: String,
      default: null,
    },

    invoice_currency: {
      type: String,
      default: null,
    },

    status: {
      type: String,
      default: null,
    },

    url_download: {
      type: String,
      default: null,
    },

    url_view: {
      type: String,
      default: null,
    },
  },

  computed: {
    show_invoice() {
      return [this.billing_date, this.invoice_number, this.invoice_amount, this.invoice_currency, this.status, this.url_download, this.url_view].every(value => value != null);
    },

    formatted_billing_date() {
      return formatDate(this.billing_date);
    },

    formatted_invoice_number() {
      if (this.invoice_number == null) return '';

      return `#${this.invoice_number}`;
    },

    formatted_invoice_amount() {
      return formatPrice(this.invoice_amount, {
        currency: this.invoice_currency,
      });
    },
  },

  data() {
    return {
      styles,
    };
  },

  methods: {
    createStatusClass(value) {
      return value.trim().toLowerCase().replace(/\s+/g, '_');
    },

    downloadInvoice() {
      if (this.url_download != null) {
        this.$http.get(this.url_download).catch(() => {
          /** suppress errors */
        });
      }
    },
  },
};
</script>
