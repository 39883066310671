<template lang="pug">
SecondaryNavbar(:wide="!full_width")

  template(v-if="$route?.params?.creative_id == null && !is_sdk_user")
    //- new folder
    div.navbar-section
      Button(
        type="dropdown"
        label="New Folder"
        :disabled="folders_depth >= 5"
        :tooltip="folders_depth >= 5 ? { value: 'You can only create up to 5 levels of folders', position: 'bottom' } : null"
        v-google-analytics="{ key: 'click', element_name: 'create new creative folder' }"
        @click="createFolder"
      )
        template(v-slot:prefix)
          IconFolder

    //- set up flight
    div.navbar-section
      Button(
        type="dropdown"
        label="DCO Tools"
        :loading="upserting_flight"
        v-google-analytics="{ key: 'click', element_name: 'open flight from secondary navbar' }"
        @click="setupFlight"
      )
        template(v-slot:prefix)
          IconFlights

  //- export options
  CreativesExport.navbar-section(
    v-if="!is_sdk_user"
    :options="publish_dropdown_options"
    :disabled="is_export_disabled"
    :loading="loading_folders_creatives"
  )

  template(v-if="show_update_live_btn")
    Button(
      type="update"
      :animate="true"
      label="Update Live"
      :tooltip="{template: 'cm.updateLiveButton'}"
      v-google-analytics="{ key: 'click', element_name: 'update live creative' }"
      @click="updateLiveCreative"
    )

  //- bulk actions
  div.navbar-section(v-if="creatives_view && selection.is_some_selected && !is_sdk_user")
    div.bulk-action-icons
      template(v-for="(icon_settings, icon) in icons")
        template(v-if="icon_settings.visible")
          i(
            :key="icon"
            aria-hidden="true"
            :class="{[icon]: true, 'disabled': icon_settings.disabled, 'cursor-pointer': true}"
            v-tooltip="{...icon_settings.tooltip, position: 'bottom'}"
            @click="icon_settings.cta"
          )

  div.flex-grow
  template(v-if="creatives_view")
    CreativesImport(:disabled="disable_import" @createPlacement="createPlacement")
    CreativesCreatePlacement(:disbale_new_creative="disbale_new_creative" @createPlacement="createPlacement")
  template(v-else-if="creatives[0]?.status != null && !isDuplicationInProgress(creatives[0].status)")
    div(v-if="last_saved_timestamp != null")
      i.nexd-icon-16-check-mini.color-success.mr-4(aria-hidden="true")
      span.color-cyan-blue.fs-12 Last save: {{ last_saved_timestamp }}
    Button(
      type="primary"
      label="Save"
      :disabled="is_navbar_disabled"
      :loading="saving_creative"
      v-google-analytics="{ key: 'click', element_name: 'save creative' }"
      @click="$emit('save', 0)"
    )
    Button(
      type="primary"
      label="Preview"
      :outline="true"
      v-google-analytics="{ key: 'click', element_name: 'open preview from secondary navbar' }"
      @click="openPreview"
    )
      template(v-slot:prefix)
        IconPreview
    Button.color-black(
      v-if="admin_toggle && !creatives_view"
      type="light"
      label="Changelog"
      :outline="true"
      :href="`${$admin}creative/changelog?creative_id=${creatives[0].creative_id}`"
    )

  LoadingModal(v-if="global_loading != null" :head="global_loading.head" :body="global_loading.body")
  PublishModal(
    v-if="show_publishing_modal"
    :creatives="[...selected_creatives, ...folders_creatives]"
    :campaign="campaign"
    :confirm="show_confirm"
    :tags="show_tags"
    :exportedFlow="creative_export"
    :downloadNewTags="new_tags"
    :validate="validate_before_export"
    @close="closePreviewPublish"
  )
  PublishingNotification(
    v-if="show_publishing_notification"
    :campaign="campaign"
    :creatives="[...selected_creatives, ...folders_creatives]"
    :updateTag="needs_tag_update"
    @close="closePublishingModal"
    @onExport="openViewTagsModal"
  )
  CreativesBulkActionModal(
    v-if="show_creatives_bulk_action_modal"
    :creative_ids="selection.selected_items"
    :creatives="selected_creatives"
    @close="bulkActionCloseHandler"
  )
  ChangeGroupDetailsModal(
    v-if="show_group_details"
    :group="group"
    @close="closeGroupDetails"
  )
  CopyMoveModal(
    v-if="copy_move"
    :selection="selection"
    :breadcrumbs="breadcrumbs"
    :has_live_items="is_any_live_selected"
    @close="copymove"
  )
  CreativeConfirmDuplicate(
    v-if="show_duplicate_confirm"
    :selection="selection"
    :creative="selected_creative"
    @close="show_duplicate_confirm = false"
  )
</template>

<script>
import CreativesService from '@master/Services/CreativesService';
import FlightService from '@master/Services/FlightService';
import FolderService from '@master/Services/FolderService';
import SaveService from '@master/Services/SaveService';

import CreativesMixins from '@cm/Views/Creatives/Components/CreativesMixins.vue';
import SDKMixin from '@root/src/global/mixins/SDKMixin.vue';

import CreativeTraits from '@master/Traits/CreativeTraits.vue';
import StatusTraits from '@master/Traits/StatusTraits.vue';

import Button from '@master/UI/Buttons/Button.vue';
import IconPreview from '@master/UI/Buttons/Icons/IconPreview.vue';

import CustomCheckbox from '@master/UI/CustomCheckbox.vue';
import IconFlights from '@master/UI/Buttons/Icons/IconFlights.vue';
import IconFolder from '@master/UI/Buttons/Icons/IconFolder.vue';
import LoadingModal from '@master/UI/LoadingModal.vue';
import SecondaryNavbar from '@master/UI/SecondaryNavbar/SecondaryNavbar.vue';

import CreativesExport from '@cm/Views/Creatives/Components/CreativesExport.vue';
import CreativesImport from '@cm/Views/Creatives/Components/CreativesImport.vue';
import CreativesCreatePlacement from '@cm/Views/Creatives/Components/CreativesCreatePlacement.vue';
import PublishModal from '@cm/Views/Creatives/Modals/PublishModalV2.vue';
import PublishingNotification from '@cm/Views/Creatives/Modals/PublishingNotification.vue';
import CreativesBulkActionModal from '@cm/Views/Creatives/Modals/BulkAction/CreativesBulkActionModal.vue';
import ChangeGroupDetailsModal from '@cm/Views/Profile/Modals/ChangeGroupDetailsModal.vue';
import CreativeConfirmDuplicate from '@cm/Views/Creatives/Creative/CreativeConfirmDuplicate.vue';

import CopyMoveModal from '@cm/UI/Global/Modals/CopyMove/CopyMoveModal.vue';

import { getFoldersDepth } from '@helpers/Global';
import { FOLDER, STATUS } from '@master/constants';
import Selection from '@libs/Selection';

export default {
  name: 'CreativesNavbar',
  mixins: [CreativesMixins, CreativeTraits, StatusTraits, SDKMixin],

  components: {
    Button,
    IconPreview,
    CustomCheckbox,
    IconFlights,
    IconFolder,
    LoadingModal,
    CreativesExport,
    CreativesImport,
    CreativesCreatePlacement,
    PublishModal,
    PublishingNotification,
    CreativesBulkActionModal,
    ChangeGroupDetailsModal,
    CopyMoveModal,
    SecondaryNavbar,
    CreativeConfirmDuplicate,
  },

  props: {
    creatives: {
      type: Array,
      default: () => [],
    },

    folders: {
      type: Array,
      default: () => [],
    },

    selection: {
      type: Selection,
      required: true,
    },

    disable_import: {
      type: Boolean,
      default: false,
    },

    disbale_new_creative: {
      type: Boolean,
      default: false,
    },

    creatives_view: {
      type: Boolean,
      default: true,
    },

    full_width: {
      type: Boolean,
      default: true,
    },

    breadcrumbs: {
      type: Array,
      default: () => null,
    },

    show_unarchive_btn: {
      type: Boolean,
      default: false,
    },

    show_duplicate_btn: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    needs_tag_update() {
      return this.tag_update_needed_creative_ids.some(item => this.selection.selected_items.includes(item));
    },

    is_navbar_disabled() {
      const creative = this.creatives?.[0];
      if (!creative) {
        return true;
      }

      return this.isProcessing(creative.status) || this.isDeprecatedLayout(creative);
    },

    show_update_live_btn() {
      if (this.creatives_view || this.is_navbar_disabled || this.is_sdk_user) {
        return false;
      }

      return this.isPublished(this.creatives?.[0]?.status);
    },

    has_items() {
      return this.creatives?.length > 0 || this.folders?.length > 0;
    },

    is_export_disabled() {
      return !this.has_items || this.is_navbar_disabled || (this.campaign && this.isDemoFinished(this.campaign.status));
    },

    selected_creatives() {
      return this.creatives?.filter(creative => this.selection.selected_items?.includes(creative?.creative_id)) ?? [];
    },

    selected_creative() {
      if (this.selection.selected_items.length !== 1) {
        return null;
      }

      return this.creatives.find(creative => creative.creative_id === this.selection.selected_items[0]);
    },

    selected_folder_creatives() {
      return this.folders_creatives.map(creative => creative.creative_id);
    },

    can_delete_folders() {
      if (this.folders == null || !this.selection.has_folders_selection) {
        return true;
      }

      return this.folders.filter(folder => this.selection?.selected_folders?.includes(folder.folder_id)).every(folder => folder?.meta?.total === 0);
    },

    can_delete_creatives() {
      if (this.creatives == null || !this.selection.has_items_selection) {
        return true;
      }

      return this.selected_creatives.every(creative => creative.status === 0);
    },

    delete_bulk_disabled() {
      return !this.can_delete_folders || !this.can_delete_creatives;
    },

    can_preview_bulk() {
      return !this.selection.has_folders_selection && this.selected_creatives.every(creative => creative.preview_url != null);
    },

    delete_bulk_tooltip() {
      if (!this.selection.is_some_selected) return { value: null };

      let warning_tooltip = null;

      if (!this.can_delete_creatives && !this.can_delete_folders) {
        warning_tooltip = 'You have selected exported creatives or folders with content. Please uncheck those and try again.';
      } else if (!this.can_delete_creatives) {
        warning_tooltip = 'You have selected exported creatives, which cannot be deleted. Please uncheck those and try again.';
      } else if (!this.can_delete_folders) {
        warning_tooltip = `
          Uncheck selected folders with content to delete; only empty folders can be removed.
        `;
      }

      if (warning_tooltip != null) {
        return {
          value: warning_tooltip,
          class: 'advanced warning',
          position: 'right',
        };
      }

      let message = 'Delete';

      if (this.selection.has_items_selection) {
        message += ` selected creative${this.selection.selected_items?.length > 1 ? 's' : ''}`;
      }

      if (this.selection.has_items_selection && this.selection.has_folders_selection) {
        message += ' and';
      }

      if (this.selection.has_folders_selection) {
        message += ` selected folder${this.selection?.selected_folders?.length > 1 ? 's' : ''}`;
      }

      return { value: message };
    },

    icons() {
      const icons = {
        'nexd-icon-32-duplicate': {
          visible: !this.selection.has_folders_selection,
          disabled: false,
          tooltip: { value: 'Duplicate' },
          cta: () => (this.show_duplicate_confirm = true),
        },
        'nexd-icon-32-delete': {
          visible: true,
          disabled: this.delete_bulk_disabled,
          tooltip: this.delete_bulk_tooltip,
          cta: () => this.bulkRemove(),
        },
        'nexd-icon-32-edit': {
          visible: !this.selection.has_folders_selection,
          disabled: false,
          tooltip: { value: 'Edit selected creatives' },
          cta: () => (this.show_creatives_bulk_action_modal = true),
        },
        'nexd-icon-32-preview': {
          visible: this.can_preview_bulk,
          disabled: false,
          tooltip: { value: 'Preview' },
          cta: () => this.bulkPreview(),
        },
        'nexd-icon-32-move': {
          visible: this.selection.is_some_selected,
          disabled: false,
          tooltip: { value: 'Copy / Move' },
          cta: () => this.copymove(),
          folder: true,
        },
        'nexd-icon-32-remove-from-folder': {
          visible: this.$route.params.folder_id != null,
          disabled: !this.selection.is_some_selected,
          tooltip: { value: 'Remove selected items from folder' },
          cta: () => this.removeFromFolder(),
        },
        'nexd-icon-32-unarchive': {
          visible: !this.selection.has_folders_selection && this.show_unarchive_btn,
          tooltip: { value: 'Unarchive creative' },
          cta: () => this.unarchive(),
        },
        'nexd-icon-32-archive1': {
          visible: !this.selection.has_folders_selection && !this.show_unarchive_btn,
          tooltip: { value: 'Archive creative' },
          cta: () => this.archive(),
        },
      };

      if (!this.show_duplicate_btn) {
        delete icons['nexd-icon-32-duplicate'];
      }

      if (!this.$user.canArchiveCreative()) {
        delete icons['nexd-icon-32-unarchive'];
        delete icons['nexd-icon-32-archive1'];
      }

      return icons;
    },

    export_label() {
      if (this.is_selected_creatives_all_published) return 'Update live creatives';
      if (this.is_some_of_selected_creatives_published) return 'Export & Update creatives';
      return 'Export as Tags';
    },

    publish_dropdown_options() {
      return [
        {
          icon: 'nexd-icon-16-export-tag',
          label: this.export_label,
          onclick: this.bulkPublish,
        },
        {
          analytics: 'view_tags_zip',
          icon: 'nexd-icon-16-download-tag',
          label: 'View and Download Tags',
          onclick: this.viewTagsZIP,
        },
        {
          icon: 'nexd-icon-16-xls-csv-file',
          label: 'Export for Platforms (XLS/CSV)',
          onclick: this.bulkPublish,
        },
      ];
    },

    is_selected_creatives_all_published() {
      const selected_creatives = [...this.selected_creatives, ...this.folders_creatives];
      return selected_creatives.length > 0 && selected_creatives.every(creative => creative.live != null);
    },

    is_some_of_selected_creatives_published() {
      const selected_creatives = [...this.selected_creatives, ...this.folders_creatives];
      return selected_creatives.length > 0 && selected_creatives.some(creative => creative.live != null);
    },

    selection_parent_id() {
      if (this.selection.has_folders_selection) {
        const folder = this.folders?.find(_folder => this.selection.selected_folders.includes(_folder.folder_id));

        if (folder != null) {
          return folder?.parent_id ?? folder?.campaign_id ?? null;
        }
      }

      if (this.selection.has_items_selection) {
        const creative = this.selected_creatives?.[0];

        if (creative != null) {
          return creative?.folder_id ?? creative?.campaign_id ?? null;
        }
      }

      return null;
    },

    is_any_live_selected() {
      if (!this.selection.is_some_selected) return false;

      for (const folder of this.folders) {
        if (this.selection?.selected_folders?.includes(folder?.folder_id) && folder?.status > STATUS.DRAFT) {
          return true;
        }
      }

      for (const creative of this.creatives) {
        if (this.selection.selected_items?.includes(creative?.creative_id) && creative?.status > STATUS.DRAFT) {
          return true;
        }
      }

      return false;
    },

    folders_depth() {
      return getFoldersDepth(this.breadcrumbs, FOLDER.CREATIVE);
    },
  },

  data() {
    return {
      admin_toggle: false,
      group: null,
      campaign: null,
      global_loading: null,
      show_publishing_modal: false,
      show_group_details: false,
      show_confirm: true,
      show_tags: false,
      creative_export: false,
      new_tags: false,
      show_publishing_notification: false,
      tag_update_needed_creative_ids: [],
      show_creatives_bulk_action_modal: false,
      validate_before_export: false,
      copy_move: false,
      upserting_flight: false,
      saving_creative: false,
      last_saved_timestamp: null,
      loading_folders_creatives: false,
      folders_creatives: [],
      show_duplicate_confirm: false,
    };
  },

  created() {
    this.$user.subscribe(_ => {
      this.group = this.$user.getGroup();
      this.admin_toggle = this.$user.adminMode();
    }, this);

    CreativesService.subscribe(state => {
      this.campaign = state?.campaign;
    }, this);

    CreativesService.export.subscribe(({ loading, show_publishing_notification, show_publishing_modal, show_group_details, tag_update_needed_creative_ids }) => {
      if (loading !== undefined) {
        this.global_loading = loading;
      }

      if (show_publishing_notification !== undefined) {
        this.show_publishing_notification = show_publishing_notification;
      }

      if (show_publishing_modal !== undefined) {
        this.show_publishing_modal = show_publishing_modal;
      }

      if (show_group_details !== undefined) {
        this.show_group_details = show_group_details;
      }

      if (tag_update_needed_creative_ids !== undefined) {
        this.tag_update_needed_creative_ids = tag_update_needed_creative_ids;
      }
    }, this);

    SaveService.creative.subscribe(({ saving, timestamp }) => {
      this.saving_creative = saving;
      this.last_saved_timestamp = timestamp;
    }, this);
  },

  methods: {
    copymove() {
      this.copy_move = !this.copy_move;
    },

    async setupFlight() {
      this.upserting_flight = true;
      await FlightService.upsert();
      this.upserting_flight = false;
    },

    async checkCreativeSelection() {
      if (!this.selection.is_some_selected) {
        this.selection.selectAll(true);
      }

      await this.$nextTick();

      if (this.selection.has_folders_selection && this.campaign?.campaign_id != null) {
        try {
          this.loading_folders_creatives = true;
          this.folders_creatives = [];

          const creatives = await this.$http.post(`/v2/campaigns/${this.campaign.campaign_id}/folders`, {
            folder_ids: this.selection.selected_folders,
          });

          if (creatives?.constructor === Array && creatives?.length > 0) {
            this.folders_creatives.push(...creatives);
          }
        } catch {
          /** supress errors */
        } finally {
          this.loading_folders_creatives = false;
        }
      }
    },

    async createPlacement(type_string) {
      if (!this.creatives_view) {
        let params = {
          new_creative: type_string,
          campaign_id: this.campaign.campaign_id,
        };

        if (this.$route?.params?.folder_id) {
          params.folder_id = this.$route.params.folder_id;
          return this.$router.push({ name: 'folder_creatives', params }).catch(_ => {
            /** supress duplicate route error */
          });
        }

        return this.$router.push({ name: 'creatives', params }).catch(_ => {
          /** supress duplicate route error */
        });
      }

      this.$emit('createPlacement', type_string);
    },

    constructDeleteBulkConfirmMessage(creatives_ids = []) {
      let message = 'Are you sure you want to delete';

      if (creatives_ids.length > 0) {
        message += ` ${creatives_ids.length} creative(s)`;
      }

      if (creatives_ids.length > 0 && this.selection.has_folders_selection) {
        message += ' and';
      }

      if (this.selection.has_folders_selection) {
        message += ` ${this.selection.selected_folders.length} folder(s)`;
      }

      return `${message}?`;
    },

    async bulkRemove() {
      if (!this.selection.is_some_selected || this.delete_bulk_disabled) return;

      if (!(await this.$confirm(this.constructDeleteBulkConfirmMessage(this.selection.selected_items)))) {
        return;
      }

      if (this.selection.has_items_selection) {
        this.$http.delete('creatives', { ids: this.selection.selected_items });
        CreativesService.removeItems(this.selection.selected_items);
      }

      if (this.selection.has_folders_selection) {
        this.$http.delete('v2/folder', {
          folders: this.selection.selected_folders,
        });
        CreativesService.removeItems(this.selection.selected_folders);
      }

      this.selection.unselectAll();
    },

    openPreview() {
      // in creative view, so only 0th element exists
      if (!this.creatives?.[0]?.preview_url) return;
      window.open(this.creatives?.[0]?.preview_url, '_blank');
    },

    bulkPreview() {
      if (!this.can_preview_bulk) return;

      if (this.selection.selected_items.length === 1) {
        const creative = this.creatives.find(_creative => _creative.creative_id === this.selection.selected_items[0]);
        if (creative?.preview_url == null) return;
        return window.open(creative.preview_url, '_blank');
      }

      this.global_loading = {
        head: 'Loading',
        body: 'Generating preview URL for the selection',
      };

      this.$http
        .post('url/shortener/preview', { ids: this.selection.selected_items })
        .then(async response => {
          this.global_loading = null;
          if (
            await this.$confirm('Link ready', `${response.url}`, {
              buttons: [
                {
                  type: 'link-primary',
                  label: 'Close',
                  action: false,
                },
                {
                  type: 'primary',
                  label: 'Open',
                  action: true,
                },
              ],
              image: 'success',
            })
          ) {
            return window.open(response.url, '_blank');
          }
        })
        .catch(() => {
          this.global_loading = null;
        });
    },

    async viewTagsZIP() {
      await this.checkCreativeSelection();

      this.validate_before_export = true;
      this.show_publishing_modal = true;
      this.show_confirm = false;
      this.show_tags = true;
    },

    openPreviewPublish() {
      this.show_publishing_modal = true;
    },

    closePreviewPublish() {
      this.show_publishing_modal = false;
      this.show_confirm = true;
      this.show_tags = false;
      this.creative_export = false;
      this.folders_creatives = [];
    },

    openViewTagsModal(input) {
      this.openPreviewPublish();
      this.show_confirm = false;
      this.show_tags = true;
      this.creative_export = true;
      this.new_tags = input.tag_update;
    },

    closePublishingModal() {
      CreativesService.export.closePublishingModal();
    },

    closeGroupDetails() {
      CreativesService.export.closeGroupDetails();
    },

    async bulkPublish() {
      await this.checkCreativeSelection();

      this.validate_before_export = false;

      this.updateLiveCreative();
    },

    bulkActionCloseHandler() {
      this.show_creatives_bulk_action_modal = false;
    },

    async removeFromFolder() {
      if (this.$route?.params?.folder_id == null) return;
      if (!(await this.$confirm('Are you sure you would like to remove items from folder?', 'This will move items back to creative list.'))) return;

      const payload = {
        creatives: this.selection.selected_items,
        folders: this.selection.selected_folders,
      };

      this.$http.delete(`v2/folder/${this.$route.params.folder_id}/items`, payload).then(success => {
        if (success) {
          CreativesService.removeItems(this.selection.selected_items);
          CreativesService.removeItems(this.selection.selected_folders);
          this.selection.unselectAll();
        }
      });
    },

    updateLiveCreative() {
      CreativesService.export.bulkExport(this.$route.params.campaign_id ?? this.creatives[0].campaign_id, [...this.selection.selected_items, ...this.selected_folder_creatives], this.is_selected_creatives_all_published);
    },

    unarchive() {
      if (this.selection.has_folders_selection) return;

      this.$http.put('creatives/unarchive', { ids: this.selection.selected_items }).then(({ creatives }) => {
        CreativesService.updateItems(creatives);
        this.selection.unselectAll();
      });
    },

    archive() {
      if (this.selection.has_folders_selection) return;

      this.$http.put('creatives/archive', { ids: this.selection.selected_items }).then(({ creatives }) => {
        CreativesService.updateItems(creatives);
        this.selection.unselectAll();
      });
    },

    createFolder() {
      const folder_id = `new-${Date.now()}`;
      const user = this.$user.get();

      const obj = {
        folder_id,
        name: 'New Folder',
        parent_id: this.$route?.params?.folder_id ?? this.$route?.params?.campaign_id ?? null,
        status: 0,
        type: FOLDER.CREATIVE,
        owner: {
          user_id: user.user_id,
          name: user.name,
        },
      };

      CreativesService.addItem(obj);
      FolderService.active.set(obj);
    },
  },
};
</script>
