<template lang="pug">
div
  div.slot(v-if="parent == null")
    CollapsableCard(
      :tooltip="parent_slot_tooltip"
      :reset_btn_visible="hasUserData()"
      :reset_btn_tooltip="{value: `Reset ${parent_slot.name || module.name} to default`}"
      @reset_btn_click="__remove"
    )
      template(v-slot:header_title)
        h5.self-align-center {{parent_slot.name || module.name}}
      template(v-slot:additional_header_content)
        TextareaWithCounter(
          v-model="object.data.value"
          :max_char="parent_slot_max_char"
          :placeholder="parent_slot.settings.placeholder ?? `Insert text (max char ${parent_slot_max_char})`"
          :expanding="needs_expanding_textarea"
          @input="__save('object', id, object)"
          @blur="blur"
        )
      template(v-slot:collapsable_body)
        TextSlot(:object="object" @update="__update")
  div(v-else)
    TextSlot(:object="object" @update="__update")
</template>

<script>
import ModuleTraits from '@master/Traits/ModuleTraits.vue';

import CollapsableCard from '@cm_modules/CollapsableCard.vue';
import TextareaWithCounter from '@master/UI/TextareaWithCounter.vue';
import TextSlot from './Slot.vue';

export default {
  name: 'TextModule',
  mixins: [ModuleTraits],
  components: {
    TextSlot,
    CollapsableCard,
    TextareaWithCounter,
  },

  props: {
    creative: Object,
    module: Object,
    parent: {
      type: Object,
      default: null,
    },
    parent_slot: {
      type: Object,
      default: null,
    },
  },

  computed: {
    parent_slot_tooltip() {
      return this.parent_slot?.settings?.tooltip ?? undefined;
    },

    parent_slot_max_char() {
      return this.parent_slot?.settings?.max_char ?? undefined;
    },

    creative_object() {
      return this.creative?.objects?.[this.$vnode.key] ?? null;
    },

    id() {
      if (this.object.object_id == null) {
        return this.module._fullpath;
      }
      return this.object.object_id;
    },
  },

  data() {
    return {
      object: null,
      module_slots: {},
    };
  },

  created() {
    this.modularitySetup();
  },

  methods: {
    blur(value) {
      if (value === '') return this.__remove();
      this.__save('object', this.id, this.object);
    },
  },

  watch: {
    creative_object: {
      handler(a, b) {
        if (b == null) {
          // only if previous value is null - handles module content update on API _inserted response
          this.applyCreativeObjectsToLocalObject();
        }
      },
    },
  },
};
</script>
