<template lang="pug">
SidebarContainer(
  title="Folder Settings"
  :timestamp="folder.updated_on"
  :path="path"
  :can_open="!is_in_view"
  :loading="creation_in_progress"
  loading_text="Creating folder, please wait"
  @close="close"
)
  template(v-slot:actions)
    FolderSiderbarIcons(:folder="folder")

  template(v-slot:content)
    div.label-group
      h4 Name:
    TextareaInput(
      ref="title"
      placeholder="Folder title"
      v-model="folder.name"
      :disabled="!can_update"
      @change="saveFolder"
    )

    template(v-if="is_campaign_folder")
      hr
      div.label-group
        h4 Owner:
        i.nexd-icon-16-help( aria-hidden="true" v-tooltip="{value: 'Only the owner of the folder can change this'}")
      SearchSelect.mb-16(v-model="owner_id" :disabled="!can_change_owner" :options="accessible_users_list" @input="changeOwnerConfirmation")

      div.label-group
        h4 Collaborate with:
        i.nexd-icon-16-help( aria-hidden="true" v-tooltip="{value: 'Share this folder with other users. Collaborators can access anything inside the folder.'}")
      CollaboratorsInput.mb-16(
        v-model="folder.collaborators"
        :path="`v2/folder/${folder.folder_id}/collaborators`"
        :can_update="can_update"
        :owner_id="folder?.owner?.user_id"
        @input="updateCollaborators"
      )

  template(v-slot:campaign_details)
    CreativeTags(v-if="folder.meta?.creatives?.total > 0" :totals="folder.meta?.creatives")
</template>

<script>
import FolderSiderbarIcons from './FolderSiderbarIcons.vue';
import CreativeTags from './../container/CreativeTags.vue';

import FolderMixin from '@root/src/global/mixins/FolderMixin.vue';
import SidebarContainer from '@root/src/global/sidebar/edit/container/SidebarContainer.vue';

import TextareaInput from '@cm/Views/Creatives/Cards/Components/Tracking/Components/TextareaInput.vue';

import CampaignService from '@master/Services/CampaignService';
import FolderService from '@master/Services/FolderService';
import CreativesService from '@master/Services/CreativesService';
import UsersService from '@master/Services/Cache/UsersService';
import GroupAssetsLibraryService from '@master/Services/GroupAssetsLibraryService';
import SearchService from '@master/Services/SearchService';

import CollaboratorsInput from '@master/UI/CollaboratorsInput.vue';
import SearchSelect from '@master/UI/SearchSelect/SearchSelect.vue';

import { VIEW } from '@master/constants';

export default {
  name: 'FolderSidebar',
  mixins: [FolderMixin],

  components: {
    SearchSelect,
    FolderSiderbarIcons,
    CreativeTags,
    CollaboratorsInput,
    TextareaInput,
    SidebarContainer,
  },

  computed: {
    is_in_view() {
      return this.$route.params.folder_id === this.folder.folder_id;
    },
  },

  data() {
    return {
      owner_id: this.folder?.owner?.user_id,
      accessible_users_list: [],
      creation_in_progress: false,
      loading: false,
    };
  },

  created() {
    if (this.is_new) {
      this.createFolder();
    } else if (this.folder.collaborators == null) {
      // might be an object from campaign view, that does not have all of the available info
      // load in full campaign object on cases like that
      this.loading = true;
      this.$http.get(`v2/folder/${this.folder.folder_id}`).then(folder => {
        CampaignService.updateItems([folder]);
        this.loading = false;
      });
    }

    UsersService.subscribe(users => {
      this.accessible_users_list = [];
      if (users == null) return;

      for (const obj of users) {
        this.accessible_users_list.push({
          label: obj.name,
          value: obj.user_id,
        });
      }
    }, this);
  },

  methods: {
    saveFolder() {
      if (this.creation_in_progress) return;

      this.$http.put(`v2/folder/${this.folder.folder_id}`, { name: this.folder.name }).then(response => {
        if (this.is_campaign_folder) {
          CampaignService.updateItems([response]);
        } else if (this.is_creative_folder) {
          CreativesService.updateItems([response]);
        } else if (this.is_asset_library_folder) {
          GroupAssetsLibraryService.updateItems([response]);
          GroupAssetsLibraryService.updateBreadcrumb(this.folder.folder_id, this.folder.name);
        }

        CampaignService.updateBreadcrumb(this.folder.folder_id, this.folder.name);

        if (this.$route.name === VIEW.SEARCH) {
          SearchService.active.set(this.folder);
        }
      });
    },

    createFolder() {
      if (this.creation_in_progress) return;
      this.creation_in_progress = true;

      this.$http
        .post('v2/folder', this.folder)
        .then(folder => {
          if (this.is_campaign_folder) {
            CampaignService.updateCreatedItem(this.folder, folder);
          } else if (this.is_creative_folder) {
            CreativesService.updateCreatedItem(this.folder, folder);
          } else if (this.is_asset_library_folder) {
            GroupAssetsLibraryService.updateCreatedItem(this.folder, folder);
          }

          FolderService.active.set(folder);
          this.creation_in_progress = false;

          if (this.$refs.title) {
            this.$refs.title.focus();
          }
        })
        .catch(_ => {
          this.creation_in_progress = false;
          this.close();
        });
    },

    close() {
      if (!this.creation_in_progress) {
        FolderService.active.set(null);
      }
    },

    async changeOwnerConfirmation(user_id) {
      if (this.creation_in_progress) return;

      const user = this.accessible_users_list.find(usr => usr.value === user_id);
      if (await this.$confirm(`Are you sure you want to change folder owner to ${user.label}?`)) {
        const path = `v2/folder/${this.folder.folder_id}/owner`;
        this.$http.put(path, { user_id }).then(response => {
          CampaignService.updateItems([response]);
        });
      }
    },

    updateCollaborators() {
      CampaignService.updateItems([this.folder]);
    },
  },
};
</script>
