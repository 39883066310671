import { VIEW } from '@master/constants';

import APP from '@login/app.vue';
import Login from '@login/Views/Login.vue';
import ForgotPassword from '@login/Views/ForgotPassword.vue';
import Register from '@login/Views/Register.vue';
import RegisterCompleted from '@login/Views/RegisterCompleted.vue';
import ResetPassword from '@login/Views/ResetPassword.vue';

const default_meta = {
  app_name: 'Login',
  app_key: 'login',
  requires_auth: false,
  show_navbar: false,
  validate_adblock: false,
  show_sidebar: false,
  intercom: true,
  sdk_client: false,
};

export default [
  {
    path: '/login',
    component: APP,
    children: [
      {
        path: '/',
        name: VIEW.LOGIN,
        component: Login,
        meta: { ...default_meta },
      },
    ],
  },
  {
    path: '/register',
    name: VIEW.REGISTER,
    component: Register,
    alias: '/invite',
    meta: { ...default_meta, ...{ app_name: 'Register' } },
  },

  {
    path: '/register/completed',
    name: VIEW.REGISTER_COMPLETED,
    component: RegisterCompleted,
    meta: { ...default_meta, ...{ app_name: 'Register' } },
  },

  {
    path: '/forgot',
    component: APP,
    children: [
      {
        path: '/',
        name: VIEW.FORGOT,
        component: ForgotPassword,
        meta: { ...default_meta },
      },
    ],
  },

  {
    path: '/reset',
    component: APP,
    redirect: '/',
    children: [
      {
        path: ':token',
        name: VIEW.RESET,
        component: ResetPassword,
        meta: { ...default_meta },
      },
    ],
  },
];
