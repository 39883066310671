<template lang="pug">
div.dropzone(:class="{'active' : dropbox_visible && active, 'library-drop': asset_library}")
  Loading(v-show="loading" :fill="true")
  div.dropzone-content
    img(v-if="mime === 'text/csv'" :src="csv_upload_icon" alt="csv-upload")
    i.nexd-icon-32-upload(v-else-if="asset_library" aria-hidden="true" style="transform: rotateZ(180deg);")
    img(v-else :src="$cdn + 'dist/assets/file-upload-icon.svg'" alt="upload")
    div.form-group
      div.title Drop your {{file}} here
      div.subtitle or&nbsp;
        span.text-underline choose file
          span(v-if="disable") s
    div.subtitle.color-gray-600.library-drop Supported file {{type_text}} {{accepted_types}}
    input(ref="input" type="file" :accept="file_input_accept_extensions" :multiple="multiple" @change="fileChangeHandler" @click="clear($event)")
</template>

<script>
import Loading from '@master/UI/Loading.vue';
import { unique } from '@helpers/Global';
import AssetHelper from '@helpers/Asset';
import IconLoader from '@helpers/IconLoader';

export default {
  name: 'Dropzone',
  components: {
    Loading,
  },
  props: {
    mime: [String, Array],
    loading: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    asset_library: {
      type: Boolean,
      default: false,
    },
    file: {
      type: String,
      default: 'file',
    },
    disable: {
      type: Boolean,
      default: false,
    },

    // helping hand to exclude extensions coming from the mime selection
    exclude: {
      type: Array,
      default: () => [],
    },

    // allow parent to tell that dropbx is visible
    active: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    type_text() {
      if (this.accepted_types.split(',').length === 1) {
        return 'type is';
      }
      return 'types are';
    },
  },

  data() {
    return {
      dropbox_visible: false,
      accepted_types: '',
      file_input_accept_extensions: '',
      csv_upload_icon: IconLoader.url('xlsx-csv-upload-icon.png'),
    };
  },

  mounted() {
    this.$el.addEventListener('dragover', e => {
      if (this.disable === true) return;
      e.preventDefault();
      e.stopPropagation();
      this.dropbox_visible = true;
    });

    this.$refs.input.addEventListener('drop', async e => {
      if (this.disable === true) return;
      const { files, errors } = await this.dropHandler(e);
      this.output(files, errors);
    });

    this.$el.addEventListener('dragleave', e => {
      if (this.disable === true) return;
      e.preventDefault();
      this.dropbox_visible = false;
    });
  },

  methods: {
    dropHandler(e) {
      e.preventDefault();
      e.stopPropagation();
      const files = [];
      const errors = [];
      const original_data = e.dataTransfer.getData('application/original-asset');

      if (original_data != null && original_data !== '') {
        const original_asset = JSON.parse(original_data);
        this.$emit('self', original_asset);
      } else if (e.dataTransfer != null && e.dataTransfer.files.length > 0) {
        return this.getFileList(e.dataTransfer.files);
      }
      return { files, errors };
    },

    clear(event) {
      // clear so user can select the same file again
      event.target.value = '';
    },

    selectFile() {
      if (this.$refs.input) {
        this.$refs.input.click();
      }
    },

    async getFileList(file_list) {
      const files = [];
      const errors = [];
      for (let file of file_list) {
        if (!AssetHelper.isAcceptedMime(this.mimes, file.type, file.name)) {
          errors.push(file);
          continue;
        }

        if (file.type === 'video/quicktime') {
          files.push(file);
          continue;
        }

        if (!(await AssetHelper.isValidFile(file))) {
          continue;
        }

        files.push(file);

        // accept only 1st file from drop
        if (!this.multiple) {
          break;
        }
      }
      return { files, errors };
    },

    async fileChangeHandler(e) {
      if (e.target != null && e.target.files.length > 0) {
        const { files, errors } = await this.getFileList(e.target.files);
        this.output(files, errors);
        return;
      }
      this.output();
    },

    output(files = null, errors = []) {
      this.dropbox_visible = false;
      this.$emit('change', { files, errors });
    },

    createMimes() {
      this.file_input_accept_extensions = '';
      let mimes = this.mime;
      if (typeof this.mime === 'string') {
        mimes = [this.mime];
      }

      this.mimes = [];
      for (const mime of mimes) {
        if (mime === 'application/zip') {
          this.mimes.push('application/zip', 'application/x-compressed', 'application/x-zip-compressed', 'multipart/x-zip');
        } else if (mime === 'application/xml' || mime === 'text/xml') {
          this.mimes.push('application/xml', 'text/xml');
        } else if (mime === 'image/*') {
          this.mimes.push(...AssetHelper.getImageMimes());
        } else if (mime === 'video/*') {
          this.mimes.push('image/gif');
          this.mimes.push(...AssetHelper.getVideoMimes());
        } else if (mime === 'text/csv' || mime === 'application/xls' || mime === 'application/xlsx') {
          this.mimes.push(...AssetHelper.getCSVMimes());
          this.mimes.push(...AssetHelper.getXLSMimes());
        } else {
          this.mimes.push(mime);
        }
      }
      this.mimes = unique(this.mimes).join(',');

      // visible text for whats accepted
      this.accepted_types = AssetHelper.mimeToExtension(this.mimes);

      for (const ext of this.exclude) {
        const type_regexp = new RegExp(`,?\\s?\\.${ext.replace('.', '')}`, 'i');
        this.accepted_types = this.accepted_types.replace(type_regexp, '');
        const mime_regexp = new RegExp(`,?\\s?image\\/${ext.replace('.', '')}`, 'i');
        this.mimes = this.mimes.replace(mime_regexp, '');
      }

      // hidden input value, can have some more accepted types herefore we clone the accepted visible type for it
      this.file_input_accept_extensions = this.accepted_types.slice(0);

      // add jpeg as well in the accept list, because we dont show it in the visible list
      if (this.file_input_accept_extensions.indexOf('.jpg') !== -1) {
        this.file_input_accept_extensions += ', .jpeg';
      }
    },
  },
  watch: {
    mime: {
      handler() {
        this.createMimes();
      },
      immediate: true,
    },
  },
};
</script>
