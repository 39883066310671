<template lang="pug">
  div.survey-question-type
    div.row.mb-16
      div.col.mr-16.self-align-center
        label Question type:
        i.nexd-icon-16-help.pl-4(aria-hidden="true" v-tooltip="{value: tooltip_text}")
      div.col.self-align-center.flex-grow
        SearchSelect(
          :options="question_types"
          :search="false"
          :array-key="'name'"
          v-model="question.type"
          @input="$emit('update')"
        )
</template>
<script>
import SearchSelect from '@master/UI/SearchSelect/SearchSelect.vue';

export default {
  name: 'QuestionType',
  components: {
    SearchSelect,
  },
  props: {
    question: Object,
  },
  data() {
    return {
      tooltip_text: `Select whether the ad viewer can select one or multiple choices when answering the question.
        Please note that all questions are mandatory, so if you need, add a “Don’t know” or “Don’t want to answer” choice below.`,
      question_types: [
        { value: 'single', label: 'Single selection', icons: ['nexd-icon-16-single-choice'] },
        { value: 'multiple', label: 'Multi selection', icons: ['nexd-icon-16-multi-choice'] },
        // 'input': 'Input field' // Will be added in next Survey version
      ],
    };
  },
};
</script>
