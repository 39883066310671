<template lang="pug">
  div(ref="modal")
    div.modal.publish-modal
      div.backdrop
      div.modal-content
        div.modal-head(v-if="exportedFlow")
          img.mr-32(v-if="!downloadNewTags" :src="$cdn + 'dist/nexd/imgs/icecream-success.svg'" alt="success")
          img.mr-32(v-else :src="$cdn + 'dist/nexd/imgs/attention.svg'" alt="warning")
          div.col
            div.modal-title.mt-8.row {{modal_title}}
            div.modal-title-text.mt-8.row {{modal_title_text}}
        div.modal-head(v-else)
          div.modal-title {{modal_title}}
        div.modal-body
          template(v-if="!exportedFlow")
            div(v-if="!exported")
              div Review whether you have selected the right creatives for exporting. Make sure categories, types, sizes and platforms are correct.
              div(v-if="any_published") Exported creatives tags will be updated, not exported creatives tags generated.
            div(v-else)
              template(v-if="!has_google")
                p You can download one file for all creatives, a separate file for each platform, or an individual file for each creative.&nbsp;
                  span As the next step set up the tag(s) in your ad delivery platform(s) to start receiving impressions.&nbsp;
                  span To learn more, here’s a&nbsp;
                  a(href="https://support.nexd.com/en/articles/2965516-work-with-ad-tags") short introduction to working with ad tags,&nbsp;
                  a(href="https://support.nexd.com/en/articles/3172184-using-nexd-tags-and-understanding-them") an overview of NEXD’s ad tags&nbsp;
                  span and a&nbsp;
                  a(href="https://support.nexd.com/en/articles/5885779-tag-set-up-for-different-platforms-and-macros") guide for setting the tags up in different platforms.
                p.fw-500
                  | Always make sure the NEXD tag has been set up for the Advertisement Delivery Platform
                  | you are inserting the tag into. If you are using Ad server use it's functionality as a "Tracking Creative",
                  | as NEXD already incorporates Adserving in it's tag. Refer to Page "Ad Servers”.
              template(v-else)
                p Download ZIP files for Google Ads. Learn more about the set up here&nbsp;
                  a(href="https://support.nexd.com/en/articles/4563259-export-for-google-ads") Export for Google Ads.
          hr

          div.row(v-if="exported")
            Buttons.col.flex-grow
              Button(v-if="can_bulk_download_zip" type="link" label="Download All ZIP Files" @click="downloadZip")
                template(v-slot:prefix)
                  IconTag
              Button(v-if="can_bulk_download_tag" type="link" label="Download All Tags" @click="downloadTag")
                template(v-slot:prefix)
                  IconTag

          div.scrollable-list(v-if="creatives.length > 0")
            //- When clicking Export|Update creatives
            template(v-if="confirm && !exported")
              template(v-for="(creatives, queue_title, index) in creatives_to_publish_queue")
                div.confirmation-title(v-if="has_diff_types_of_publish_status") {{ queue_title }}

                template(v-for="creative of creatives")
                  PublishCreative(
                    ref="published-creative"
                    :key="creative.creative_id"
                    :creative="creative"
                    :published="exported"
                    :categorize="has_diff_types_of_publish_status"
                    @publishModalShowLoading="showLoading"
                    @publishModalHideLoading="hideLoading"
                  )

            //- When viewing ceatives sorted by dsp (view and download tags click or after publish/update)
            template(v-else)
              template(v-for="(sorted_creatives, dsp, index) in combined_creatives")
                UIGroup.color-cyan-blue(:title="sorted_creatives.title")

                  template(v-for="creative in sorted_creatives.creatives")
                    PublishCreative(
                      ref="published-creative"
                      :key="creative.creative_id"
                      :creative="creative"
                      :published="exported"
                      @publishModalShowLoading="showLoading"
                      @publishModalHideLoading="hideLoading"
                    )

                  //- Action button -> Export || Download excel
                  Buttons.mt-16(:key="index")
                    Button(
                      v-if="sorted_creatives.exportable"
                      type="primary"
                      :outline="true"
                      label="Download CSV"
                      @click="downloadSpecificExcel(dsp, EXPORT_TYPES.CSV)"
                    )
                      template(v-slot:prefix)
                        IconDownload
                    Button(
                      v-if="sorted_creatives.exportable"
                      type="primary"
                      :outline="true"
                      label="Download XLSX"
                      @click="downloadSpecificExcel(dsp, EXPORT_TYPES.XLSX)"
                    )
                      template(v-slot:prefix)
                        IconDownload
                    Button(v-if="dsp === 'unpublished'" type="primary" :outline="true" label="Export" @click="exportCreatives(true)")

        div.modal-footer
          template(v-if="!exported")
            Buttons
              Button(type="link-primary" label="Cancel" @click="close")
              Button(type="primary" label="Export" :animate="true" :loading="loading" @click="exportCreatives")
          div.flex(v-else)
            div.text-left.flex-grow
              span(v-if="!published_ids.length")
                i.nexd-icon-32-dwell-time.color-primary( aria-hidden="true")
                span.color-cyan-blue Please be aware that the creatives might take up to five minutes to become active after exporting.
            Button(type="primary" label="Done!" :animate="true" @click="close")

        div.modal-loading(v-if="loading")
          Loading(:text="heading" :subtext="loading_text")
</template>

<script>
import PublishCreative from '@cm/Views/Creatives/Cards/PublishCreative.vue';

import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';
import IconDownload from '@master/UI/Buttons/Icons/IconDownload.vue';
import IconTag from '@master/UI/Buttons/Icons/IconTag.vue';
import Loading from '@master/UI/Loading.vue';
import UIGroup from '@master/UI/UIGroup.vue';

import Modal from '@master/UI/Modal.vue';
import DSPService from '@master/Services/Cache/DSPService';
import CreativesService from '@master/Services/CreativesService';

import CreativeTraits from '@master/Traits/CreativeTraits.vue';

import { DSP, EXPORT_TYPES } from '@master/constants';

export default {
  name: 'PublishModal',
  mixins: [Modal, CreativeTraits],

  components: {
    PublishCreative,
    Loading,
    UIGroup,
    Button,
    Buttons,
    IconDownload,
    IconTag,
  },

  props: {
    creatives: Array,
    campaign: Object,

    confirm: {
      type: Boolean,
      default: true,
    },
    tags: {
      type: Boolean,
      default: false,
    },
    exportedFlow: {
      type: Boolean,
      default: false,
    },
    downloadNewTags: {
      type: Boolean,
      default: false,
    },
    validate: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    can_bulk_download_tag() {
      let num_of_tags = 0;

      for (const creative of this.creatives) {
        if (this.canDownloadTag(creative)) num_of_tags++;
        if (num_of_tags > 1) return true;
      }

      return false;
    },

    can_bulk_download_zip() {
      let num_of_zips = 0;

      for (const creative of this.creatives) {
        if (this.canDownloadZip(creative)) num_of_zips++;
        if (num_of_zips > 1) return true;
      }

      return false;
    },

    has_google() {
      for (const creative of this.creatives) {
        if (creative.dsp === DSP.GOOGLEADS) return true;
      }

      return false;
    },

    has_vast() {
      for (const creative of this.creatives) {
        if (creative.type != null && this.isVast(creative.type)) return true;
      }

      return false;
    },

    modal_title() {
      if (this.exportedFlow && !this.downloadNewTags) return 'Everything is up to date!';
      if (this.exportedFlow && this.downloadNewTags && this.creatives.length <= 1) {
        const creative = this.creatives?.[0] ?? this.creatives?.[0]?.[0] ?? null;
        if (creative != null && DSPService.hasZip(creative.dsp)) return 'These changes require a new zip';
      }
      if (this.exportedFlow && this.downloadNewTags) return 'These changes require a new tag!';
      if (this.published_ids.length > 0 && !this.new_export) return 'View and download tags';
      if (this.published_ids.length > 0 && this.new_export) return 'Exported!';

      return 'Export creatives';
    },

    modal_title_text() {
      if (this.exportedFlow && !this.downloadNewTags) return 'Your creative has been fully updated and is all good! You do not need to update your live tag(s).';

      if (this.exportedFlow && this.downloadNewTags && this.creatives.length <= 1) {
        const creative = this.creatives?.[0] ?? this.creatives?.[0]?.[0] ?? null;
        if (creative != null && DSPService.hasZip(creative.dsp)) {
          return 'Your creative is now updated and republished, please download your zip.';
        }
      }

      if (this.exportedFlow && this.downloadNewTags) return 'The creative(s) has been fully updated, but you need to update your live tag(s).';

      return '';
    },

    published_ids() {
      if (this.creatives == null) return [];

      return this.creatives.reduce((ids, creative) => {
        ids.push(creative.creative_id);
        return ids;
      }, []);
    },

    has_diff_types_of_publish_status() {
      return this.any_non_published && this.any_published;
    },

    creatives_to_publish_queue() {
      let result = {};

      if (this.previously_published_creatives.length > 0) {
        result['Updating'] = this.previously_published_creatives;
      }

      if (this.not_published_creatives.length > 0) {
        result['Exporting'] = this.not_published_creatives;
      }

      return result;
    },
  },

  data() {
    return {
      EXPORT_TYPES,

      exported: false,
      loading: false,
      users: [],
      value: null,
      hide_input: false,
      categorize: false,
      not_published_creatives: [],
      previously_published_creatives: [],
      any_non_published: false,
      any_published: false,
      dsp_options: {},
      combined_creatives: {},
      block_collapsed: false,
      loading_text: '',
      heading: '',
      new_export: false,
    };
  },

  created() {
    DSPService.subscribe(dsps => {
      if (dsps == null) return;

      this.dsp_options = {};

      for (const dsp of dsps) {
        this.dsp_options[dsp.slug] = dsp.title;
      }

      this.sortByDSP();
    }, this);

    this.sortCreatives();
  },

  mounted() {
    if (this.tags) {
      this.exported = true;
      this.sortByDSP();
    }
  },

  methods: {
    canDownloadTag(creative) {
      return creative.status > 0 && !this.isVideo(creative.type) && DSPService.hasTag(creative.live?.dsp || creative.dsp);
    },

    canDownloadZip(creative) {
      return DSPService.hasZip(creative.live?.dsp || creative.dsp);
    },

    sortCreatives() {
      for (let creative of this.creatives) {
        this.chooseCreativeStatus(creative);
      }
    },

    chooseCreativeStatus(creative) {
      if (creative.live == null) {
        this.any_non_published = true;
        this.not_published_creatives.push(creative);
      } else {
        this.any_published = true;
        this.previously_published_creatives.push(creative);
      }
    },

    close() {
      this.new_export = false;
      this.previously_published_creatives = null;
      this.not_published_creatives = null;
      this.$emit('close');
    },

    async exportCreatives(draft_creatives = false) {
      if (this.loading) return;
      this.loading = true;

      const creatives_ids = this.creatives.reduce((ids, creative) => {
        ids.push(creative.creative_id);
        return ids;
      }, []);

      // validate not exported creatives before publishing
      if (draft_creatives && this.validate) {
        const is_valid = await CreativesService.export.bulkExport(this.campaign.campaign_id, creatives_ids, false);

        if (!is_valid) {
          this.loading = false;
          return;
        }
      }

      this.$http
        .post(`campaigns/${this.campaign.campaign_id}/publish`, { creatives: creatives_ids, save: true })
        .then(response => {
          if (response.data) {
            this.new_export = true;
            this.exported = true;

            for (const creative of this.creatives) {
              if (response?.data?.info?.creatives?.[creative.creative_id] != null) {
                CreativesService.export.resetNeedTagUpdate(creative.creative_id);
                this.updateCreative(creative, response.data.info.creatives[creative.creative_id]);
              }
            }

            this.combined_creatives = {};
            this.sortByDSP();
          }
        })
        .catch(() => {
          /** suppress errors */
        })
        .finally(() => {
          this.hideLoading();
          this.any_non_published = false;
        });
    },

    updateCreative(creative, values) {
      for (const key in values) {
        this.$set(creative, key, values[key]);
      }
    },

    sortByDSP() {
      const combined_creatives = {};

      for (const creative of this.creatives) {
        if (creative.live == null) {
          if (!combined_creatives.hasOwnProperty('unpublished')) {
            this.$set(combined_creatives, 'unpublished', { creatives: [], exportable: false, title: 'Not exported' });
          }

          combined_creatives['unpublished'].creatives.push(creative);
          continue;
        }

        const dsp = creative.live?.dsp || creative.dsp;
        let dsp_title = 'No platform';

        if (dsp !== 'None') {
          dsp_title = this.dsp_options[dsp];
        }

        if (combined_creatives[dsp] == null) {
          this.$set(combined_creatives, dsp, {
            creatives: [],
            exportable: false,
            title: dsp_title,
          });
        }
        combined_creatives[dsp].creatives.push(creative);

        // dsp group is exportable only if it has atleast one non vast or vpaid creative
        if (!combined_creatives[dsp].exportable && !this.isVast(creative.type) && !this.isVpaid(creative.type)) {
          combined_creatives[dsp].exportable = true;
        }
      }

      // sort unpublished to be last on the list
      const dsp_keys = Object.keys(combined_creatives).sort((a, b) => {
        if ((a > b && a !== 'None' && b !== 'None') || a === 'unpublished') {
          return 1;
        }
        if ((a < b && a !== 'None' && b !== 'None') || b === 'unpublished') {
          return -1;
        }

        return 0;
      });

      for (const key of dsp_keys) {
        this.$set(this.combined_creatives, key, combined_creatives[key]);
      }
    },

    downloadTag() {
      this.showLoading();

      this.$http
        .post(`campaigns/${this.campaign.campaign_id}/tag`, { creatives: this.published_ids })
        .then(() => {
          /** downlaod handled by this.$http */
        })
        .catch(() => {
          this.$notifications.add('error', 'Could not download tags');
        })
        .finally(() => {
          this.hideLoading();
        });
    },

    downloadZip() {
      this.heading = 'Generating ZIP...';
      this.loading_text = 'Please wait while we collect your selected creative(s) and generate a single ZIP. This may take a while...';
      this.showLoading();

      this.$http
        .post(`campaigns/${this.campaign.campaign_id}/zip`, { creatives: this.published_ids })
        .then(() => {
          /** downlaod handled by this.$http */
        })
        .catch(() => {
          this.$notifications.add('error', 'Could not download ZIP files');
        })
        .finally(() => {
          this.hideLoading();
        });
    },

    downloadSpecificExcel(dsp, type) {
      const creatives_ids = this.combined_creatives[dsp].creatives.reduce((ids, creative) => {
        ids.push(creative.creative_id);
        return ids;
      }, []);

      this.downloadExcel(dsp, type, creatives_ids);
    },

    downloadExcel(dsp, type, ids) {
      this.showLoading();

      this.$http
        .post(`campaigns/${this.campaign.campaign_id}/export`, { filename: dsp + 'export', type, creatives: ids })
        .then(() => {
          /** downlaod handled by this.$http */
        })
        .catch(() => {
          this.$notifications.add('error', `Could not export ${type.toUpperCase()}`);
        })
        .finally(() => {
          this.hideLoading();
        });
    },

    showLoading() {
      this.loading = true;
    },

    hideLoading() {
      this.loading = false;
    },
  },
};
</script>
