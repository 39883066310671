<template lang="pug">
div.slot
  CollapsableCard(
    :tooltip="(parent_slot != null && parent_slot.settings != null ? parent_slot.settings.tooltip : null)"
    :reset_btn_visible="hasUserData()"
    :reset_btn_tooltip="{value: `Reset ${parent_slot.name || module.name} to default`}"
    @reset_btn_click="remove"
  )
    template(v-slot:header_title)
      h5.self-align-center {{parent_slot.name || module.name}}
    template(v-slot:additional_header_content)
      TextareaWithCounter(
        :key="has_user_data"
        v-model="text_object.data.value"
        :max_char="parent_slot_max_char"
        :placeholder="`Insert text (max char ${parent_slot_max_char || 90})`"
        @input="__save('object', text_object.object_id, text_object)"
        @blur="blur"
      )
    template(v-slot:collapsable_body)
      TextSettings(:object="text_object.settings" @update="__save('object', text_object.object_id, text_object)")
      div.row.col.mb-8.mt-14
        h4 Style:
      ButtonSettings(:object="object.settings" @update="__save('object', module._fullpath, object)")
</template>

<script>
import ModuleTraits from '@master/Traits/ModuleTraits.vue';
import { clone } from '@helpers/Global';

import CollapsableCard from '@cm_modules/CollapsableCard.vue';
import TextareaWithCounter from '@master/UI/TextareaWithCounter.vue';
import ButtonSettings from '@cm_modules/Button/ButtonSettings.vue';
import TextSettings from '@cm_modules/Text/TextSettings.vue';

export default {
  name: 'ButtonModule',
  mixins: [ModuleTraits],
  components: {
    CollapsableCard,
    TextareaWithCounter,
    ButtonSettings,
    TextSettings,
  },

  props: {
    creative: Object,
    module: Object,
    parent: {
      type: Object,
      default: null,
    },
    parent_slot: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      object: null,
      text_object: null,
      module_slots: {},
    };
  },

  computed: {
    creative_object() {
      return this.creative?.objects?.[this.$vnode.key] ?? null;
    },

    parent_slot_max_char() {
      return this.parent_slot?.settings?.max_char ?? undefined;
    },

    has_user_data() {
      for (const k in this.creative.objects) {
        if (k.startsWith(this.$vnode.key)) {
          this.validateTextObjectDataValue();
          return true;
        }
      }
      return false;
    },
  },

  created() {
    this.modularitySetup();
    this.initTextSettings();
  },

  methods: {
    initTextSettings() {
      for (const slot in this.module.slots) {
        for (const module of this.module.slots[slot].modules) {
          if (module.module_id !== 'text') continue;
          this.applyCreativeObjectsToLocalObject('text_object', module._fullpath);
          this.validateTextSettings(module);
        }
      }
    },

    validateTextSettings(module) {
      if (this.text_object == null) {
        this.$set(this, 'text_object', {});
      }

      if (this.text_object.data == null) {
        this.$set(this.text_object, 'data', { value: '' });
      }

      if (this.text_object.object_id == null) {
        this.$set(this.text_object, 'object_id', module._fullpath);
      }

      if (this.text_object.settings == null) {
        this.$set(this.text_object, 'settings', clone(module.settings));
        if (this.module?.settings?.text?.color != null) {
          this.$set(this.text_object.settings, 'color', this.module.settings.text.color);
        }
      }
    },

    validateTextObjectDataValue() {
      if (this.creative?.objects?.[this.text_object?.object_id]?.data == null) return;

      const data = clone(this.creative.objects[this.text_object.object_id].data);
      if (data.value != null) {
        this.$set(this.text_object.data, 'value', data.value);
      }
    },

    remove() {
      this.__remove();
      this.__delete('object', this.text_object.object_id);

      if (this.creative != null) {
        this.$delete(this.creative.objects, this.text_object.object_id);
      }
      this.$set(this, 'text_object', {});
      this.initTextSettings();
    },

    blur(value) {
      if (value === '') return this.remove();
      this.__save('object', this.text_object.object_id, this.text_object);
    },
  },

  watch: {
    creative_object: {
      handler(a, b) {
        if (b == null) {
          // only if previous value is null - handles module content update on API _inserted response
          this.applyCreativeObjectsToLocalObject();
        }
      },
    },
  },
};
</script>
