import { render, staticRenderFns } from "./Extra.vue?vue&type=template&id=f8d0d142&lang=pug"
import script from "./Extra.vue?vue&type=script&lang=js"
export * from "./Extra.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports